import styled from "styled-components";
import { THEME } from "THEME";

export const PayCard = styled.div`
  width: 100%;
`;

export const LoadingWrapper = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 200;
    opacity: 0.2;
`;

export const PayCardWrapper = styled.div`
  width: 100%;
`;

export const Card = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto 7px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    background-color: #fff;
    opacity: 0.1;
    border-radius: 14px;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
  }
`;
export const CardInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
  transition: opacity ${THEME.TRANSITION};
  opacity: ${props => props.error ? 1 : 0};
`;
export const CardInfoText = styled.p`
  font-weight: bold;
  font-size: 10px;
  line-height: 5px;
  color: ${THEME.COLOR_RED};
  text-align: center;
`;

export const CardWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
`;

export const CardInputNumber = styled.div``;

export const CardInput = styled.input`
    background-color: #fff;
    border-radius: 60px;
    border: solid 1px #fff;
    width: 100%;
    height: 36px;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    color: #97a2ac;
    text-align: left;
`

export const CardInputDate = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 100%;
  margin: 5px 0;
`;

export const CardInputBlock = styled.div`
  position: relative;
  input {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #D5D5D9;
    width: 100%;
    height: 50px;
    font-size: 20px;
    line-height: 28px;
    padding-left: 12px;
    padding-right: 12px;
    color: #3F5366;
    text-align: left;
    opacity: 0.54;

    &::placeholder {
      font-size: 20px;
      line-height: 28px;
      color: #3F5366;
      opacity: 0.54;
      text-align: left;
    }
    
    &:focus {
      border: 1px solid #6270F2;
      color: #616FF1;
      opacity: 1;
    }
  }
`;

export const CardInputIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 20px;
`

export const CardInputTitle = styled.p`
  font-size: 20px;
  line-height: 20px;
  color: #616FF1;
  margin-bottom: 10px;
`;

export const DateLine = styled.p`
  font-size: 25px;
  line-height: 20px;
  color: ${THEME.COLOR_DARK_GRAY};
  opacity: 0.54;
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 13px;
`;

export const WrapperMonth = styled.div`
  input {
    min-width: 100%;
  }
`;

export const WrapperYear = styled.div`
  input {
    min-width: 100%;
  }
`;
export const CardInputBottom = styled.div`
`;

export const WrapperSecure = styled.div`
  max-width: 100%;
  position: relative;

  input {
    min-width: 283px;
    &::placeholder {
     text-align: left; 
    }
  }
`;

export const ButtonCard = styled.div`
  width: 100%;
  margin: 0 auto 16px;
`;

export const CvvInfo = styled.div`
  width: 20px;
  height: 20px;
  font-size: 16px;
  border-radius: 50%;
  background-color: #C2BBA8;
  color: #fff;
  text-align: center;
  margin-top: 2px;
  padding-left: 1px;
  &:hover {
    cursor: pointer;
  }
`

export const CardInputTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const CardInputPowered = styled.div`
  display: flex;
  justify-content: center;
`

export const CardInputPoweredText = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-right: 4px;
  padding-top: 2px;
`
export const CardInputPoweredCompanyName = styled.div`
  max-width: 44px;
  max-height: 18px;
`

export const CardDescription = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin: 30px 0;
  @media(min-width: 500px) {
    font-size: 16px;
    line-height: 28px;
    margin: 40px 0;
  }
`

export const CardSecureContent = styled.section`
  text-align: center;
  margin-bottom: 30px;
  @media(min-width: 960px) {
    margin-bottom: 40px;
  }
`

export const CardTypeWrapper = styled.div`
  max-width: 40px;
  max-height: 12px;
`

export const CardType = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`