export const THEME = {
  COLOR_BLACK: '#191B1F',
  COLOR_BLACK_ALT: '#090909',
  COLOR_LIGHT_BLACK: '#4C4C4C',
  COLOR_DARK_GRAY: '#3F5366',
  COLOR_GREEN: '#0ABFB6',
  COLOR_GRAY: '#ACB1DA',
  COLOR_PURPLE:'#7E80F7',
  COLOR_GRAY_LIGHT: '#F6F6FC',
  COLOR_RED: '#FA5C78',
  COLOR_BROWN: '#F4F1E9',
  COLOR_BROWN_FOCUSED: '#EAE4D1',
  COLOR_BLUE: '#537EE7',
  COLOR_SAND: '#f8f6f0',
  COLOR_LIGHT_GREEN: '#43BB5E',
  COLOR_DARK_GREEN: '#3B5948',
  COLOR_OLD_GREEN: '#D8E6DF',
  COLOR_OLD_GREEN_DARKEN: '#A2C3B2',
  COLOR_GOLD: '#7C7357',
  TRANSITION: '.4s ease-out'
};

export const BACKGROUND_GRADIENT = {
  COLOR_BLUE_NORMAL: 'linear-gradient(90deg, #84C1E9 0%, #8B7BE6 51.04%, #5563E1 100%)',
  COLOR_BLUE_HOVER: 'linear-gradient(90deg, #99D3F9 0%, #A394FD 47.36%, #616FF1 92.79%)',
  COLOR_BLUE_PRESSED: 'linear-gradient(90deg, #84C1E9 0%, #8B7BE6 51.04%, #5563E1 100%)',
  COLOR_PINK_NORMAL: 'linear-gradient(270deg, #FA5C78 0%, #FF6C86 49.48%, #FFABBA 100%)',
  COLOR_PINK_HOVER: 'linear-gradient(270deg, #E64864 0%, #EB5872 49.48%, #EB97A6 100%)',
  COLOR_PINK_PRESSED: 'linear-gradient(270deg, #FA5C78 0%, #FF6C86 49.48%, #FFABBA 100%)',
  EXPLANATION_BACKGROUND: 'radial-gradient(87.2% 64.75% at 104.26% 79.58%, #65CCD5 0%, #B393F7 55.73%, #FF727A 100%)',

  COLOR_BROWN_HOVER: 'linear-gradient(90deg, #EAE4D1 5.28%, #DBD5C4 100%)',
  COLOR_GREEN_PROGRESS: 'linear-gradient(90deg, #537EE7 0%, #43BB5E 100%)',
}