import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { gaGetMyPlanClickButton } from 'analytics/ga';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import Timer from 'components/Timer';
import { createTime } from 'utils';
import { ButtonComponent as Button } from 'components/Button';
import { changeDataProgress } from 'store/actions';
import {
  DiscountExpires,
  DiscountExpiresWrapper,
  DiscountExpiresContent,
  DiscountExpiresButton,
  DiscountExpiresLine
} from './styled';
import { useIsAppVersion } from 'customHooks';

const getPercentScroll = () => {
  const scrollHeight = Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight
  );
  return window.pageYOffset * 100 / (scrollHeight - window.innerHeight);
};

const DiscountExpiresComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { progress, remoteConfig } = useSelector((state) => state);
  const { timer } = progress;
  const { discountSubscriptionTimer } = remoteConfig?.checkoutPage ?? 0;
  const isV4 = useIsAppVersion('v4');
  const isV3 = useIsAppVersion('v3');
  const [percentScroll, setPercentScroll] = useState(getPercentScroll());
  let url = location.search;
  const scrollProgress = () => setPercentScroll(getPercentScroll());
  const urlEmail = url && url.includes('utm_medium=email');

  const goToNextPage = () => {
    if ('/overall-review' === location.pathname || '/v3/overall-review' === location.pathname) {
      sendAmplitudeEvent('click_overall-review_1');
      gaGetMyPlanClickButton();
      const link = isV3 ? 'paid-trial' : 'payment';
      history.push(link);
    } else {
      sendAmplitudeEvent('click_overall-results_1');
      sendAmplitudeEvent('y_quiz_results_getMyPlan_button_click');
      const link = isV4 ? 'email' : 'overall-review';
      history.push(link);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollProgress, true);
    return () => {
      window.removeEventListener('scroll', scrollProgress);
    };
  }, []);

  useEffect(() => {
    (timer === null) && ('end' !== timer) && dispatch(changeDataProgress({ timer: +createTime(discountSubscriptionTimer) }));
  }, [timer]);

  useEffect(() => {
    urlEmail && dispatch(changeDataProgress({ timer: null }));
  }, [urlEmail]);

  const getTimerBlock = () => {
    if (null !== timer && 'end' !== timer) {
      return (
        <>{t('discountExpires.textTimer')}
          <Timer expiryTimestamp={timer}/>
        </>
      );
    }
    return (
      t('discountExpires.text')
    );
  };

  return (
    <DiscountExpires>
      <DiscountExpiresWrapper>
        <DiscountExpiresContent>
          {getTimerBlock()}
        </DiscountExpiresContent>
        <DiscountExpiresButton>
          <Button title={'Get my plan'} onClick={goToNextPage}/>
        </DiscountExpiresButton>
      </DiscountExpiresWrapper>
      <DiscountExpiresLine style={{width: `${percentScroll}%`}}/>
    </DiscountExpires>
  );
};

export default DiscountExpiresComponent;
