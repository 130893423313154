import React, { useEffect, useState } from 'react';
import { reduxForm, reset, clearFields } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { WeightGain } from './styled';
import ChoiceQuizComponent from 'components/Quiz/ChoiceQuiz';
import { getChoiceValues } from 'utils';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils/getNextProgressStep';

const WeightGainComponent = ({ handleSubmit, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formRequired, changeFormRequired] = useState(false);
  const items = t(`weightGain.items`, { returnObjects: true });
  const isV4 = useIsAppVersion('v4');

  const submitForm = (values) => {
    const array = getChoiceValues(values);
    sendAmplitudeEvent('16_weight_gain_event_click', {
      'weight_gain_event': array
    });
    const arrValues = Object.values(values);
    for (let i = 0; i < arrValues.length; i++) {
      if (arrValues[i]) {
        changeFormRequired(false);
        const link = isV4 ? '/v4/health-condition' : 'perfect-weight';
        return history.push(link);
      }
    }
    changeFormRequired(true);
  };

  const resetSelected = () => {
    dispatch(reset('weightGain'));
    changeFormRequired(false);
  };

  const handleSelect = () => {
    dispatch(clearFields('weightGain', false, false, 'reset'));
    changeFormRequired(false);
  };

  useEffect(() => {
    sendAmplitudeEvent('16_weight_gain_event_view');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <WeightGain onSubmit={handleSubmit(submitForm)}>
      <ChoiceQuizComponent
        title={t('weightGain.title')}
        subtitle={t('weightGain.subtitle')}
        items={items}
        reset={t('weightGain.reset', { returnObjects: true })}
        formRequired={formRequired}
        resetSelected={resetSelected}
        handleSelect={handleSelect}
      />
    </WeightGain>
  );
};

export default reduxForm(
  {
    form: 'weightGain',
    destroyOnUnmount: false
  }
)(WeightGainComponent);
