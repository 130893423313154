import styled, {keyframes} from "styled-components";

const fadeIn = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  display: block;
  width: 80px;
  margin: 0 auto;
  height: 80px;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #0fbfb6;
      border-color: #0fbfb6 transparent #0fbfb6 transparent;
      animation: ${fadeIn} 1.2s linear infinite;
  }
`;

export const LoaderWrapper = styled.div`
    height: 100%;
    width: 100%;
`