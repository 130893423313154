import React, { useEffect, useState } from 'react';
import { changeDataProgress } from 'store/actions';
import { useDispatch } from 'react-redux';
import { ButtonComponent as Button } from 'components/Button';
import Input from 'components/Auth/Input';
import { recoveryPassword } from 'api'

import {
  RecoveryPassword,
  RecoveryPasswordContent,
  RecoveryPasswordTitle,
  RecoveryPasswordText,
  RecoveryPasswordForm,
  RecoveryPasswordNotificationBlock,
  RecoveryPasswordError,
  RecoveryPasswordNotification
} from './styled';

const RecoveryPasswordComponent = () => {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState('');
  const [error, setError] = useState('');

  const updateNotification = (message) => {
    setNotification(message);
  }

  const updateError = (message) => {
    setError(message);
  };

  const hideNotification = () => {
    setError('');
    setNotification('');
  };

  const validate = email => {
    return (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
  };

  const submitForm = (e) => {
    e.preventDefault();
    const email = e.target[0].value;

    if (true === validate(email)) {
      recoveryPassword(email).then(res => {
        if (res.status === 200) {
          updateNotification('Password sent to email');
        }
        if (res.status === 400) {
          updateError(res.data.message);
        }
      });
      return;
    }
    updateError('Email not valid');
  };

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, []);

  return (
    <RecoveryPassword>
      <RecoveryPasswordContent>
        <RecoveryPasswordTitle>Recovery Password</RecoveryPasswordTitle>
        <RecoveryPasswordText>Enter your email, we will send the password to your mail</RecoveryPasswordText>
        <RecoveryPasswordForm onSubmit={submitForm}>
          <Input type='text' name='email' placeholder='mail@website.com' title='Your email*' onChange={hideNotification}/>
          <RecoveryPasswordNotificationBlock>
            <RecoveryPasswordError>{error}</RecoveryPasswordError>
            <RecoveryPasswordNotification>{notification}</RecoveryPasswordNotification>
          </RecoveryPasswordNotificationBlock>
          <Button title='Restore' type='submit'/>
        </RecoveryPasswordForm>
      </RecoveryPasswordContent>
    </RecoveryPassword>
  );
};

export default RecoveryPasswordComponent;