import styled from 'styled-components';
import { THEME } from 'THEME';

export const InputTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  @media (min-width: 960px) {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
`;

export const InputValue = styled.input`
  border-radius: 12px;
  border: 2px solid rgba(234, 228, 209, 0);
  height: 48px;
  width: 100%;
  padding-left: 20px;
  padding-right: 50px;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_BLACK};
  transition: border ${THEME.TRANSITION}, background-color ${THEME.TRANSITION};

  &:focus {
    border: 2px solid rgba(234, 228, 209, 0);
    background-color: ${THEME.COLOR_BROWN_FOCUSED};
  }

  @media (min-width: 960px) {
    height: 64px;
  }

  ::-webkit-input-placeholder {
    color: #d8d8db;
  }

  ::-moz-placeholder {
    color: #d8d8db;
  }

  :-moz-placeholder {
    color: #d8d8db;
  }

  :-ms-input-placeholder {
    color: #d8d8db;
  }
`;

export const InputBody = styled.div`
  position: relative;
`;

export const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);
`;

export const Input = styled.div`
  width: 100%;
  position: relative;
  
  ${InputValue} {
    background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN};

    @media (min-width: 960px) {
      &:hover {
        border: ${props => props?.isV4 ? `2px solid ${THEME.COLOR_DARK_GREEN}` : '2px solid rgba(234, 228, 209, 1)'}
      }

      &:focus {
        border: ${props => props?.isV4 ? `2px solid ${THEME.COLOR_DARK_GREEN}` : '2px solid rgba(234, 228, 209, 0)'}
        background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN_FOCUSED};
      }
    }

    ::-webkit-input-placeholder {
      color: #d8d8db;
    }

    ::-moz-placeholder {
      color: #d8d8db;
    }

    :-moz-placeholder {
      color: #d8d8db;
    }

    :-ms-input-placeholder {
      color: #d8d8db;
    }
  }
`;
