import React from "react";

import {
  CountJoin,
  CountJoinWrapper,
  CountJoinContent,
  CountJoinTitle,
  CountJoinText,
  CountJoinImageLeft,
  CountJoinImageRight
} from "./styled";

const CountJoinComponent = () => {
  return <CountJoin>
    <CountJoinWrapper>
      <CountJoinImageLeft src={`/images/CountJoinMale.png`}/>
      <CountJoinContent>
        <CountJoinTitle>Start losing weight right now!</CountJoinTitle>
        <CountJoinText>Join <span>149593</span> Of Our Satisfied Users</CountJoinText>
      </CountJoinContent>
      <CountJoinImageRight src={`/images/CountJoinFemale.png`}/>
    </CountJoinWrapper>
  </CountJoin>;
};

export default CountJoinComponent;
