import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, initialize } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { changeProgressStep, setProfileData } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import validate from './validate';
import { Weight } from './styled';
import { getNextProgressStep, resetField, transformWeight } from 'utils';
import WeightQuiz from 'components/Quiz/WeightQuiz';

const Weigh = ({ handleSubmit, dispatch }) => {
  const { targetWeight } = useSelector((state) => state.form);
  const { height } = useSelector((state) => state.form);
  const { measure } = targetWeight.values;
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  const textWeight = `measure.${measure}.weight`;

  const submitForm = (data) => {
    const { currentMeasure, weight } = data;
    const desiredWeight = transformWeight(currentMeasure, measure, weight);
    dispatch(setProfileData({ desiredWeight }));
    sendAmplitudeEvent('5_click_desired_weight', {
      'Target.Weight': desiredWeight
    });
    if (!height) {
      dispatch(initialize('height', { measure }));
    }
    history.push('/height');
  };

  useEffect(() => {
    sendAmplitudeEvent('5_view_desired_weight');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  useEffect(() => {
    if (!targetWeight?.fields?.weight) {
      return;
    }

    const errorResult = validate(targetWeight.values);

    if ('error' === errorResult.weight) {
      setError(() => 'Your target weight should be lower than current');
      return;
    }

    if ('imperial' === measure && 'difference' === errorResult.weight) {
      setError(() => 'Your weight difference should be from 6 to 88 lbs');
      return;
    }

    if ('metric' === measure && 'difference' === errorResult.weight) {
      setError(() => 'Your weight difference should be from 3 to 40 kg');
      return;
    }

    if ('imperial' === measure && errorResult.weight) {
      setError(() => 'Please enter a value between 90 and 550');
      return;
    }

    if ('metric' === measure && errorResult.weight) {
      setError(() => 'Please enter a value between 40 and 250');
      return;
    }

    return setError('');
  });

  const moveReset = () => {
    return resetField(dispatch, 'targetWeight', {
      weight: ''
    });
  };

  return (
    <Weight onSubmit={handleSubmit(submitForm)}>
      <WeightQuiz
        title={t('weight.title')}
        inputTitle={`${t('weight.desiredWeight')} (${t(textWeight)})`}
        placeholder={`00 (${t(textWeight)})`}
        error={error}
        measure={measure}
        moveReset={moveReset}
      />
    </Weight>
  )
}

export default reduxForm({
  form: 'targetWeight',
  destroyOnUnmount: false,
  validate
})(Weigh);
