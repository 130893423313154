import styled from "styled-components";
import { THEME } from "THEME";

export const StartingWrapper = styled.div`
`;
export const StartingV3Wrapper = styled.div`
`;

export const StartingFooter = styled.div`
  background-color: #F9F9FF;
`

export const StartingFooterContainer = styled.div`
    width: 100%;
    max-width: 960px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 30px auto 0;
    min-height: 96px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    
    @media (min-width: 960px) {
      flex-direction: row;
      justify-content: space-between;
      min-height: 64px;
    }
`;

export const AllRightsReserved = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.BLACK};
  margin-top: 8px;
  @media (min-width: 960px) {
    margin-top: 0;
  }
`;

export const SupportEmail = styled.a`
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.COLOR_PURPLE};
  transition: color ${THEME.TRANSITION};

  &:hover {
    color: ${THEME.COLOR_PURPLE};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto 90px auto;
  padding: 0 16px;
`