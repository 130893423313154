import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setVisitCookie, checkVisitCookie } from 'helper/cookie';
import { sendAmplitudeEvent, setUserProperties } from 'analytics/amplitude';
import { changeDataProgress, setRemoteConfig, setUrlLinkRemoteConfig } from 'store/actions';
import config from 'config';
import { gaFirstVisit } from 'analytics/ga';
import { gaFirstClick } from 'analytics/ga';
import {
  StartingFooter,
  AllRightsReserved,
  SupportEmail,
  StartingFooterContainer,
  StartingWrapper,
  Wrapper
} from './styled';
import {
  ExplanationWork,
  Benefits,
  GetMyPlan,
  Feedback,
  AppStore,
  HelpFaq
} from 'components/Landing';
import Nutrition from 'components/Nutrition';
import { LastaSlider } from 'components/Slider';
import SideBenefits from 'components/SideBenefits';
import { useTranslation } from 'react-i18next';
import { DisclaimerText } from 'components/Reviews/styled';
import { getRemoteConfig } from 'remoteConfig';
import { useGetGaExperimentId } from 'customHooks';

export const StartingV4 = () => {
  const routeMatch = useRouteMatch();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { hasBeenToPaymentBefore } = useSelector(state => state.user);
  const landing = t('landingV4', { returnObjects: true });
  const { features, trackers, slideButtonText } = landing;
  const cookieName = 'mpofv';
  const { search } = window.location;
  const routing = routeMatch?.params?.id && `/${routeMatch?.params?.id+search}`;
  const experimentIdData = useGetGaExperimentId();

  const goToQuiz = (event) => {
    sendAmplitudeEvent(event);
    gaFirstClick();
    const link = !hasBeenToPaymentBefore ? '/v4/welcome' : '/v4/payment';
    history.push(link);
  };

  useEffect(() => {
    dispatch(setUrlLinkRemoteConfig(routing));
    setUserProperties({ ...experimentIdData });
    dispatch(changeDataProgress({ visible: false }));
    getRemoteConfig('v4', routeMatch.params.id).then((data) => {
      dispatch(setRemoteConfig(data));
    });
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);

  useEffect(() => {
    sendAmplitudeEvent('MealPlan_home_page_view');
    if (undefined === checkVisitCookie(cookieName)) {
      gaFirstVisit();
      setVisitCookie(cookieName);
    } else {
      sendAmplitudeEvent('site_open');
    }
  }, []);

  const handleClick = () => {
    sendAmplitudeEvent('lp_support_email_click');
  };

  const currentYear = new Date(Date.now()).getFullYear();
  return <StartingWrapper>
    <GetMyPlan handleClick={() => goToQuiz('MealPlan_home_page_get_my_plan_click')}/>
    <ExplanationWork handleClick={goToQuiz}/>
    <Nutrition/>
    <LastaSlider
      items={features.items}
      title={features.title}
      buttonText={slideButtonText}
      handleClick={() => goToQuiz('MealPlan_home_page_try_now_click')}
    />
    <Benefits/>
    <Wrapper>
      <SideBenefits/>
    </Wrapper>
    <LastaSlider
      items={trackers.items}
      title={trackers.title}
      buttonText={slideButtonText}
      backgroundColor={'#3B5948'}
      dotColor={'#D8E6DF'}
      titleColor={'#D8E6DF'}
      textColor={'#FFF'}
      handleClick={() => goToQuiz('MealPlan_home_page_try_now_click')}
    />
    <Feedback/>
    <AppStore
      buttonText={slideButtonText}
      handleClick={() => goToQuiz('MealPlan_home_page_try_now_click')}
    />
    <HelpFaq/>
    <DisclaimerText>{t('disclaimer')}</DisclaimerText>
    <StartingFooter>
      <StartingFooterContainer>
        <AllRightsReserved>
          ©{currentYear} Your Meal Plan. All rights reserved.
        </AllRightsReserved>
        <SupportEmail onClick={handleClick} href={`mailto:${config.supportEmail}`}>{config.supportEmail}</SupportEmail>
      </StartingFooterContainer>
    </StartingFooter>
  </StartingWrapper>
};