import styled from "styled-components";
import { THEME } from "THEME";

export const Header = styled.header`
  width: 100%;
  background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_SAND};
  position: relative;
  z-index: 40;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1402px;
  margin: auto;
  padding: 0 16px;
  min-height: 48px;
  @media (min-width: 960px) {
    min-height: 64px;
  }
`;

export const LogoText = styled.p`
  font-weight: 700;
  letter-spacing: -0.02em;
  color: ${THEME.COLOR_BLACK};
  font-size: 15px;
  line-height: 21px;
  margin-left: 7px;
  transition: color ${THEME.TRANSITION};
  @media (min-width: 960px) {
    margin-left: 10px;
    font-size: 20px;
    line-height: 28px;
  }
`;

export const LogoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 5px;
`;

export const LogoSet = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 1;
  @media (min-width: 960px) {
    &:hover ${LogoText} {
      color: #537EE7;
    }
  }
`;

export const HeaderRight = styled.div`
  //position: relative;
  z-index: 3;
`;

export const MenuBtn = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 14px;
  position: relative;
  z-index: 5;
  
  @media (max-width: 959px) {
     display: flex;
  }

  &:before, &:after, i {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    display: block;
    right: 0;
    background-color: ${THEME.COLOR_BLACK};
    border-radius: 2px;
    transition: width ${THEME.TRANSITION}, transform ${THEME.TRANSITION}, top ${THEME.TRANSITION}, bottom ${THEME.TRANSITION};
  }

  i {
    width: ${props => props.open ? "0" : "100%"};
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    top: ${props => props.open ? "6px" : "0"};
    transform: ${props => props.open ? "rotate(-45deg)" : "rotate(0)"};
  }

  &:after {
    bottom: ${props => props.open ? "6px" : "0"};
    transform: ${props => props.open ? "rotate(45deg)" : "rotate(0)"};
  }
`;
