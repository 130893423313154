import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep, setProfileData } from 'store/actions';
import { MealsPerDay } from './styled';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { getNextProgressStep } from 'utils';

const MealsPerDayComponent = ({ dispatch }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const items = t(`mealsPerDay.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const mealsPerDay = data.target.value;
    dispatch(setProfileData({ mealsPerDay }));
    sendAmplitudeEvent('21_click_meal_per_day', {
      'Meals_aDay': mealsPerDay
    });
    history.push('/vegetables');
  };

  useEffect(() => {
    sendAmplitudeEvent('31_view_meal_per_day');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <MealsPerDay>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('mealsPerDay.title')}
        subtitle={t('mealsPerDay.subtitle')}
        name={'diet'}
      />
    </MealsPerDay>
  );
};

export default reduxForm(
  {
    form: 'mealsPerDay',
    destroyOnUnmount: false
  }
)(MealsPerDayComponent);
