import styled from "styled-components";

export const HealthCondition = styled.form`
  width: 100%;
  
  @media (max-width: 991px) {
    margin-bottom: 50px;
  }
`;

