import styled from "styled-components";
import {NavLink} from "react-router-dom";
import { THEME } from "THEME";

const activeClassName = 'nav-item-active'

export const StyledLink = styled(NavLink).attrs({activeClassName})`
  color: ${THEME.COLOR_BLACK};
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 20px;

  &.${activeClassName} {
    color: #537EE7;
  }

  @media (min-width: 960px) {
    margin: 0 15px 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    z-index: 3;
    transition: color ${THEME.TRANSITION};

    &:hover {
      color: #537EE7;
    }
  }
`;

export const Menu = styled.nav`
  position: absolute;
  top: 0;
  transition: right ${THEME.TRANSITION}, opacity ${THEME.TRANSITION}, visibility ${THEME.TRANSITION};
  right: ${props => props.visibleMenu ? "0" : "-100vw"};
  opacity: ${props => props.visibleMenu ? "1" : "0"};
  visibility: ${props => props.visibleMenu ? "visible" : "hidden"};
  width: 100vw;
  height: 100vh;
  background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_SAND};
  padding: 74px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 960px) {
    position: relative;
    right: auto;
    top: auto;
    opacity: 1;
    visibility: visible;
    width: auto;
    height: auto;
    flex-direction: row;
    padding: 0;
  }
`;

export const DroppingName = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.COLOR_BLACK};
  display: none;
  @media (min-width: 960px) {
    display: inline;
    cursor: pointer;
    position: relative;
    z-index: 3;
    transition: color ${THEME.TRANSITION};
  }
`;

export const DroppingArrow = styled.div`
  width: 8.5px;
  height: 8.5px;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  display: none;
  @media (min-width: 960px) {
    display: flex;
    color: inherit;
    transition: transform ${THEME.TRANSITION}, color ${THEME.TRANSITION};
    transform: rotate(0);
    position: relative;
    z-index: 3;
  }
`;

export const DroppingList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 960px) {
    position: absolute;
    top: 90%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    align-items: flex-start;
    padding: 25px 15px 16px;
    transition: opacity ${THEME.TRANSITION}, visibility ${THEME.TRANSITION}, top ${THEME.TRANSITION} ;

    ${StyledLink} {
      text-align: left;
      margin: 0 0 10px 0;
    }
  }
`;

export const Dropping = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  @media (min-width: 960px) {
    padding-left: 16px;
    padding-right: 16px;
    &:hover ${DroppingList} {
      opacity: 1;
      visibility: visible;
      top: calc(100% - 1px);
    }

    &:hover ${DroppingArrow} {
      color: #537EE7;
      transform: rotate(90deg);
    }

    &:hover ${DroppingName} {
      color: #537EE7;
    }
  }
`;

export const DroppingShadow = styled.span`
  display: none;
  @media (min-width: 960px) {
    position: absolute;
    display: block;
    top: -30px;
    left: 0;
    width: 100%;
    height: calc(100% + 30px);
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
  }
`

export const LoginLink = styled(NavLink)`
    background: #FFFFFF;
    border: 1.5px solid #7E80F7;
    box-sizing: border-box;
    border-radius: 90px;
    color: #7E80F7;
    margin: 0 0 0 15px;
    padding: 10px 25px 10px 25px;
    font-size: 16px;
    font-weight: 600;
    
    &:hover {
      color: #fff;
      background: linear-gradient(90deg, #99D3F9 0%, #A394FD 47.36%, #616FF1 92.79%);
      border:none;
      padding: 10px 26.5px 10px 26.5px;
    }
`



