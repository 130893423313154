import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useHistory} from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep, setProfileData } from 'store/actions';
import { Workout } from './styled';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { getNextProgressStep } from 'utils';

const WorkoutComponent = ({ dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const itemsWorkout = t(`workout.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const workoutType = data.target.value;
    const workoutTitle = data.target.title;
    dispatch(setProfileData({ workoutType }));
    sendAmplitudeEvent('20_click_workout', {
      'Workout': workoutTitle
    });
    history.push('/feel');
  };

  useEffect(() => {
    sendAmplitudeEvent('20_view_workout');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);
  return (
    <Workout>
      <StandardQuizComponent
        items={itemsWorkout}
        handleSelect={handleSelect}
        title={t('workout.title')}
        subtitle={t('workout.subtitle')}
        name={'workout'}
      />
    </Workout>
  );
};

export default reduxForm(
  {
    form: 'workout',
    destroyOnUnmount: false
  }
)(WorkoutComponent);
