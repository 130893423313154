import { EmailWrapper } from "./styled";
import { useDispatch } from "react-redux";
import { changeDataProgress } from "store/actions";
import { useEffect } from "react";
import EmailResultsComponent from "components/Payment/EmailForm/EmailResults/EmailResults";

const Email = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, [])

  return (
    <EmailWrapper>
      <EmailResultsComponent/>
    </EmailWrapper>
  )
}
export default Email;
