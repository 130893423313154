import React from 'react';
import { Field } from 'redux-form';
import {
  Checkbox,
  CheckboxTitle,
  CheckboxImg,
  CheckboxBackground
} from './styled';
import { useIsAppVersion } from 'customHooks';

const CheckboxComponent = ({ title, imageName, name, value, type, handleSelect }) => {
  const isV4 = useIsAppVersion('v4') && '/v4/payment' !== window.location.pathname;
  return (
    <Checkbox onClick={handleSelect} isV4={isV4}>
      <CheckboxTitle>{title}</CheckboxTitle>
      {imageName && <CheckboxImg src={`/images/${imageName}`}/>}
      <Field name={name} type={type} title={title} value={value} component='input'/>
      <CheckboxBackground/>
    </Checkbox>
  );
};
export default CheckboxComponent;
