import { yearDiff } from 'utils';

const validate = values => {
  const errors = {};

  if (!values || !values.birthDate) {
    errors.birthDate = 'required'
  } else if (yearDiff(values.birthDate) < 18 || yearDiff(values.birthDate) > 99) {
    errors.birthDate = 'error'
  }

  return errors;
}

export default validate;
