import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import { ButtonComponent as Button } from 'components/Button';
import {
  ChooseYourPlan,
  ChooseYourPlanWrapper,
  ChooseYourPlanItems,
  ChooseYourPlanSubtitle
} from './styled';
import { isNullish, scrollTo } from 'utils';
import { PaymentSectionTitle } from 'pages/Payment/styled';
import { useDispatch, useSelector } from 'react-redux';
import { changePaymentPlan } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { ChooseYourPlanItem } from './components/ChooseYourPlanItem';

const ChooseYourPlanComponent = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { progress, remoteConfig, form } = useSelector((state) => state) || {};
  const { subscriptionCardsBlock } = remoteConfig?.checkoutPage || {};
  const { h1, subHeader, buttonText, cards } = subscriptionCardsBlock || {};
  const { chooseYourPlan } = form?.chooseYourPlan?.values || {};
  const [isShowPopup, setIsShowPopup] = useState('0' === chooseYourPlan);
  const isCardBlock = !isNullish(subscriptionCardsBlock);
  const { timer } = progress || {};
  const isDiscountTime = 'end' !== timer;
  const items = cards || {};

  const handleSelect = (e) => {
    sendAmplitudeEvent(`MP_Checkout_Subscription_Card${Number(e.target.value)+1}_click`);
    setIsShowPopup('0' === e.target.value);
    dispatch(changePaymentPlan({
      chooseYourPlan: items[e.target.value],
    }));
  };

  const submitForm = () => {
    scrollTo('payment');
  };

  return (
    <>
      {isCardBlock && <ChooseYourPlan onSubmit={handleSubmit(submitForm)}>
        <ChooseYourPlanWrapper>
          <PaymentSectionTitle>{h1}</PaymentSectionTitle>
          <ChooseYourPlanSubtitle>{subHeader}</ChooseYourPlanSubtitle>
          <ChooseYourPlanItems>
            {items.map(({ title, defaultPrice, discountPrice, discountPriceDuration, defaultPriceDuration, discountPercent }, count) =>
              <ChooseYourPlanItem
                key={count}
                title={title}
                discountPrice={discountPrice}
                defaultPrice={defaultPrice}
                discountPriceDuration={discountPriceDuration}
                defaultPriceDuration={defaultPriceDuration}
                discountPercent={discountPercent}
                isDiscountTime={isDiscountTime}
                handleSelect={handleSelect}
                id={count.toString()}
                isShowPopup={isShowPopup}
              />)}
          </ChooseYourPlanItems>
          <Button title={buttonText} type='submit'/>
          </ChooseYourPlanWrapper>
      </ChooseYourPlan>}
    </>
  );
};

export default reduxForm(
  {
    form: 'chooseYourPlan',
    initialValues: { chooseYourPlan: '0' },
    destroyOnUnmount: false
  }
)(ChooseYourPlanComponent);