import styled from "styled-components";
import { THEME } from "THEME";

export const InfoTile = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  background: #F4F2EE;
  border-radius: 12px;
  max-width: 227px;
  height: ${props => props?.height ? '200px' : 'auto'};
  flex: 1;
  @media (min-width: 991px) {
    padding: 24px;
  }

  @media (max-width: 499px) {
    height: ${props => props?.height ? 'auto' : 'auto'};
  }
`;

export const InfoTileContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const InfoTileIcon = styled.div`
  width: 44px;
  min-width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-bottom: 20px;
`;

export const InfoTileImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
`;

export const InfoTilePlaceholder = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: ${THEME.COLOR_DARK_GRAY};
  @media (min-width: 991px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2px;
  }
`;
export const InfoTileText = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
  color: ${THEME.COLOR_BLACK};
  @media (min-width: 991px) {
    font-size: 20px;
    line-height: 28px;
    margin-top: 15px;
  }
`;
