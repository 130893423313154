import React, { useState } from "react";
import { ButtonComponent } from "components/Button";
import { useSelector } from "react-redux";
import config from "config";
import PaymentPopup from "components/Popup/Payment";

import {
    PeriodExpired,
    PeriodExpiredImage,
    PeriodExpiredTextBlock,
    PeriodExpiredHeader,
    PeriodExpiredText,
    PeriodExpiredButtonBlock
} from './styled'

const PeriodExpiredComponent = () => {
    const {subscription} = useSelector((state) => state);
    const {email} = useSelector((state) => state.profile);
    const [showPopup, updateStatePopup] = useState(false)

    const successHandler = () => () => {
        updateStatePopup(false)
    }

    const handlePayment = () => {
        // if (email != undefined) {
        //     if ('production' !== process.env.NODE_ENV) {
        //         Paddle.Environment.set('sandbox');
        //     }
        //
        //     Paddle.Setup({vendor: config.paddleVendorId});
        //     Paddle.Checkout.open({
        //         product: config.paddleProductId,
        //         email: email,
        //         successCallback: (data, err) => {
        //         }
        //     })
        // }
    }

    return (
        <PeriodExpired>
            <PeriodExpiredImage src={'/images/periodExpired/main.svg'}/>
            <PeriodExpiredTextBlock>
                {subscription.trial && (
                    <PeriodExpiredHeader>Your trial period has expired</PeriodExpiredHeader>
                )}
                {subscription.premium && (
                    <PeriodExpiredHeader>Your subscription has expired</PeriodExpiredHeader>
                )}
                <PeriodExpiredText>Please update your payment method to <br /> continue using YourMealPlan!</PeriodExpiredText>
            </PeriodExpiredTextBlock>
            { 'stripe' === subscription.provider && (
                <PeriodExpiredButtonBlock>
                    <ButtonComponent
                        title="Continue"
                        type="submit"
                        onClick={() => updateStatePopup(true)}
                    />
                </PeriodExpiredButtonBlock>
            )}
            { 'paddle' === subscription.provider && (
                <PeriodExpiredButtonBlock>
                    <ButtonComponent
                        title="Continue"
                        type="submit"
                        onClick={() => handlePayment()}
                    />
                </PeriodExpiredButtonBlock>
            )}
            {
                showPopup
                    ? <PaymentPopup
                        handle={successHandler}
                    />
                    : <div></div>
            }
        </PeriodExpired>
    )
}

export default PeriodExpiredComponent;