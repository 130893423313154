import styled from "styled-components";
import { THEME } from "THEME";

export const GuaranteeBlock = styled.div`
  color: ${THEME.COLOR_BLACK};
  background-color: #D9F1DF;
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  padding: 0 10px;
  border-radius: 12px;
`;

export const GuaranteeBlockWrapper = styled.div`
  width: 100%;
  max-width: 1002px;
  margin: auto;
`;

export const GuaranteeBlockContent = styled.div`
  position: relative;
  z-index: 3;
  padding: 16px 32px;
  @media(min-width: 960px) {
    padding: 70px 16px;
    max-width: 570px;
    margin: auto;
  }
`;

export const GuaranteeBlockTitle = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #191B1F;
  margin-bottom: 30px;
  
  @media(max-width: 768px) {
    font-size: 24px;
    line-height: 130%;
  }
`;

export const GuaranteeBlockText = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3F5366;

  @media(max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
  
  @media(min-width: 490px) {
    text-align: ${props => props.isPaymentPage ? 'left': 'center'};
  }

  p {
    margin-bottom: 20px;
    color: #3F5366;
    text-align: ${props => props.isPaymentPage ? 'left': 'center'};

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${THEME.COLOR_BLUE};
  }
`;

export const GuaranteeImg = styled.img`
  position: absolute;
  content: '';
  top: -100px;
  right: -35%;
  z-index: 1;
  width: 143px;
  height: 143px;
  
  @media (max-width: 960px) {
    position: static;
    margin-top: 25px;
  }
  
  @media (max-width: 768px) {
    width: 103px;
    height: 103px;
  }
`

export const GuaranteeImageBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;