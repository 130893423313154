import styled from "styled-components";

export const DashboardHeaderWrapper = styled.div`
    height: 64px;
    display: flex;
    justify-content: space-between;
`

export const DashboardHeaderLogo = styled.div`
    width: 63px;
`

export const DashboardHeaderLogoImage = styled.img`
  margin: 17px 10px 10px 13px;
  width: 34px;
  height: auto;
  display: block;
`

export const DashboardHeaderBell = styled.div`
    margin: 22px 30px 20px 20px;
`

export const DashboardHeaderBellImage = styled.img`
    border-radius: 10px;
`

export const DashboardHeaderNavigation = styled.div`
`

export const DashboardHeaderText = styled.div`
    font-size: 18px;
    font-weight:bold;
    margin: 22px 0 0 25px
`

export const DashboardHeaderAvatarBlock = styled.div`
    width: 36px;
    height: 36px;
    margin: 15px 10px 0 0;
`

export const DashboardHeaderAvatar = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 18px;
`

export const DashboardHeaderRightBlock = styled.div`
    display: flex;
    margin: 0 30px 0 0;
`

export const DashboardHeaderLeftBlock = styled.div`
    display: flex;
`