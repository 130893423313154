import React from 'react';
import { useTranslation } from "react-i18next";
import {
  FastingDescriptionWrapper,
  FastingDescriptionTitle,
  FastingDescriptionImagesBlock,
  FastingDescriptionImagSalad,
  FastingDescriptionImagPhone
} from './styled';

const FastingDescription = () => {
  const { t } = useTranslation();
  const fastingDescription= t(`fastingDescription`, { returnObjects: true });

  const { salad, phone } = fastingDescription;
  const saladSrc = `/images/fastingDescription/${salad.src}`;
  const saladSrcSet = `/images/fastingDescription/${salad.src} 320w, /images/fastingDescription/${salad.src2x} 480w`;
  const phoneSrc = `/images/fastingDescription/${phone.src}`;
  const phoneSrcSet = `/images/fastingDescription/${phone.src} 320w, /images/fastingDescription/${phone.src2x} 480w`;
  return (
    <FastingDescriptionWrapper>
      <FastingDescriptionTitle>{t("fastingDescription.title")}</FastingDescriptionTitle>
      <FastingDescriptionImagesBlock>
        <FastingDescriptionImagSalad
          src={saladSrc}
          srcSet={saladSrcSet}
        />
        <FastingDescriptionImagPhone
          src={phoneSrc}
          srcSet={phoneSrcSet}
        />
      </FastingDescriptionImagesBlock>

    </FastingDescriptionWrapper>
  )
}

export default FastingDescription;