import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import store from 'store';
import RootRoutesComponent from 'components/RootRoutes';
import RootComponent from 'components/RootComponent';
import GlobalStyle from './styles';
import Analytics from 'react-router-ga';
import config  from 'config';
import { HeaderStrategy } from './components/HeaderStrategy';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { saveState } from './localStorage';
import { setInitialBizzOffersCookie } from './analytics/bizzOffers';
import { storeUtmParams } from 'utils/storeUtmParams';

export const history = createBrowserHistory();
const supportsHistory = 'pushState' in window.history;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

store.subscribe(() => {
  const { user, progress, form, profile, subscription, paymentPlan, onboardResult, mealProgress, remoteConfig } = store.getState();
  saveState({ user, progress, form, profile, subscription, paymentPlan, onboardResult, mealProgress, remoteConfig }, 'reduxState');
});
storeUtmParams();

const App = () => {
  const url = window.location.search;
  const { form } = store.getState();
  if ((0 === Object.keys(form).length)) {
    url && url.includes('utm_medium=email') && history.push('/');
  }

  useEffect(() => {
    setInitialBizzOffersCookie();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <BrowserRouter basename='/' forceRefresh={!supportsHistory}>
          <Analytics id={config.gaid}>
            <RootComponent>
              <Suspense fallback={null}>
                <GlobalStyle/>
                <HeaderStrategy />
                <RootRoutesComponent/>
              </Suspense>
            </RootComponent>
          </Analytics>
        </BrowserRouter>
      </Provider>
    </Elements>

  );
};

export default App;
