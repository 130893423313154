import React from 'react';
import './slick.css';
import './slick-theme.css';
import {
  CustomDot,
  CustomDotIndex,
  Title,
  LastaSlickSlider,
  SliderFirstText,
  SliderItemImage,
  SliderItemTextWrapper,
  SliderItemTitle,
  SliderItemWrapper,
  SliderSecondText,
  SliderWrapper,
  Wrapper
} from './styled';
import { ButtonComponent as Button } from 'components/Button';
import { useSelector } from 'react-redux';

const SliderItem = ({ image, title, textFirst, textSecond, buttonText, handleClick, srcSet }) => {
  return (
    <SliderItemWrapper>
      <SliderItemImage
        srcSet={srcSet}
        src={image}
        alt='slider'
        loading='lazy'
      />
      <SliderItemTextWrapper>
        <SliderItemTitle>{title}</SliderItemTitle>
        <SliderFirstText>{textFirst}</SliderFirstText>
        {textSecond && <SliderSecondText>{textSecond}</SliderSecondText>}
        <Button title={buttonText} type='button' onClick={handleClick}/>
      </SliderItemTextWrapper>
    </SliderItemWrapper>
  );
};

export const LastaSlider = ({ items, backgroundColor, title, titleColor, textColor, dotColor, buttonText, handleClick }) => {
  const { isMobile } = useSelector((state) => state.user);
  const sliderDots = items.map(({title}) => title);
  const settings = {
    className: 'center',
    infinite: true,
    vertical: !isMobile,
    dots: !isMobile,
    appendDots: dots => (
      <ul>{dots}</ul>
    ),
    customPaging: i => {
      return(
        <div>
          <CustomDotIndex>0{i+1}</CustomDotIndex>
          <CustomDot>{sliderDots[i]}</CustomDot>
        </div>
      );
    },
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 10000
  };

  return(
    <SliderWrapper backgroundColor={backgroundColor}>
      <Wrapper
        backgroundColor={backgroundColor}
        dotColor={dotColor}
        textColor={textColor}
      >
        <Title titleColor={titleColor}>{title}</Title>
        <LastaSlickSlider {...settings}>
          {items.map(({image, title, textFirst, textSecond}, index) => {
            return(
              <SliderItem
                key={index}
                title={title}
                textFirst={textFirst}
                textSecond={textSecond}
                image={`${image}.png`}
                srcSet={`${image}.png 320w, ${image}@2x.png 480w`}
                buttonText={buttonText}
                handleClick={handleClick}
              />
            );
          })}
        </LastaSlickSlider>
      </Wrapper>
    </SliderWrapper>
  );
};