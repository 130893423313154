import styled from 'styled-components';

export const PopoverContent = styled.div`
  margin-right: -20px;
  max-width: 307px;
  min-width: 264px;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #EAE4D1;
  border: unset;
  @media(min-width: 960px) {
    padding: 12px 16px;
  }
  box-shadow: none;
`

export const PopoverArrow = styled.div`
  .arrow {
     position: absolute;
     width: 0; 
     height: 0; 
     border-left: 10px solid transparent;
     border-right: 10px solid transparent;
     border-bottom: 8px solid #EAE4D1;
     z-index: 2;
     &::after, &::before {
       content: unset;
     }
  }
`

export const PopoverText = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: #3F5366;
  font-weight: 500;
   @media(min-width: 960px) {
      font-size: 13px;
      line-height: 16px;
    }
`