import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { Discomfort } from './styled';
import YesNoQuizComponent from 'components/Quiz/YesNoQuiz';
import { getNextProgressStep } from 'utils';

const DiscomfortComponent = ({dispatch}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleSelect = (data) => {
    const discomfort = data.target.value;
    sendAmplitudeEvent('12_click_stomach', {
      'Stomach_discomfort': discomfort
    });
    history.push('/typical-lunch');
  };

  useEffect(() => {
    sendAmplitudeEvent('12_view_stomach');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <Discomfort>
      <YesNoQuizComponent
        title={t('discomfort.title')}
        name={'discomfort'}
        handleSelect={handleSelect}
      />
    </Discomfort>
  );
};

export default reduxForm(
  {
    form: 'discomfort',
    destroyOnUnmount: false
  }
)(DiscomfortComponent)
