import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, reset, clearFields, change } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { MeatFish } from './styled';
import ChoiceQuizComponent from 'components/Quiz/ChoiceQuiz';
import { getChoiceValues, scrollTo } from 'utils';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils/getNextProgressStep';

const MeatFishComponent = ({ handleSubmit, dispatch, history }) => {
  const { t } = useTranslation();
  const [formRequired, changeFormRequired] = useState(false);
  const itemsMeatFish = t(`meatFish.items`, { returnObjects: true });
  const { allergies } = useSelector((state) => state.form);
  const { fish, red_meat } = allergies ? allergies.values : {};
  const { diets } = useSelector((state) => state.form);
  const vegetarian = diets ?  'vegetarian' === diets.values.diet : {};
  const isV4 = useIsAppVersion('v4');

  const submitForm = (values) => {
    const array = getChoiceValues(values);
    sendAmplitudeEvent('35_click_meat', { Meat: array });
    const arrValues = Object.values(values);
    for (let i = 0; i < arrValues.length; i++) {
      if (arrValues[i]) {
        changeFormRequired(false);
        if (isV4) {
          return history.push('preferred-products');
        } else {
          return history.push('/v3/achievement', { id: 2, flowId: 36 });
        }
      }
    }
    changeFormRequired(true);
    scrollTo('error');
  };

  const resetSelected = () => {
    dispatch(reset('meatFish'));
    changeFormRequired(false);
  };

  const handleSelect = () => {
    dispatch(clearFields('meatFish', false, false, 'reset'));
    changeFormRequired(false);
  };

  const disabledChoice = (name) => {
    switch (name) {
      case 'turkey':
        return vegetarian;
      case 'beef':
        return vegetarian || red_meat;
      case 'chicken':
        return vegetarian;
      case 'pork':
        return vegetarian || red_meat;
      case 'fish':
        return fish;
      default:
        return false;
    }
  };

  const cleanChoice = () => {
    if (vegetarian) {
      dispatch(change('meatFish', 'turkey', false));
      dispatch(change('meatFish', 'beef', false));
      dispatch(change('meatFish', 'chicken', false));
      dispatch(change('meatFish', 'pork', false));
    }
  };

  useEffect(() => {
    sendAmplitudeEvent('35_view_meat');
    dispatch(changeProgressStep(getNextProgressStep()));
    cleanChoice();
  }, []);

  return (
    <MeatFish onSubmit={handleSubmit(submitForm)}>
      <ChoiceQuizComponent
        title={t('meatFish.title')}
        items={itemsMeatFish}
        imagePath={'meatFish'}
        reset={t('meatFish.reset', { returnObjects: true })}
        formRequired={formRequired}
        resetSelected={resetSelected}
        handleSelect={handleSelect}
        disabled={disabledChoice}
      />
    </MeatFish>
  );
};

export default reduxForm(
  {
    form: 'meatFish',
    destroyOnUnmount: false
  }
)(MeatFishComponent);
