import styled from 'styled-components';
import { THEME } from 'THEME';

export const CheckboxBackground = styled.span`
  position: absolute;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid rgba(234, 228, 209, 0);
  transition: border ${THEME.TRANSITION}, background-color ${THEME.TRANSITION};
  z-index: 1;
`;

export const CheckboxTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  z-index: 2;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const CheckboxImg = styled.img`
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 2;
  @media (min-width: 960px) {
    width: 36px;
    height: 36px;
  }
`;

export const Checkbox = styled.label`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 30px;
  margin-bottom: 12px;
  border-radius: 90px;
  
  ${CheckboxBackground} {
    background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN};
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 90px;
    z-index: 3;
    opacity: 0;
    cursor: pointer;

    &:checked + ${CheckboxBackground} {
      border: ${props => `2px solid ${props?.isV4 ? THEME.COLOR_DARK_GREEN : THEME.COLOR_BROWN_FOCUSED}`};
    }
  }

  @media (min-width: 960px) {
    margin-bottom: 16px;
    padding: 14px 30px 14px 30px;
    &:last-child {
      margin-bottom: 0;
    }

    &:hover ${CheckboxBackground} {
      border: ${props => `2px solid ${props?.isV4 ? THEME.COLOR_DARK_GREEN : THEME.COLOR_BROWN_FOCUSED}`};
    }
  }

  &:active ${CheckboxBackground} {
    background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN_FOCUSED};
    border: ${props => `2px solid ${props?.isV4 ? THEME.COLOR_DARK_GREEN : THEME.COLOR_BROWN_FOCUSED}`};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;


