import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';

import { changeProgressStep } from 'store/actions';
import {
  PerfectWeight
} from './styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils';

const PerfectWeightComponent = ({ history, dispatch }) => {
  const { t } = useTranslation();
  const isV4 = useIsAppVersion('v4');

  const items = t(`perfectWeight.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const perfectWeight = data.target.title;
    sendAmplitudeEvent('17_click_last_weight', {
      'Last_perfect_weight': perfectWeight
    });
    const link = isV4 ? '/v4/weight-gain' : '/v3/about-you';
    history.push(link);
  };

  useEffect(() => {
    sendAmplitudeEvent('17_view_last_weight');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <PerfectWeight>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('perfectWeight.title')}
        name={'perfectWeight'}
      />
    </PerfectWeight>
  );
};

export default reduxForm(
  {
    form: 'perfectWeight',
    destroyOnUnmount: false
  }
)(PerfectWeightComponent);
