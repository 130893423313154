import styled from "styled-components";
import { THEME } from "THEME";


export const Example = styled.div`
  background-color: #D9F1DF;
  color: ${THEME.COLOR_BLACK};
  margin: 0 -16px;
  padding: 70px 16px 105px;
`;


export const ExampleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1002px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const ExampleCard = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px 12px 9px;
  width: 100%;
  max-width: 430px;
  margin: 0 auto 30px;
`;

export const ExampleImageWrap = styled.div`
  width: 100%;
  height: 322px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  margin-bottom: 7.52px;
`;

export const ExampleImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ExampleCardText = styled.div``;

export const ExampleCardName = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;

export const ExampleCardOld = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #7C7357;
`;


export const ExampleContent = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
`;

export const ExampleTitle = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #191B1F;
  margin-bottom: 20px;
`;

export const ExampleText = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3F5366;
  margin-bottom: 20px;
`;

export const ExampleButton = styled.div`
  width: 100%;
`;
