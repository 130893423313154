import React from 'react'
import { useTranslation } from 'react-i18next';
import { ButtonComponent as Button } from 'components/Button';
import {
  ExplanationWorkHeading,
  ExplanationWork,
  ExplainWorkListWrapper,
  ExplainWorkListLeftSection,
  ExplainWorkListStepTitle,
  ExplainWorkListSubtitle,
  ExplainWorkListTitle,
  ExplainWorkListRightSection,
  ExplainWorkListImage,
  ExplainWorkButtonWrapper,
  ButtonWrapper,
  ExplanationWorkWrapper,
  ExplanationWorkWrapperImage,
  ExplainWorkBackground
} from './styled';
import { replaceString } from 'utils';
import { useIsAppVersion } from 'customHooks';

const ExplanationWorkComponent = ({ handleClick }) => {
  const { t } = useTranslation();
  const stepList =  t(`landing.explanationWork.stepList`, { returnObjects: true });
  const isV4 = useIsAppVersion('v4');

  const processClick = (eventName) => {
    const event = isV4 ? 'MealPlan_home_page_get_my_plan_click' : eventName;
    handleClick(event);
  }

  const color = isV4 ? '#43BB5E' : '#7E80F7';
  const getTitleColor = (title) => {
    return replaceString(title, [{
      what: '{color}',
      forWhat: color
    }]);
  };

  return <ExplanationWork isV4={isV4}>
    <ExplanationWorkWrapper>
      <ExplanationWorkHeading>How <span style={{ color }}>It Works</span></ExplanationWorkHeading>
      {stepList.map(({ stepTitle, title, subtitle, src }, index) => {
        const source = `${src}${isV4 ? 'v4' : ''}`
        return (
          <ExplainWorkListWrapper key={index} className={`list-${[index + 1]}`}>
            <ExplainWorkListLeftSection>
              <ExplainWorkListStepTitle>{stepTitle}</ExplainWorkListStepTitle>
              <ExplainWorkListTitle dangerouslySetInnerHTML={{__html: getTitleColor(title)}} />
              <ExplainWorkListSubtitle dangerouslySetInnerHTML={{__html: subtitle}} />
              {stepTitle !== 'STEP 1' && (
                <ExplainWorkButtonWrapper>
                  {!isV4 && <Button title='Take the Quiz' type='button' onClick={() => processClick(`${`quiz_button2_${index+1}`}`)}/>}
                </ExplainWorkButtonWrapper>
              )}
            </ExplainWorkListLeftSection>
            <ExplainWorkListRightSection>
              {stepTitle === 'STEP 1' ?
                (<ExplainWorkListImage
                    src={`${source}.png`}
                    srcSet={`${source}.png 320w, ${source}@2x.png 480w`}
                    className={`image-${[index + 1]}`} onClick={() => processClick('quiz_button2_1')}
                    loading='lazy'
                  />
                ) : (
                  <ExplainWorkListImage
                    src={`${source}.png`}
                    srcSet={`${source}.png 320w, ${source}@2x.png 480w`}
                    className={`image-${[index + 1]}`}
                    loading='lazy'
                  />
                )}
            </ExplainWorkListRightSection>
          </ExplainWorkListWrapper>
        )
      })}
      <ExplanationWorkWrapperImage/>
    </ExplanationWorkWrapper>
    {isV4 && <ButtonWrapper>
      <Button title={t('messages.getMyPlan')} type='button' onClick={processClick}/>
    </ButtonWrapper>}
    <ExplainWorkBackground/>
  </ExplanationWork>
};

export default ExplanationWorkComponent;