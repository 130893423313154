import React from 'react';
import { useTranslation } from "react-i18next";
import { 
  PaymentContainer, 
  PaymentSectionTitle,
} from 'pages/Payment/styled';
import ResultsChart from 'components/ResultsChart';

const ForecastChart = ({ measure, month, year }) => {
  const { t } = useTranslation();
  const title = t('payment.forecastChart.title');
  return (
    <PaymentContainer style={{marginTop: 0}}>
      <PaymentSectionTitle>
        {title}
      </PaymentSectionTitle>
      <ResultsChart measure={measure} style={{ maxWidth: '670px' }} month={month} year={year}/>
    </PaymentContainer>
  )
}

export default ForecastChart;