const validate = (values) => {
  const errors = {};

  const minHeight = 'imperial' === values.measure ? 4 : 120;
  const maxHeight = 'imperial' === values.measure ? 7 : 250;

  if (!values.height) {
    errors.height = 'required';
  } else if (isNaN(Number(values.height))) {
    errors.height = 'number';
  } else if (Number(values.height) < minHeight) {
    errors.height = 'count_min';
  } else if (Number(values.height) > maxHeight) {
    errors.height = 'count_max';
  }

  if('imperial' !== values.measure) {
    return errors;
  }

  if ('imperial' === values.measure && !values.heightInches) {
    errors.heightInches = 'required';
  } else if (isNaN(Number(values.heightInches))) {
    errors.heightInches = 'number';
  } else if (Number(values.heightInches) < 0) {
    errors.heightInches = 'count_min';
  } else if (Number(values.heightInches) > 11) {
    errors.heightInches = 'count_max';
  }

  return errors;
};

export default validate;
