import {CHANGE_SUBSCRIPTION_DATA} from "store/actions";

const subscriptionInitialState = {
    is_active: true,
    type: 'trial',
    is_updated: false
};

const subscriptionReducer = (state = subscriptionInitialState, action) => {
    switch (action.type) {
        case CHANGE_SUBSCRIPTION_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default subscriptionReducer;
