import styled from "styled-components";
import { THEME } from "THEME";

export const Input = styled.div`
  width: 100%;
  position: relative;
  margin: 0 0 15px 0;
`;

export const InputTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
  @media (min-width: 960px) {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
`;

export const InputValue = styled.input`
  background-color: ${THEME.COLOR_BROWN};
  border-radius: 12px;
  border: 2px solid rgba(234, 228, 209, 0);
  height: 48px;
  width: 100%;
  padding-left: 20px;
  padding-right: 50px;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_BLACK};
  transition: border ${THEME.TRANSITION}, background-color ${THEME.TRANSITION};

  &:focus {
    border: 2px solid rgba(234, 228, 209, 0);
    background-color: ${THEME.COLOR_BROWN_FOCUSED};
  }

  @media (min-width: 960px) {
    height: 64px;
    &:hover {
      border: 2px solid rgba(234, 228, 209, 1);
    }

    &:focus {
      border: 2px solid rgba(234, 228, 209, 0);
      background-color: ${THEME.COLOR_BROWN_FOCUSED};
    }
  }

  ::-webkit-input-placeholder {
    color: #d8d8db;
  }

  ::-moz-placeholder {
    color: #d8d8db;
  }

  :-moz-placeholder {
    color: #d8d8db;
  }

  :-ms-input-placeholder {
    color: #d8d8db;
  }
`;

export const InputBody = styled.div`
  position: relative;
`;