import styled from "styled-components";
import { THEME } from "THEME";

export const Support = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 960px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;
export const SupportWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


export const SupportTitle = styled.h2`
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 10px;
  text-align: center;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
  }
`;


export const SupportEmail = styled.a`
  font-size: 18px;
  line-height: 26px;
  color: ${THEME.COLOR_DARK_GRAY};
  transition: color ${THEME.TRANSITION};
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }

  &:hover {
    color: ${THEME.COLOR_PURPLE};
  }
`;
