import styled from "styled-components";
import { THEME } from "THEME";

export const Payment = styled.div`
  width: 100%;
`;

export const PaymentContainer = styled.div`
  padding: 0 16px;
  margin: 80px auto;
  max-width: 630px;
  @media (min-width: 960px) {
    padding: 0;
    margin: ${props => props?.benefits ? '120px auto' : '40px auto'};
  }
`;

export const PaymentSectionTitle = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const PaymentSectionSubtitle = styled.div`
  font-family: 'DM Sans', sans-serif;
  max-width: 628px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #537EE7;
  margin-bottom: 36px;
  
  @media (max-width: 960px) {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
    text-align: center;
  }
`;

export const Title = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 10px;
  }
`;

export const Subtitle = styled.p`
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #444444;
  margin-bottom: 40px;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const Companies = styled.div`
  width: 100%;
  padding-top: 33px;
  padding-bottom: 3px;
  background-color: rgba(244, 241, 233, 0.6);
  margin-top: 56px;
`;
export const CompaniesWrapper = styled.div`
  width: 100%;
  max-width: 702px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  @media (min-width: 960px) {
    flex-direction: row;
  }
`;
export const CompaniesItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
`;
export const CompaniesItem = styled.img`
  width: 48px;
  min-width: 48px;
  height: auto;
  display: block;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
`;
