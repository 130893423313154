import DataProcessing from 'components/DataProcessing';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { changeDataProgress } from 'store/actions';
import { useDispatch } from 'react-redux';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { useIsAppVersion } from 'customHooks';

const LoadingResults = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isV4 = useIsAppVersion('v4');


  useEffect(() => {
    sendAmplitudeEvent('49_view_load')
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, []);

  const afterLoading = () => {
    const link = isV4 ? 'overall-results' : 'email';
    history.push(link);
  }

  return <DataProcessing afterLoading={afterLoading}/>
}

export default LoadingResults;
