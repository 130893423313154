import { SET_REMOTE_CONFIG } from 'store/actions';

const remoteConfigInitialState = {};

const remoteConfigReducer = (state = remoteConfigInitialState, action) => {
  switch (action.type) {
    case SET_REMOTE_CONFIG:
      return state = action.payload;
    default:
      return state;
  }
};

export default remoteConfigReducer;
