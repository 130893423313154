import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 30px 0;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 700px;
  margin: auto;
  display: block;
`;
