import styled from 'styled-components';
import { THEME, BACKGROUND_GRADIENT } from 'THEME';

export const ProgressWrapper = styled.div`
  position: relative;
  z-index: 3;
  min-height: 48px;
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProgressBack = styled.div`
  width: 18px;
  min-width: 18px;
  height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 960px) {
    cursor: pointer;
    transition: color ${THEME.TRANSITION};
    &:hover {
      color: #7E80F7;
    }
  }
`;

export const ProgressBackSpace = styled.div`
  width: 18px;
  min-width: 18px;
  height: 11px;
`;

export const ProgressCounts = styled.p`
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  @media (min-width: 960px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
export const ProgressLine = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: 4px;
  width: 0;
  transition: width ${THEME.TRANSITION};
  background: ${BACKGROUND_GRADIENT.COLOR_GREEN_PROGRESS};
`;

export const Progress = styled.div`
  position: relative;
  
  ${ProgressCounts}, ${ProgressBack} {
    color: ${props => props?.isV4 ? '#FFF' : THEME.COLOR_DARK_GRAY};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => props?.isV4 ? THEME.COLOR_DARK_GREEN : BACKGROUND_GRADIENT.COLOR_BROWN_HOVER};
    opacity: 0.8;
    z-index: 1;
  }
`;