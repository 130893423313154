import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { changeProgressStep } from 'store/actions';
import { EmotionalComfort } from './styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import StronglyQuizComponent from 'components/Quiz/StronglyQuiz';
import { getNextProgressStep } from 'utils';

const EmotionalComfortComponent = ({ history, dispatch }) => {
  const { t } = useTranslation();

  const handleSelect = (data) => {
    const emotionalComfort = data.target.title;
    sendAmplitudeEvent('44_click_Statement1_emotional_comfort', {
      'emotional_comfort': emotionalComfort
    });
    history.push('fail-back');
  }

  useEffect(() => {
    sendAmplitudeEvent('44_view_Statement1_emotional_comfort');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);
  return (
    <EmotionalComfort>
      <StronglyQuizComponent
        handleSelect={handleSelect}
        uptitle={t('emotionalComfort.upTitle')}
        title={t('emotionalComfort.title')}
        name={'emotional_comfort'}
      />
    </EmotionalComfort>
  );
};

export default reduxForm(
  {
    form: 'emotionalComfort',
    destroyOnUnmount: false
  }
)(EmotionalComfortComponent);
