import styled from 'styled-components';
import { THEME } from 'THEME';

export const FeedBackWrapper = styled.div`
  background: ${THEME.COLOR_SAND};
  padding: 120px 0;
  @media(max-width: 960px) {
    padding: 80px 0;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 16px;
  @media(max-width: 960px) {
    max-width: 468px;
  }
`

export const Headline = styled.h2`
  margin-bottom: 40px;
  font-size: 48px;
  line-height: 52px;
  font-weight: 500;
  text-transform: none;
  color: ${THEME.COLOR_BLACK};
  @media (max-width: 960px) {
    font-size: 36px;
    line-height: 39px;
  }
`

export const FeedBackContentWrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 80px;
  grid-row-gap: 66px;
  @media (max-width: 960px) {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 66px;
  }
`

export const FeedbackContentTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${THEME.COLOR_BLACK_ALT};
  margin-bottom: 12px;
  @media (max-width: 960px) {
    color: ${THEME.COLOR_LIGHT_GREEN};
  }
`

export const FeedbackContentText = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${THEME.COLOR_BLACK_ALT};
`

export const FeedbackContentAvatarWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.3px;
  margin-top: 30px;
  color: ${THEME.COLOR_LIGHT_BLACK};
`

export const FeedbackContentAvatar = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`

export const FeedbackContentAvatarImage = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 10px;
`

export const FeedbackContentAvatarName = styled.div`
`

export const FeedbackContentAvatarResults = styled.div`
  color: ${THEME.COLOR_BLUE}
`