const validate = (values) => {
  const errors = {};

  const minWeight = 'imperial' === values.measure ? 110 : 50;
  const maxWeight = 'imperial' === values.measure ? 550 : 250;

  if (!values) {
    errors.weight = 'required';
  } else if (isNaN(Number(values.weight))) {
    errors.weight = 'number';
  } else if (Number(values.weight) < minWeight) {
    errors.weight = 'count_min';
  } else if (Number(values.weight) > maxWeight) {
    errors.weight = 'count_max';
  }

  return errors;
};

export default validate;
