export const CHANGE_PROGRESS_STEP = "CHANGE_PROGRESS_STEP";
export const CHANGE_DATA_PROGRES = "CHANGE_DATA_PROGRES";

export const changeProgressStep = (step) => {
  return {
    type: CHANGE_PROGRESS_STEP,
    payload: step,
  };
};


export const changeDataProgress = (data) => {
  return {
    type: CHANGE_DATA_PROGRES,
    payload: data,
  };
};



