import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfoTile from 'components/InfoTile';
import BannerFood from 'components/BannerFood';
import ResultsChart from 'components/ResultsChart';
import CurrentBMI from 'components/CurrentBMI';
import ProblemAreas from 'components/ProblemAreas';
import Advantages from 'components/Advantages';
import SideBenefits from 'components/SideBenefits';
import { changeDataDiscount } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import {
  OverallResults,
  OverallResultsWrapper,
  OverallResultsTitle,
  OverallResultsContent,
  OverallResultsTiles,
  OverallResultsTile,
  OverallResultsLine,
} from './styled';
import { useIsAppVersion } from 'customHooks';
import { sendFacebookEvent } from 'analytics/facebookPixel';
import { getMonthForProgram } from 'utils';

const OverallResultsComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const isV4 = useIsAppVersion('v4');
  const { water_minimum, after_first_week, daily_calorie_norm } = useSelector((state) => state.onboardResult);
  const {
    age,
    currentWeight,
    desiredWeight,
    measure,
    height,
    heightInches,
    gender
  } = useSelector((state) => state.profile);
  const { month, year } = getMonthForProgram(currentWeight, desiredWeight, '0');
  const sex = ('male' ===  gender ? 'male' : 'female') ?? '';
  const { foot, inch, centimeters } = t(`measure.${measure}`, { returnObjects: true });
  const userHeight = 'imperial' === measure ? `${height} ${foot} ${heightInches} ${inch}` : `${height} ${centimeters}`;

  const goToPlan = () => {
    const link = isV4 ? 'email' : 'overall-review';
    history.push(link);
  }

  useEffect(() => {
    sendFacebookEvent('CompleteRegistration');
    sendAmplitudeEvent('view_overall-results');
    dispatch(changeDataDiscount({ visibleDiscountHeader: true }));
    return () => {
      dispatch(changeDataDiscount({ visibleDiscountHeader: false }));
    };
  }, []);

  return (
    <OverallResults>
      <OverallResultsWrapper>
        <OverallResultsLine>
          <OverallResultsTitle>
            {t(`overallResults.forecast.title`)}
          </OverallResultsTitle>
          <OverallResultsContent>
            <ResultsChart measure={measure} month={month} year={year}/>
            <OverallResultsTiles>
              <OverallResultsTile>
                <InfoTile placeholder={t(`overallResults.forecast.firstWeek`)}
                          imageName={'overallResults/cup.svg'}>
                  -{after_first_week} {t(`measure.${measure}.weight`)}
                </InfoTile>
              </OverallResultsTile>
              <OverallResultsTile>
                <InfoTile
                  placeholder={t(`overallResults.forecast.waterMinimum`)}
                  imageName={'overallResults/water.svg'}>
                  {water_minimum} {t(`measure.${measure}.volume`)}
                </InfoTile>
              </OverallResultsTile>
              <OverallResultsTile>
                <InfoTile placeholder={t(`overallResults.forecast.recommended`)}
                          imageName={'overallResults/duration.svg'}>
                  30 days
                </InfoTile>
              </OverallResultsTile>
              <OverallResultsTile>
                <InfoTile placeholder={t(`overallResults.forecast.calorieNorm`)}
                          imageName={'overallResults/lightning.svg'}>
                  {daily_calorie_norm} kcal
                </InfoTile>
              </OverallResultsTile>
            </OverallResultsTiles>
          </OverallResultsContent>
        </OverallResultsLine>
        <OverallResultsLine>
          <OverallResultsTitle>
            {t(`overallResults.profileSummary.title`)}
          </OverallResultsTitle>
          <OverallResultsContent>
            <CurrentBMI />
            <OverallResultsTiles>
              <OverallResultsTile>
                <InfoTile height placeholder={t('messages.age')} imageName={'overallResults/age.svg'}>
                  {age} {t('messages.years')}
                </InfoTile>
              </OverallResultsTile>
              <OverallResultsTile>
                <InfoTile height placeholder={`${t('messages.height')} / ${t('messages.weight')}`}
                          imageName={'overallResults/measurements.svg'}>
                  {userHeight} / {currentWeight}
                  {t(`measure.${measure}.weight`)}
                </InfoTile>
              </OverallResultsTile>
            </OverallResultsTiles>
          </OverallResultsContent>
        </OverallResultsLine>
        <OverallResultsLine>
          <BannerFood
            handleClick={goToPlan}
            measure={measure}
          />
        </OverallResultsLine>
        <OverallResultsLine>
          <ProblemAreas gender={sex} handleClick={goToPlan}/>
        </OverallResultsLine>
        <OverallResultsLine>
          <Advantages handleClick={goToPlan}/>
        </OverallResultsLine>
        <OverallResultsLine>
          <SideBenefits/>
        </OverallResultsLine>
      </OverallResultsWrapper>
    </OverallResults>
  );
};

export default OverallResultsComponent;
