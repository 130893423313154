import React from 'react';

import {InfoTile, InfoTileIcon, InfoTileImage, InfoTileContent, InfoTilePlaceholder, InfoTileText} from './styled';

const InfoTileComponent = ({ children, placeholder, imageName, height }) => {
  return (
    <InfoTile height={height}>
      <InfoTileIcon>
        <InfoTileImage src={`/images/${imageName}`}/>
      </InfoTileIcon>
      <InfoTileContent>
        <InfoTilePlaceholder>{placeholder}</InfoTilePlaceholder>
        <InfoTileText>{children}</InfoTileText>
      </InfoTileContent>
    </InfoTile>
  )
}

export default InfoTileComponent;
