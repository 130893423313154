import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSlide from 'components/ButtonSlide';
import { ButtonComponent as Button } from 'components/Button';
import Input from 'components/Input';

import {
  Title,
  Content,
  WrapButton,
  HeightInput,
  HeightLine,
  ErrorText,
  Subtitle,
  Wrapper
} from 'components/Quiz/styled';

const HeightQuiz = (props) => {
  const { t } = useTranslation();

  const {
    title,
    subtitle,
    inputHeightTitle,
    inputHeightInchTitle,
    heightError,
    heightInchError,
    heightPlaceholder,
    heightInchPlaceholder,
    measure,
    moveReset
  } = props;

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <ButtonSlide
        name='measure'
        valueFirst='imperial'
        valueSecond='metric'
        textFirst={t('messages.imperial')}
        textSecond={t('messages.metric')}
        move={'imperial' !== measure}
        moveReset={moveReset}
      />
      <Content>
        <HeightLine>
          <HeightInput>
            <Input type='number'
                   name='height'
                   placeholder={heightPlaceholder}
                   title={inputHeightTitle}
            />
            <ErrorText>
              {heightError}
            </ErrorText>
          </HeightInput>
          {'imperial' === measure && (
            <HeightInput>
              <Input
                type='number'
                name='heightInches'
                placeholder={heightInchPlaceholder}
                title={inputHeightInchTitle}
              />
              <ErrorText>
                {heightInchError}
              </ErrorText>
            </HeightInput>
          )}
        </HeightLine>
        <WrapButton>
          <Button title={t('messages.continue')} type='submit' />
        </WrapButton>
      </Content>
    </Wrapper>
  );
};

export default HeightQuiz;
