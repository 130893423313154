import React from 'react';
import {
  CheckBoxContentCheck,
  CheckBoxContentCheckIcon,
  CheckBoxContentCheckName,
  CheckBoxContentDescription
} from './styled';
import { CheckRounded } from 'components/Icons';

const CheckBoxContent = ({ checkboxItems, ...props }) => {
  const getAdditionalValue = (identificator, title) => {
    if (props.getDiffWeight && identificator && identificator === 'reach') {
      return (
        <div>
          <CheckBoxContentCheckName dangerouslySetInnerHTML={{ __html: title }}/>
          {' '}
          <CheckBoxContentCheckName>
            {props.getDiffWeight()}
          </CheckBoxContentCheckName>
        </div>
      )
    }

    return <CheckBoxContentCheckName dangerouslySetInnerHTML={{ __html: title }}/>;
  }

  return (
    <>
    {checkboxItems.map(({ title, description, link, identificator }, index) =>
      <React.Fragment key={index}>
        <CheckBoxContentCheck>
          <CheckBoxContentCheckIcon>
            <CheckRounded width='100%' height='100%' color={'#ffffff'}/>
          </CheckBoxContentCheckIcon>
          {getAdditionalValue(identificator, title)}
        </CheckBoxContentCheck>
        <CheckBoxContentDescription dangerouslySetInnerHTML={{ __html: description }} />
      </React.Fragment>
      )}
    </>
  )
}

export default CheckBoxContent;