import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CheckRounded } from 'components/Icons';
import { ButtonComponent as Button } from 'components/Button';
import { sendAmplitudeEvent } from 'analytics/amplitude';

import {
  WeightLossKit,
  WeightLossKitWrapper,
  WeightLossKitImageWrap,
  WeightLossKitImage,
  WeightLossKitContent,
  WeightLossKitTitle,
  WeightLossKitItems,
  WeightLossKitItem,
  WeightLossKitItemIcon,
  WeightLossKitItemText,
  WeightLossKitButton,
  WeightLossKitSmallT,
  WeightLossKitBottom,
  WeightLossKitOldPrice,
  WeightLossKitNewPrice
} from './styled';
import { getPrices, isNullish } from 'utils';

const WeightLossKitComponent = ({ handleClick }) => {
  const { t } = useTranslation();
  const { progress, remoteConfig } = useSelector((state) => state);
  const { timer } = progress;
  const { buyNow } = remoteConfig?.checkoutPage || {};
  const {
    discountPrice,
    defaultPrice,
    priceDescription,
    buttonText
  } = buyNow || {};
  const isDiscountEndTime = 'end' === timer;
  const isBuyNow = !isNullish(buyNow);
  const isEmptyPrice = !defaultPrice || !discountPrice;

  const itemsWightLossKit = t(`weightLossKit.items`, { returnObjects: true });

  const handler = () => {
    sendAmplitudeEvent('click_overall-review_2');
    handleClick();
  };

  return (
    <WeightLossKit>
      <WeightLossKitWrapper>
        <WeightLossKitImageWrap>
          <WeightLossKitImage
            src={`/images/WeightLossKitPhone.png`}
            srcSet={`/images/WeightLossKitPhone.png 320w, /images/WeightLossKitPhone@2x.png 480w`}
          />
        </WeightLossKitImageWrap>
        <WeightLossKitContent>
          <WeightLossKitTitle>{t('weightLossKit.title')}</WeightLossKitTitle>
          <WeightLossKitItems>
            {itemsWightLossKit.map((elem, count) =>
              <WeightLossKitItem key={count}>
                <WeightLossKitItemIcon>
                  <CheckRounded width={'100%'} height={'100%'}/>
                </WeightLossKitItemIcon>
                <WeightLossKitItemText>{elem.title}</WeightLossKitItemText>
              </WeightLossKitItem>
            )}
          </WeightLossKitItems>
          {isBuyNow && <WeightLossKitBottom>
            {(isDiscountEndTime || isEmptyPrice) ? (
              <WeightLossKitOldPrice style={{ textDecoration: 'none', marginBottom: '16px' }}>{t('weightLossKit.price.currency')} {defaultPrice ? defaultPrice : discountPrice} </WeightLossKitOldPrice>
            ) : (
              defaultPrice && discountPrice && <>
                <WeightLossKitOldPrice>{t('weightLossKit.price.currency')} {defaultPrice} </WeightLossKitOldPrice>
                <WeightLossKitNewPrice>{t('weightLossKit.price.text')} {discountPrice}</WeightLossKitNewPrice>
              </>
            )}
            <WeightLossKitButton>
              <Button title={buttonText} type='button' onClick={handler}/>
            </WeightLossKitButton>
            <WeightLossKitSmallT>{priceDescription}</WeightLossKitSmallT>
          </WeightLossKitBottom>}
        </WeightLossKitContent>
      </WeightLossKitWrapper>
    </WeightLossKit>
  )
}

export default WeightLossKitComponent;
