const isEmptyObject = (obj) => {
  return (
    Object.keys(obj || {}).length === 0
  );
};

export const isNullish = (obj) => {
  if (isEmptyObject(obj)) {
    return true;
  }
  return Object.values(obj).every(value => !value);
};
