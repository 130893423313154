import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { sendAmplitudeEvent } from "analytics/amplitude";
import { ArrowDown } from "components/Icons";
import { logout } from "api";

import {
  Menu,
  StyledLink,
  Dropping,
  DroppingList,
  DroppingArrow,
  DroppingName,
  DroppingShadow
} from "./styled";

const MenuComponent = ({visibleMenu}) => {
  const { email } = useSelector((state) => state.profile);
  const history = useHistory();

  const logoutHandle = (e) => {
    sendAmplitudeEvent('logout');
    e.preventDefault();
    logout().then((res) => {
      if (200 === res.status) {
        history.push('/login')
      }
    })
  }

  return (
    <Menu visibleMenu={visibleMenu}>
      {/*<StyledLink to="/meal-access">{t("header.plan")}</StyledLink>*/}
      <Dropping>
        <DroppingName>{email}</DroppingName>
        <DroppingArrow>
          <ArrowDown width="8.5px" height="8.5px"/>
        </DroppingArrow>
        <DroppingList>
          <StyledLink to="/meal-plan/settings">Settings</StyledLink>
          <StyledLink to="/" onClick={logoutHandle}>Log out</StyledLink>
          <DroppingShadow/>
        </DroppingList>
      </Dropping>
    </Menu>
  );
};

export default MenuComponent;
