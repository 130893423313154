import styled from "styled-components";
import { THEME } from "THEME";

export const DropdownBody = styled.div`
  width: 100%;
  padding: 20px 30px;
  font-size: 16px;
  line-height: 22px;
  color: #000000;

  @media (min-width: 960px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const DropdownHeaderText = styled.p`
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  @media (min-width: 960px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const DropdownHeaderArrow = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${THEME.COLOR_BLACK};
  position: absolute;
  right: 16px;
  top: calc(50% - 8px);
  transform: rotate(0);
  transition: color ${THEME.TRANSITION}, transform ${THEME.TRANSITION};
  @media (min-width: 960px) {
    width: 24px;
    min-width: 24px;
    height: 24px;
    top: calc(50% - 12px);
    right: 30px;
  }
`;

export const DropdownHeader = styled.div`
  width: 100%;
  position: relative;
  min-height: 64px;
  padding: 5px 64px 5px 30px;
  display: flex;
  align-items: center;
  background-color: ${THEME.COLOR_BROWN};
  transition: background-color ${THEME.TRANSITION};
`;

export const Dropdown = styled.div`
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid ${THEME.COLOR_BROWN_FOCUSED};
  border-radius: 12px;
  overflow: hidden;

  .is-open {
    background-color: ${THEME.COLOR_BROWN_FOCUSED};

    ${DropdownHeader} {
      background-color: ${THEME.COLOR_BROWN_FOCUSED};
    }

    ${DropdownHeaderArrow} {
      transform: rotate(180deg);
    }
  }
`;

