import styled from "styled-components";
import { THEME } from 'THEME';

export const ReachGoalAndLoseTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  text-align: left;
  margin: 60px 0 20px;
  @media(min-width: 960px) {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 30px;
  }
`

export const ReachGoalAndLoseSubTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.COLOR_DARK_GRAY};
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`

export const Discount = styled.section`
  border-radius: 30px;
  background-color: #FFEAED;
  border: 1px solid #FB5E79;
  padding: 24px 20px;
  margin-top: 30px;
`

export const DiscountGiftIcon = styled.img`
  max-width: 30px;
  display: block;
  margin: auto auto 8px;
`

export const DiscountTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #191B1F;
  text-align: center;
`

export const DiscountPrice = styled.div`
  font-weight: 700;
  text-align: center;
  margin: 8px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
export const DiscountPriceValue = styled.span`
  font-size: 24px;
  line-height: 24px;
  &:first-child {
    font-size: 20px;
    text-decoration: line-through;
    @media(max-width: 349px) {
      display: block;
      width: 100%;
    }
    @media(min-width: 349px) {
      font-size: 24px;
    }
    @media(min-width: 960px) {
      font-size: 30px;
    }
  }
  @media (min-width: 960px) {
    font-size: 30px;
  }
`
export const DiscountPriceText = styled.span`
  font-size: 20px;
  line-height: 28px;
`

export const DiscountValue = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_RED};
  margin: 8px 0;
  text-align: center;
`

export const DiscountText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #3F5366;
`