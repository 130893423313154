import styled from "styled-components";

export const BannerFood = styled.div`
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
    background: #F8F6F0;
    border-radius: 12px;
  }

  @media (min-width: 960px) {
    flex-direction: row;
    align-items: center;
    padding-right: 100px;
  }
`;

export const BannerFoodImageWrap = styled.div`
  padding: 0 9px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  margin-bottom: 16px;
  @media (min-width: 960px) {
    padding: 0;
    margin-right: 43px;
    margin-bottom: 0;
    min-height: 390px;
    width: 100%;
    max-width: 457px;
  }
`;

export const BannerFoodImage = styled.img`
  height: 250px;
  width: auto;
  margin-top: -10px;
  @media (min-width: 960px) {
    height: calc(100% + 10px);
    margin-top: 0;
    position: absolute;
    top: 12px;
    left: 17px;
  }
`;

export const BannerFoodContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  padding: 0 16px 30px;
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    padding: 20px 0;
  }
`;

export const BannerFoodTitle = styled.h2`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 10px;
  @media (min-width: 960px) {
    font-size: 42px;
    line-height: 48px;
  }
`;

export const BannerFoodText = styled.p`
  font-size: 16px;
  line-height: 24px;

  b, span {
    font-weight: 700;
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;

  }
`;

export const BannerFoodButton = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 300px;
  align-self: center;
  @media (min-width: 960px) {
    margin-top: 40px;
  }
`;
