import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Support,
  SupportWrapper,
  SupportTitle,
  SupportEmail
} from './styled';
import { changeDataProgress } from "store/actions";
import config from 'config';

const SupportComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, [])
  return (
    <Support>
      <SupportWrapper>
        <SupportTitle>{t('support.title')}</SupportTitle>
        <SupportEmail href={`mailto:${config.supportEmail}`}>{config.supportEmail}</SupportEmail>
      </SupportWrapper>
    </Support>
  )
}
export default SupportComponent;
