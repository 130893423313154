import styled from 'styled-components';
import { Title } from 'components/Title';

export const ForecastWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 30px 16px;
  @media (min-width: 960px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

export const SubTitle = styled(Title)`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #4C4C4C;
  margin: 20px auto;

  @media (min-width: 960px) {
    font-size: 18px;
  }
`

export const ResultTitle = styled(Title)`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #43BB5E;
  margin: 20px auto 60px auto;
  
  @media(max-width: 960px){
    font-size: 16px;
    margin: 20px auto;
  }

`

export const StyledTitle = styled(Title)`
  font-size: 20px;
  @media (min-width: 960px) {
    font-size: 24px;
    margin-bottom: 30px;
  }
`

export const ReviewTitle = styled(Title)`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin: 80px auto 10px auto;
  text-align: start;
`

export const ForecastWrapButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 12px 16px;
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  @media (min-width: 960px) {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0);
    padding: 16px 0 0 0;
  }
`;
