import React from 'react';
import {
  Item,
  Title,
  Subtitle
} from 'components/Quiz/styled';
import { ComponentWrapper, ComponentItems } from 'components/UI';
import Checkbox from 'components/Checkbox';

const StandardQuizComponent = ({ handleSelect, subtitle, title, name, items, imagePath }) => {
  return (
      <ComponentWrapper>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <ComponentItems>
          {items.map((item, count) => <Item key={count}>
            <Checkbox
              title={item.title}
              type="radio"
              name={name}
              value={item.value ?? item.name ?? item.title}
              imageName={imagePath ? `${imagePath}/${item.name}.svg` : null}
              handleSelect={handleSelect}
            />
          </Item>)}
        </ComponentItems>
      </ComponentWrapper>
  );
};

export default StandardQuizComponent;