import React from 'react';
import { Field } from 'redux-form';
import { Plus } from 'components/Icons';
import {
  Choice,
  ChoiceTitle,
  ChoiceImg,
  ChoiceBackground,
  ChoiceCheck
} from './styled';
import { useIsAppVersion } from 'customHooks';

const ChoiceComponent = ({ title, imageName, name, value, type, disabled, handleSelect=()=>{}, ...props }) => {
  const isV4 = useIsAppVersion('v4');
  return (
    <Choice onClick={handleSelect} disabled={disabled} isV4={isV4}>
      <ChoiceTitle>{title}</ChoiceTitle>
      {imageName && <ChoiceImg src={`/images/${imageName}`}/>}
      <Field name={name} type={type} value={value} component="input" {...props}/>
      <ChoiceBackground/>
      <ChoiceCheck>
        <Plus width="100%" height="100%" color="#537EE7"/>
      </ChoiceCheck>
    </Choice>
  );
};
export default ChoiceComponent;
