import { getWebRemoteConfig } from 'api';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { getUtmParams } from 'utils/storeUtmParams';

const getUtmParameter = (name, value) => {
  return value ? `&${name}=${value}` : '';
};

export function getRemoteConfig(branch, id) {
  const configId = id ? `/${id}` : '';
  const { source, campaign, adContent, adTerm } = getUtmParams() ?? {};
  const utmParams = getUtmParameter('source', source)
    + getUtmParameter('campaign', campaign)
    + getUtmParameter('adContent', adContent)
    + getUtmParameter('adTerm', adTerm);
  return getWebRemoteConfig(branch, configId, utmParams)
    .then(res => {
      if (200 === res.status) {
        sendAmplitudeEvent('y_config_name', { name: res.data.data.name });
        return res.data.data.content;
      } else {
        return {};
      }
    }).catch(err => console.log(err));
}