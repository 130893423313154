import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery} from 'react-responsive';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components'

import { changeUserData, setAppVersion } from 'store/actions';
import { useHistory } from 'react-router-dom';
import { getAppVersion } from 'utils';

const RootComponent = ({ children }) => {
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state);
  const [loadedContent, setLoadedContent] = useState(false);
  const dir = user.language === 'ar' ? 'rtl' : 'ltr';
  const isMobile = useMediaQuery({
    query: '(max-device-width: 960px)',
  });

  useEffect(() => {
    i18n
      .use(Backend)
      .use(initReactI18next)
      .init({
        lng: `${user.language}`,
        backend: {
          loadPath: '/translations/{{lng}}.json',
        },
        ns: ['translations'],
        defaultNS: 'translations',
      })
      .then((t) => setLoadedContent(true));
  }, []);

  useEffect(() => {
    dispatch(changeUserData({isMobile}));
  }, [isMobile]);

  const history = useHistory();

  useEffect(() => {
    const appVersion = getAppVersion(history.location.pathname);
    dispatch(setAppVersion(appVersion));
  }, []);

  return <>
    <Helmet>
      <html lang={user.language}/>
      <body dir={dir}/>
    </Helmet>
    {loadedContent ?
      <ThemeProvider theme={{dir}}>{children}</ThemeProvider> : null}
  </>;
};

export default RootComponent;
