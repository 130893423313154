const validate = values => {
  const errors = {};

  const word = /^[A-Za-z0-9]+$/g

  if (!values || !values.name) {
    errors.name = 'required'
  } else if (values.name.length > 12 || values.name.length < 2) {
    errors.name = 'length'
  } else if (!values.name.match(word)) {
    errors.name = 'error'
  }

  return errors;
}

export default validate;
