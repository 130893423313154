import { trimSentence } from 'utils';

const validate = values => {
  const errors = {};

  const word = /^[A-Za-z0-9 ]+$/g

  if (!values) {
    errors.error = 'required';
  } else if ((values.customEvent && trimSentence(values.customEvent).length > 15)
            || (values.customEvent && trimSentence(values.customEvent).length < 2)) {
    errors.customEvent = 'length';
  } else if (values.customEvent && !values.customEvent.match(word)) {
    errors.customEvent = 'error';
  }

  return errors;
}

export default validate;
