import styled from "styled-components";

import { THEME } from "THEME";

export const Advantages = styled.div`

`;

export const AdvantagesTitle = styled.h2`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: ${THEME.COLOR_BLACK};
  margin-bottom: 30px;
  text-align: center;
  @media (min-width: 960px) {
    margin-bottom: 50px;
  }
`;

export const AdvantagesItems = styled.div``;

export const AdvantagesContent = styled.div`

  @media (min-width: 960px) {
    width: 100%;
    max-width: 470px;
  }
`;

export const AdvantagesItem = styled.div`
  margin-bottom: 30px;
  @media (min-width: 960px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    &:nth-child(2n) {
      flex-direction: row-reverse;

      ${AdvantagesContent} {
        margin-right: 20px;
        margin-left: 0;
      }
    }
  }
`;

export const AdvantagesImageWrap = styled.div`
  width: 100%;
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 16px;
  @media (min-width: 960px) {
    margin: 0;
  }
`;

export const AdvantagesImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 30px;
`;

export const AdvantagesContentTitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 12px;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    margin-bottom: 20px;
  }
`;

export const AdvantagesContentText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_DARK_GRAY};
  margin-bottom: 20px;

  p {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
    p {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const AdvantagesButton = styled.div`
  width: 100%;
  max-width: 370px;
  margin: auto;
`;

export const AdvantagesCheck = styled.div`
      
  display: flex;
  align-self: flex-start;
  flex: 1 1 40%;
  max-width: 450px;
  margin-bottom: 12px;
`;

export const AdvantagesCheckIcon = styled.div`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AdvantagesCheckName = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #3F5366;

`;

export const AdvantagesAnimationBlock = styled.div`
 background-color: #F6F6FC;
 min-width: 450px;
 min-height: 306px;
 max-height: 306px;
 overflow: hidden;
 border-radius: 30px;
`;