import styled from 'styled-components';
import { THEME } from 'THEME';

export const ChoiceBackground = styled.span`
  position: absolute;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid rgba(234, 228, 209, 0);
  transition: border ${THEME.TRANSITION}, background-color ${THEME.TRANSITION};
  z-index: 1;
`;

export const ChoiceCheck = styled.span`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: dashed 1px ${THEME.COLOR_BLUE};
  left: 20px;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  z-index: 2;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border ${THEME.TRANSITION};

  svg {
    position: relative;
    transform: rotate(0);
    opacity: 1;
    transition: transform ${THEME.TRANSITION}, opacity ${THEME.TRANSITION};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${THEME.COLOR_BLUE};
    border-radius: 50%;
    opacity: 0;
    transition: opacity ${THEME.TRANSITION}, transform ${THEME.TRANSITION};
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    height: 2px;
    width: 0;
    background-color: #fff;
    transition: width ${THEME.TRANSITION};
  }
`;

export const Choice = styled.label`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px 12px 66px;
  margin-bottom: 12px;
  border-radius: 12px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  opacity: ${props => props.disabled ? 0.4 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'auto'};
  
  ${ChoiceBackground} {
    background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN};
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: 3;
    opacity: 0;
    cursor: pointer;

    &:checked + ${ChoiceBackground} {
      background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN_FOCUSED};
      border: ${props => `2px solid ${props?.isV4 ? THEME.COLOR_DARK_GREEN : THEME.COLOR_BROWN_FOCUSED}`};
    }

    &:checked + ${ChoiceBackground} + ${ChoiceCheck} {
      border: dashed 1px transparent;

      svg {
        opacity: 0;
        transform: rotate(45deg) scale(0);
      }

      &::before {
        opacity: 1;
      }

      &::after {
        width: 12px;
      }
    }
  }

  @media (min-width: 960px) {
    margin-bottom: 16px;
    padding: 14px 30px 14px 66px;
    &:last-child {
      margin-bottom: 0;
    }

    &:hover ${ChoiceBackground} {
      border: ${props => `2px solid ${props?.isV4 ? THEME.COLOR_DARK_GREEN : THEME.COLOR_BROWN_FOCUSED}`};
    }

    &:hover ${ChoiceCheck} svg {
      transform: rotate(45deg) scale(1);
    }

  }

  &:active ${ChoiceBackground} {
    background-color: #ECECF8;
    border: 2px solid rgba(234, 228, 209, 0) !important;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ChoiceTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_BLACK};
  position: relative;
  z-index: 2;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ChoiceImg = styled.img`
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 2;
  min-width: 24px;
  margin-left: 7px;
  @media (min-width: 960px) {
    width: 36px;
    height: 36px;
    min-width: 36px;
    margin-left: 7px;
  }
`;

