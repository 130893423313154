import React from 'react';
import {
  PaymentContainer,
} from 'pages/Payment/styled';
import { useTranslation } from "react-i18next";
import CheckBoxContent from "components/Payment/ui/CheckBoxContent";

const TrustContent = () => {
  const { t } = useTranslation();
  const checkboxItems = t(`payment.trustContent.checkboxItems`, { returnObjects: true });

  return (
    <PaymentContainer>
      <CheckBoxContent checkboxItems={checkboxItems} />
    </PaymentContainer>
  )
}

export default TrustContent;