export const transformWeight = (measure, modifyMeasure, modifyWeight) => {

  if (measure === modifyMeasure) {
    return modifyWeight;
  } else if ('metric' === measure) {
    //convert to kg
    return Math.round(modifyWeight * 0.4535);
  } else {
    //convert to lbs
    return Math.round(modifyWeight * 2.2046);
  }
}