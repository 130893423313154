import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { ButtonComponent as Button} from 'components/Button';
import { useHistory } from 'react-router-dom';
import {
  SeparatorParent,
  SeparatorWrapper,
  SeparatorWrapButton,
  SeparatorTextWrapper,
  SeparatorImage
} from './styled';
import { useTranslation } from 'react-i18next';
import { changeDataProgress } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';

export const Separator = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const content = t(`separator.items`, { returnObjects:true });
  const id = content.map(item => item.link).indexOf(window.location.pathname.replace('/v4/', ''));
  const { event, title, image, image2x } = content[id];

  useEffect(() => {
    sendAmplitudeEvent(`${event}_view`);
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);

  const goNextPage = () => {
    sendAmplitudeEvent(`${event}_click`);
    switch (id) {
      case 0:
        history.push('going-day');
        break;
      case 1:
        history.push('diets');
        break;
      case 2:
        history.push('vegetables');
        break;
      case 3:
        history.push('outside-motivation');
        break;
    }
  };

  return (
    <SeparatorParent id={'expand'}>
      <SeparatorWrapper>
        <SeparatorImage
          src={`/images/separator/${image}`}
          srcSet={`/images/separator/${image} 320w, /images/separator/${image2x} 480w`}
          maxWidth={'256px'}
        />
        <SeparatorTextWrapper dangerouslySetInnerHTML={{__html: title}}/>
        <SeparatorWrapButton>
          <Button title={t('messages.continue')} type='submit' onClick={goNextPage}/>
        </SeparatorWrapButton>
      </SeparatorWrapper>
    </SeparatorParent>
  );
};
