export const checkVisitCookie = (cookieName) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cookieName}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const setVisitCookie = (cookieName) => {
    let CookieDate = new Date;
    CookieDate.setFullYear(CookieDate.getFullYear() +10);
    document.cookie = cookieName + "=vf5NrETkUKCa6FhkyRSazD; expires=" + CookieDate.toGMTString() + ';';
}