import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { sendAmplitudeEvent, setUserProperties } from 'analytics/amplitude';
import { sendFacebookEvent } from 'analytics/facebookPixel';
import { changeProgressStep, setProfileData, setRemoteConfig, setUrlLinkRemoteConfig } from 'store/actions';
import { DisclaimerText, GenderForm } from './styled';
import { useGetGaExperimentId, useIsAppVersion } from 'customHooks';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { reduxForm } from 'redux-form';
import { getNextProgressStep } from 'utils/getNextProgressStep';
import { getRemoteConfig } from 'remoteConfig';
import { pinterestEvent } from 'analytics/pinterest';

const GenderV3 = ({ dispatch }) => {
  const routeMatch = useRouteMatch();
  const { search } = window.location;
  const { t } = useTranslation();
  const history = useHistory();
  const isV4 = useIsAppVersion('v4');
  const routing = routeMatch?.params?.id && `/${routeMatch?.params?.id+search}`;

  const handleSelect = (data) => {
    const gender = data.target.value;
    dispatch(setProfileData({ gender }));
    sendAmplitudeEvent('1_click_gender', { gender });
    const link = isV4 ? '/v4/birthday' : '/v3/your-name';
    history.push(link);
  };

  const experimentIdData = useGetGaExperimentId();
  useEffect(() => {
    setUserProperties({ ...experimentIdData });
    sendAmplitudeEvent('1_view_gender');
    sendFacebookEvent('ViewContent');
    pinterestEvent('Pagevisit');
    !isV4 && dispatch(setUrlLinkRemoteConfig(routing));
    dispatch(changeProgressStep(getNextProgressStep()));
    if (!isV4) {
      getRemoteConfig('v3', routeMatch.params.id).then((data) => {
        dispatch(setRemoteConfig(data));
      });
    }
  }, []);

  const items = t('gender.items', { returnObjects: true });

  return (
    <GenderForm>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('gender.title')}
        subtitle={!isV4 && t('gender.subtitle')}
        name={'sex'}
      />
      <DisclaimerText>{t('disclaimer')}</DisclaimerText>
    </GenderForm>
  )
}

export default reduxForm(
  {
    form: 'gender',
    destroyOnUnmount: false
  }
)(GenderV3);
