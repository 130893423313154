export const CHANGE_LANGUAGE = "SET_LANGUAGE";
export const CHANGE_USER_DATA = "CHANGE_USER_DATA";

export const changeLanguage = (data) => {
    localStorage.setItem("language", data);
    return {
        type: CHANGE_LANGUAGE,
        payload: data,
    };
};
export const changeUserData = (data) => {
    return {
        type: CHANGE_USER_DATA,
        payload: data,
    };
};