import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeDataProgress, changeProgressStep } from 'store/actions';
import { OutsideMotivation } from './styled';
import YesNoQuizComponent from 'components/Quiz/YesNoQuiz';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils';

const OutsideMotivationComponent = ({ dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isV4 = useIsAppVersion('v4');

  const handleSelect = (data) => {
    const outsideMotivation = data.target.title;
    sendAmplitudeEvent('46_click_outside_motivation',{
      'outside_motivation': outsideMotivation
    });
    if (isV4) {
      history.push('loading-results');
    } else {
      history.push('achievement', { id: 4, flowId: 47 });
    }
  };

  useEffect(() => {
    sendAmplitudeEvent('46_view_outside_motivation');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <OutsideMotivation>
      <YesNoQuizComponent
        uptitle={!isV4 && t('outsideMotivation.upTitle')}
        title={isV4 ? t('outsideMotivation.titleV4') : t('outsideMotivation.title')}
        name={'outside_motivation'}
        handleSelect={handleSelect}
      />
    </OutsideMotivation>
  );
};

export default reduxForm(
  {
    form: 'outsideMotivation',
    destroyOnUnmount: false
  }
)(OutsideMotivationComponent);