import {
  ReviewContentWrapper,
  ReviewContentLeftSection,
  ReviewContentRightSection,
  ReviewContentRightSectionText,
  ReviewContentSubTitle,
  ReviewContentTitle,
  ReviewWrapper,
} from './styled';
import { Star } from 'components/Icons';
import { REVIEW_CONTENT_DATA } from './reviewContentData';

const getReviewContent = (title, name, date, text, index) => {
  const STARS = [
    {
      Component: Star,
    },
    {
      Component: Star,
    },
    {
      Component: Star,
    },
    {
      Component: Star,
    },
    {
      Component: Star,
    },
  ];
  return (
    <ReviewContentWrapper key={index} style={{ margin: index === 2 ? '0px auto 80px' : '10px auto' }}>
      <ReviewWrapper>
        <ReviewContentLeftSection>
          {STARS.map(({ Component }, index) => (
            <Component key={index} />
          ))}
        </ReviewContentLeftSection>
        <ReviewContentRightSection>
          <ReviewContentRightSectionText style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '4px' }}>
            {date}
          </ReviewContentRightSectionText>
          <ReviewContentRightSectionText>{name}</ReviewContentRightSectionText>
        </ReviewContentRightSection>
      </ReviewWrapper>
      <ReviewContentTitle>{title}</ReviewContentTitle>
      <ReviewContentSubTitle>{text}</ReviewContentSubTitle>
    </ReviewContentWrapper>
  );
};

export const ReviewContent = () => {
  return <>{REVIEW_CONTENT_DATA.map(({ title, name, text, date }, index) => getReviewContent(title, name, date, text, index))}</>;
};
