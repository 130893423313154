import React from 'react';
import { useTranslation } from "react-i18next";
import Checkbox from 'components/Checkbox';
import { Wrapper, Title, YesNoItems, YesNoItem, UpTitle } from 'components/Quiz/styled';

const YesNoQuizComponent = ({ title, uptitle, name, handleSelect }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      { uptitle && <UpTitle>{uptitle}</UpTitle>}
      {title && <Title>{title}</Title>}
      <YesNoItems>
        <YesNoItem>
          <Checkbox title={t("messages.yes")} type="radio" name={name} value="yes" handleSelect={handleSelect} />
        </YesNoItem>
        <YesNoItem>
          <Checkbox title={t("messages.no")} type="radio" name={name} value="no" handleSelect={handleSelect} />
        </YesNoItem>
      </YesNoItems>
    </Wrapper>
  )
}

export default YesNoQuizComponent;
