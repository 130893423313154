import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dropdown from 'components/Dropdown';

import {
  Faq,
  FaqWrapper,
  FaqItem
} from './styled';
import { changeDataProgress } from 'store/actions';

export const FaqMapper = ({items}) => {
  return(
    <>
      {items.map((elem, count) =>
        <FaqItem key={count}>
          <Dropdown title={elem.title} text={elem.text}/>
        </FaqItem>
      )}
    </>
  )
}

const FaqComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, [])

  const itemsFaq = t(`faq.items`, { returnObjects: true });

  return (
    <Faq>
      <FaqWrapper>
        <FaqMapper items={itemsFaq}/>
      </FaqWrapper>
    </Faq>
  )
}

export default FaqComponent;
