import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep, setProfileData } from 'store/actions';
import { MealsPerDay } from './styled';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils/getNextProgressStep';

const MealsPerDayComponent = ({ dispatch }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const items = t(`mealsPerDay.items`, { returnObjects: true });
  const isV4 = useIsAppVersion('v4');

  const handleSelect = (data) => {
    const mealsPerDay = data.target.value;
    dispatch(setProfileData({ mealsPerDay }));
    sendAmplitudeEvent('31_click_meal_per_day', {
      'Meals_aDay': mealsPerDay
    });
    const link = isV4 ? 'cooking-long' : 'vegetables';
    history.push(link);
  };

  useEffect(() => {
    sendAmplitudeEvent('31_view_meal_per_day');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <MealsPerDay>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('mealsPerDay.title')}
        subtitle={!isV4 && t('mealsPerDay.subtitle')}
        name={'diet'}
      />
    </MealsPerDay>
  );
};

export default reduxForm(
  {
    form: 'mealsPerDay',
    destroyOnUnmount: false
  }
)(MealsPerDayComponent);
