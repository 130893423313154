import {SET_MEAL_PROGRESS} from "store/actions";

const mealInitialState = {
    weekNumber: 1,
};

const mealProgressReducer = (state = mealInitialState, action) => {
    switch (action.type) {
        case SET_MEAL_PROGRESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default mealProgressReducer;
