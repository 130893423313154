import styled from "styled-components";
import { THEME } from "THEME";


export const DiscountExpires = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  background: #FFFFFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding-top: 14px;
  padding-bottom: 14px;

  @media (min-width: 960px) {
    padding-top: 18px;
    padding-bottom: 18px;
  }
`;
export const DiscountExpiresWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1002px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
`;

export const DiscountExpiresContent = styled.div`
  margin-right: 22px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_BLACK};

  @media (min-width: 960px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const DiscountExpiresButton = styled.div`
  width: 100%;
  max-width: 160px;

  @media (min-width: 960px) {
    max-width: 300px;
  }
`;

export const DiscountExpiresLine = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #43BB5E;
  transition: width .2s ease-out;
  width: 0;

  @media (min-width: 960px) {

  }
`;

