import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { sendAmplitudeEvent, setUserProperties } from "analytics/amplitude";
import LoginFormComponent from "components/Auth/LoginForm";
import { Logo } from "components/Icons";

import {
    LoginWrapper,
    LoginLeftBlock,
    LoginLogoBlock,
    LoginLeftContent,
    LoginLogoText,
    LoginTextBlock,
    LoginLeftHeaderText,
    LoginLeftText,
    LoginFooter,
    LoginFooterCopyright,
    LoginFooterSupport,
    LoginRightBlock,
    LoginRightHeaderText,
    LoginRightText,
    LoginTopCircle,
    // LoginBottomCircle,
    LoginCalendar
} from "./styled";

import { useDispatch } from "react-redux";
import { changeDataProgress } from "store/actions";

const LoginComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(changeDataProgress({ visible: false }));
        return () => {
            dispatch(changeDataProgress({ visible: true }));
        }
    }, []);

    useEffect(() => {
        if (history.location.state && history.location.state.prevPath === '/') {
            sendAmplitudeEvent('click_landing_login')
        }
    }, []);

    return (
            <LoginWrapper>
                <LoginLeftBlock>
                    <LoginLeftContent>
                        <LoginLogoBlock>
                            <Logo width="39px" height="48px" />
                            <LoginLogoText>Your Meal Plan</LoginLogoText>
                        </LoginLogoBlock>
                        <LoginTextBlock>
                            <LoginLeftHeaderText>Login</LoginLeftHeaderText>
                        </LoginTextBlock>
                        <LoginFormComponent />
                    </LoginLeftContent>
                    <LoginFooter>
                        <LoginFooterCopyright>
                            © 2021 Your Meal Plan. All rights reserved.
                        </LoginFooterCopyright>
                        <LoginFooterSupport>support@lasta.app</LoginFooterSupport>
                    </LoginFooter>
                </LoginLeftBlock>
                <LoginRightBlock>
                    <LoginTopCircle></LoginTopCircle>
                    <LoginCalendar src={'/images/login/calendar.png'}></LoginCalendar>
                    <LoginRightHeaderText>Personalized meal plan</LoginRightHeaderText>
                    <LoginRightText>This plan is a key to boost your metabolic engine. All meals</LoginRightText>
                    <LoginRightText>were created by professional nutritionists. </LoginRightText>
                </LoginRightBlock>
            </LoginWrapper>
    )
}

export default LoginComponent

