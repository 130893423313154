import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { changeProgressStep } from 'store/actions';
import { Metabolism } from './styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils';

const MetabolismComponent = ({ history, dispatch }) => {
  const { t } = useTranslation();
  const items = t(`metabolism.items`, { returnObjects: true });
  const isV4 = useIsAppVersion('v4');

  const handleSelect = (data) => {
    const metabolism = data.target.title;
    sendAmplitudeEvent('14_click_metabolism', {
      'Metabolism': metabolism
    });
    if (isV4) {
      history.push('typical-lunch');
    } else {
      history.push('achievement', { id: 1, flowId: 15 });
    }
  };

  useEffect(() => {
    sendAmplitudeEvent('14_view_metabolism');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <Metabolism>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('metabolism.title')}
        subtitle={!isV4 && t('metabolism.subtitle')}
        name={'Metabolism'}
      />
    </Metabolism>
  );
};

export default reduxForm(
  {
    form: 'Metabolism',
    destroyOnUnmount: false
  }
)(MetabolismComponent);
