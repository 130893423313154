import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initialize, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { changeProgressStep, setProfileData } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { Height } from './styled';
import validate from './validate';
import { resetField, transformHeight } from 'utils';
import HeightQuiz from 'components/Quiz/HeightQuiz';
import { getNextProgressStep } from 'utils/getNextProgressStep';

const HeightComponent = ({ handleSubmit, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { height, currentWeight } = useSelector((state) => state.form);
  const { measure } = height.values;
  const [heightError, setHeightError] = useState('');
  const [heightInchError, setHeightInchError] = useState('');
  const textHeight = `measure.${measure}.${
    'imperial' === measure ? 'foot' : 'centimeters'
  }`;

  const submitForm = (data) => {
    const { height: peak, heightInches: inches} = data;
    const { height, heightInches } = transformHeight(measure, measure, peak, inches);
    dispatch(setProfileData({
      measure,
      height,
      heightInches,
    }));
    sendAmplitudeEvent('6_click_height', {
      'Height': height
    });
    if (!currentWeight) {
      dispatch(initialize('currentWeight', { measure }));
    }
    history.push('current-weight');
  };

  useEffect(() => {
    if (!height?.fields?.height) {
      return;
    }
    const errorResult = validate(height.values);
    if (errorResult.height && 'metric' === measure) {
      setHeightError(() => 'Please enter a value between 120 and 250');
      return;
    }
    if (errorResult.height && 'imperial' === measure) {
      setHeightError(() => 'Please enter a value between 4 and 7');
      return;
    }
    setHeightError('');
  });

  useEffect(() => {
    if (!height?.fields?.heightInches) {
      return;
    }
    const errorResult = validate(height.values);
    if (errorResult.heightInches && 'imperial' === measure) {
      setHeightInchError(() => 'Please enter a value between 0 and 11');
      return;
    }
    setHeightInchError('');
  });

  useEffect(() => {
    sendAmplitudeEvent('6_view_height');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  const moveReset = () => {
    return resetField(dispatch, 'height', {
      height: '',
      heightInches: ''
    });
  };

  return (
    <Height onSubmit={handleSubmit(submitForm)}>
      <HeightQuiz
        title={t('height.title')}
        inputHeightTitle={`${t('messages.height')} (${t(textHeight)})`}
        inputHeightInchTitle={`${t('messages.height')} (${t(`measure.${measure}.inch`)})`}
        heightError={heightError}
        heightInchError={heightInchError}
        heightPlaceholder={`00 (${t(textHeight)})`}
        heightInchPlaceholder={`00 (${t(`measure.${measure}.inch`)})`}
        measure={measure}
        moveReset={moveReset}
      />
    </Height>
  );
};

export default reduxForm({
  form: 'height',
  destroyOnUnmount: false,
  validate,
  initialValues: { measure: 'imperial' },
})(HeightComponent);
