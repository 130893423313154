const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'invalid'
  }

  return errors;
}

export default validate;
