import styled from "styled-components";
import { THEME } from "THEME";

export const CountJoin = styled.div`
  width: 100%;
  color: ${THEME.COLOR_BLACK};
  position: relative;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 184px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #D9F1DF;
    z-index: 1;
  }

  @media (min-width: 960px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const CountJoinWrapper = styled.div`
  width: 100%;
  max-width: 1332px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  position: relative;
  z-index: 2;
`;

export const CountJoinContent = styled.div`
  width: 100%;
  max-width: 760px;
  margin: auto;
  position: relative;
  z-index: 3;
  @media (min-width: 960px) {
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const CountJoinTitle = styled.h2`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: ${THEME.COLOR_BLACK};
  margin-bottom: 10px;
  text-align: center;
  margin-top: 10px;
  @media (min-width: 960px) {
    font-size: 42px;
    line-height: 48px;
  }
`;

export const CountJoinText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  b, span {
    font-weight: 700;
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const CountJoinImageLeft = styled.img`
  display: none;
  @media (min-width: 960px) {
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    left: -20px;
    display: block;
  }
`;
export const CountJoinImageRight = styled.img`
  display: none;
  @media (min-width: 960px) {
    height: 100%;
    width: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    right: -20px;
    display: block;
  }
`;
