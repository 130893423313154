import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonComponent as Button } from 'components/Button';
import { createMarkup } from 'utils';
import { sendAmplitudeEvent } from 'analytics/amplitude';

import {
  Advantages,
  AdvantagesTitle,
  AdvantagesItems,
  AdvantagesItem,
  AdvantagesImageWrap,
  AdvantagesImage,
  AdvantagesContent,
  AdvantagesContentTitle,
  AdvantagesContentText,
  AdvantagesButton,
  AdvantagesCheck,
  AdvantagesCheckIcon,
  AdvantagesCheckName,
} from './styled';
import { CheckRounded2 } from 'components/Icons';

const AdvantagesComponent = ({ handleClick }) => {
  const { t } = useTranslation();

  const itemsAdvantages = t(`advantages.items`, { returnObjects: true });

  const handler = () => {
      sendAmplitudeEvent('y_quiz_results_getMyPlan_button_click');
      handleClick();
  };

  const checkboxItems = t(`advantages.checkboxItems`, { returnObjects: true });

  const getMediaMarkupBlock = (title, image, image2x) => {
      return (
        <AdvantagesImage
          src={`/images/advantages/${image}`}
          srcSet={`/images/advantages/${image} 320w, /images/advantages/${image2x} 480w`}
        />
      );
  };

  return (
    <Advantages>
      <AdvantagesTitle>{t('advantages.title')}</AdvantagesTitle>
      <AdvantagesItems>
        {itemsAdvantages.map((item, index) => {
          const {image, image2x, description, text, title} = item;
          return (<AdvantagesItem key={index}>
            <AdvantagesImageWrap>
              {getMediaMarkupBlock(title, image, image2x)}
            </AdvantagesImageWrap>
            <AdvantagesContent>
              <AdvantagesContentTitle>{description}</AdvantagesContentTitle>
              <AdvantagesContentText dangerouslySetInnerHTML={createMarkup(text)}/>
              {title === 'support' && checkboxItems.map((check) => <AdvantagesCheck>
                <AdvantagesCheckIcon>
                  <CheckRounded2 viewBox={'0 0 25 10'} width='100%' height='100%' color={'#fff'}/>
                </AdvantagesCheckIcon>
                <AdvantagesCheckName>{check.title}</AdvantagesCheckName>
              </AdvantagesCheck>)}
            </AdvantagesContent>
          </AdvantagesItem>)
        })}
      </AdvantagesItems>
      <AdvantagesButton>
        <Button title={t('messages.getMyPlan')} type='button' onClick={handler}/>
      </AdvantagesButton>
    </Advantages>
  );
};

export default AdvantagesComponent;
