import React from 'react';

import {Warning} from 'components/Icons';
import {ErrorRequired, ErrorRequiredIcon, ErrorRequiredText} from './styled';

const ErrorRequiredComponent = ({ children, ...props }) => {
  return (
    <ErrorRequired {...props}>
      <ErrorRequiredIcon>
        <Warning width="100%" height="100%"/>
      </ErrorRequiredIcon>
      <ErrorRequiredText>{children}</ErrorRequiredText>
    </ErrorRequired>
  )
}

export default ErrorRequiredComponent;
