import styled from "styled-components";

export const PersonalApproach = styled.div`
  background-color: #fff;
  padding-top: 110px;
  @media (min-width: 960px) {
    padding-top: 0;
    margin-top: 150px;
  }
`;

export const PersonalApproachTitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 8px;
  @media (min-width: 960px) {
    margin-bottom: 16px;
  }
`

export const PersonalApproachTitle = styled.h2`
  display: inline;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  font-weight: 800;
  
  @media (min-width: 960px) {
    font-size: 42px;
    line-height: 48px;
    font-weight: 800;
  }
  
  &:nth-child(2) {
    color: #7E80F7;
  }
`

export const PersonalApproachDetailedText = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  max-width: 289px;
  margin: auto auto 32px;
  
  @media (min-width: 960px) {
    margin: auto auto 80px;
    font-size: 24px;
    line-height: 31px;
    max-width: 100%;
  }
`

export const PersonalApproachWrapper = styled.div`
   max-width: 960px;
   margin: auto;
    @media(min-width: 1170px) {
      max-width: 970px;
    }
  
   img {
    max-width: 288px;
    @media (max-width: 960px) {
      margin: auto; 
    }
    @media (min-width: 960px) {
       max-width: 450px;
    }
    @media (min-width: 1170px) {
       max-width: 470px;
    }
    
    &:nth-child(1) {
      margin-bottom: 30px;
      
      @media (min-width: 960px) {
       margin-bottom: 0;
      }
    }
  }
`;

export const PersonalApproachImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (min-width: 960px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const PersonalApproachImage = styled.img`
    width: 100%;
    @media (min-width: 1170px) {
        max-width: 450px;
    }
`;