import styled from "styled-components";

export const MealProgress = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(3, 3, 35, 0.04);
    border-radius: 16px;
    padding: 10px 25px 25px 25px;
`

export const ProgressWeekWrapper = styled.div`
    width:21%;
    
`

export const ProgressWeekTitle = styled.h4`
    padding: 0 0 0 6px;
`

export const ProgressWeekTitleActive = styled.h4`
    padding: 0 0 0 6px;
    color: #7E80F7;
`

export const ProgressWeekBlock = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 10px 0 0 0;
`

export const ProgressDayImage = styled.img`
`