import React from 'react';
import { useTranslation } from "react-i18next";

import { createMarkup } from "utils";
import {
  GuaranteeBlock,
  GuaranteeBlockWrapper,
  GuaranteeBlockContent,
  GuaranteeBlockTitle,
  GuaranteeBlockText,
  GuaranteeImageBlock,
  GuaranteeImg
} from './styled';

const GuaranteeBlockComponent = ({ isPaymentPage, ...props }) => {
  const { t } = useTranslation();
  const guaranteeBlock = t(`guaranteeBlock`, { returnObjects: true });
  const { image, image2x } = guaranteeBlock;
  const src = `/images/guaranteeBlock/${image}`;
  const srcSet = `/images/guaranteeBlock/${image} 320w, /images/guaranteeBlock/${image2x} 480w`;
  return (
    <GuaranteeBlock>
      <GuaranteeBlockWrapper {...props} isPaymentPage={isPaymentPage}>
        <GuaranteeBlockContent isPaymentPage={isPaymentPage}>
          <GuaranteeBlockTitle isPaymentPage={isPaymentPage}>{t("guaranteeBlock.title")}</GuaranteeBlockTitle>
          <GuaranteeBlockText dangerouslySetInnerHTML={createMarkup(t("guaranteeBlock.text"))} isPaymentPage={isPaymentPage}/>
          <GuaranteeImageBlock>
            <GuaranteeImg
              src={src}
              srcSet={srcSet}
              isPaymentPage={isPaymentPage}
            />
          </GuaranteeImageBlock>
        </GuaranteeBlockContent>
      </GuaranteeBlockWrapper>
    </GuaranteeBlock>
  )

}
export default GuaranteeBlockComponent;
