import React, {useState} from "react";
import Sidebar from "components/Sidebar";
import ChangePasswordLayout from "components/Auth/Settings/Layout/ChangePasswordLayout";
import SubscriptionLayout from "components/Auth/Settings/Layout/SubscriptionLayout";

import {
    SettingsWrapper,
    SettingsContentWrapper,
    SettingsHeaderBlock,
    SettingsNavigationBlock,
    SettingsContentBlock,
    SettingsAccountDetails,
    SettingsNavigationLink,
    SettingsNavigationWrapper,
    SettingsAccountDetailsTitle, SettingContainer,
} from './styled';
import SuggesticLink from "components/SuggesticLink";

const SettingsComponent = () => {
    const [tab, setTab] = useState('subscription')

    return (
        <SettingsWrapper>
            <SettingContainer>
            <Sidebar />
            <SettingsHeaderBlock></SettingsHeaderBlock>
            <SettingsContentWrapper>
                <SettingsNavigationWrapper>
                    <SettingsNavigationBlock>
                        {/*<SettingsNavigationLink active={'account_details' === tab} onClick={() => setTab('account_details')}>Account details</SettingsNavigationLink>*/}
                        <SettingsNavigationLink active={'subscription' === tab} onClick={() => setTab('subscription')}>Subscription</SettingsNavigationLink>
                        <SettingsNavigationLink active={'change_password' === tab} onClick={() => setTab('change_password')}>Change password</SettingsNavigationLink>
                    </SettingsNavigationBlock>
                </SettingsNavigationWrapper>
                <SettingsContentBlock>
                    {/*{tab === 'account_details' && (*/}
                    {/*    <SettingsAccountDetails>*/}
                    {/*        <SettingsAccountDetailsTitle>Account Details</SettingsAccountDetailsTitle>*/}
                    {/*    </SettingsAccountDetails>*/}
                    {/*)}*/}
                    {tab === 'subscription' && (
                        <SubscriptionLayout />
                    )}
                    {tab === 'change_password' && (
                        <ChangePasswordLayout />
                    )}
                </SettingsContentBlock>
            </SettingsContentWrapper>
            </SettingContainer>
          <SuggesticLink style={{ justifyContent: 'flex-end', marginRight: '36px' }}/>
        </SettingsWrapper>
    )
}

export default SettingsComponent