import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { changeProgressStep } from 'store/actions';
import { SnackTrigger } from './styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils/getNextProgressStep';

const SnackTriggerComponent = ({ history, dispatch }) => {
  const { t } = useTranslation();
  const items = t(`snackTrigger.items`, { returnObjects: true });
  const isV4 = useIsAppVersion('v4');

  const handleSelect = (data) => {
    const trigger = data.target.title;
    sendAmplitudeEvent('19_click_snack_trigger', {
      'snack_trigger': trigger
    });
    const link = isV4 ? 'discomfort' : 'workout';
    history.push(link);
  }

  useEffect(() => {
    sendAmplitudeEvent('19_view_snack_trigger');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <SnackTrigger>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('snackTrigger.title')}
        name={'snack_trigger'}
      />
    </SnackTrigger>
  );
};

export default reduxForm(
  {
    form: 'snackTrigger',
    destroyOnUnmount: false
  }
)(SnackTriggerComponent);
