import styled from "styled-components";
import {BACKGROUND_GRADIENT, THEME} from "THEME";


export const DataProcessing = styled.form`
  width: 100%;
`;

export const DataProcessingWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 54px 16px 30px;
  @media (min-width: 960px) {
    padding-top: 96px;
    padding-bottom: 40px;
  }
`;

export const DataProcessingTitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #191B1F;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const DataProcessingPreTitle = styled.p`
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${THEME.COLOR_DARK_GRAY};
  @media (min-width: 960px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Linear = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
  @media (min-width: 960px) {
    margin-bottom: 40px;
  }
`;

export const LinearLine = styled.div`
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: #E0E0E0;
  position: relative;
  overflow: hidden;
`;

export const LinearFilling = styled.div`
  width: 0%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-image: ${BACKGROUND_GRADIENT.COLOR_GREEN_PROGRESS};
  border-radius: 5px;
  transition: width .2s ease-out;
`;

export const LinearPercent = styled.p`
  background-color: #43BB5E;
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-weight: bold;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 10px;
  @media (min-width: 960px) {
    font-size: 65px;
    line-height: 76px;
    margin-bottom: 10px;
  }
`;

export const LinearText = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${THEME.COLOR_DARK_GRAY};
  margin-bottom: 20px;

  @media (min-width: 960px) {
    margin-bottom: 40px;
  }
`;
