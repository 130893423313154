import styled from 'styled-components';
import { THEME } from 'THEME';

export const SeparatorParent = styled.div`
  background-color: ${THEME.COLOR_DARK_GREEN};
`
export const SeparatorWrapper = styled.div`
  width: 100%;
  max-width: 460px;
  padding: 30px 16px;
  margin: 0 auto 50px auto;
  @media (min-width: 960px) {
    padding: 0 40px;
  }
  @media (max-width: 460px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
`

export const SeparatorTextWrapper = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: #FFF;
  margin: 40px 0;
  @media (max-width: 460px) {
    align-self: start;
  }
  @media (max-width: 400px) {
    line-height: 24px;
    font-size: 18px;
  }
`

export const SeparatorImage = styled.img`
  margin: 80px 0 0 0;
  max-width: ${props => props.maxWidth ? props.maxWidth : '122px'};
  
  @media (max-width: 400px) {
    margin: 0;
  }
`

export const SeparatorWrapButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 12px 16px;
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.25);
  background-color: ${THEME.COLOR_DARK_GREEN};
  @media (min-width: 960px) {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0);
    padding: 16px 0 0 0;
  }
`;
