import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { controlSteps, V3Steps, V4Steps } from 'store/reducers/progress';
import { ArrowLineLeft } from 'components/Icons';
import {
  Progress,
  ProgressWrapper,
  ProgressBack,
  ProgressBackSpace,
  ProgressCounts,
  ProgressLine
} from './styled';
import { changeDataProgress } from 'store/actions';
import { useIsAppVersion } from 'customHooks';

const ProgressComponent = () => {
  const { activeStep, allSteps } = useSelector((state) => state.progress);
  const history = useHistory();
  const dispatch = useDispatch();
  const isV3 = useIsAppVersion('v3');
  const isV4 = useIsAppVersion('v4');

  const getSteps = () => {
    if (!isV3 && !isV4) {
      return controlSteps;
    }
    return isV3 ? V3Steps : V4Steps;
  };

  useEffect(() => {
    dispatch(changeDataProgress({ allSteps: getSteps() }));
  }, [isV3, isV4]);

  const goBack = () => {
    const objectRoute = activeStep > 0 ? allSteps[activeStep - 1] : '/';
    history.push(`${objectRoute.route}`);
  };

  return (
    <Progress isV4={isV4}>
      <ProgressWrapper>
        {activeStep === 0 ? <ProgressBackSpace/> :
          <ProgressBack onClick={goBack}>
            <ArrowLineLeft width='100%' height='100%' />
          </ProgressBack>}
        <ProgressCounts>Q {(activeStep + 1)}/{allSteps.length}</ProgressCounts>
      </ProgressWrapper>
      <ProgressLine style={{width: `${(activeStep + 1) / allSteps.length * 100}%`}}/>
    </Progress>
  );
};

export default ProgressComponent;
