import React from "react";
import {useLocation} from "react-router-dom";
import DashboardHeaderComponent from "components/DashboardHeader";
import HeaderComponent from "../Header/Header";

const HeaderStrategyComponent = () => {
  const location = useLocation();

  const getHeader = () => {
    if (location.pathname === '/login') {
      return <></>
    }
    if (true === authPaths.some(isAuth)) {
      return <DashboardHeaderComponent />
    }

    return <HeaderComponent />
  }

  const authPaths = [
    '^/meal-plan$',
    '^/meal-plan/recipe/',
    '^/meal-plan/settings$'
  ]

  function isAuth(path) {
    return location.pathname.match(path)
  }

  return (
    getHeader()
  )
}

export default HeaderStrategyComponent;