import amplitude from "amplitude-js";
import config from "config";

amplitude.getInstance().init(
    config.amplitudeApiKey,
    null,
    {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true,
        includeGclid: true,
        saveParamsReferrerOncePerSession: false
    }
);

export const sendAmplitudeEvent = (name, properties = {}) => {
    amplitude.getInstance().logEvent(name, properties);
}

export const setUserProperties = (props = {}) => {
    amplitude.getInstance().setUserProperties(props);
}