import styled from 'styled-components';
import Select from 'react-select';
import { THEME } from 'THEME';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 30px 16px;
  @media (min-width: 960px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
`;

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #444444;
  margin-top: 20px;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const UpTitle = styled.p`
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #444444;
  margin-bottom: 10px;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const StyledSelect = styled(Select)`
  margin-right: 10px;
  width: ${props => props.width ?? 'inherit'};
  .Select__control {
    height: 40px;
    width: 100%;
    background: ${props => props.background ?? '#F4F1E9'};
    border-color: transparent;
    border-radius: 8px;
    margin: 5px 0;
    padding-left: 10px;
    color: #7C7357;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
  }
  
  .Select__single-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7C7357;
    text-align: start;
    max-width: 70px;
  }
  
  .Select__value-container {
    padding: 0;
  }

  .Select__control:hover {
    border-color: transparent;
    outline: none !important;
    box-shadow: none;
  }

  .Select__control--is-focused {
    outline: none !important;
    box-shadow: none;
  }
  
  .Select__indicator {
    padding: 8px 8px 8px 0;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7C7357;
    border-radius: 8px;
    margin: 0;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
  }

  .Select__menu-list {
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    border-radius: 8px;
    border: 1px transparent;
    padding: 0;
  }

  .Select__option {
    padding: 4px 12px;
    text-align: start;
    
    
  }

  .Select__option--is-focused, .Select__option--is-selected {
    color: #7C7357;
    font-weight: 500;
  }
`;

export const SelectWrapper = styled.div`
  margin: 0;
  display: flex;
  justify-content: center;

  .Select__option--is-focused, .Select__option--is-selected {
    background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN};
  }

  .Select__option {
    :active {
      background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN};
    }
  }
`

export const Input = styled.div`
  width: 100%;
  margin-bottom: 20px;
  flex: 1 1 100%;
  @media (min-width: 960px) {
    margin-bottom: 16px;
    flex: 1 1 40%;
    &:nth-child(odd) {
      margin-right: 30px;
      max-width: 270px;
    }
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  right: 20px;
  width: 42px;
  height: 42px;
  top:50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: 0.4s;
`;

export const InputValue = styled.input`
  border-radius: 12px;
  border: 2px solid rgba(234, 228, 209, 0);
  height: 48px;
  width: 100%;
  padding-left: 20px;
  padding-right: 50px;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_BLACK};
  transition: border ${THEME.TRANSITION}, background-color ${THEME.TRANSITION};
  
  &:focus + div {
    background: white;
  }

  @media (min-width: 960px) {
    height: 64px;
    
  }

  ::-webkit-input-placeholder {
    color: #d8d8db;
  }

  ::-moz-placeholder {
    color: #d8d8db;
  }

  :-moz-placeholder {
    color: #d8d8db;
  }

  :-ms-input-placeholder {
    color: #d8d8db;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;
  @media (min-width: 960px) {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #4C4C4C;
`;


export const ErrorText = styled.p`
    display: block;
    width: 100%;
    color: red;
    height: 30px;
`

export const ErrorForm = styled.div`
  margin-top: 20px;
`;

export const WrapButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  padding: 12px 16px;
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  @media (min-width: 960px) {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0);
    padding: 16px 0 0 0;
  }
`;

export const Items = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: 960px) {
    padding-top: 30px;
    padding-bottom: 4px;
  }
`;

export const Item = styled.div`
  margin-bottom: 12px;
  @media (min-width: 960px) {
    margin-bottom: 16px;
  }
`;

export const YesNoItems = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    padding-top: 30px;
    flex-direction: row;
    margin: 0 -15px;
  }
`;

export const YesNoItem = styled.div`
  margin-bottom: 12px;
  width: 100%;
  @media (min-width: 960px) {
    margin: 0 15px;
  }
`;

export const StronglyItem = styled.div`
  & label{
    padding: 0;
    justify-content: center;
  }
  
  & label, & label input, &{
    height:64px;
    width:100%;
    margin-right: 10px;
  }
  @media (max-width: 960px) {
    & label, & label input, &{
      height: 48px;
      margin-right:2px
    }
  }
`;

export const StronglyItems = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 4px;
  @media (min-width: 960px) {
    padding-top: 30px;
  }
  
  & ${StronglyItem}:nth-last-child(1){
    margin-right: 0;
  }
`;

export const WeightInput = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const HeightInput = styled.div`
  width: 100%;
  margin-bottom: 20px;
  flex: 1 1 100%;
  @media (min-width: 960px) {
    margin-bottom: 16px;
    flex: 1 1 40%;
    &:nth-child(odd) {
      margin-right: 30px;
      max-width: 270px;
    }
  }
`;

export const HeightLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

export const InputBody = styled.div`
  position: relative;
  
  ${InputValue} {
    background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN};

    @media (min-width: 960px) {
      &:hover {
        border: ${`2px solid ${props => props?.isV4 ? THEME.COLOR_DARK_GREEN : 'rgba(234, 228, 209, 1)'}`};
      }

      &:focus {
        border: ${`2px solid ${props => props?.isV4 ? THEME.COLOR_DARK_GREEN : 'rgba(234, 228, 209, 0)'}`};
        background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN_FOCUSED};
      }
    }
  }
`;