import styled from 'styled-components';

export const Title = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: #090909;
  ${(props) => ({...props.styleProps})};
`;

export const Title2 = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #787878;
  margin-top: 8px;
  margin-bottom: 36px;
  ${(props) => ({...props.styleProps})};
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px 12px 12px;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 36px;
  ${(props) => ({...props.styleProps})};
`;

export const SubTitleInner = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;

export const SubTitleHeader = styled.div`
  font-family: 'DM Sans',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #090909;
  text-align: left;
`;

export const SubTitle = styled.div`
  max-width: 230px;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #090909;
`;

export const SubTitleImg = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 40px;
  ${(props) => ({...props.styleProps})};
  
  @media (max-width: 500px) {
    margin-right: 20px;
  }
  @media (max-width: 380px) {
    margin-right: 12px;
  }
`;

export const WhatYouGetSection = styled.div`
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

export const ImageBlockText = styled.div`
  position: absolute;
  font-family: 'DM Sans',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  color: #090909;
  max-width: 220px;
  width: 100%;
  text-align: start;
  z-index: 1;
  ${(props) => ({...props.styleProps})};
  
  @media (max-width: 475px) {
    max-width: 180px;
    width: 100%;
    font-size: 14px;
  }
  @media (max-width: 380px) {
    max-width: 160px;
    width: 100%;
    font-size: 14px;
  }


  span {
    font-family: 'DM Sans',sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #537EE7;
    
  }
`;

export const ContentList = styled.div`
  &:last-child {
    margin-bottom: 0;
  }
  margin-bottom: 36px;
`;

export const ContentImg = styled.img`
  margin-bottom: 14px;
  margin-right: 12px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ImageSection = styled.img`
  position: relative;
  width: 100%;
`;

export const ContentItem = styled.div`
  position: relative;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #090909;
  margin-bottom: 14px;
`;

export const DiscountBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ECE8DE;
  border-radius: 100px;
  width: 100%;
  margin-bottom: 120px;
`;

export const DiscountLeft = styled.div`
  padding: 20px;
  background-image: url(/images/upSell/background.png);
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: 'DM Sans', sans-serif;
  color: #17181B;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 110%;

  @media (min-width: 380px) {
    margin-right: 25px;
  }
`;

export const DiscountRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px 0;
`;

export const DiscountRightItem = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #43BB5E;
  
  span {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #17181B;
    margin: 0 5px;
  }
`;
export const DefaultPrice = styled.div`
  display: inline;
  color: #43BB5E;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration: line-through;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 30px 16px;
  @media (min-width: 960px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 602px;
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  z-index: 1;
  padding: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

export const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 13px;
`;

export const ButtonWrapperModal = styled.div`
  width: 100%;
  background-color: white;
`;

export const ButtonNext = styled.div`
  text-decoration: underline;
  width: 100%;
  margin-top: 10px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #43BB5E;
  text-align: center;
  background-color: white;
  cursor: pointer;
  &:hover {
    color: #43BB55;
  }

`;

export const ModalWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SuccessTitle = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #17181B;
  margin-bottom: 8px;
  margin-top: 14px;
  ${(props) => ({...props.styleProps})};
`;

export const ContentModalBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F8F6F0;
  border-radius: 12px;
  margin-top: 24px;
  margin-bottom: 52px;
`;
export const ContentModalText = styled.div`
  padding: 22px 20px;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #17181B;
  text-align: center;
  
  span {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #537EE7;
  }

`;

export const ContentModalIcon = styled.img`
  padding: 8px 12px;

`;

