import React from 'react';
import { Field } from 'redux-form';
import {
  TileTypeImage,
  TileTypeContent,
  TileTypeTitle,
  TileTypeDescription,
  TileTypeLabel,
  TileTypeBackground
} from './styled';

const TileTypeComponent = ({ title, description, imageName, name, value, handleSelect, isV4 }) => {
  return (
    <TileTypeLabel isV4={isV4} onClick={handleSelect}>
      <TileTypeContent>
        <TileTypeTitle>{title}</TileTypeTitle>
        <TileTypeDescription>{description}</TileTypeDescription>
      </TileTypeContent>
      <TileTypeImage
        src={`/images/${imageName}`}
        width={80}
        height={80}
        loading='lazy'
      />
      <Field name={name} type='radio' value={value} component='input'/>
      <TileTypeBackground/>
    </TileTypeLabel>
  )
}

export default TileTypeComponent;
