import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentContainer, PaymentSectionTitle } from 'pages/Payment/styled';
import {
  BenefitPlanWrapper,
  PriceInfoBlock,
  PriceInfoBlockWrapper,
  PriceInfoBlockText,
  PriceInfoBlockDescription,
  PriceInfoBlockValue,
  PriceInfoBlockContent,
  CheckBoxContentWrapper,
  WrapButton
} from './styled';
import CheckBoxContent from 'components/Payment/ui/CheckBoxContent';
import { useSelector } from 'react-redux';
import { ButtonComponent as Button } from 'components/Button';
import { DiscountTrialBlock } from './components/DiscountTrialBlock';
import { isNullish } from 'utils';
import PaidTrial from 'pages/PaidTrial';
import { useIsAppVersion } from 'customHooks';
import ChooseYourPlan from 'pages/Payment/components/ChooseYourPlan';

const BenefitsPlan = ({ getDiffWeight, scrollToCheckout }) => {
  const { t } = useTranslation();
  const { paymentPlan, remoteConfig } = useSelector((state) => state);
  const { paidTrial } = paymentPlan;
  const { checkoutPage } = remoteConfig || {};
  const {
    priceBlock,
    trial,
    additionalBlock,
    paidTrialBlock
  } = checkoutPage || {};
  const {
    trialText,
    trialCost,
    trialDuration
  } = trial || {};

  const { benefits } = additionalBlock || {};
  const checkboxItems = t(`payment.benefitPlan.checkboxItems`, { returnObjects: true });
  const title = t('payment.benefitPlan.title');
  const isPriceBlock = !isNullish(priceBlock);
  const isPriceInfoBlock = !isNullish(trial);
  const isV4 = useIsAppVersion('v4');

  const getPriceInfoBlock = () => {
    return (
      <>
        {isPriceInfoBlock && <PriceInfoBlock>
          <PriceInfoBlockWrapper>
            <PriceInfoBlockText>
              {trialText}
            </PriceInfoBlockText>
            <PriceInfoBlockContent>
              <PriceInfoBlockValue>
                ${paidTrial?.price ?? trialCost}
              </PriceInfoBlockValue>
              <PriceInfoBlockDescription>
                {trialDuration}
              </PriceInfoBlockDescription>
            </PriceInfoBlockContent>
          </PriceInfoBlockWrapper>
        </PriceInfoBlock>}
      </>
    );
  };

  const benefitPlanBlock = () => {
    return (
      <>
        <PaymentSectionTitle>{title}</PaymentSectionTitle>
        <BenefitPlanWrapper>
          <CheckBoxContentWrapper>
            <CheckBoxContent checkboxItems={checkboxItems.left} getDiffWeight={getDiffWeight}/>
          </CheckBoxContentWrapper>
          <CheckBoxContentWrapper>
            <CheckBoxContent checkboxItems={checkboxItems.right} getDiffWeight={getDiffWeight}/>
          </CheckBoxContentWrapper>
        </BenefitPlanWrapper>
      </>
    );
  };

  const benefitPlan = () => {
    if (isV4 && benefits) {
      return benefitPlanBlock();
    } else if (!isV4) {
      return benefitPlanBlock();
    }
  };


  return (
    <PaymentContainer benefits={benefits}>
      {benefitPlan()}
      {isV4 && paidTrialBlock && <PaidTrial isV4  />}
      {isV4 && <ChooseYourPlan />}
      {getPriceInfoBlock()}
      <DiscountTrialBlock/>
      {isPriceBlock && <WrapButton>
        <Button title={t('messages.getMyPlan')} type="submit" onClick={scrollToCheckout}/>
      </WrapButton>}
    </PaymentContainer>
  );
};

export default BenefitsPlan;