import styled from "styled-components";

export const Email = styled.div`
  width: 100%;
`;


export const EmailItems = styled.div`
  margin-bottom: 24px;
`;


export const EmailWrapButton = styled.div`
  position: ${props => props.isPaymentPage ? 'relative' : 'fixed'};
  bottom: 0;
  left: 0;
  width: ${props => props.isPaymentPage ? '100%' : '100vw'};
  padding: ${props => props.isPaymentPage ? '12px 0' : '12px 16px'};
  box-shadow: ${props => props.isPaymentPage ? 'unset' : '0px -0.5px 0px rgba(0, 0, 0, 0.25)'};
  background-color: #fff;
  @media (min-width: 960px) {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0);
    padding: 16px 0 0 0;
  }
`;
