import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { changeProgressStep } from 'store/actions';
import { FailBack } from './styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import StronglyQuizComponent from 'components/Quiz/StronglyQuiz';
import { getNextProgressStep } from 'utils/getNextProgressStep';

const FailBackComponent = ({ history, dispatch }) => {
  const { t } = useTranslation();

  const handleSelect = (data) => {
    const failBack = data.target.title;
    sendAmplitudeEvent('45_click_Statement2_fail_back', {
      'fail_back': failBack
    });
    history.push('outside-motivation');
  };

  useEffect(() => {
    sendAmplitudeEvent('45_view_Statement2_fail_back')
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);
  return (
    <FailBack>
      <StronglyQuizComponent
        handleSelect={handleSelect}
        uptitle={t('failBack.upTitle')}
        title={t('failBack.title')}
        name={'fail_back'}
      />
    </FailBack>
  );
};

export default reduxForm(
  {
    form: 'failBack',
    destroyOnUnmount: false
  }
)(FailBackComponent);
