import moment from 'moment';

export const getTrialDate = (trialDuration) => {
  if (!trialDuration) {
    return;
  }
  const dayNumber = trialDuration[0];
  const date = moment().add(Number(dayNumber), 'days');
  return  {
    month: date.format('MMMM'),
    day: date.format('DD'),
    year: date.year(),
  };
};