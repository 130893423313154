import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonComponent as Button } from 'components/Button';
import Input from 'components/Input';
import {
  Wrapper,
  Title,
  Subtitle,
  Content,
  WrapButton,
  ErrorText
} from 'components/Quiz/styled';
import { useIsAppVersion } from 'customHooks';

const SingleInputQuiz = ({ title, inputTitle, subtitle, type, name, error, placeholder, onChange }) => {
  const { t } = useTranslation();
  const isV4 = useIsAppVersion('v4');

  return (
      <Wrapper>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Content isV4={isV4}>
          <Input
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            title={inputTitle} />
          <ErrorText>
            {error}
          </ErrorText>
          <WrapButton>
            <Button title={t('messages.continue')} type='submit'/>
          </WrapButton>
        </Content>
      </Wrapper>
  );
};

export default SingleInputQuiz;
