import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckRounded2 } from 'components/Icons';
import { ButtonComponent as Button } from 'components/Button';
import { createMarkup } from 'utils';
import { sendAmplitudeEvent } from 'analytics/amplitude';

import {
  ProblemAreas,
  ProblemAreasContent,
  ProblemAreasTitle,
  ProblemAreasChecks,
  ProblemAreasCheck,
  ProblemAreasCheckIcon,
  ProblemAreasCheckName,
  ProblemAreasText,
  ProblemAreasImageWrap,
  ProblemAreasImage,
  ProblemAreasButton
} from './styled';

const ProblemAreasComponent = ({ gender, handleClick }) => {
  const { t } = useTranslation();
  const checkItems = t(`problemAreas.checks`, { returnObjects: true });

  const handler = () => {
      sendAmplitudeEvent('y_quiz_results_getMyPlan_button_click');
      handleClick();
  };

  return (
    <ProblemAreas>
      <ProblemAreasContent>
        <ProblemAreasTitle>{t(`problemAreas.title`)}</ProblemAreasTitle>
        <ProblemAreasChecks>
          {checkItems.map((check, index) => <ProblemAreasCheck key={index}>
            <ProblemAreasCheckIcon>
              <CheckRounded2 width='100%' height='100%' color={'#fff'}/>
            </ProblemAreasCheckIcon>
            <ProblemAreasCheckName>{check.title}</ProblemAreasCheckName>
          </ProblemAreasCheck>)}
        </ProblemAreasChecks>
        <ProblemAreasText dangerouslySetInnerHTML={createMarkup(t(`problemAreas.text`))}/>
      </ProblemAreasContent>
      <ProblemAreasImageWrap>
        <ProblemAreasImage src={`/images/problemAreas-${gender}.png`}/>
      </ProblemAreasImageWrap>
      <ProblemAreasButton>
        <Button title={t('messages.getMyPlan')} type='button' onClick={handler}/>
      </ProblemAreasButton>
    </ProblemAreas>
  )
}

export default ProblemAreasComponent;
