import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { changeProgressStep, setProfileData } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { Birthday } from './styled';
import DateQuiz from 'components/Quiz/DateQuiz';
import validate from './validate';
import { formatDate, getNextProgressStep, yearDiff } from 'utils';

const BirthdayComponent = ({ handleSubmit, dispatch }) => {
  const { birthday } = useSelector((state) => state.form);
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const history = useHistory();

  const submitForm = (data) => {
    const age = yearDiff(data.birthDate);
    const birthDate = formatDate(data.birthDate);
    dispatch(setProfileData({
      age,
      birthDate
    }));

    sendAmplitudeEvent('3_click_birthday', {
      birthday: birthDate
    });

    history.push('/current-weight');
  };

  useEffect(() => {
    sendAmplitudeEvent('3_view_birthday');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, [])

  useEffect(() => {
    if (!birthday.fields) {
      return;
    }

    if (!birthday.fields.birthDate) {
      return;
    }

    const errorResult = validate(birthday.values);

    if ('required' === errorResult.birthDate) {
      setError(() => 'Your date is required');
      return;
    }

    if ('error' === errorResult.birthDate) {
      setError(() => 'Your age should be between 18 and 99');
      return;
    }

    return setError('');
  });

  return (
    <Birthday onSubmit={handleSubmit(submitForm)}>
      <DateQuiz
        title={t('birthday.title')}
        subtitle={t('birthday.subtitle')}
        type={'text'}
        name={'birthDate'}
        placeholder={'Select a date'}
        inputTitle={'Date of birth'}
        maxYear={100}
        minYear={18}
        error={error}
        onChange={() => setError('')}
      />
    </Birthday>
  )
}

export default reduxForm({
  form: 'birthday',
  destroyOnUnmount: false,
  validate
})(BirthdayComponent);
