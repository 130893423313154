import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { sendAmplitudeEvent, setUserProperties } from 'analytics/amplitude';
import { sendFacebookEvent } from 'analytics/facebookPixel';
import { changeProgressStep, setProfileData, setRemoteConfig, setUrlLinkRemoteConfig } from 'store/actions';
import { GenderForm, DisclaimerText } from './styled';
import { useGetGaExperimentId } from 'customHooks';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { reduxForm } from 'redux-form';
import { getNextProgressStep } from 'utils';
import { pinterestEvent } from 'analytics/pinterest';
import { getRemoteConfig } from 'remoteConfig';

const Gender = ({ dispatch }) => {
  const routeMatch = useRouteMatch();
  const { t } = useTranslation();
  const { search } = window.location;
  const history = useHistory();
  const routing = routeMatch?.params?.id && `/${routeMatch?.params?.id+search}`;

  const handleSelect = (data) => {
    const gender = data.target.value;
    dispatch(setProfileData({ gender }));
    sendAmplitudeEvent('1_click_gender', { gender });
    history.push('/your-name');
  };

  const experimentIdData = useGetGaExperimentId();
  useEffect(() => {
    setUserProperties({ ...experimentIdData });
    sendAmplitudeEvent('1_view_gender');
    sendFacebookEvent('ViewContent');
    pinterestEvent('Pagevisit');
    dispatch(changeProgressStep(getNextProgressStep()));
    dispatch(setUrlLinkRemoteConfig(routing));
    getRemoteConfig('master', routeMatch.params.id).then((data) => {
      dispatch(setRemoteConfig(data));
    })
  }, []);

  const items = t('gender.items', { returnObjects: true });

  return (
    <GenderForm>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('gender.title')}
        subtitle={t('gender.subtitle')}
        name={'sex'}
      />
      <DisclaimerText>{t('disclaimer')}</DisclaimerText>
    </GenderForm>
  );
};

export default reduxForm(
  {
    form: 'gender',
    destroyOnUnmount: false
  }
)(Gender);
