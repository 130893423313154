import styled from "styled-components";

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100px;
  @media (min-width: 960px) {
    max-width: 150px;
  }
`;

export const LoadingImage = styled.img`
  width: 100%;
  height: auto;
`;
