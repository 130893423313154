import styled from "styled-components";

export const GoingDay = styled.form`
  width: 100%;
`;


export const Schedule = styled.div`
  width: 100%;
`;

export const ScheduleWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 30px 16px;
  @media (min-width: 960px) {
    padding-top: 96px;
    padding-bottom: 40px;
  }
`;

export const ScheduleTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
  text-align: center;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const SchedulePreTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ScheduleImageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 33px;
  @media (min-width: 960px) {
    padding-top: 30px;
    padding-bottom: 20px;
  }
`;

export const ScheduleImage = styled.img`
  width: 100%;
  max-width: 230px;
  height: auto;
  @media (min-width: 960px) {
    max-width: 280px;
  }
`;


export const ScheduleWrapButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 12px 16px;
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  @media (min-width: 960px) {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0);
    padding: 16px 0 0 0;
  }
`;
