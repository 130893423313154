import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import { loadState } from "../localStorage"
import reducer from "./reducers";
import promise from "./middlewares/promise";

const middleware = [thunk, promise];
const persistedStateCookie = loadState('reduxState');
export default createStore(
  reducer,
  persistedStateCookie,
  composeWithDevTools(applyMiddleware(...middleware))
);
