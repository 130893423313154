import React from 'react';
import { useTranslation } from "react-i18next";

import { createMarkup } from "utils";
import {
  OftenAsk,
  OftenAskWrapper,
  OftenAskTitle,
  OftenAskItems,
  OftenAskItem,
  OftenAskItemTitle,
  OftenAskItemText
} from './styled';

const OftenAskComponent = () => {

  const { t } = useTranslation();
  const itemsOftenAsk = t(`oftenAsk.items`, { returnObjects: true });

  return (
    <OftenAsk>
      <OftenAskWrapper>
        <OftenAskTitle>{t("oftenAsk.title")}</OftenAskTitle>
        <OftenAskItems>
          {itemsOftenAsk.map((elem, count) =>
            <OftenAskItem key={count}>
              <OftenAskItemTitle>{elem.title}</OftenAskItemTitle>
              <OftenAskItemText dangerouslySetInnerHTML={createMarkup(elem.text)} />
            </OftenAskItem>)}
        </OftenAskItems>
      </OftenAskWrapper>
    </OftenAsk>
  )
}

export default OftenAskComponent;
