import React, { useEffect } from 'react';
import { reduxForm } from "redux-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { changeDataProgress } from "store/actions";
import Email from 'components/Email';
import {
  MealAccess,
  MealAccessWrapper,
  MealAccessTitle
} from './styled';
import validate from "./validate";

const MealAccessComponent = ({handleSubmit}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const submitForm = () => {
    // history.push("/overall-results");
  };

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, []);

  return (
    <MealAccess onSubmit={handleSubmit(submitForm)}>
      <MealAccessWrapper>
        <MealAccessTitle>{t("mealAccess.title")}</MealAccessTitle>
        <Email placeholder={t("mealAccess.email.placeholderInput")}
               titleInput={t("mealAccess.email.titleInput")} buttonText={t("messages.continue")}/>
      </MealAccessWrapper>
    </MealAccess>
  )
}

export default reduxForm({
  form: 'email',
  destroyOnUnmount: false,
  validate
})(MealAccessComponent)

