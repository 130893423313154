import styled from "styled-components";


export const GenderForm = styled.form`
  width: 100%;
`;

export const DisclaimerText = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 48px;
  margin-top: 130px;
  color: #A7A7A7;
  padding: 0 15px;

  @media(max-width: 768px) {
    margin-bottom: 64px;
    margin-top: 24px;
  }
`
