import React from 'react'
import { useTranslation } from 'react-i18next';

import { ButtonComponent as Button } from 'components/Button';

import {
  GetMyPlan,
  GetMyPlanImage,
  GetMyPlanContent,
  GetMyPlanTitle,
  GetMyPlanSubtitle,
  GetMyPlanButton,
  GetMyPlanWrapper,
  GetMyPlanLeftSection,
  GetMyPlanRightSection
} from './styled'

const GetMyPlanComponent = ({ handleClick }) => {
  const { t } = useTranslation();

  const items = t('landingV4.getMyPlan', { returnObjects: true });
  const { title, subtitle, buttonText, image } = items;

  return <GetMyPlan>
    <GetMyPlanContent>
      <GetMyPlanWrapper>
        <GetMyPlanLeftSection>
          <GetMyPlanTitle>
            {title}
          </GetMyPlanTitle>

          <GetMyPlanSubtitle>
            {subtitle}
          </GetMyPlanSubtitle>
          <GetMyPlanButton>
            <Button title={buttonText} type='button' onClick={handleClick}/>
          </GetMyPlanButton>
        </GetMyPlanLeftSection>

        <GetMyPlanRightSection>
          <GetMyPlanImage src={image} />
        </GetMyPlanRightSection>
      </GetMyPlanWrapper>
    </GetMyPlanContent>
  </GetMyPlan>
}

export default GetMyPlanComponent;