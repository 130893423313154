import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDown } from 'components/Icons';
import {
  Menu,
  StyledLink,
  Dropping,
  DroppingList,
  DroppingArrow,
  DroppingName,
  DroppingShadow,
  LoginLink
} from './styled';

const MenuComponent = ({ visibleMenu, changeVisibleMenu, isV4 }) => {
  const { t } = useTranslation();
  return (
    <Menu visibleMenu={visibleMenu} isV4={isV4}>
      <StyledLink onClick={() => changeVisibleMenu(false)} to='/faq'>{t('header.faq')}</StyledLink>
      <StyledLink onClick={() => changeVisibleMenu(false)} to='/support'>{t('header.support')}</StyledLink>
      <Dropping>
        <DroppingName>{t('header.termsPolicies')}</DroppingName>
        <DroppingArrow>
          <ArrowDown width='8.5px' height='8.5px'/>
        </DroppingArrow>
        <DroppingList>
          <StyledLink onClick={() => changeVisibleMenu(false)} to='/refund-policy'>{t('header.refundPolicy')}</StyledLink>
          <StyledLink onClick={() => changeVisibleMenu(false)} to='/privacy-policy'>{t('header.privacyPolicy')}</StyledLink>
          <StyledLink onClick={() => changeVisibleMenu(false)} to='/terms-of-service'>{t('header.termsOfUse')}</StyledLink>
          <DroppingShadow/>
        </DroppingList>
      </Dropping>
    </Menu>
  );
};

export default MenuComponent;
