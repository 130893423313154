import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';

import { changeProgressStep } from 'store/actions';
import { Diabetes } from './styled';
import YesNoQuizComponent from 'components/Quiz/YesNoQuiz';
import { getNextProgressStep } from 'utils';

const DiabetesComponent = ({ dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleSelect = (data) => {
    const diabetes = data.target.value;
    sendAmplitudeEvent('26_click_Diabetes', { diabetes });
    history.push('allergies');
  }

  useEffect(() => {
    sendAmplitudeEvent('26_view_Diabetes');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <Diabetes>
      <YesNoQuizComponent
        title={t('diabetes.title')}
        name={'diabetes'}
        handleSelect={handleSelect}
      />
    </Diabetes>
  );
};

export default reduxForm(
  {
    form: 'diabetes',
    destroyOnUnmount: false
  }
)(DiabetesComponent);