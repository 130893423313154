import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonComponent as Button } from 'components/Button';
import {
  Wrapper,
  Title,
  Subtitle,
  Content,
  WrapButton,
  InputBody,
  ErrorText,
  InputValue,
  SelectWrapper,
  StyledSelect,
  InputIcon,
} from 'components/Quiz/styled';
import DatePicker from 'react-datepicker';
import 'components/Quiz/DateQuiz/react-datepicker.css'
import moment from 'moment';
import { Field } from 'redux-form';
import { DateIcon } from 'components/Icons';
import { Input, InputTitle } from 'components/Input/styled';
import {
  currentMonth,
  range,
  subtractYears
} from 'utils';
import { monthNames as months } from 'utils';
import { useIsAppVersion } from 'customHooks';

const StyledInput = ({ onChange, placeholder, type, name, onClick, value, isV4 }) => {
  return (
    <InputBody isV4={isV4}>
      <InputValue value={value} type={type} name={name} placeholder={placeholder} onChange={onChange} onClick={onClick} readOnly/>
      <InputIcon onClick={onClick}>
        <DateIcon/>
      </InputIcon>
    </InputBody>
  );
};

const renderDateTimePicker = ({ input: { onChange, value }, minYear, maxYear, years, isOpen, setIsOpen, defaultYearValue, isV4 }) =>
{
  const mapOptions = (array) => {
    return array.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  };
  const monthOptions = mapOptions(months);
  const yearOptions = mapOptions(years);

  defaultYearValue = defaultYearValue ? yearOptions[years.indexOf(defaultYearValue)] : yearOptions[0];
  return(
    <DatePicker
      renderCustomHeader={({ changeYear, changeMonth }) => (
        <SelectWrapper isV4={isV4}>
          <StyledSelect
            classNamePrefix='Select'
            width={'140px'}
            background={isV4 ? '#D8E6DF' : '#F4F1E9'}
            options={monthOptions}
            onChange={(e) => changeMonth(months.indexOf(e.value))}
            defaultValue={monthOptions[months.indexOf(currentMonth())]}
            isSearchable={false}
          />
          <StyledSelect
            classNamePrefix='Select'
            background={'white'}
            width={'inherit'}
            options={yearOptions}
            onChange={(e) => {changeYear(e.value)}}
            defaultValue={defaultYearValue}
            isSearchable={false}
          />
        </SelectWrapper>
      )}
      onChange={(newDate: Date, e) => {
        if (newDate) {
          onChange(moment(newDate));
          setIsOpen(false);
        }
      }}
      minDate={subtractYears(maxYear).toDate()}
      maxDate={subtractYears(minYear).toDate()}
      dateFormat={'MMMM dd yyyy'}
      placeholderText={'Select a date'}
      showPopperArrow={false}
      closeOnScroll={false}
      shouldCloseOnSelect={true}
      showMonthDropdown
      showYearDropdown
      popperPlacement={'bottom-end'}
      dropdownMode='select'
      customInput={<StyledInput isV4={isV4}/>}
      onInputClick={() => setIsOpen(true)}
      onClickOutside={() => setIsOpen(false)}
      selected={!value? null : moment(value).toDate()}
      open={isOpen}
      readOnly={true}
    />
  );
};

const DateQuiz = ({ title, inputTitle, subtitle, name, error, minYear = 0, maxYear, defaultYearValue }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const min = subtractYears(minYear).year();
  const max = subtractYears(maxYear).year();
  const isV4 = useIsAppVersion('v4');

  const years = range(max, min, 1).reverse();
  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Content isV4={isV4}>
        <Input>
          <InputTitle>{inputTitle}</InputTitle>
          <Field
            name={name}
            component={renderDateTimePicker}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            years={years}
            maxYear={maxYear}
            minYear={minYear}
            defaultYearValue={defaultYearValue}
            isV4={isV4}
          />
        </Input>
        <ErrorText>
          {error}
        </ErrorText>
        <WrapButton>
          <Button title={t('messages.continue')} type='submit' style={{zIndex:'0'}}/>
        </WrapButton>
      </Content>
    </Wrapper>
  );
};

export default DateQuiz;
