import {
  SubTitle,
  SubTitleHeader,
  SubTitleImg,
  SubTitleInner,
  SubTitleWrapper,
  Title,
  WhatYouGetSection,
  ImageBlockText,
  ImageSection,
  DiscountBlock,
  DiscountRightWrapper,
  DiscountLeft,
  DiscountRightItem,
  DefaultPrice,
  Wrapper,
  ButtonWrapper,
  ButtonNext,
  ModalWrapper,
  SuccessTitle,
  ContentModalBlock,
  ContentModalText,
  ContentModalIcon,
  ButtonWrapperModal,
  ButtonInner,
  Title2
} from './styled';
import { useEffect, useState } from 'react';
import { scrollPageToTop } from 'utils';
import { ButtonComponent as Button } from 'components/Button';
import { useHistory } from 'react-router-dom';
import { Modal } from 'components/Modal';
import { upSellPayment } from 'api';
import { errorNotification } from 'utils';
import config from 'config';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { useSelector } from 'react-redux';

export const UpSellComponent = ({ data }) => {
  const history = useHistory();
  const [isShowModal, setIsShowModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { title, subTitle, whatYouGetSection, upSellImg, title2, discount, price, discountPrice } = data;
  const { appVersion: isV3 } = useSelector((state) => state);

  useEffect(() => {
    sendAmplitudeEvent(data.eventShow);
    scrollPageToTop();
  }, []);

  const goNextPage = () => {
    if (data?.id < 4) {
      sendAmplitudeEvent(data.eventNextClick);
      history.push(`${isV3}/upsell/${data.id + 1}`);
    } else {
      history.push(`${isV3}/payment-successful`);
    }
  };
  const goBack = () => {
    if (1 < data?.id)  {
      sendAmplitudeEvent(data.eventBeforeClick);
      history.push(`${isV3}/upsell/${data.id - 1}`);
    }
  };

  const filterPriceUpSell = () => {
    const prices = config.prices.upSell;
    if (1 === data?.id) {
      return prices.mindfulEating;
    } else if (2 === data?.id) {
      return prices.walkingChallengePro;
    } else if (3 === data?.id) {
      return prices.smoothieChallenge;
    } else if (4 === data?.id) {
      return prices.flatBellyRules;
    } else return null;
  };

  const handlePayment = async () => {
    setIsFetching(true);
    sendAmplitudeEvent(data.eventGetItNowClick);
    const prices = filterPriceUpSell();
    const result = await upSellPayment(prices);
    if (200 !== result?.status) {
      setIsFetching(false);
      setIsShowModal(false);
      errorNotification('Oops, something went wrong, try again later');
    } else {
      sendAmplitudeEvent('MealPlan_upsell_success_purchase_view');
      setIsShowModal(true);
      setIsFetching(false);
    }
  };

  const goToSuccessPage = () => {
    sendAmplitudeEvent('MealPlan_upsell_success_purchase_gotIt_click');
    history.push(`${isV3}/payment-successful`);
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Title2>{title2}</Title2>
      <SubTitleWrapper styleProps={{background: data.background, padding: data.padding}}>
        <SubTitleImg src={subTitle?.src} styleProps={4 === data.id ? {height: '82px'} : {}}/>
        <SubTitleInner>
          {subTitle?.title && <SubTitleHeader>{subTitle?.title}</SubTitleHeader>}
          <SubTitle>{subTitle?.text}</SubTitle>
        </SubTitleInner>
      </SubTitleWrapper>
      <WhatYouGetSection>
        <Title styleProps={{fontSize: '20px', lineHeight: '28px', color: '#090909'}}>{whatYouGetSection?.title}</Title>
        <Title2>{whatYouGetSection?.title2}</Title2>
      </WhatYouGetSection>
      <WhatYouGetSection>
        <ImageBlockText styleProps={{top: `${whatYouGetSection?.top}`, left: `${whatYouGetSection?.left}`}} dangerouslySetInnerHTML={{__html: whatYouGetSection?.text}}/>
        <ImageSection src={upSellImg} alt={'upSellImg'} width={288}/>
      </WhatYouGetSection>
      <DiscountBlock>
        <DiscountLeft>
          {discount}
        </DiscountLeft>
        <DiscountRightWrapper>
          <DiscountRightItem>Best Offer</DiscountRightItem>
          <DiscountRightItem><DefaultPrice>{discountPrice}</DefaultPrice> <span>now only</span>{price}</DiscountRightItem>
        </DiscountRightWrapper>
      </DiscountBlock>
      <ButtonWrapper>
        <Button
          title={'Get it right away'}
          isDisabled={isFetching}
          isLoading={isFetching}
          onClick={!isFetching && handlePayment}
        />
        <ButtonInner>
        <ButtonNext onClick={goBack}>
          Return
        </ButtonNext>
          <ButtonNext onClick={goNextPage}>
          Show next
        </ButtonNext>
        </ButtonInner>
      </ButtonWrapper>
      <Modal isShow={isShowModal}>
        <ModalWrapper>
          <SuccessTitle styleProps={{fontSize: '34px'}}>
            🎉
          </SuccessTitle>
          <SuccessTitle>
            All set!
          </SuccessTitle>
          <ContentModalBlock>
            <ContentModalText>We'll send your challenge to your <span>mailbox</span></ContentModalText>
          </ContentModalBlock>
          <ButtonWrapperModal>
            <Button
              title={'Ok, got it'}
              onClick={goToSuccessPage}
            />
            <ButtonNext onClick={goNextPage}>
              Show next
            </ButtonNext>
          </ButtonWrapperModal>
        </ModalWrapper>
      </Modal>
    </Wrapper>
  );
};