export const transformHeight = (currentMeasure, heightMeasure, height, heightInches) => {

  if (currentMeasure === heightMeasure) {
    return {
      height,
      heightInches: 'metric' === currentMeasure ? '' : heightInches
    }
  } else if ('metric' === currentMeasure){
    //convert to metric
    let cm = Math.round(((Number(height) * 12) + Number(heightInches)) * 2.54);
    return { height: cm, heightInches: '' };
  } else {
    //convert to imperial
    const heightNumber = height < 122  ? 122 : height > 240  ? 240 : height;
    const totalInches = Math.round(heightNumber / 2.54);
    const inches = totalInches % 12;
    const feet = (totalInches-inches) / 12;

    return { height: feet, heightInches: inches };
  }
}