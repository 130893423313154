import React, { useState, useEffect } from 'react';
import MaskInput from 'react-maskinput';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { createSubscription, restoreSubscription, attachPaymentCard } from 'api';
import Cookies from 'js-cookie';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import {
  createMarkup,
  errorNotification,
  getCardType,
  getTrialDate,
  isNullish,
  replaceString,
  successNotification
} from 'utils';
import { ButtonComponent as Button } from 'components/Button';
import { changeSubscriptionData } from 'store/actions';
import Loader from 'components/Auth/Loader';
import {
  PayCard,
  PayCardWrapper,
  Card,
  CardWrapper,
  CardInputBlock,
  CardInputTitle,
  CardInputNumber,
  CardInputDate,
  WrapperMonth,
  WrapperYear,
  DateLine,
  CardInputBottom,
  WrapperSecure,
  ButtonCard,
  CardInfo,
  CardInfoText,
  LoadingWrapper,
  CardInput,
  CardInputIcon,
  CvvInfo,
  CardInputTitleWrapper,
  CardInputPowered,
  CardInputPoweredText,
  CardInputPoweredCompanyName,
  CardDescription,
  CardSecureContent,
  CardType,
  CardTypeWrapper
} from './styled';
import { Lock, Secure, StripeLogo } from 'components/Icons';
import Popover from 'components/Popover';
import { monthValidate, yearValidate } from './validate';
import { sendGAStore } from 'analytics/backend';
import { saveOnboard } from 'utils/saveOnbord';
import { useIsAppVersion } from 'customHooks';

const PayCardComponent = ({ buttonText, handler, isPaymentPage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorPay, setErrorPay] = useState(null);
  const [cardNumber, setCardNumber] = useState('');
  const [cardMonth, setCardMonths] = useState('');
  const [cardMonthError, setCardMonthError] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [cardYearError, setCardYearError] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [cvvMask, setCvvMask] = useState('000');
  const [cardType, setCardType] = useState('');
  const dispatch = useDispatch();
  const { progress, paymentPlan, profile, remoteConfig } = useSelector((state) => state);
  const { checkoutPage } = remoteConfig || {};
  const {
    defaultSubscription,
    discountSubscription,
    subscriptionCardsBlock
  } = checkoutPage || {};
  const {
    defaultFullPrice,
    discountFullPrice,
  } = checkoutPage?.priceBlock || {};
  const {
    trialDuration,
  } = checkoutPage?.trial || {};
  const {
    subDescription,
  } = checkoutPage?.payment || {};
  const timer = progress?.timer ?? '';
  const { paidTrial, chooseYourPlan } = paymentPlan || {};
  const {
    discountPlanId,
    defaultPlanId,
    defaultFullPrice: chooseYourPlanDefaultFullPrice,
    discountFullPrice: chooseYourPlanDiscountFullPrice,
  } = chooseYourPlan || {};
  const { email } = profile;
  const isV4 = useIsAppVersion('v4');
  const isChooseYourPlan = isV4 && !isNullish(subscriptionCardsBlock);
  const isDiscount = 'end' !== timer;

  const getPlan = () => {
    let planId = '';
    if(isChooseYourPlan && (discountPlanId || defaultPlanId)) {
      planId = isDiscount ? (discountPlanId ?? defaultPlanId) : (defaultPlanId ?? discountPlanId);
    }

    return {
      planId: planId ? planId : (isDiscount ? discountSubscription : defaultSubscription),
      paidTrialPlanId: paidTrial?.plan_id ?? null
    };
  };

  const getPrice = () => {
    if (isChooseYourPlan) {
      return isDiscount ? chooseYourPlanDiscountFullPrice : chooseYourPlanDefaultFullPrice;
    }
    return isDiscount ? discountFullPrice : defaultFullPrice;
  };

  const resetResetError = () => {
    setErrorPay(false);
  };

  const changeCardNumber = (e) => {
    setCardCvv('');
    let data = e.target.value;
    let newStr = data.replace(/[^0-9]/g, '').substr(0,16).replace(/\d{4}(?=.)/g, '$& ');
    setCardNumber(newStr);
    resetResetError();
  };

  const changeCardYear = (e) => {
    const { value } = e.target;
    const isInvalidYear = yearValidate(value);
    monthValidate(cardMonth, cardYear);
    setCardYearError('');
    if (isInvalidYear) {
      setCardYearError('Year');
      setErrorPay(true);
    }
    setCardYear(value);
    resetResetError();
  };

  const changeCardCvv = (e) => {
    setCardCvv(e.target.value);
    resetResetError();
  };

  const changeCardMonth = (e) => {
    const { value } = e.target;
    const isInvalidMonth = monthValidate(value, cardYear);
    setCardMonthError('');
    if (isInvalidMonth) {
      setErrorPay(true);
    }
    setCardMonths(value);
    resetResetError();
  };

  useEffect(() => {
    setCardType(getCardType(getValidCardNumber(cardNumber)));
    const cardLength = getValidCardNumber(cardNumber).length;
    if (cardLength === 16 || cardLength === 14) {
      setCvvMask('000');
      return;
    }
    setCvvMask('0000');
  }, [cardNumber]);

  useEffect(() => {
    if (monthValidate(cardMonth, cardYear)) {
      setCardMonthError('Month');
      return;
    }
    setCardMonthError('');
  }, [cardMonth, cardYear]);

  const getCompanyLogo = () => {
    let companyLogo = '';

    switch (cardType) {
      case 'visa':
        companyLogo = 'visa';
        break;
      case 'mastercard':
        companyLogo = 'mastercard';
        break;
      case 'amex':
        companyLogo = 'american-express';
        break;
      case 'discover':
        companyLogo = 'discover';
        break;
    }

    return <CardTypeWrapper><CardType src={`/images/payment/${companyLogo}.svg`}/></CardTypeWrapper>
  };

  const getSubDescription = () => {
    const { month, day, year } = getTrialDate(trialDuration) ?? {};
    return replaceString(subDescription ?? '',
    [
      {
        what: '{subPrice}',
        forWhat: getPrice()
      },
      {
        what: '{month}',
        forWhat: month,
      },
      {
        what: '{day}',
        forWhat: day,
      },
      {
        what: '{year}',
        forWhat: year,
      }
    ]);
  }

  const submitForm = async () => {
    const isValidCardMonth = !monthValidate(cardMonth, cardYear);
    const isValidCardYear = !yearValidate(cardYear);
    const isValidCardNumber = validationCardNumber(cardNumber);
    const isValidCardCvv = cardValidationData(cardCvv, cvvMask.length);

    if (
      isValidCardNumber
      && isValidCardMonth
      && isValidCardCvv
      && isValidCardYear
    ) {
      setLoading(true);
      setErrorPay(false);

      const { planId, paidTrialPlanId } = getPlan();
      const price = getPrice();

      const url = window.location.pathname;
      switch (url) {
        case '/payment':
        case '/v3/payment':
        case '/v4/payment':
          await sendAmplitudeEvent('click_payment');
          await sendGAStore(email);
          await createSubscription(
            getValidCardNumber(cardNumber),
            cardYear, cardMonth,
            cardCvv,
            email,
            planId,
            paidTrialPlanId
          ).then((res) => {
            if (200 === res.status) {
              const { authToken } = res.data.data;
              Cookies.set('authToken', authToken);
              Cookies.set('price', price);
              '/v4/payment' !== url && saveOnboard(authToken);
              sendAmplitudeEvent('purchase', { YMP_Price: price });
              '/v4/payment' === url ? history.push('payment-successful', { authToken, price }) : history.push('upsell/1');
              setLoading(false);
            }

            if (400 === res.status || 409 === res.status) {
              errorNotification(res.data.message);
              setLoading(false);
            }

            if (500 === res.status) {
              errorNotification('Oops, Something went wrong. Please try again or try using another card');
              setLoading(false);
            }
          })
          break;
        case '/meal-plan/settings':
          await restoreSubscription(
            getValidCardNumber(cardNumber),
            cardYear, cardMonth,
            cardCvv,
            email,
            planId
          ).then((res) => {
            if (200 === res.status) {
              handler();
              setLoading(false)
            }
            if (400 === res.status) {
              errorNotification(res.data.message);
              setLoading(false);
            }
          });
          break;
        case '/meal-plan':
          await attachPaymentCard(
            getValidCardNumber(cardNumber),
            cardYear, cardMonth,
            cardCvv,
            email,
            planId
          ).then((res) => {
            if (200 === res.status) {
              dispatch(changeSubscriptionData({
                is_active: true,
                type: 'premium'
              }));
              successNotification('Your payment method was successfully updated');
              handler();
              setLoading(false);
            }
            if (400 === res.status) {
              errorNotification('Oops, Something went wrong. Please try again or try using another card');
              handler();
              setLoading(false);
            }
          })
          break;
      }
    } else {
      setErrorPay(true);
    }
  };

  const getValidCardNumber = (cardNumber) => {
    return cardNumber.replace(/\s/g, '');
  };

  const getRegexCardNumber = (cardLength) => {
    if (cardLength === 16) {
      return new RegExp('^[0-9]{16}$');
    }

    if (cardLength === 14) {
      return new RegExp('^[0-9]{14}$');
    }

    return new RegExp('^[0-9]{15}$');
  };

  const validationCardNumber = (cardNumber) => {
    if (null === cardNumber) {
      return false;
    }

    const number = cardNumber.replace(/\s/g, '');
    const regex = getRegexCardNumber(number.length);

    return regex.test(number);
  };

  const cardValidationData = (value, length) => {
    if (null === value) {
      return false;
    }

    const pattern = `^[0-9]{${length}}$`;
    const regex = new RegExp(pattern);

    if (!regex.test(value)) {
      return false;
    }

    return true;
  };

  const cvvDescription = t(`payment.cvvDescription`);
  const poweredBy = t(`payment.poweredBy`);

  const getPoweredBlock = () => {
    return (
      <CardInputPowered>
        <CardInputPoweredText>{poweredBy}</CardInputPoweredText>
        <CardInputPoweredCompanyName>
          <StripeLogo/>
        </CardInputPoweredCompanyName>
      </CardInputPowered>
    );
  };

  return (
    <PayCard>
      <PayCardWrapper>
        <Card>
          <CardWrapper>
            <CardInputNumber>
              <CardInputBlock>
                <CardInputTitleWrapper>
                  <CardInputTitle style={{ marginBottom: 0 }}>{t(`payment.cardNumber`)}</CardInputTitle>
                  {getPoweredBlock()}
                </CardInputTitleWrapper>
                <CardInput
                  value={cardNumber}
                  placeholder='1234 1234 1234 1234'
                  onChange={changeCardNumber}
                  id='cc-num'
                />
                <CardInputIcon>
                  {cardType ? (
                    getCompanyLogo()
                  ) : (
                    <Lock/>
                  )}
                </CardInputIcon>
              </CardInputBlock>
            </CardInputNumber>
            <CardInputBottom>
              <CardInputDate>
                <WrapperMonth>
                  <CardInputBlock>
                    <CardInputTitle>{t(`payment.month`)}</CardInputTitle>
                    <MaskInput
                      maskChar='M'
                      placeholder='MM' mask={'00'}
                      onChange={changeCardMonth}
                      value={cardMonth}
                      id='cc-exp-month'
                      name='cc-exp-month'
                      autoComplete='cc-exp-month'
                      x-autocompletetype='cc-exp-month'
                    />
                  </CardInputBlock>
                </WrapperMonth>
                <DateLine>/</DateLine>
                <WrapperYear>
                  <CardInputBlock>
                    <CardInputTitle>{t(`payment.year`)}</CardInputTitle>
                    <MaskInput
                      mask={'0000'}
                      placeholder='YEAR'
                      onChange={changeCardYear}
                      value={cardYear}
                      id='cc-exp-year'
                      name='cc-exp-year'
                      autoComplete='cc-exp-year'
                      x-autocompletetype='cc-exp-year'
                    />
                  </CardInputBlock>
                </WrapperYear>
              </CardInputDate>
              <WrapperSecure>
                <CardInputBlock>
                  <CardInputTitle>{t(`payment.secureNumber`)}</CardInputTitle>
                  <MaskInput
                    mask={cvvMask}
                    placeholder='CVV2'
                    type='password'
                    onChange={changeCardCvv}
                    value={cardCvv}
                    id='cvv2'
                    name='cvv2'
                    autoComplete='cc-csc'
                  />
                  <CardInputIcon top={'51%'} minTop={'51%'}>
                    <Popover dangerouslySetInnerHTML={cvvDescription}>
                      <CvvInfo>
                        ?
                      </CvvInfo>
                    </Popover>
                  </CardInputIcon>
                </CardInputBlock>
              </WrapperSecure>
            </CardInputBottom>
          </CardWrapper>
        </Card>
        {errorPay && (
          <CardInfo error={errorPay}>
            <CardInfoText>
              {t(`payment.error`)}
              {' '}{cardMonthError || cardYearError ? t('payment.errorMonthOrYear') : ''}
              {' '}{cardMonthError} {cardMonthError && cardYearError ? 'and' : ''} {cardYearError}
            </CardInfoText>
          </CardInfo>
        )}
        {isPaymentPage && (
          <>
            <CardDescription dangerouslySetInnerHTML={createMarkup(getSubDescription())}/>
            <CardSecureContent>
              <Secure/>
              {getPoweredBlock()}
            </CardSecureContent>
          </>
        )}
        <ButtonCard style={{ margin: `${isPaymentPage ? 0 : '20px 0'}` }}>
          <Button type='submit' title={buttonText} onClick={() => submitForm()}/>
        </ButtonCard>
      </PayCardWrapper>
      {isLoading
        ? <LoadingWrapper>
          <Loader/>
        </LoadingWrapper>
        : <></>
      }
    </PayCard>
  );
};

export default reduxForm(
  {
    form: 'pay',
    destroyOnUnmount: false
  }
)(PayCardComponent);
