import React from 'react';
import Collapsible from 'react-collapsible';
import { useTranslation } from "react-i18next";

import { createMarkup } from "utils";
import { ArrowRounded } from 'components/Icons';
import {
  SideBenefits,
  SideBenefitsTitle,
  SideBenefitsItems,
  SideBenefitsItem,
  SideBenefitsItemTop,
  SideBenefitsItemText,
  SideBenefitsItemArrow,
  SideBenefitsItemImageWrap,
  SideBenefitsItemImage,
  SideBenefitsItemTitle
} from './styled';

const ItemTop = ({ data = {} }) => {
  const { title, image } = data;
  return (<SideBenefitsItemTop>
    <SideBenefitsItemImageWrap>
      <SideBenefitsItemImage src={`/images/${image}`}/>
    </SideBenefitsItemImageWrap>
    <SideBenefitsItemTitle>{title}</SideBenefitsItemTitle>
    <SideBenefitsItemArrow>
      <ArrowRounded width="100%" height="100%"/>
    </SideBenefitsItemArrow>
  </SideBenefitsItemTop>)
}

const SideBenefitsComponent = () => {
  const { t } = useTranslation();

  const itemsBenefits = t(`sideBenefits.items`, { returnObjects: true });

  return (
    <SideBenefits>
      <SideBenefitsTitle>{t("sideBenefits.title")}</SideBenefitsTitle>
      <SideBenefitsItems>
        {itemsBenefits.map((item, count) =>
          <SideBenefitsItem key={count}>
            <Collapsible trigger={<ItemTop data={item}/>}>
              <SideBenefitsItemText dangerouslySetInnerHTML={createMarkup(item.text)}/>
            </Collapsible>
          </SideBenefitsItem>
        )}
      </SideBenefitsItems>
    </SideBenefits>
  )
}

export default SideBenefitsComponent;
