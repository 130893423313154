import styled from "styled-components";
import {NavLink} from "react-router-dom";
import { THEME } from "THEME";

const activeClassName = 'nav-item-active'

export const Form = styled.form`

`;

export const StyledLink = styled(NavLink).attrs({activeClassName})`
  color: ${THEME.COLOR_BLACK};
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 20px;

  &.${activeClassName} {
    color: ${THEME.COLOR_PURPLE};
  }

  @media (min-width: 960px) {
    margin: 0 15px 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    z-index: 3;
    transition: color ${THEME.TRANSITION};

    &:hover {
      color: ${THEME.COLOR_PURPLE};
    }
  }
`;

export const MealPlanWrapper = styled.div`
    background: #F6F6FC;
    display: flex;
    min-height: 85vh;
`

export const MealPlanHeader = styled.h2`
    font-size: 30px;
    font-weight: 700;
    margin: 50px 0 50px 0;
    
`

export const MealPlanBlocksWrapper = styled.div`
    width: 100%;
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

export const DailyMealPlanBlockWrapper = styled.div`
    width: 48%;
`

export const DailyMealPlanHeader = styled.h3`
    font-size: 16px;
    font-weight: 700;
`

export const DailyMealPlanHeaderActive = styled.h3`
    font-size: 16px;
    font-weight: 700;
    color: #7E80F7;
`

export const DailyMealPlanBlock = styled.div`
    background: #fff;
    border-radius: 25px;
    width: 100%;
    @media (min-width: 960px) {
        
    }
`

export const DailyMealPlanItem = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const DailyMealPlan = styled.div`
    display: flex;
    flex-direction: row;
`

export const DailyMealPlanSwap = styled.button`
    display: flex;
    align-items: center;
    cursor: ${props => props.disable ? "auto" : "pointer"};
`

export const DailyPlanImage = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 10px;
`
export const DailyPlanContent = styled.div`
    padding: 0 10px;
`

export const DailyPlanPeriod = styled.div`
    text-transform: uppercase;
    font-size: 13px;
`

export const DailyPlanTitleLink = styled(NavLink)`
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: #191B1F;
`

export const DailyPlanDescription = styled.div`
    font-size: 13px;
    margin: 4px 0 0 0;
`

export const TodayLabelWrapper = styled.div`
    padding: 1px 10px;
    font-size:10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    /* Gradient */
    margin: 0 0 0 15px;
    line-height: 18px;
    
    background: linear-gradient(90deg, #99D3F9 3.38%, #A394FD 39.14%, #616FF1 92.79%);
    border-radius: 20px;
`

export const TodayLabelText = styled.p`
    padding 5px;
`

export const DailyMealPlanHeaderWrapper = styled.div`
    display: flex;
    margin: 10px;
`

export const MealPlanContent = styled.div`
    padding: 0;
    width: 88%;
    margin: 0 auto;
`

export const DailyMealImageLink = styled(NavLink)`
`

export const DailyPlanTitle = styled.h4`
`

export const DailyMealPlanDisable = styled.div`
    background: #fff;
    opacity: 0.6;
    border-radius: 25px;
    width: 100%;
    @media (min-width: 960px) {
        
    }
`

export const DailyMealPlanWrapperDisable = styled.div`
    position: relative;
`

export const DailyMealPlanComplete = styled.img`
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    width: 60px;
`

export const MealPlanTitle = styled.h4`
    color: #3F5366;
    font-size: 16px;
    font-weight: bold;
    
`
