import styled from "styled-components";

export const Layout = styled.div`
    background: #F6F6FC;
    display: flex;
    min-height: 85vh;
    flex-wrap: wrap;
    align-content: center;
`

export const LayoutContent = styled.div`
    display: flex;
`