import styled from "styled-components";

export const ImprovesHealth = styled.div`
   @media (max-width: 959px) {
     background-color: #FFEAEB;
   }
  
  @media (min-width: 960px) {
    position: relative;
  }
`;

export const ImprovesHealthContent = styled.div`
    position: relative;
`;

export const ImprovesHealthContentBackground = styled.div`
  position: absolute;
  top: 0;
  min-height: 625px;
  max-height: 625px;
  width: 100%;
  background: radial-gradient(87.2% 193.49% at 104.26% 32.83%, #22BB8C 0%, #B393F7 55.73%, #FF727A 100%);
  opacity: 0.1;
  @media (max-width: 959px) {
    display: none;
  }
`

export const ImprovesHealthButton = styled.div`
    max-width: 256px;
    margin: auto;

    @media (min-width: 960px) {
      margin: 0 auto 0 0;
    }
  
    button {
      background: linear-gradient(90deg, #99D3F9 0%, #A394FD 47.36%, #616FF1 92.79%);
    }
`

export const ImprovesHealthImageWrapper = styled.div`
`

export const ImprovesHealthWrapper = styled.section`
  padding: 30px 16px 0;
  max-width: 330px;
  margin: auto;
  
  @media (min-width: 960px) {
    display: flex;
    max-width: 960px;
    align-items: center;
    justify-content: space-between;
    padding: 60px 16px 0;
  }

  @media (min-width: 1170px) {
    max-width: 1170px;
  }
`;

export const ImprovesHealthLeftSection = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;

  @media (min-width: 960px) {
    max-width: 400px;
    margin-bottom: 80px;
  }
`

export const ImprovesHealthTitle = styled.div`
    color: #000;
    font-weight: 800;
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 10px;
    text-align: center;
    padding: 0 5px;
    @media (min-width: 960px) {
      font-size: 56px;
      line-height: 67px;
      text-align: left;
      display: inline;
    }
`;

export const ImprovesHealthTitleFirstWorld = styled.span`
  color: #7E80F7;
  font-weight: 800;
  font-size: 28px;
  line-height: 33px;
  display: inline-block;
  width: 100%;
  text-align: center;
  @media (min-width: 960px) {
    font-size: 56px;
    line-height: 67px;
    text-align: left;
    width: auto;
  }
`;

export const ImprovesHealthPreTitle = styled.div`
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 20px;
    
    @media (min-width: 960px) {
      text-align: left;
      font-size: 24px;
      line-height: 31px;
    }
    
    @media (min-width: 960px) {
      margin: 20px 0 32px;
    }
`;


export const ImprovesHealthRightSection = styled.div`
  max-width: 223px;
  margin: 30px auto -40px;
  position: relative;
  z-index: 1;

  @media (min-width: 960px) {
    margin: 0;
    max-width: 400px;
  }
`

export const ImprovesHealthImage = styled.img`
  display: block;
  width: 100%;
`;