import React from 'react';
import { SuggesticLinkWrapper } from "./styled";
import { SuggesticLogo } from "components/Icons";

const SuggesticLink = ({ ...props }) => {
  return (
    <SuggesticLinkWrapper href="https://suggestic.com/" target="_blank" {...props}>
      <SuggesticLogo/>
    </SuggesticLinkWrapper>
  )
}

export default SuggesticLink;