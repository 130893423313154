import React from 'react'
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { useState } from 'react';

import {
  Video,
  Player,
  VideoWrapper,
  VideoContent,
  PlayerPreviewImage,
  PlayerPreviewImageWrapper
} from './styled'

const VideoComponent = () => {
  const { t } = useTranslation();
  const image = t('landingV4.benefits.videoPreviewImage');
  const [opts, setOpts] = useState({
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  });

  const [isPlayVideo, setIsPlayVideo] = useState(false);

  const videoClickHandler = () => {
    setIsPlayVideo(true);
    sendAmplitudeEvent('click_video');
    setOpts({...opts, playerVars: { autoplay: 1 }});
  };

  return <Video>
    <VideoWrapper>
      <VideoContent>
        <Player onClick={videoClickHandler}>
          <YouTube videoId={'dUTO-9BmCQo'} opts={opts} containerClassName={`youtube ${isPlayVideo && 'is-show-youtube'}`} />
          <PlayerPreviewImageWrapper>
            <PlayerPreviewImage
              src={`${image}.png`}
              srcSet={`${image}.png 320w, ${image}@2x.png 480w`}
              isPlayVideo={isPlayVideo} />
          </PlayerPreviewImageWrapper>
        </Player>
      </VideoContent>
    </VideoWrapper>
  </Video>
};

export default VideoComponent;