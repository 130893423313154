import React from 'react';
import Input from 'components/Input';
import { ButtonComponent as Button } from "components/Button";
import { Email, EmailItems, EmailWrapButton } from './styled';
import { EmailError } from "components/Payment/EmailForm/EmailResults/styled";

const EmailComponent = ({ placeholder, titleInput, buttonText, updateValue, isPaymentPage, error, onButtonClick }) => {
    const handleChange = (e) => {
      if (updateValue) {
        updateValue(e.target.value)
      }
    };
  return (
    <Email>
      <EmailItems>
        <Input
          type="text"
          name="email"
          placeholder={placeholder}
          onChange={handleChange}
          title={titleInput}
        />
        {error && (
          <EmailError>{error}</EmailError>
        )}
      </EmailItems>
      <EmailWrapButton isPaymentPage={isPaymentPage}>
        <Button title={buttonText} type="submit" onClick={onButtonClick} />
      </EmailWrapButton>
    </Email>
  )
}

export default EmailComponent;
