import { ButtonComponent as Button } from "components/Button";
import React from "react";

import {
    PopupWrapper,
    PopupBlock,
    PopupTitle,
    PopupText,
    PopupButtonBlock,
    ButtonWrapper
} from './styled'

const TwoButtonPopupComponent = ({...props}) => {
    return (
        <PopupWrapper>
            <PopupBlock>
                <PopupTitle>{props.title}</PopupTitle>
                <PopupText>{props.text}</PopupText>
            <PopupButtonBlock>
                <ButtonWrapper>
                    <Button title="No, I'll stay" type="submit" onClick={props.disable()}/>
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button title="Yes, I'll quit" type="submit" color='pink' onClick={props.execute()}/>
                </ButtonWrapper>
            </PopupButtonBlock>
            </PopupBlock>
        </PopupWrapper>
    )
}

export default TwoButtonPopupComponent;