import styled from "styled-components";
import { THEME } from "THEME";

export const WeightLossKit = styled.div`
  width: 100%;
  color: ${THEME.COLOR_BLACK};
  position: relative;
  z-index: 5;

  @media (min-width: 960px) {
    padding-top: 90px;
  }
`;

export const WeightLossKitWrapper = styled.div`
  width: 100%;
  max-width: 1002px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;

  @media (min-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const WeightLossKitImageWrap = styled.div`
  width: 100%;
  max-width: 155px;
  margin: -135px auto 38px;

  @media (min-width: 960px) {
    margin: 0 75px 0 80px;
    max-width: 260px;
  }
`;

export const WeightLossKitImage = styled.img`
  width: 100%;
  height: auto;
`;

export const WeightLossKitContent = styled.div`
  width: 100%;
  max-width: 550px;
  margin: auto;
`;

export const WeightLossKitTitle = styled.h2`
  margin-bottom: 16px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 30px;
  }
`;

export const WeightLossKitItems = styled.div`
  margin-bottom: 30px;
  @media (min-width: 960px) {
    margin-bottom: 40px;
  }
`;

export const WeightLossKitItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const WeightLossKitItemIcon = styled.div`
  width: 24px;
  min-width: 24px;
  margin-right: 10px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #537EE7;
  color: #fff;
  padding: 6px;
`;

export const WeightLossKitItemText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #191B1F;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const WeightLossKitPrice = styled.div`
  border-radius: 90px;
  padding: 4px 30px 8px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 370px;
  margin: 0 auto 12px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
    background-color: ${THEME.COLOR_PURPLE};
    top: 0;
    left: 0;
    opacity: 0.1;
    border-radius: 90px;
  }

  @media (min-width: 960px) {
    margin: 0 0 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export const WeightLossKitOld = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: ${THEME.COLOR_PURPLE};
  text-align: center;
  text-decoration: ${props => props.discount ? 'line-through' : 'none'};
  @media (min-width: 960px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const WeightLossKitSum = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_PURPLE};
  text-align: center;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const WeightLossKitButton = styled.div`
  width: 100%;
  max-width: 370px;
  margin: 0 auto 12px;
  @media (min-width: 960px) {
    margin: 0 0 16px;
  }
`;

export const WeightLossKitBottom = styled.div`
  width: 100%;
  max-width: 370px;
  margin: auto;
  background-color: #F8F6F0;
  border-radius: 30px;
  padding: 16px;
  @media (min-width: 960px) {
    margin: 0;
  }
`;

export const WeightLossKitSmallT = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: ${THEME.COLOR_DARK_GRAY};
`;

export const WeightLossKitOldPrice = styled.p`
  text-decoration: line-through;
  text-decoration-style: 1px solid ${THEME.COLOR_PURPLE};
  color: #537EE7;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
`;

export const WeightLossKitNewPrice = styled.p`
  text-decoration-color: ${THEME.COLOR_PURPLE};
  color: #537EE7;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 16px;
`;

