export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const getMonthAndDay = (date, month, day, id) => {
  const lastDayOfPrevMonth = new Date(date.getFullYear(), month, 0).getDate();
  const DAY = day - (id * 8);
  const MONTH = DAY <= 0 ? month - 1 : month;

  return {
    DAY : DAY <= 0 ? lastDayOfPrevMonth + DAY : DAY,
    MONTH
  }
};

export const getMonthForProgram = (current_weight, desired_weight, id) => {
  const neededMonth = (Number(current_weight) - Number(desired_weight)) / 10;
  const transformMonth = neededMonth > 1 ? neededMonth : 1;
  const date = new Date();
  const { MONTH, DAY } = getMonthAndDay(date, Math.floor(date.getMonth() + transformMonth), date.getDate(), id);

  if (MONTH > 11) {
    const resultMonth = MONTH - (date.getMonth() + 1) > 12 ? MONTH - (date.getMonth() + 1) - 12 : MONTH - (date.getMonth() + 1);
    return {
      month: monthNames[resultMonth],
      year: date.getFullYear() + 1,
      day: DAY,
    };
  }

  return {
    month: monthNames[MONTH],
    year: date.getFullYear(),
    day: DAY,
  };
};