const commonConfig = {
  defaultLanguage: 'en',
  languages: {
    en: 'English',
    es: 'Español',
  },
  price: '50.00',
  discount: '19.99',
  supportEmail: 'support@lasta.app',
};

var config = {};

if ('production' === process.env.REACT_APP_ENVIRONMENT) {
  let envConfig = {
    gaid: 'UA-199617118-1',
    paddleVendorId: 131508,
    paddleProductId: 663144,
    amplitudeApiKey: '604a21852190b509d1c0bc92b7586299',
    prices: {
      paidTrial: [
        'price_1KyERoIlKxdbfNF5C7799BIz',
        'price_1KyERoIlKxdbfNF58bi6Cf6x',
        'price_1KyERoIlKxdbfNF5SRzw2N0B',
        'price_1KyERoIlKxdbfNF5SP5RD2Xv',
      ],
      upSell: {
        mindfulEating: 'price_1L3IZXIlKxdbfNF52Fu3oIgo',
        smoothieChallenge: 'price_1L3IZXIlKxdbfNF565wH8DzO',
        walkingChallengePro: 'price_1L3IZXIlKxdbfNF586BL2ZWq',
        flatBellyRules: 'price_1L3IZXIlKxdbfNF5BVZFbIRU'
      }
    }
  };
  config = {...commonConfig, ...envConfig};
}

if ('development' === process.env.REACT_APP_ENVIRONMENT) {
  const envConfig = {
    gaid: 'test123',
    paddleVendorId: 2266,
    paddleProductId: 13083,
    amplitudeApiKey: '604a21852190b509d1c0bc92b7586299',
    prices: {
      paidTrial: [
        'price_1KyEVqIr0VH9QFtQ6aqsGPdK',
        'price_1KyEVqIr0VH9QFtQHGiTpeWY',
        'price_1KyEVqIr0VH9QFtQlW1zM5O8',
        'price_1KyEVqIr0VH9QFtQBLGbThyr',
      ],
      upSell: {
        mindfulEating: 'price_1L3If0Ir0VH9QFtQyl91wtEX',
        smoothieChallenge: 'price_1L3If0Ir0VH9QFtQFHmfPmyR',
        walkingChallengePro: 'price_1L3If0Ir0VH9QFtQXEMwmtl5',
        flatBellyRules: 'price_1L3If0Ir0VH9QFtQeAmm4uAa'
      }
    }
  };
  config = {...commonConfig, ...envConfig};
}

export default config;
