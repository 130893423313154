import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep, changeDataProgress } from 'store/actions';
import { ButtonComponent as Button } from 'components/Button';
import {
  GoingDay,
  Schedule,
  ScheduleWrapper,
  ScheduleTitle,
  SchedulePreTitle,
  ScheduleImageWrap,
  ScheduleImage,
  ScheduleWrapButton
} from './styled';
import { floorAge, replaceString, getNextProgressStep } from 'utils';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { useIsAppVersion } from 'customHooks';

const GoingDayComponent = ({ dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [visibleSchedule, changeVisibleSchedule] = useState(false);
  const { age } = useSelector((state) => state.profile);
  const isV4 = useIsAppVersion('v4');
  const itemsGoingDay = t(`goingDay.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const goingDay = data.target.title;
    sendAmplitudeEvent('8_click_day', {
      'Day.Going': goingDay
    });
    if (isV4) {
      history.push('workout');
    } else {
      sendAmplitudeEvent('9_behavior_view');
      changeVisibleSchedule(true);
      dispatch(changeDataProgress({ visible: false }));
    }
  };

  const goNextPage = () => {
    sendAmplitudeEvent('9_behavior_click');
    history.push('/v3/achievement', { id: 0, flowId: 10 });
  }

  useEffect(() => {
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  useEffect(() => {
    if (!visibleSchedule) {
      sendAmplitudeEvent('8_view_day');
    }
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);

  const getTitle = () => {
    return replaceString(t(`goingDay.preTitle`), [{
      what: '{years}',
      forWhat: floorAge(age)
    }]);
  };

  return (
    <>
      {visibleSchedule ? <Schedule>
        <ScheduleWrapper>
          <ScheduleTitle>{t('schedule.title')}</ScheduleTitle>
          <SchedulePreTitle>{t('schedule.preTitle')}</SchedulePreTitle>
          <ScheduleImageWrap>
            <ScheduleImage
              src={`/images/schedule.svg`}
              width={230}
              height={202}
              loading='lazy'
              alt='schedule'
            />
          </ScheduleImageWrap>
          <ScheduleWrapButton>
            <Button title={t('messages.continue')} type='button' onClick={goNextPage}/>
          </ScheduleWrapButton>
        </ScheduleWrapper>
      </Schedule> : <GoingDay>
        <StandardQuizComponent
          items={itemsGoingDay}
          handleSelect={handleSelect}
          title={t('goingDay.title')}
          subtitle={getTitle()}
          name={'goingType'}
          imagePath={'goingDay'}
        />
      </GoingDay>}
    </>
  );
};

export default reduxForm(
  {
    form: 'goingDay',
    destroyOnUnmount: false
  }
)(GoingDayComponent);
