export const getAppVersion = (pathname) => {
  const urlStringToArray = pathname.split('/');
  switch (urlStringToArray[1]) {
    case 'v3':
      return '/v3';
    case 'v4':
      return '/v4';
    default:
      return '';
  }
};