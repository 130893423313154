import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Error from 'pages/Error';
import { OverallResults, OverallResultsV3 } from 'pages/OverallResults';
import { OverallReview, OverallReviewV3 } from 'pages/OverallReview';
import Faq from 'pages/Policy/Faq';
import Support from 'pages/Policy/Support';
import Policy from 'pages/Policy/MainPolicy';
import Login from 'pages/Login';
import MealPlan from 'pages/MealPlan';
import Debug from 'pages/Debug';
import Recipe from 'pages/Recipe';
import Settings from 'pages/Settings';
import RecoveryPassword from 'pages/RecoveryPassword';
import { Starting, StartingV3, StartingV4 } from 'pages/Starting';
import { Gender, GenderV3 } from 'pages/Gender';
import { TargetWeight, TargetWeightV3 } from 'pages/TargetWeight';
import { CurrentWeight, CurrentWeightV3, CurrentWeightV4 } from 'pages/CurrentWeight';
import { BodyType, BodyTypeV3 } from 'pages/BodyType';
import { GoingDay, GoingDayV3} from 'pages/GoingDay';
import { Discomfort, DiscomfortV3 } from 'pages/Discomfort';
import { PerfectWeight, PerfectWeightV3 } from 'pages/PerfectWeight';
import { AboutYou, AboutYouV3 } from 'pages/AboutYou';
import { Workout, WorkoutV3 } from 'pages/Workout';
import { Feel, FeelV3 } from 'pages/Feel';
import { Sleep, SleepV3 } from 'pages/Sleep';
import { DrinkWater, DrinkWaterV3 } from 'pages/DrinkWater';
import { CookingLong, CookingLongV3 } from 'pages/CookingLong';
import { Allergies, AllergiesV3 } from 'pages/Allergies';
import { Vegetables, VegetablesV3 } from 'pages/Vegetables';
import { Cereals, CerealsV3 } from 'pages/Cereals';
import { PreferredProducts, PreferredProductsV3 } from 'pages/PreferredProducts';
import { MeatFish, MeatFishV3 } from 'pages/MeatFish';
import { MealAccess, MealAccessV3 } from 'pages/MealAccess';
import { PaymentSuccessful, PaymentSuccessfulV3 } from 'pages/PaymentSuccessful';
import { Email } from 'pages/Email';
import { Forecast } from 'pages/Forecast';
import PaidTrial from 'pages/PaidTrial';
import { Payment } from 'pages/Payment';
import { Achievement } from 'pages/Achievement';
import { TypicalLunch, TypicalLunchV3 } from 'pages/TypicalLunch';
import MetabolismComponent from 'pages/Metabolism';
import WeightGainComponent from 'pages/WeightGain';
import SnackTriggerComponent from 'pages/SnackTrigger';
import HealthConditionComponent from 'pages/HealthCondition';
import DiabetesComponent from 'pages/Diabetes';
import JourneyFeelingComponent from 'pages/JourneyFeeling';
import IncreaseInMeComponent from 'pages/IncreaseInMe';
import SeeYourselfComponent from 'pages/SeeYourself';
import GoalAdditionComponent from 'pages/GoalAddition';
import EmotionalComfortComponent from 'pages/EmotionalComfort';
import FailBackComponent from 'pages/FailBack';
import OutsideMotivationComponent from 'pages/OutsideMotivation';
import LoadingResults from 'pages/LoadingResults';
import { YourName, YourNameV3 } from 'pages/YourName';
import { Birthday, BirthdayV3 } from 'pages/Birthday';
import { Height, HeightV3, HeightV4 } from 'pages/Height';
import { Diets, DietsV3 } from 'pages/Diets';
import { CaloriesPerDay, CaloriesPerDayV3 } from 'pages/CaloriesPerDay';
import { MealsPerDay, MealsPerDayV3 } from 'pages/MealsPerDay';
import { EventName, EventNameV3 } from 'pages/EventName';
import { EventDate, EventDateV3 } from 'pages/EventDate';
import Welcome from 'pages/Welcome';
import { Separator } from 'pages/Separator';
import { UpSell } from 'pages/UpSell';

const routesV4 = [
  {
    path: '/v4/home/:id',
    component: StartingV4,
    exact: true
  },
  {
    path: '/v4/home',
    component: StartingV4,
    exact: true
  },
  {
    path: '/v4/welcome',
    component: Welcome,
    exact: true,
  },
  {
    path: '/v4/gender',
    component: GenderV3,
    exact: true,
  },
  {
    path: '/v4/your-name',
    component: YourNameV3,
    exact: true,
  },
  {
    path: '/v4/birthday',
    component: BirthdayV3,
    exact: true,
  },
  {
    path: '/v4/body-type',
    component: BodyTypeV3,
    exact: true,
  },
  {
    path: '/v4/current-weight',
    component: CurrentWeightV4,
    exact: true,
  },
  {
    path: '/v4/target-weight',
    component: TargetWeightV3,
    exact: true,
  },
  {
    path: '/v4/height',
    component: HeightV4,
    exact: true,
  },
  {
    path: '/v4/event-name',
    component: EventNameV3,
    exact: true,
  },
  {
    path: '/v4/event-date',
    component: EventDateV3,
    exact: true,
  },
  {
    path: '/v4/perfect-weight',
    component: PerfectWeightV3,
    exact: true,
  },
  {
    path: '/v4/weight-gain',
    component: WeightGainComponent,
    exact: true
  },
  {
    path: '/v4/health-condition',
    component: HealthConditionComponent,
    exact: true
  },
  {
    path: '/v4/general-passed',
    component: Separator,
    exact: true
  },
  {
    path: '/v4/going-day',
    component: GoingDayV3,
    exact: true,
  },
  {
    path: '/v4/workout',
    component: WorkoutV3,
    exact: true,
  },
  {
    path: '/v4/sleep',
    component: SleepV3,
    exact: true,
  },
  {
    path: '/v4/feel',
    component: FeelV3,
    exact: true,
  },
  {
    path: '/v4/lifestyle-passed',
    component: Separator,
    exact: true
  },
  {
    path: '/v4/diets',
    component: DietsV3,
    exact: true,
  },
  {
    path: '/v4/metabolism',
    component: MetabolismComponent,
    exact: true
  },
  {
    path: '/v4/typical-lunch',
    component: TypicalLunchV3,
    exact: true
  },
  {
    path: '/v4/about-you',
    component: AboutYouV3,
    exact: true,
  },
  {
    path: '/v4/snack-trigger',
    component: SnackTriggerComponent,
    exact: true
  },
  {
    path: '/v4/discomfort',
    component: DiscomfortV3,
    exact: true,
  },
  {
    path: '/v4/drink-water',
    component: DrinkWaterV3,
    exact: true,
  },
  {
    path: '/v4/meals',
    component: MealsPerDayV3,
    exact: true,
  },
  {
    path: '/v4/cooking-long',
    component: CookingLongV3,
    exact: true,
  },
  {
    path: '/v4/eating-passed',
    component: Separator,
    exact: true
  },
  {
    path: '/v4/vegetables',
    component: VegetablesV3,
    exact: true,
  },
  {
    path: '/v4/cereals',
    component: CerealsV3,
    exact: true,
  },
  {
    path: '/v4/meat-fish',
    component: MeatFishV3,
    exact: true,
  },
  {
    path: '/v4/preferred-products',
    component: PreferredProductsV3,
    exact: true,
  },
  {
    path: '/v4/allergies',
    component: AllergiesV3,
    exact: true,
  },
  {
    path: '/v4/calories',
    component: CaloriesPerDayV3,
    exact: true,
  },
  {
    path: '/v4/food-passed',
    component: Separator,
    exact: true,
  },
  {
    path: '/v4/outside-motivation',
    component: OutsideMotivationComponent,
    exact: true
  },
  {
    path: '/v4/loading-results',
    component: LoadingResults,
    exact: true
  },
  {
    path: '/v4/overall-results',
    component: OverallResultsV3,
    exact: true,
  },
  {
    path: '/v4/email',
    component: Email,
    exact: true,
  },
  {
    path: '/v4/payment',
    component: Payment,
    exact: true,
  },
  {
    path: '/v4/payment-successful',
    component: PaymentSuccessfulV3,
    exact: true
  },
];

const routesV3 = [
  {
    path: '/v3',
    component: StartingV3,
    exact: true
  },
  {
    path: '/v3/gender/:id',
    component: GenderV3,
    exact: true,
  },
  {
    path: '/v3/gender',
    component: GenderV3,
    exact: true,
  },
  {
    path: '/v3/your-name',
    component: YourNameV3,
    exact: true,
  },
  {
    path: '/v3/birthday',
    component: BirthdayV3,
    exact: true,
  },
  {
    path: '/v3/target-weight',
    component: TargetWeightV3,
    exact: true,
  },
  {
    path: '/v3/current-weight',
    component: CurrentWeightV3,
    exact: true,
  },
  {
    path: '/v3/height',
    component: HeightV3,
    exact: true,
  },
  {
    path: '/v3/body-type',
    component: BodyTypeV3,
    exact: true,
  },
  {
    path: '/v3/going-day',
    component: GoingDayV3,
    exact: true,
  },
  {
    path: '/v3/achievement',
    component: Achievement,
    exact: true
  },
  {
    path: '/v3/forecast',
    component: Forecast,
    exact: true
  },
  {
    path: '/v3/typical-lunch',
    component: TypicalLunchV3,
    exact: true
  },
  {
    path: '/v3/metabolism',
    component: MetabolismComponent,
    exact: true
  },
  {
    path: '/v3/weight-gain',
    component: WeightGainComponent,
    exact: true
  },
  {
    path: '/v3/discomfort',
    component: DiscomfortV3,
    exact: true,
  },
  {
    path: '/v3/perfect-weight',
    component: PerfectWeightV3,
    exact: true,
  },
  {
    path: '/v3/about-you',
    component: AboutYouV3,
    exact: true,
  },
  {
    path: '/v3/snack-trigger',
    component: SnackTriggerComponent,
    exact: true
  },
  {
    path: '/v3/workout',
    component: WorkoutV3,
    exact: true,
  },
  {
    path: '/v3/feel',
    component: FeelV3,
    exact: true,
  },
  {
    path: '/v3/sleep',
    component: SleepV3,
    exact: true,
  },
  {
    path: '/v3/drink-water',
    component: DrinkWaterV3,
    exact: true,
  },
  {
    path: '/v3/health-condition',
    component: HealthConditionComponent,
    exact: true
  },
  {
    path: '/v3/diabetes',
    component: DiabetesComponent,
    exact: true
  },
  {
    path: '/v3/diets',
    component: DietsV3,
    exact: true,
  },
  {
    path: '/v3/cooking-long',
    component: CookingLongV3,
    exact: true,
  },
  {
    path: '/v3/calories',
    component: CaloriesPerDayV3,
    exact: true,
  },
  {
    path: '/v3/meals',
    component: MealsPerDayV3,
    exact: true,
  },
  {
    path: '/v3/allergies',
    component: AllergiesV3,
    exact: true,
  },
  {
    path: '/v3/vegetables',
    component: VegetablesV3,
    exact: true,
  },
  {
    path: '/v3/cereals',
    component: CerealsV3,
    exact: true,
  },
  {
    path: '/v3/preferred-products',
    component: PreferredProductsV3,
    exact: true,
  },
  {
    path: '/v3/event-name',
    component: EventNameV3,
    exact: true,
  },
  {
    path: '/v3/event-date',
    component: EventDateV3,
    exact: true,
  },
  {
    path: '/v3/journey-feeling',
    component: JourneyFeelingComponent,
    exact: true
  },
  {
    path: '/v3/increase-in-me',
    component: IncreaseInMeComponent,
    exact: true
  },
  {
    path: '/v3/see-yourself',
    component: SeeYourselfComponent,
    exact: true
  },
  {
    path: '/v3/goal-addition',
    component: GoalAdditionComponent,
    exact: true
  },
  {
    path: '/v3/emotional-comfort',
    component: EmotionalComfortComponent,
    exact: true
  },
  {
    path: '/v3/fail-back',
    component: FailBackComponent,
    exact: true
  },
  {
    path: '/v3/outside-motivation',
    component: OutsideMotivationComponent,
    exact: true
  },
  {
    path: '/v3/meat-fish',
    component: MeatFishV3,
    exact: true,
  },
  {
    path: '/v3/loading-results',
    component: LoadingResults,
    exact: true
  },
  {
    path: '/v3/meal-access',
    component: MealAccessV3,
    exact: true,
  },
  {
    path: '/v3/payment-successful',
    component: PaymentSuccessfulV3,
    exact: true
  },
  {
    path: '/v3/email',
    component: Email,
    exact: true,
  },
  {
    path: '/v3/overall-results',
    component: OverallResultsV3,
    exact: true,
  },
  {
    path: '/v3/overall-review',
    component: OverallReviewV3,
    exact: true,
  },
  {
    path: '/v3/paid-trial',
    component: PaidTrial,
    exact: true
  },
  {
    path: '/v3/payment',
    component: Payment,
    exact: true,
  },
  {
    path: '/v3/upsell/:id',
    component: UpSell,
    exact: true
  },
];

const routes = [
  ...routesV3,
  ...routesV4,
  {
    path: '/',
    component: Starting,
    exact: true
  },
  {
    path: '/gender',
    component: Gender,
    exact: true,
  },
  {
    path: '/gender/:id',
    component: Gender,
    exact: true,
  },
  {
    path: '/your-name',
    component: YourName,
    exact: true,
  },
  {
    path: '/birthday',
    component: Birthday,
    exact: true,
  },
  {
    path: '/current-weight',
    component: CurrentWeight,
    exact: true,
  },
  {
    path: '/target-weight',
    component: TargetWeight,
    exact: true,
  },
  {
    path: '/height',
    component: Height,
    exact: true,
  },
  {
    path: '/body-type',
    component: BodyType,
    exact: true,
  },
  {
    path: '/going-day',
    component: GoingDay,
    exact: true,
  },
  {
    path: '/discomfort',
    component: Discomfort,
    exact: true,
  },
  {
    path: '/typical-lunch',
    component: TypicalLunch,
    exact: true
  },
  {
    path: '/metabolism',
    component: MetabolismComponent,
    exact: true
  },
  {
    path: '/health-condition',
    component: HealthConditionComponent,
    exact: true
  },
  {
    path: '/weight-gain',
    component: WeightGainComponent,
    exact: true
  },
  {
    path: '/perfect-weight',
    component: PerfectWeight,
    exact: true,
  },
  {
    path: '/about-you',
    component: AboutYou,
    exact: true,
  },
  {
    path: '/snack-trigger',
    component: SnackTriggerComponent,
    exact: true
  },
  {
    path: '/workout',
    component: Workout,
    exact: true,
  },
  {
    path: '/diabetes',
    component: DiabetesComponent,
    exact: true
  },
  {
    path: '/feel',
    component: Feel,
    exact: true,
  },
  {
    path: '/sleep',
    component: Sleep,
    exact: true,
  },
  {
    path: '/drink-water',
    component: DrinkWater,
    exact: true,
  },
  {
    path: '/cooking-long',
    component: CookingLong,
    exact: true,
  },
  {
    path: '/allergies',
    component: Allergies,
    exact: true,
  },
  {
    path: '/diets',
    component: Diets,
    exact: true,
  },
  {
    path: '/calories',
    component: CaloriesPerDay,
    exact: true,
  },
  {
    path: '/meals',
    component: MealsPerDay,
    exact: true,
  },
  {
    path: '/vegetables',
    component: Vegetables,
    exact: true,
  },
  {
    path: '/journey-feeling',
    component: JourneyFeelingComponent,
    exact: true
  },
  {
    path: '/increase-in-me',
    component: IncreaseInMeComponent,
    exact: true
  },
  {
    path: '/see-yourself',
    component: SeeYourselfComponent,
    exact: true
  },
  {
    path: '/goal-addition',
    component: GoalAdditionComponent,
    exact: true
  },
  {
    path: '/emotional-comfort',
    component: EmotionalComfortComponent,
    exact: true
  },
  {
    path: '/fail-back',
    component: FailBackComponent,
    exact: true
  },
  {
    path: '/outside-motivation',
    component: OutsideMotivationComponent,
    exact: true
  },
  {
    path: '/achievement',
    component: Achievement,
    exact: true
  },
  {
    path: '/forecast',
    component: Forecast,
    exact: true
  },
  {
    path: '/cereals',
    component: Cereals,
    exact: true,
  },
  {
    path: '/preferred-products',
    component: PreferredProducts,
    exact: true,
  },
  {
    path: '/meat-fish',
    component: MeatFish,
    exact: true,
  },
  {
    path: '/event-name',
    component: EventName,
    exact: true,
  },
  {
    path: '/event-date',
    component: EventDate,
    exact: true,
  },
  {
    path: '/loading-results',
    component: LoadingResults,
    exact: true
  },
  {
    path: '/meal-access',
    component: MealAccess,
    exact: true,
  },
  {
    path: '/overall-results',
    component: OverallResults,
    exact: true,
  },
  {
    path: '/faq',
    component: Faq,
    exact: true,
  },
  {
    path: '/support',
    component: Support,
    exact: true,
  },
  {
    path: '/refund-policy',
    component: Policy,
    exact: true,
  },
  {
    path: '/privacy-policy',
    component: Policy,
    exact: true,
  },
  {
    path: '/terms-of-service',
    component: Policy,
    exact: true,
  },
  {
    path: '/payment',
    component: Payment,
    exact: true,
  },
  {
    path: '/overall-review',
    component: OverallReview,
    exact: true,
  },
  {
    path: '/email',
    component: Email,
    exact: true,
  },
  {
    path: '/payment-successful',
    component: PaymentSuccessful,
    exact: true
  },
  {
    path: '/login',
    component: Login,
    exact: true
  },
  {
    path: '/meal-plan',
    component: MealPlan,
    exact: true
  },
  {
    path: '/debug',
    component: Debug,
    exact: true
  },
  {
    path: '/meal-plan/recipe/:id',
    component: Recipe,
    exact: true
  },
  {
    path: '/meal-plan/settings',
    component: Settings,
    exact: true
  },
  {
    path: '/recovery-password',
    component: RecoveryPassword,
    exact: true
  },
  {
    path: '/upsell/:id',
    component: UpSell,
    exact: true
  },
  {
    component: Error,
  },
];
const RootRoutesComponent = () => {
  return (
    <Switch>
      {routes.map((route, key) => {
        return <Route key={key} {...route} />;
      })}
    </Switch>
  );
};

export default RootRoutesComponent;
