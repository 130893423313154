import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { Feel } from './styled';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { getNextProgressStep } from 'utils';

const FeelComponent = ({ dispatch }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const itemsFeel = t(`feel.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const feel = data.target.title;
    sendAmplitudeEvent('21_click_day_feel', {
      'Day_feeling': feel
    });
    history.push('/sleep');
  };

  useEffect(() => {
    sendAmplitudeEvent('21_view_day_feel');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <Feel>
      <StandardQuizComponent
        items={itemsFeel}
        handleSelect={handleSelect}
        title={t('feel.title')}
        name={'feelType'}
        imagePath={'Feel'}
      />
    </Feel>
  );
};

export default reduxForm(
  {
    form: 'feel',
    destroyOnUnmount: false
  }
)(FeelComponent);

