import styled from "styled-components";

export const MealAccess = styled.form`
  width: 100%;
`;

export const MealAccessWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 30px 16px;
  @media (min-width: 960px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const MealAccessTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 40px;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
  }
`;
