export const GRAPH_IMAGE_DATA = {
  overall_review: {
    image: `/images/results/goal-graph.png`,
    image_2x: `/images/results/goal-graph@2x.png`,
  },
  forecast: {
    image: `/images/forecast/forecast-graph.png`,
    image_2x: `/images/forecast/forecast-graph@2x.png`,
  },
};
