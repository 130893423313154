import {CHANGE_PAYMENT_PLAN} from "store/actions";

const paymentPlanInitialState = {
    payment_type: '',
    plan_id: '',
    slug: '',
    with_trial: false,
    price: 0
};

const paymentPlanReducer = (state = paymentPlanInitialState, action) => {
    switch (action.type) {
        case CHANGE_PAYMENT_PLAN:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default paymentPlanReducer;
