import React from 'react';
import { PaymentContainer } from 'pages/Payment/styled';
import {
  Header,
  HeaderName,
  MethodContent,
  PaymentFormWrapper
} from './styled';
import { useSelector } from 'react-redux';
import { PayCard } from 'components/PayCard';
import { PaymentMethodsContent } from 'components/PaymentMethodsContent';
import { isNullish } from 'utils';

const PaymentForm = ({ isPaymentPage, scrollId }) => {
  const { remoteConfig } = useSelector((state) => state);
  const { payment } = remoteConfig?.checkoutPage || {};
  const { buttonText, paymentText } = payment || {};
  const isPayment = !isNullish(payment);

  const getHeader = () => {
    return (
      <Header>
        <HeaderName>
          {paymentText}
        </HeaderName>
        <MethodContent>
        </MethodContent>
      </Header>
    );
  };

  return (
    <PaymentFormWrapper>
      {isPayment && <PaymentContainer id={scrollId}>
        {getHeader()}
        <PaymentMethodsContent/>
        <PayCard
          buttonText={buttonText}
          isPaymentPage={isPaymentPage}
        />
      </PaymentContainer>}
    </PaymentFormWrapper>
  );
};

export default PaymentForm;