import { googleAnalyticsStoreApi } from 'api';
import Cookies from 'js-cookie';
import { getUtmParams } from 'utils/storeUtmParams';

const getGaCookie = () => {
  try {
    const ga = Cookies.get('_ga');
    if (null === ga) {
      return '';
    }
    return ga.slice(6)
  } catch (err) {
    return undefined;
  }
};

export const sendGAStore = (email) => {
  const ga = getGaCookie();
  const { source, campaign, medium } = getUtmParams() || {};
  googleAnalyticsStoreApi(email, ga, source, campaign, medium)
    .catch(err => console.error(err));
};