import React, {useState} from "react";

import {
    DashboardHeaderWrapper,
    DashboardHeaderLogo,
    DashboardHeaderLogoImage,
    DashboardHeaderBell,
    DashboardHeaderBellImage,
    DashboardHeaderAvatarBlock,
    DashboardHeaderRightBlock,
    DashboardHeaderLeftBlock,
    DashboardHeaderAvatar,
    DashboardHeaderText
} from "./styled";

import Menu from "components/Auth/Menu";
import { MenuBtn } from "../Header/styled";
import { useSelector } from "react-redux";

const DashboardHeaderComponent = () => {
    const [visibleMenu, changeVisibleMenu] = useState(false);
    const { isMobile } = useSelector((state) => state.user);

    const openMenu = () => changeVisibleMenu(!visibleMenu);
    return (
        <DashboardHeaderWrapper>
            <DashboardHeaderLeftBlock>
                <DashboardHeaderLogo>
                    <DashboardHeaderLogoImage src={`/images/dashboardHeader/logo.svg`} />
                </DashboardHeaderLogo>
                <DashboardHeaderText>Your Meal Plan</DashboardHeaderText>
            </DashboardHeaderLeftBlock>
            <DashboardHeaderRightBlock>
                {/*<DashboardHeaderBell>*/}
                {/*    <DashboardHeaderBellImage src={`/images/dashboardHeader/bell.svg`} />*/}
                {/*</DashboardHeaderBell>*/}
                <DashboardHeaderAvatarBlock>
                    <DashboardHeaderAvatar src={'/images/dashboardHeader/avatar.png'}></DashboardHeaderAvatar>
                </DashboardHeaderAvatarBlock>
                <Menu visibleMenu={visibleMenu}/>
                {isMobile && <MenuBtn onClick={openMenu} open={visibleMenu}><i/></MenuBtn>}
            </DashboardHeaderRightBlock>

        </DashboardHeaderWrapper>
    )
}

export default DashboardHeaderComponent