import styled from 'styled-components';
import { THEME } from 'THEME';

export const Title = styled.div`
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props?.titleColor ?? THEME.COLOR_BLUE};
  margin: 0 auto 60px 0;
  @media(max-width: 1170px) {
    margin: 0 auto 60px auto;
  }
  @media(min-width: 960px) {
    max-width: 960px;
  }
  @media(max-width: 960px) {
    margin-bottom: 30px;
  }
`