import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from 'components/Loading';
import { createMarkup } from 'utils';
import { changeDataProgress } from 'store/actions';
import {
  Policy,
  PolicyWrapper,
  PolicyTitle,
  PolicyText,
  PolicyLoading,
} from './styled';

const RefundComponent = ({ match }) => {
  const dispatch = useDispatch()
  const [data, changeData] = useState(null);
  const {title, text} = data || {}
  const fileName = match.path.slice(1);
  const domain = window.location?.hostname;

  useEffect(() => {
    fetch('lasta-app.com' === domain ? `/policy/${fileName}-new-domain.json` : `/policy/${fileName}.json`)
      .then(response => response.json())
      .then(data => changeData(data));
  }, []);

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, []);

  return (
    <Policy>
      <PolicyWrapper>
        {data ?
          <>
            <PolicyTitle>{title}</PolicyTitle>
            <PolicyText dangerouslySetInnerHTML={createMarkup(text)}/>
          </> :
          <PolicyLoading>
            <Loading/>
          </PolicyLoading>
        }
      </PolicyWrapper>
    </Policy>
  );
};
export default RefundComponent;
