import { useSelector } from 'react-redux';

export function useIsAppVersion(branchName = 'control') {
  const { appVersion } = useSelector(state => state);
  const getAppVersion = () => {
    let branch = branchName.toLowerCase().replace('\\', '/');
    branch = '/' === branch.substring(0, 1) ? branch : `/${branch}`;
    if('control' === branch && !appVersion) {
      return true;
    }
    return branch === appVersion;
  }
  return getAppVersion();
}
