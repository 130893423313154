import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { ButtonComponent as Button } from 'components/Button';
import {
  PaidTrial,
  PaidTrialWrapper,
  PaidTrialTitle,
  PaidTrialSubTitle,
  PaidTrialSubTitle2,
  PaidTrialWarning,
  PaidTrialItems,
  PaidTrialItem,
  PaidTrialWrapButton
} from './styled';
import Checkbox from 'components/Checkbox';
import { changeDataProgress, changePaymentPlan } from 'store/actions';
import { getPaymentPlansId } from 'api';
import { useDispatch, useSelector } from 'react-redux';
import config from 'config';
import { useIsAppVersion } from 'customHooks';
import { createMarkup, scrollTo } from 'utils';
import { PaymentSectionTitle, PaymentSectionSubtitle } from 'pages/Payment/styled';

const PaidTrialComponent = ({ handleSubmit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const radioButtonsData = t(`paidTrial.items`, { returnObjects: true });
  const isV4 = useIsAppVersion('v4');
  const { remoteConfig } = useSelector((state) => state);
  const { checkoutPage } = remoteConfig || {};
  const { paidTrialBlock, additionalBlock } = checkoutPage || {};
  const {
    headerH1,
    text,
    buttonText,
    prices,
    pricesSubHeader,
    subHeaderH2,
  } = paidTrialBlock || {};
  const {
    benefits
  } = additionalBlock || {};
  const priceData = isV4 ? prices : radioButtonsData;

  const pricesID = () => {
    return prices.map(item => item.planId);
  };

  const changePaidTrial = (e) => {
    const price = e?.target?.value;
    if(price) {
      const priceID = isV4 && pricesID();
      getPaymentPlansId(isV4 ? priceID : config.prices.paidTrial).then(res => {
        const result = res.prices.find(({ price: planPrice }) => planPrice === Number(price));
        dispatch(changePaymentPlan({
          paidTrial: result,
        }));
      });
    }
  };

  const submitForm = ({ price }) => {
    const event = `y_Buy_Now_paid_trial_${price ?? ''}_click`;
    sendAmplitudeEvent(event);
    isV4 ? scrollTo('payment') : history.push('/v3/payment');
  };

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    sendAmplitudeEvent('y_Buy_Now_paid_trial_view');
  }, []);

  const textBlock = () => {
    if (isV4 && text) {
      return <PaidTrialTitle dangerouslySetInnerHTML={createMarkup(text)} />;
    } else if (!isV4) {
      return (
        <>
          <PaidTrialTitle>{t('paidTrial.title')}</PaidTrialTitle>
          <PaidTrialSubTitle>{t('paidTrial.subtitle')}</PaidTrialSubTitle>
          <PaidTrialWarning dangerouslySetInnerHTML={createMarkup(t('paidTrial.warning'))}/>
        </>
      );
    }
  };

  return (
    <PaidTrial isV4={isV4} onSubmit={handleSubmit(submitForm)} benefits={benefits}>
      <PaidTrialWrapper>
        {isV4 && headerH1 && <PaymentSectionTitle>{headerH1}</PaymentSectionTitle>}
        {isV4 && subHeaderH2 && <PaymentSectionSubtitle>{subHeaderH2}</PaymentSectionSubtitle>}
        {textBlock()}
        {pricesSubHeader && isV4 && <PaidTrialSubTitle2>{pricesSubHeader}</PaidTrialSubTitle2>}
        <PaidTrialItems>
          {priceData.map((item, count) => <PaidTrialItem key={count}>
            <Checkbox title={`$${item.price}`} type="radio" name="price" value={item.price.toString()}
                      handleSelect={changePaidTrial}/>
          </PaidTrialItem>)}
        </PaidTrialItems>
        <PaidTrialWrapButton>
          {buttonText && isV4 ? <Button title={buttonText} type="submit"/> : <Button title={t('messages.continue')} type="submit"/>}
        </PaidTrialWrapButton>
      </PaidTrialWrapper>
    </PaidTrial>
  )
}

export default reduxForm(
  {
    form: 'paidTrial',
    initialValues: { price: '16.41' },
    destroyOnUnmount: false
  }
)(PaidTrialComponent);
