import {
  BottomText,
  DownloadAppFromAppStore,
  DownloadAppFromAppStoreContent,
  DownloadAppFromAppStoreIcon,
  PaymentSuccessFulContainer,
  PaymentSuccessfulDescription,
  PaymentSuccessFulSubTitle,
  PaymentSuccessFulTitle,
  PaymentSuccessFulWrapper,
  SupportBlock,
  TopText,
  BlockOr,
  QrCodeWrapper
} from './styledV3';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDeepLink } from 'api';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { gaPurchaseSuccess } from 'analytics/ga';
import { changeDataProgress, setPurchaseEventSent } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { createMarkup, scrollPageToTop } from 'utils';
import { Redirect } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import Cookies from 'js-cookie';
import { sendFacebookEvent } from 'analytics/facebookPixel';
import { pinterestEvent } from 'analytics/pinterest';
import { saveOnboard } from 'utils/saveOnbord';

const PaymentSuccessful = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const price  = Cookies.get('price');
  const authToken = Cookies.get('authToken');
  const url = window.location.pathname;
  const { isMobile } = useSelector((state) => state.user);
  const [deepLink, setDeepLink] = useState('https://yourmeal.onelink.me/aMaD/8c07121b');
  const paymentContent = t('paymentSuccessfulV3', { returnObjects: true });
  const { paymentPlan } = useSelector((state) => state);
  const { isPurchaseEventSent, email } = useSelector((state) => state.profile);
  const paymentPrice = paymentPlan?.paidTrial?.is_paid_trial ? paymentPlan?.paidTrial?.price : price;
  useEffect(() => {
    scrollPageToTop();
    '/v4/payment-successful' === url && saveOnboard(authToken);
    if (!isPurchaseEventSent) {
      gaPurchaseSuccess();
      dispatch(setPurchaseEventSent({ isPurchaseEventSent: true }));
    }
    sendFacebookEvent('Purchase', price);
    sendAmplitudeEvent('f_sub_started', { YMP_Price: price });
    sendAmplitudeEvent('y_view_thanks_page');
    window.gtag('event', 'conversion',
      { 'send_to': 'AW-377676309/fOubCLqX2NYCEJXEi7QB', 'transaction_id': '' }
    );
    pinterestEvent('Checkout', paymentPrice);
    getDeepLink(authToken).then((res) => {
      if (200 === res.status) {
        const { appleAuthLink = 'https://yourmeal.onelink.me/aMaD/8c07121b' } = res.data.data;
        setDeepLink(appleAuthLink);
      }
    });
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);

  const handleAppStoreClick = () => {
    sendAmplitudeEvent('y_click_thanks_page_download');
  };

  const handleQrCodeClick = () => {
    sendAmplitudeEvent('y_qr_code_click');
  };

  return (
    email ?
      <PaymentSuccessFulWrapper id='expand'>
        <PaymentSuccessFulContainer>
          <PaymentSuccessFulTitle dangerouslySetInnerHTML={createMarkup(t(paymentContent.title))}/>
          <PaymentSuccessFulSubTitle>{t(paymentContent.subtitle)}</PaymentSuccessFulSubTitle>
          <QrCodeWrapper>
            <a href={deepLink} target='_blank' onClick={handleQrCodeClick}>
              <QRCodeCanvas
                value={deepLink}
                includeMargin={true}
                size={isMobile ? 202 : 288}
              />
            </a>
          </QrCodeWrapper>
          <BlockOr>Or</BlockOr>
          <PaymentSuccessfulDescription dangerouslySetInnerHTML={createMarkup(t(paymentContent.description))}/>
          <a href={deepLink} target='_blank'>
            <DownloadAppFromAppStore onClick={handleAppStoreClick}>
              <DownloadAppFromAppStoreIcon src='/images/thankYouPage/apple-icon.svg' />
              <DownloadAppFromAppStoreContent>
                <TopText>Download on the</TopText>
                <BottomText>App Store</BottomText>
              </DownloadAppFromAppStoreContent>
            </DownloadAppFromAppStore>
          </a>
          <SupportBlock
            dangerouslySetInnerHTML={{ __html: t('paymentSuccessful.supportText') }}
          />
        </PaymentSuccessFulContainer>
      </PaymentSuccessFulWrapper>
      :
      (
        <Redirect to='/404'/>
      )
  );
};

export default PaymentSuccessful;