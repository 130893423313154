import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonComponent as Button } from 'components/Button';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { createMarkup } from 'utils';

import {
  Example,
  ExampleWrapper,
  ExampleCard,
  ExampleImageWrap,
  ExampleImage,
  ExampleCardText,
  ExampleCardName,
  ExampleCardOld,
  ExampleContent,
  ExampleTitle,
  ExampleText,
  ExampleButton
} from './styled';

const ExampleComponent = ({ sex, handleClick }) => {
  const { t } = useTranslation();
  const gender = t(`example.${sex}`, { returnObjects: true });

  const handler = () => {
    sendAmplitudeEvent('Get_my_plan_button_click');
    handleClick();
  }

  return (
    <Example>
      <ExampleWrapper>
        <ExampleCard>
          <ExampleImageWrap>
            <ExampleImage src={`/images/${gender.image}`} width={192} height={322}/>
          </ExampleImageWrap>
          <ExampleCardText>
            <ExampleCardName>{gender.name}</ExampleCardName>
            <ExampleCardOld>{gender.old}</ExampleCardOld>
          </ExampleCardText>
        </ExampleCard>
        <ExampleContent>
          <ExampleTitle>{t('example.title')}</ExampleTitle>
          <ExampleText dangerouslySetInnerHTML={createMarkup(t('example.text'))}/>
          <ExampleButton>
            <Button title={t('messages.getMyPlan')} type='button' onClick={handler} />
          </ExampleButton>
        </ExampleContent>
      </ExampleWrapper>

    </Example>
  )
}

export default ExampleComponent
