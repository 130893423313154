import styled from 'styled-components';
import { Subtitle } from 'components/Quiz/styled';

export const WelcomeWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
`;

export const WelcomeText = styled(Subtitle)`
  text-align: start;
  & li {
    margin-left: 30px;
  }
  & ul li {
    list-style-type: disc;
  }
  margin-bottom: 50px;
`