import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Loader from 'components/Auth/Loader'
import Sidebar from 'components/Sidebar'
import { ButtonComponent as Button } from "components/Button";
import CircleProgress from "components/CircleProgress";
import { sendAmplitudeEvent } from "analytics/amplitude";

import {
    RecipeWrapper,
    RecipeContentWrapper,
    RecipeContent,
    RecipeLeftBlock,
    RecipeRightBlock,
    RecipeImage,
    RecipeImageBlock,
    RecipeText,
    RecipeTitle,
    RecipeHeader,
    RecipeNutritionalWrapper,
    RecipeNutritionalBlock,
    RecipeNutritionalTitle,
    RecipeNutritionalValue,
    RecipeNutritionalDetailedBlock,
    RecipeNutritionalDetailedTitle,
    RecipeNutritionalDetailedValue,
    RecipeIngredientItem,
    RecipeIngredientList,
    RecipeIngredientBlock,
    RecipeBackButton,
    RecipeBackButtonImage,
    RecipeBackButtonText,
    RecipeInstructionBlock,
    RecipeInstructionButtonBlock,
    RecipeProgressWrapper,
    RecipeProgressBlock,
    RecipeProgressNutritionalTitle
} from './styled';
import { getMealPlanRecipe } from "api"
import SuggesticLink from "components/SuggesticLink";

const RecipeComponent = () => {
    const routeMatch = useRouteMatch();
    const [loading, changeLoading] = useState(true)
    const [recipe, setRecipe] = useState({})
    const history = useHistory();

    useEffect(() => {
        getMealPlanRecipe(routeMatch.params.id).then(result => {
            if (200 === result.status) {
                setRecipe(() => result.data.data.recipe);
                changeLoading(false)
            }
        })

        sendAmplitudeEvent('meal_open')
    }, [])

    const getTimeInMinutes = (timeInSeconds) => {
        if (timeInSeconds % 60 !== 0) {
            return +(timeInSeconds / 60).toFixed() + 1
        }

        return timeInSeconds / 60
    }

    const backHandle = () => history.push("/meal-plan")

    const openMealRecipe = (recipeUrl) => {
        sendAmplitudeEvent('meal_recipe');
        window.open(recipeUrl)
    }

    return (
        <RecipeWrapper>
            <Sidebar />
            <RecipeContentWrapper>
                <RecipeBackButton onClick={backHandle}>
                    <RecipeBackButtonImage src={'/images/arrow-back.svg'}/>
                    <RecipeBackButtonText>Back</RecipeBackButtonText>
                </RecipeBackButton>
            {loading ? <Loader/> : <RecipeContent>
                <RecipeLeftBlock>
                    <RecipeImageBlock>
                        <RecipeImage src={recipe.mainImage}></RecipeImage>
                    </RecipeImageBlock>
                    <RecipeText>RECIPE</RecipeText>
                    <RecipeText></RecipeText>
                    <RecipeTitle>{recipe.name}</RecipeTitle>
                    <RecipeText>{recipe.numOfServings} Servings ({recipe.servingWeight.toFixed()} g)
                        | {getTimeInMinutes(recipe.totalTimeInSeconds)} min | {recipe.ingredientsCount} Ingradients</RecipeText>
                    <RecipeIngredientBlock>
                        <RecipeHeader>Ingredients</RecipeHeader>
                        <RecipeIngredientList>
                            {recipe.ingredientLines.map((item, itemCount) =>
                                <RecipeIngredientItem key={itemCount}>{item}</RecipeIngredientItem>
                            )}
                        </RecipeIngredientList>
                    </RecipeIngredientBlock>
                    <RecipeInstructionBlock>
                        <RecipeHeader>Instruction</RecipeHeader>
                        <RecipeInstructionButtonBlock>
                            <Button title="Read on “Clean Eating”" type="submit" onClick={() => openMealRecipe(recipe.source.recipeUrl)}/>
                        </RecipeInstructionButtonBlock>
                    </RecipeInstructionBlock>
                </RecipeLeftBlock>
                <RecipeRightBlock>
                    <RecipeHeader>Ingredients</RecipeHeader>
                    <RecipeProgressWrapper>
                        <RecipeProgressBlock>
                            <CircleProgress percent={recipe.percents.carbs.percent} text={(recipe.nutrientsTotal.carbs) + ' g'}/>
                            <RecipeProgressNutritionalTitle>Carbs</RecipeProgressNutritionalTitle>
                        </RecipeProgressBlock>
                        <RecipeProgressBlock>
                            <CircleProgress percent={recipe.percents.protein.percent} text={(recipe.nutrientsTotal.protein) + ' g'}/>
                            <RecipeProgressNutritionalTitle>Protein</RecipeProgressNutritionalTitle>
                        </RecipeProgressBlock>
                        <RecipeProgressBlock>
                            <CircleProgress percent={recipe.percents.fat.percent} text={(recipe.nutrientsTotal.fat) + ' g'}/>
                            <RecipeProgressNutritionalTitle>Fat</RecipeProgressNutritionalTitle>
                        </RecipeProgressBlock>
                    </RecipeProgressWrapper>
                    <RecipeNutritionalWrapper>
                        <RecipeNutritionalDetailedBlock>
                            <RecipeNutritionalDetailedTitle>Carbs</RecipeNutritionalDetailedTitle>
                            <RecipeNutritionalDetailedValue>{recipe.nutrientsTotal.carbs}</RecipeNutritionalDetailedValue>
                        </RecipeNutritionalDetailedBlock>
                        <RecipeNutritionalBlock>
                            <RecipeNutritionalTitle>Sugar</RecipeNutritionalTitle>
                            <RecipeNutritionalValue>{recipe.nutrientsTotal.sugar}</RecipeNutritionalValue>
                        </RecipeNutritionalBlock>
                        <RecipeNutritionalBlock>
                            <RecipeNutritionalTitle>Fiber</RecipeNutritionalTitle>
                            <RecipeNutritionalValue>{recipe.nutrientsTotal.fiber}</RecipeNutritionalValue>
                        </RecipeNutritionalBlock>
                        <RecipeNutritionalDetailedBlock>
                            <RecipeNutritionalDetailedTitle>Protein</RecipeNutritionalDetailedTitle>
                            <RecipeNutritionalDetailedValue>{recipe.nutrientsTotal.protein}</RecipeNutritionalDetailedValue>
                        </RecipeNutritionalDetailedBlock>
                        <RecipeNutritionalDetailedBlock>
                            <RecipeNutritionalDetailedTitle>Fat</RecipeNutritionalDetailedTitle>
                            <RecipeNutritionalDetailedValue>{recipe.nutrientsTotal.fat}</RecipeNutritionalDetailedValue>
                        </RecipeNutritionalDetailedBlock>
                        <RecipeNutritionalBlock>
                            <RecipeNutritionalTitle>Saturated fats</RecipeNutritionalTitle>
                            <RecipeNutritionalValue>{recipe.nutrientsTotal.saturatedFat}</RecipeNutritionalValue>
                        </RecipeNutritionalBlock>
                        <RecipeNutritionalBlock>
                            <RecipeNutritionalTitle>Trans fats</RecipeNutritionalTitle>
                            <RecipeNutritionalValue>{recipe.nutrientsTotal.transFat}</RecipeNutritionalValue>
                        </RecipeNutritionalBlock>
                        <RecipeNutritionalBlock>
                            <RecipeNutritionalTitle>Cholesterol</RecipeNutritionalTitle>
                            <RecipeNutritionalValue>{recipe.nutrientsTotal.cholesterol}</RecipeNutritionalValue>
                        </RecipeNutritionalBlock>
                        <RecipeNutritionalBlock>
                            <RecipeNutritionalTitle>Sodium</RecipeNutritionalTitle>
                            <RecipeNutritionalValue>{recipe.nutrientsTotal.sodium}</RecipeNutritionalValue>
                        </RecipeNutritionalBlock>
                        <RecipeNutritionalBlock>
                            <RecipeNutritionalTitle>Potassium</RecipeNutritionalTitle>
                            <RecipeNutritionalValue>{recipe.nutrientsTotal.potassium}</RecipeNutritionalValue>
                        </RecipeNutritionalBlock>
                    </RecipeNutritionalWrapper>
                </RecipeRightBlock>
                </RecipeContent>
            }
                <SuggesticLink style={{ justifyContent: 'center' }}/>
            </RecipeContentWrapper>
        </RecipeWrapper>
    )
}

export default RecipeComponent