import styled from "styled-components";
import { THEME } from "THEME";

export const ButtonSlide = styled.div`
  width: 100%;
  background-color: ${props => props?.isV4 ? THEME.COLOR_OLD_GREEN : THEME.COLOR_BROWN};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const ButtonSlideText = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.COLOR_DARK_GRAY};
  text-align: center;
  transition: text-shadow .2s ease-out;
  text-shadow: 0 0 0 currentColor;

  @media (min-width: 960px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Label = styled.label`
  width: 50%;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  border-radius: 12px;
  cursor: pointer;
  margin: 0 2px;

  @media (min-width: 960px) {
    min-height: 48px;
  }

  &:hover ${ButtonSlideText} {
    text-shadow: 0.5px 0 0 currentColor;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;

    &:checked + ${ButtonSlideText} {
      text-shadow: 0.5px 0 0 currentColor;
    }

    @media (min-width: 960px) {
      cursor: pointer;
    }
  }
`;

export const ButtonSlideBackground = styled.span`
  position: absolute;
  background-color: #FFFFFF;
  box-shadow: 0 1px 4px #DBD7EE;
  border-radius: 12px;
  top: 2px;
  width: calc(50% - 4px);
  height: calc(100% - 4px);
  z-index: 1;
  left: ${props => props.move ? "calc(50% + 2px)" : "2px"};
  transition: left ${THEME.TRANSITION};
`;
