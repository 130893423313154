import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initialize, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { changeProgressStep, setProfileData } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';

import { CurrentWeight } from './styled';
import validate from './validate';
import WeightQuiz from 'components/Quiz/WeightQuiz';
import { getNextProgressStep, resetField } from 'utils';

const CurrentWeightComponent = ({ handleSubmit, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentWeight, targetWeight } = useSelector((state) => state.form)
  const { measure } = currentWeight.values;
  const target = targetWeight?.values?.weight;
  const [currentWeightError, setCurrentWeightError] = useState('');

  const textWeight = `measure.${measure}.weight`;

  const submitForm = (data) => {
    const currentWeight = data.weight;

    dispatch(setProfileData({
      currentWeight,
      measure
    }));

    sendAmplitudeEvent('4_click_fact_weight', {
      'Fact.weight': currentWeight
    })

    dispatch(initialize('targetWeight', {
      measure,
      currentWeight,
      currentMeasure: measure,
      'weight': target ?? ''
    }));

    history.push('target-weight');
  };

  useEffect(() => {
    if (!currentWeight?.fields?.weight) {
      return;
    }

    const errorResult = validate(currentWeight.values);
    if (errorResult.weight && 'imperial' === measure) {
      setCurrentWeightError(() => 'Please enter a value between 110 and 550');
      return;
    }

    if (errorResult.weight && 'metric' === measure) {
      setCurrentWeightError(() => 'Please enter a value between 50 and 250');
      return;
    }
    setCurrentWeightError('');
  });

  useEffect(() => {
    sendAmplitudeEvent('4_view_fact_weight');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  const moveReset = () => {
    return resetField(dispatch, 'currentWeight', {
      weight: ''
    });
  };

  return (
    <CurrentWeight onSubmit={handleSubmit(submitForm)}>
      <WeightQuiz
        title={t('currentWeight.title')}
        inputTitle={`${t('currentWeight.inputTitle')} (${t(textWeight)})`}
        placeholder={`00 (${t(textWeight)})`}
        error={currentWeightError}
        measure={measure}
        moveReset={moveReset}
      />
    </CurrentWeight>
  );
};

export default reduxForm({
  form: 'currentWeight',
  destroyOnUnmount: false,
  validate,
  initialValues: { measure: 'imperial' },
})(CurrentWeightComponent);
