import {
  SET_PROFILE_EMAIL,
  SET_PURCHASE_EVENT_SENT,
  SET_PROFILE_DATA,
  SET_URL_LINK
} from 'store/actions';


const profileInitialState = {
  email: localStorage.getItem('email'),
  isPurchaseEventSent: false,
  urlLink: ''

};

const profileReducer = (state = profileInitialState, action) => {
  switch (action.type) {
    case SET_PROFILE_EMAIL:
      localStorage.setItem('email', action.payload.email);
      return {
        ...state,
        ...action.payload,
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PURCHASE_EVENT_SENT:
      return {
        ...state,
        ...action.payload,
      };
      case SET_URL_LINK:
      return {
        ...state,
        urlLink: action.payload?? '',
      };
    default:
      return state;
  }
};

export default profileReducer;
