import React from "react";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import {
  ResultsChart,
  ResultsChartPreTitle,
  ResultsChartTitle,
  ResultsChartTitleWeight
} from "./styled";
import { useSelector } from "react-redux";

const ResultsChartComponent = ({ measure, month, year,  ...props }) => {
  const { t } = useTranslation();
  const dataChart = [];
  const { chart } = useSelector((state) => state.onboardResult);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  chart.points.map((elem, count) => {
    let month = '';
    if (count === 0) {
      month = monthNames[chart.start_month-1]
    }
    if (count === 14) {
      month = t(`overallResults.forecast.descriptionText`)
    }
    return dataChart.push({
      name: month,
      weight: elem,
    });
  });

  const getDiffWeight = () => {
    let sign = chart.direct === 'loss' ? '-' : '';

    return sign + chart.weight_difference
  }

  return (
    <ResultsChart {...props}>
      <ResultsChartPreTitle>
        {chart.direct === 'loss' ? t(`overallResults.forecast.loss`) : t(`overallResults.forecast.gained`)}
      </ResultsChartPreTitle>
      <ResultsChartTitle>
        <ResultsChartTitleWeight>
          {getDiffWeight()} {t(`measure.${measure}.weight`)}</ResultsChartTitleWeight>  {t(`overallResults.forecast.description`)} {t(`overallResults.forecast.descriptionText`)}
      </ResultsChartTitle>
      <ResponsiveContainer height={265} width="100%">
        <LineChart data={dataChart} margin={{top: 10, right: 10, left: 0, bottom: 0}} label={false}>
          <XAxis dataKey="name" tickLine={false} tickSize={15} fontSize={"13px"} strokeWidth={0}
                 stroke="#9aa4b0" label={false}/>
          <YAxis dataKey="weight" tickLine={false} tickSize={5} tickMargin={6} domain={["dataMin", "dataMax"]}
                 fontSize={"12px"} strokeWidth={0} width={45} tickCount={dataChart.length - 5} stroke="#9aa4b0"
                 label={false}/>
          <Tooltip/>
          <CartesianGrid stroke="#e4e6ed" vertical={false}/>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="50%" x2="50%" y2="100%">
              <stop offset="0%" stopColor="#FB7483"/>
              <stop offset="40%" stopColor="#B295F6"/>
              <stop offset="70%" stopColor="#9DA4ED"/>
              <stop offset="100%" stopColor="#68CBD6"/>
            </linearGradient>
          </defs>
          <Line
            type="monotone"
            activeDot={false}
            strokeWidth={3}
            dot={{stroke: "#B295F6", strokeWidth: 6, fill: "#B295F6",}}
            dataKey="weight"
            stroke="url(#gradient)"
            yAxisId={0}
          />
        </LineChart>
      </ResponsiveContainer>
    </ResultsChart>
  )
}
export default ResultsChartComponent;
