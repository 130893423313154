import { Route } from 'react-router-dom';
import { UpSellComponent } from './UpSellComponent';
import {  useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { changeDataProgress } from 'store/actions';

export const UpSell = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { appVersion: isV3 } = useSelector((state) => state);
  const upSellData = t(`upSellData`, { returnObjects: true });

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
  }, []);

  return (
    <>
      {upSellData.map(data => {
        return (
          <Route path={`${isV3}/upsell/${data.id}`} key={data.id}>
            <UpSellComponent data={data}/>
          </Route>
        )
      })}
    </>
  );
};
