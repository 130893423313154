import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { createMarkup } from 'utils';
import { sendAmplitudeEvent } from 'analytics/amplitude';

import { ButtonComponent as Button } from 'components/Button';
import {
  BannerFood,
  BannerFoodImageWrap,
  BannerFoodImage,
  BannerFoodContent,
  BannerFoodTitle,
  BannerFoodText,
  BannerFoodButton
} from './styled';

const BannerFoodComponent = ({ handleClick, measure }) => {
  const { chart } = useSelector((state) => state.onboardResult);

  const getText = () => {
    const text = 'loss' === chart.direct ? t(`bannerFood.textLost`) : t(`bannerFood.textGained`);
    return text
        .replace(/value/i, chart.weight_difference)
        .replace(/measure/i, t(`measure.${measure}.weight`));
  };

  const handler = () => {
    sendAmplitudeEvent('y_quiz_results_getMyPlan_button_click');
    handleClick();
  };

  const { t } = useTranslation();
  return (
    <BannerFood>
      <BannerFoodImageWrap>
        <BannerFoodImage src={`/images/b-food.png`}/>
      </BannerFoodImageWrap>
      <BannerFoodContent>
        <BannerFoodTitle>{t(`bannerFood.title`)}</BannerFoodTitle>
        <BannerFoodText dangerouslySetInnerHTML={createMarkup(getText())}/>
        <BannerFoodButton>
          <Button title={t('messages.getMyPlan')} type='button' onClick={handler}/>
        </BannerFoodButton>
      </BannerFoodContent>
    </BannerFood>
  );
};

export default BannerFoodComponent;
