import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, change, reset, clearFields } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { Cereals } from './styled';
import { getChoiceValues, getNextProgressStep, scrollPageToTop, scrollTo } from 'utils';
import ChoiceQuizComponent from 'components/Quiz/ChoiceQuiz';
import { useIsAppVersion } from 'customHooks';

const CerealsComponent = ({ handleSubmit, dispatch, history }) => {
  const { t } = useTranslation();
  const [formRequired, changeFormRequired] = useState(false);
  const itemsCereal = t(`cereals.items`, { returnObjects: true });
  const { allergies } = useSelector((state) => state.form);
  const { lactose, gluten } = allergies ? allergies?.values : {};
  const isV4 = useIsAppVersion('v4');

  const submitForm = (values) => {
    const array = getChoiceValues(values);
    sendAmplitudeEvent('33_click_cereals', { Cereals: array });
    const arrValues = Object.values(values);
    for (let i = 0; i < arrValues.length; i++) {
      if (arrValues[i]) {
        changeFormRequired(false);
        const link = isV4 ? 'meat-fish' : 'preferred-products';
        return history.push(link);
      }
    }
    changeFormRequired(true);
    scrollTo('error');
  };

  const handleSelect = () => {
    dispatch(clearFields('cereals', false, false, 'reset'));
    changeFormRequired(false);
  };

  const resetSelected = () => {
    dispatch(reset('cereals'));
    changeFormRequired(false);
  };

  const disabledChoice = (name) => {
    switch (name) {
      case 'rice':
        return lactose;
      case 'bulgur':
        return gluten;
      case 'couscous':
        return gluten;
      default:
        return false;
    }
  };

  const cleanChoice = () => {
    if (lactose) {
      dispatch(change('cereals', 'rice', false));
    }
    if (gluten) {
      dispatch(change('cereals', 'couscous', false));
      dispatch(change('cereals', 'bulgur', false));
    }
  };

  useEffect(() => {
    sendAmplitudeEvent('33_view_cereals');
    scrollPageToTop();
    dispatch(changeProgressStep(getNextProgressStep()));
    cleanChoice();
  }, []);

  return (
    <Cereals onSubmit={handleSubmit(submitForm)}>
      <ChoiceQuizComponent
        title={t('cereals.title')}
        items={itemsCereal}
        imagePath={'cereals'}
        reset={t('cereals.reset', { returnObjects: true })}
        formRequired={formRequired}
        resetSelected={resetSelected}
        handleSelect={handleSelect}
        disabled={disabledChoice}
      />
    </Cereals>
  );
};

export default reduxForm(
  {
    form: 'cereals',
    destroyOnUnmount: false
  }
)(CerealsComponent);
