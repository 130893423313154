import React from "react";

import {
    MealProgress,
    ProgressWeekWrapper,
    ProgressWeekTitle,
    ProgressWeekTitleActive,
    ProgressWeekBlock,
    ProgressDayImage
} from './styled'
import { useSelector } from "react-redux";

const MealProgressComponent = (props) => {
    const { mealProgress } = useSelector((state) => state);

    const getWeekList = () => {
        const defaultValues = [1,2,3,4];
        const multiplier = Math.floor(mealProgress.weekNumber / 4)

        return defaultValues.map((week) => {
            return multiplier * 4 + week;
        });
    }

    const dayList = [1,2,3,4,5,6,7]

    const getImage = (dayCount, weekCount) => {
        if (weekCount > mealProgress.weekNumber) {
            return <ProgressDayImage src={'/images/mealProgress/next-day.svg'}/>
        }

        if (weekCount < mealProgress.weekNumber) {
            return <ProgressDayImage src={'/images/mealProgress/complete-day.svg'}/>
        }

        if (dayCount === props.day) {
            return <ProgressDayImage src={'/images/mealProgress/current-day.svg'}/>
        }

        if (dayCount < props.day) {
            return <ProgressDayImage src={'/images/mealProgress/complete-day.svg'}/>
        }

        if (dayCount > props.day) {
            return <ProgressDayImage src={'/images/mealProgress/next-day.svg'}/>
        }
    }

    return (
        <MealProgress>
            {getWeekList().map((weekCount) =>
                <ProgressWeekWrapper>
                    {mealProgress.weekNumber === weekCount ? <ProgressWeekTitleActive>Week {weekCount}</ProgressWeekTitleActive> : <ProgressWeekTitle>Week {weekCount}</ProgressWeekTitle>}
                    <ProgressWeekBlock>
                        {dayList.map((dayCount) =>
                            <div>{getImage(dayCount, weekCount)}</div>
                        )}
                    </ProgressWeekBlock>
                </ProgressWeekWrapper>
            )}
        </MealProgress>
    )
}

export default MealProgressComponent