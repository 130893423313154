import React from 'react';
import {
  HelpFaqHeadline,
  HelpFaqWrapper,
  Wrapper
} from './styled';
import { Title } from 'components/Landing/styled';
import { useTranslation } from 'react-i18next';
import { FaqMapper } from 'pages/Policy/Faq';

const HelpFaq = () => {
  const { t } = useTranslation();
  const helpFaq = t('landingV4.helpFaq', { returnObjects: true });

  return(
    <HelpFaqWrapper>
      <Wrapper>
        <Title>{helpFaq.title}</Title>
        <HelpFaqHeadline>{helpFaq.headline}</HelpFaqHeadline>
        <FaqMapper items={helpFaq.items}/>
      </Wrapper>
    </HelpFaqWrapper>
  );
};

export default HelpFaq;