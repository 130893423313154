import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeDataProgress, changeUserData } from 'store/actions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Payment,
  Companies,
  CompaniesWrapper,
  CompaniesItems,
  CompaniesItem,
} from './styled';
import ReachGoalAndLose from 'components/Payment/ReachGoalAndLose';
import ForecastChart from 'components/Payment/ForecastChart';
import BenefitsPlan from 'components/Payment/BenefitsPlan';
import TrustContent from 'components/Payment/TrustContent';
import { getMonthForProgram, scrollPageToTop, scrollTo } from 'utils';
import { GuaranteeBlock } from 'components/GuaranteeBlock';
import { PaymentForm } from 'components/Payment/PaymentForm';
import { sendFacebookEvent } from 'analytics/facebookPixel';
import { pinterestEvent } from 'analytics/pinterest';
import { useIsAppVersion } from 'customHooks';
import { gaGetMyPlanClickButton } from 'analytics/ga';

const PaymentComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isV4 = useIsAppVersion('v4');
  const { remoteConfig } = useSelector((state) => state);
  const { checkoutPage } = remoteConfig || {};
  const { additionalBlock } = checkoutPage || {};

  const {
    graph,
    refundPolicy,
    secureList
  } = additionalBlock || {}

  useEffect(() => {
    scrollPageToTop();
    sendFacebookEvent('InitiateCheckout');
    sendAmplitudeEvent('payment-screen');
    isV4 && gaGetMyPlanClickButton();
    dispatch(changeUserData({ hasBeenToPaymentBefore: true }));
    dispatch(changeDataProgress({ visible: false }));
    pinterestEvent('AddToCart');
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);

  const companiesProviderLogo = [
    'https',
    'visa',
    'unionpay',
    'mc',
    'american-express',
    'discover',
    'maestro',
    'jcb',
    'paypal',
    'google-pay',
    'mastercard',
    'ssl-img',
    'apple-pay',
    'norton'
  ];

  const getCompaniesProviderLogo = () => {
    return (
      companiesProviderLogo.map((companyLogo, index) => {
        return <CompaniesItem src={`/images/payment/${companyLogo}.svg`} key={index}/>
      })
    );
  };

  const { chart } = useSelector((state) => state.onboardResult);
  const { measure, currentWeight, desiredWeight } = useSelector((state) => state.profile);
  const { month, year } = getMonthForProgram(currentWeight, desiredWeight, '0');

  const getDiffWeight = () => {
    const sign = 'loss' === chart.direct ? '-' : '';

    const currentMeasure = t(`measure.${measure}.weight`);
    return `${sign}${chart.weight_difference}${currentMeasure}`;
  };

  const scrollToCheckout = () => {
    scrollTo('payment');
  };

  const chartBlock = () => {
    if (isV4 && graph) {
      return <ForecastChart measure={measure} month={month} year={year}/>;
    } else if (!isV4) {
      return <ForecastChart measure={measure} month={month} year={year}/>;
    }
  };

  const guaranteeBlock = () => {
    if (isV4 && refundPolicy) {
      return <GuaranteeBlock style={{ maxWidth: '100%' }} isPaymentPage={true}/>;
    } else if (!isV4) {
      return <GuaranteeBlock style={{ maxWidth: '100%' }} isPaymentPage={true}/>;
    }
  };

  const trustContentBlock = () => {
    if (isV4 && secureList) {
      return <TrustContent/>;
    } else if (!isV4) {
      return <TrustContent/>;
    }
  };

  return (
    <Payment>
      <ReachGoalAndLose getDiffWeight={getDiffWeight}/>
      {chartBlock()}
      <BenefitsPlan getDiffWeight={getDiffWeight} scrollToCheckout={scrollToCheckout}/>
      {guaranteeBlock()}
      <PaymentForm isPaymentPage={true} isVersionMain={true} scrollId={'payment'}/>
      {trustContentBlock()}
        <Companies>
          <CompaniesWrapper>
            <CompaniesItems>
              {getCompaniesProviderLogo()}
            </CompaniesItems>
          </CompaniesWrapper>
        </Companies>
    </Payment>
  );
};

export default PaymentComponent;