import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setVisitCookie, checkVisitCookie } from 'helper/cookie';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeDataProgress } from 'store/actions';
import ExplanationWork from 'components/Landing/ExplanationWork';
import ImprovesHealth from 'components/Landing/ImprovesHealth';
import Reviews from 'components/Reviews';
import config from 'config';
import { gaFirstVisit, gaFirstClick } from 'analytics/ga';
import {
  StartingFooter,
  AllRightsReserved,
  SupportEmail,
  StartingFooterContainer,
  StartingV3Wrapper
} from './styled';
import { sendFacebookEvent } from 'analytics/facebookPixel';

export const StartingV3 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { urlLink } = useSelector((state) => state.profile);
  const goToQuiz = () => {
    gaFirstClick();
    history.push(`/v3/gender${urlLink}`);
  };

  const cookieName = 'mpofv';

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);
  
  useEffect(() => {
    sendFacebookEvent('Lead');
    sendAmplitudeEvent('view_landing');
    if (undefined === checkVisitCookie(cookieName)) {
      sendAmplitudeEvent('first_open');
      gaFirstVisit();
      setVisitCookie(cookieName);
    }
  }, []);

  const handleClick = () => {
    sendAmplitudeEvent('lp_support_email_click');
  };

  const currentYear = new Date(Date.now()).getFullYear();
  return <StartingV3Wrapper>
    <ImprovesHealth handleClick={goToQuiz}/>
    <ExplanationWork handleClick={goToQuiz}/>
    <Reviews />
    <StartingFooter>
      <StartingFooterContainer>
        <AllRightsReserved>
          ©{currentYear} Your Meal Plan. All rights reserved.
        </AllRightsReserved>
        <SupportEmail onClick={handleClick} href={`mailto:${config.supportEmail}`}>{config.supportEmail}</SupportEmail>
      </StartingFooterContainer>
    </StartingFooter>
  </StartingV3Wrapper>
};