import { ModalContent, ModalWrapper } from './styled';

export const Modal = ({ children, ...props }) => {
  const isShowState = props.isShow;

  if (!isShowState) {
    return null;
  }
  return (
    <ModalWrapper>
      <ModalContent {...props}>{children}</ModalContent>
    </ModalWrapper>
  );
};
