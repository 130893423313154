import { AnaliticsGoalGraphBottomValue, AnaliticsGoalGraphPicture, AnaliticsGoalGraphTopValue, AnaliticsGoalGraphWrapper } from './styled';
import { useSelector } from "react-redux";

export const AnaliticsGoalGraph = ({ date, currentWeight, isShowBottomValue = true, images, valueTop, valueLeft, color }) => {
  const { measure } =  useSelector((store) => store.profile);
  const measureValue = 'imperial' === measure   ? 'lbs' : 'kg';
  const { image, image_2x } = images;
  const { month, year } = date;
  return (
    <AnaliticsGoalGraphWrapper>
      <AnaliticsGoalGraphTopValue top={valueTop} left={valueLeft} color={color}>
        {currentWeight} {measureValue}
      </AnaliticsGoalGraphTopValue>
      <AnaliticsGoalGraphPicture
        src={image}
        srcSet={`${image} 320w, ${image_2x} 480w`}
        alt='score'
        loading='lazy'
        width={293}
        height={152}
      />
      {isShowBottomValue && (
        <AnaliticsGoalGraphBottomValue>
          by {month} {year}
        </AnaliticsGoalGraphBottomValue>
      )}
    </AnaliticsGoalGraphWrapper>
  );
};
