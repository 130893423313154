const promise = store => next => action => {
  if (action.type.match(/PROMISE/)) {
    const {dispatch} = store;
    const {actions, params} = action;
    const [REQ_ACTION, RES_ACTION, ERR_ACTION] = actions;

    dispatch({type: REQ_ACTION, middleware: true, params});
    return action.promise
      .then(res => res.json())
      .then(res => {
        dispatch({type: RES_ACTION, middleware: true, payload: res, params});
      })
      .catch(err => {
        dispatch({type: ERR_ACTION, params, payload: err});
      });
  }

  return next(action);
};

export default promise;
