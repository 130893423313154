import React from "react";

import {Loader, LoaderWrapper} from "./styled";

const LoaderComponent = () => {
    return (
        <LoaderWrapper>
            <Loader />
        </LoaderWrapper>
    )
}

export default LoaderComponent