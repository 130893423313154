import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { gaGetMyPlanClickButton } from 'analytics/ga';

import CountJoin from 'components/CountJoin';
import WeightLossKit from 'components/WeightLossKit';
import OftenAsk from 'components/OftenAsk';
import Reviews from 'components/Reviews';
import FastingDescription from 'components/FastingDescription';
import { changeDataDiscount } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';

import { OverallReview, OverallReviewLine } from './styled';
import Nutrition from 'components/Nutrition';
import { scrollPageToTop } from 'utils';
import { GuaranteeBlock } from 'components/GuaranteeBlock';
import { Example } from 'components/Example';

const OverallReviewComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { gender } = useSelector((state) => state.profile);
  const sex = ('male' ===  gender ? 'male' : 'female') ?? '';

  const goToNextPage = () => {
    gaGetMyPlanClickButton();
    history.push('/v3/paid-trial');
  }

  useEffect(() => {
    scrollPageToTop();
    sendAmplitudeEvent('overall_review');
    dispatch(changeDataDiscount({ visibleDiscountHeader: true }));
    return () => {
      dispatch(changeDataDiscount({ visibleDiscountHeader: false }));
    }
  }, []);

  return (
    <OverallReview>
      <CountJoin/>
      <OverallReviewLine>
        <WeightLossKit handleClick={goToNextPage}/>
      </OverallReviewLine>
      <OverallReviewLine>
        <Nutrition/>
      </OverallReviewLine>
      <OverallReviewLine>
        <FastingDescription/>
      </OverallReviewLine>
      <OverallReviewLine>
        <Example sex={sex} handleClick={goToNextPage}/>
      </OverallReviewLine>
      <OverallReviewLine>
        <OftenAsk/>
      </OverallReviewLine>
      <OverallReviewLine>
        <Reviews isOverallReviewPage/>
      </OverallReviewLine>
      <OverallReviewLine>
        <GuaranteeBlock/>
      </OverallReviewLine>
    </OverallReview>
  );
};

export default OverallReviewComponent;
