import {SET_ONBOARD_RESULT} from "store/actions";

const onboardResultInitialState = {
    after_first_week: 0.0,
    bmi: {
        weight_state: '',
        weight_state_slug: '',
        current_bmi: 0.0,
        percent: 0
    },
    chart: {
        direct: '',
        weight_difference: 0.0,
        start_month: 1,
        end_month: 1,
        end_year: 2021,
        points: []
    },
    daily_calorie_norm: 2354,
    water_minimum: 0.0
};

const onboardResultReducer = (state = onboardResultInitialState, action) => {
    switch (action.type) {
        case SET_ONBOARD_RESULT:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default onboardResultReducer;
