import styled from "styled-components";

export const BodyType = styled.form`
  width: 100%;
`;

export const BodyTypeWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 30px 16px;
  @media (min-width: 960px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const BodyTypeTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const BodyTypePreTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const BodyTypeItems = styled.div`
  padding-top: 20px;
  @media (min-width: 960px) {
    padding-top: 30px;
  }
`;

