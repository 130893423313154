import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { changeProgressStep } from 'store/actions';
import { TypicalLunch } from './styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { replaceString, getAge, getNextProgressStep } from 'utils';
import { useSelector } from 'react-redux';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { useIsAppVersion } from 'customHooks';

const TypicalLunchComponentV3 = ({ history, dispatch }) => {
  const { t } = useTranslation();
  const { age, gender } = useSelector((state) => state.profile);
  const sex = gender ? 'male' ===  gender ? 'male' : 'female' : null;
  const isV4 = useIsAppVersion('v4');
  const items = t(`typicalLunch.items`, {returnObjects: true});

  const handleSelect = (data) => {
    const typical = data.target.title;
    sendAmplitudeEvent('13_click_typical_lunch', {
      'typical_lunch': typical
    });
    const link = isV4 ? 'about-you' : 'metabolism';
    history.push(link);
  };

  useEffect(() => {
    sendAmplitudeEvent('13_view_typical_lunch');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  const getSubTitle = () => {
    return replaceString(t(`typicalLunch.subtitle.${sex}`), [{
      what: '{years}',
      forWhat: getAge(age)
    }]);
  };

  return (
    <TypicalLunch>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('typicalLunch.title')}
        subtitle={!isV4 && getSubTitle()}
        name={'typical_lunch'}
      />
    </TypicalLunch>

  );
};

export default reduxForm(
  {
    form: 'typicalLunch',
    destroyOnUnmount: false
  }
)(TypicalLunchComponentV3);
