import styled from "styled-components";
import { THEME } from "THEME";

export const Policy = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 960px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

export const PolicyWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PolicyLoading = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 960px) {
    min-height: 400px;
  }
`;


export const PolicyTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  text-align: center;
  color: #000000;
  @media (min-width: 960px) {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 40px;
  }
`;

export const PolicyText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  
  span {
    font-weight: bold;
  }

  p {
    margin-bottom: 16px;
  }

  b, strong {
    font-weight: bold;
  }

  .pre-title {
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 2px;
  }

  ul {
    margin-bottom: 16px;

    li {
      margin-bottom: 12px;
    }
  }

  .list-dote {
    li {
      position: relative;
      padding-left: 20px;

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #537EE7;
      }
    }
  }


  a {
    color: #537EE7;
    
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .list-anchor {
    counter-reset: anchor;
    margin-bottom: 30px;

    li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 22px;

      a {
        color: #537EE7;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }

        &::before {
          counter-increment: anchor;
          content: counter(anchor) ".";
          position: absolute;
          top: 1px;
          left: 0;
          color: #000000;
        }
      }
    }
  }

  .list-category {
    counter-reset: category;

    li {
      & > .list-category__title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 20px;
        font-weight: 700;
        display: inline-block;

        &::before {
          counter-increment: category;
          content: counter(category) ". ";
          top: 1px;
          left: 0;
          color: #000000;
        }
      }
    }
  }

  .policy-color__red {
    color: #FF0100;
    font-weight: 700;
  }

  .list-count {
    counter-reset: count;
    margin-left: 20px;
    margin-top: 20px;

    & > li {
      margin-bottom: 20px;
      font-weight: 400;
      display: inline-block;
      padding-left: 30px;
      font-size: 18px;
      line-height: 26px;
      position: relative;

      &::before {
        counter-increment: count;
        content: counter(count) ". ";
        color: #000000;
        position: absolute;
        top: 0;
        left: 0;
      }

    }
  }

  .list-numbers {
    counter-reset: numbers;

    &__title {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 15px;
      font-weight: 700;
      display: inline-block;

      &::before {
        counter-increment: numbers;
        content: counter(numbers) ". ";
        top: 1px;
        left: 0;
        color: #000000;
      }
    }

    .list-numbers {
      padding-left: 10px;

      .list-numbers__title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        margin-bottom: 5px;
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  @media (min-width: 960px) {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    p {
      margin-bottom: 20px;
    }

    ul {
      margin-bottom: 20px;
    }

    h1, h2, h3, h4, h5, h6 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
`;
