import styled from 'styled-components';
import { THEME } from 'THEME';

export const BenefitsWrapper = styled.div`
  width: 100%;
  margin-bottom: 90px;
  background: linear-gradient(
          to top,
          ${THEME.COLOR_BROWN} 0%,
          ${THEME.COLOR_BROWN} 50%,
          white 50%,
          white 100%
  );
  @media(max-width: 960px) {
    background: linear-gradient(
            to top,
            ${THEME.COLOR_BROWN} 0%,
            ${THEME.COLOR_BROWN} 75%,
            white 25%,
            white 100%
    );
    margin-bottom: 50px;
  }
  @media (min-width: 1600px) {
    background: linear-gradient(
            to top,
            ${THEME.COLOR_BROWN} 0%,
            ${THEME.COLOR_BROWN} 60%,
            white 40%,
            white 100%
    );
  }
`

export const StatisticsWrapper = styled.div`
  position: relative;
  padding: 100px 0;

  @media(max-width: 960px) {
    padding: 40px 0 100px;
  }
`
export const StatisticsContent = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 60px;

  @media(max-width: 960px) {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

`

export const StatisticsNumber = styled.div`
  font-weight: bold;
  font-size: 72px;
  line-height: 79px;
  color: ${THEME.COLOR_BLUE};

  @media(max-width: 960px) {
    font-size: 48px;
    line-height: 52px;
  }
`

export const StatisticsCaption = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: #7C7357;

  @media(max-width: 960px) {
    font-size: 16px;
    line-height: 22px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 16px;

  @media(max-width: 960px) {
    max-width: 468px;
  }
`

export const VideoContent = styled.div`
  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 20px;

  @media(max-width: 960px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

`

export const VideoContentCaption = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 28px;
  color: #7C7357;
`