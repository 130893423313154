import styled from "styled-components";
import { BindingSectionLine } from 'components/Icons';
import { THEME, BACKGROUND_GRADIENT } from 'THEME';

export const ExplainWorkBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  @media (max-width: 961px) {
    top: 40px;
  }
`;

export const ExplanationWorkHeading = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  margin: auto;
  @media (min-width: 960px) {
    text-align: left;
    font-size: 42px;
    line-height: 48px;
  }
`;

export const ExplanationWorkWrapper = styled.div`
  position: relative;
  max-width: 288px;
  margin: auto;
  @media (min-width: 960px) {
    max-width: 960px;
    padding: 0 16px;
  }

  @media (min-width: 1170px) {
    max-width: 1170px;
  }
`;

export const ExplainWorkListWrapper = styled.div`
  @media (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.list-2, &.list-4 {
      flex-direction: row-reverse;
    }
  }
`;

export const ExplainWorkListLeftSection = styled.div`
  @media (min-width: 960px) {
    flex-basis: 50%;
  }
`;

export const ExplainWorkListStepTitle = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
`;

export const ExplainWorkListTitle = styled.p`
  font-weight: 800;
  font-size: 20px;
  line-height: 26px;
  color: #191B1F;
  margin: 8px 0 10px;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
    margin: 8px 0 24px;
  }
`;

export const ExplainWorkListSubtitle = styled.p`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 32px;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ExplainWorkButtonWrapper = styled.div`
  max-width: 256px;
  max-height: 48px;
  @media (max-width: 959px) {
    margin: auto;
  }
  @media (min-width: 960px) {
    max-width: 240px;
    max-height: 64px;
  }

  button {
    background: ${BACKGROUND_GRADIENT.COLOR_BLUE_HOVER};
    
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 30px;
  max-width: 256px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

export const ExplainWorkListRightSection = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 40px 0;
  @media (min-width: 960px) {
    justify-content: center;
    flex-basis: 50%;
  }
`;

export const ExplainWorkListImage = styled.img`
  position: relative;
  z-index: 1;
  &.image-1 {
    max-width: 266px;
    max-height: 153px;
    &:hover {
      cursor: pointer;
    }
    @media (min-width: 960px) {
      max-width: 529px;
      max-height: 304px;
    }
  }

  &.image-2 {
    max-width: 249px;
    max-height: 181px;
    @media (min-width: 960px) {
      max-width: 500px;
      max-height: 362px;
    }
  }

  &.image-3 {
    max-width: 270px;
    max-height: 214px;
    @media (min-width: 960px) {
      max-width: 548px;
      max-height: 432px;
    }
  }

  &.image-4 {
    max-width: 265px;
    max-height: 210px;
    @media (min-width: 960px) {
      max-width: 497px;
      max-height: 384px;
    }
  }
`;

export const ExplanationWorkWrapperImage = styled(BindingSectionLine)`
  position: absolute;
  top: 312px;
  right: 374px;
  z-index: 0;
  @media (max-width: 959px) {
    display: none;
  }
  @media (min-width: 1170px) {
    right: 374px;
  }
`;

export const ExplanationWork = styled.div`
  position: relative;
  z-index: 2;
  padding: 64px 0;
  background-color: ${props => props?.isV4 ? THEME.COLOR_SAND : 'unset'};
  @media (min-width: 960px) {
    padding: 150px 0 120px;
  }

  ${ExplainWorkBackground} {
    background: ${props => props?.isV4 ? 'unset' : BACKGROUND_GRADIENT.EXPLANATION_BACKGROUND};
    opacity: ${props => props?.isV4 ? 1 : 0.1};
  }
  
  ${ExplainWorkListStepTitle} {
    color: ${props => props?.isV4 ? THEME.COLOR_LIGHT_GREEN : THEME.COLOR_PURPLE};
  }
  
  ${ExplainWorkListSubtitle} {
    color: ${props => props?.isV4 ? THEME.COLOR_LIGHT_BLACK : 'unset'};
  }
`;

