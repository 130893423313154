import styled from "styled-components";

const activeClassName = 'nav-item-active'

export const SettingsWrapper = styled.div`
    background: #F6F6FC;
    display: flex;
    min-height: 85vh;
`;

export const SettingsContentWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0 0 0;
`;

export const SettingsHeaderBlock = styled.div`
`;

export const SettingsNavigationBlock = styled.div`
    width: 270px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(3, 3, 35, 0.04);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 0;
`;

export const SettingsContentBlock = styled.div`
    width: 60%;
    height: 788px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(3, 3, 35, 0.04);
    border-radius: 20px;
`;

export const SettingsChangePassword = styled.div`
    margin: 50px 0 0 0;
`

export const SettingsAccountDetails = styled.div`
`

export const SettingsNavigationLink = styled.span`
    font-weight: bold;
    line-height: 24px;
    font-size: 16px;
    width: 80%;
    margin: 10px auto;
    text-align: center;
    background: ${props => props.active ? "#F6F6FC" : "#fff"};
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
`

export const SettingsNavigationWrapper = styled.div`
`

export const SettingsChangePasswordContent = styled.form`
    width: 60%;
    margin: 0 auto;
`

export const SettingsChangePasswordTitle = styled.h3`
    text-align: center;
    font-size: 24px;
`

export const SettingsChangePasswordInputWrapper = styled.div`
    margin: 30px 0 15px 0;
`

export const SettingsAccountDetailsTitle = styled.h3`
    text-align: center;
    font-size: 24px;
    margin: 50px 0 0 0;
`

export const SettingsNotificationBlock = styled.div`
    height: 45px;
`

export const SettingsError = styled.p`
    color: red;
`

export const SettingsNotification = styled.p`
    color: green;
`



