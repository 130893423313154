import React from 'react';
import {Input, InputTitle, InputValue, InputBody} from './styled';

const InputComponent = ({type, name, placeholder, title, ...props}) => {
  return (
        <Input>
            <InputTitle>{title}</InputTitle>
            <InputBody>
                <InputValue type={type} placeholder={placeholder} {...props}/>
            </InputBody>
        </Input>
    )
};

export default InputComponent;
