import styled from "styled-components";
import { THEME } from "THEME";

export const FastingDescriptionWrapper = styled.div`
  max-width: 1000px;
  margin: auto auto 50px;
  @media (min-width: 960px) {
    margin: auto auto 120px;
  }
`

export const FastingDescriptionTitle = styled.h2`
  color: ${THEME.COLOR_BLACK};
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  padding: 0 10px;
  @media (min-width: 960px) {
    padding: 0;
    font-size: 42px;
    line-height: 48px;
    max-width: 630px;
    margin: auto;
  }
`

export const FastingDescriptionImagesBlock = styled.section`
  position: relative;
  margin: 140px 0 120px;
  max-height: 309px;
  &::before {
    content: '';
    background: #F8F6F0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 309px;
    margin: auto;
    @media(min-width: 960px) {
      max-height: 390px;
    }
    @media(min-width: 1002px) {
      max-width: 1002px;
      border-radius: 30px;
    }
  }
  @media(min-width: 960px) {
    display: flex;
    max-height: 506px;
  }
  @media(min-width: 1002px) {
    max-width: 1002px;
    margin: 90px auto auto;
  }
`

export const FastingDescriptionImagSalad = styled.img`
  max-width: 292px;
  position: relative;
  z-index: 2;
  top: -125px;
  display: block;
  margin: auto;
  left: -41px;
  @media(min-width:960px) {
    max-width: 511px;
    top: -46px;
    left: -69px;
  }
`
export const FastingDescriptionImagPhone = styled.img`
  max-width: 176px;
  position: relative;
  z-index: 2;
  display: block;
  margin: auto;
  left: 0;
  max-height: 298px;
  bottom: 200px;
  @media(min-width: 960px) {
    max-width: 301px;
    top: -57px;
    left: -126px !important;
    bottom: 0;
    max-height: 100%;
  }
`