import { facebookConversionApi } from 'api';
import Cookies from 'js-cookie';

const getFacebookPixelClientId = () => {
  try {
    const fbpId = Cookies.get('_fbp');
    if (!fbpId) {
      return null;
    }
    return fbpId;
  } catch (err) {
    return null;
  }
};

export const sendFacebookEvent = (event, price = '', isV4 = false, em = null, fn = null, db = null, ge = null) => {
  const timestamp = Date.now();
  const sourceUrl = document.location.toString();
  const fbpId = getFacebookPixelClientId();

  if ('Purchase' === event) {
    window.fbq('track', event, { currency: 'USD', value: price });
  } else if (isV4 && 'Purchase' === event) {
    window.fbq('track', event, { currency: 'USD', value: price, em, fn, db, ge: (ge ? ('male' === ge ? 'm' : 'f') : null) });
  } else {
    window.fbq('track', event);
  }
  facebookConversionApi(
    event,
    sourceUrl,
    timestamp,
    price,
    fbpId,
    isV4 ? em : null,
    isV4 ? fn : null,
    isV4 ? db : null,
    isV4 ? ge : null
  ).then((res) => {
    return res;
  });
}