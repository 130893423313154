import React from 'react';
import {
  FeedbackContentAvatar, FeedbackContentAvatarImage, FeedbackContentAvatarName, FeedbackContentAvatarResults,
  FeedbackContentAvatarWrapper,
  FeedbackContentText,
  FeedbackContentTitle,
  FeedBackContentWrapper,
  FeedBackWrapper,
  Headline,
  Wrapper
} from './styled';
import { Title } from 'components/Landing/styled';
import { useTranslation } from 'react-i18next';

const Feedback = () => {
  const { t } = useTranslation();
  const feedback = t('landingV4.feedback', { returnObjects: true });

  return(
    <FeedBackWrapper>
      <Wrapper>
        <Title>{feedback.title}</Title>
        <Headline>{feedback.headline}</Headline>
        <FeedBackContentWrapper>
          {feedback.items.map(({title, text, image, name, result}, index) => {
            return(
              <li key={index}>
                <FeedbackContentTitle>{title}</FeedbackContentTitle>
                <FeedbackContentText>{text}</FeedbackContentText>
                <FeedbackContentAvatarWrapper>
                  <FeedbackContentAvatar>
                    <FeedbackContentAvatarImage
                      src={image}
                      alt={'avatar'}
                      loading='lazy'
                    />
                    <FeedbackContentAvatarName>{name}</FeedbackContentAvatarName>
                  </FeedbackContentAvatar>
                  <FeedbackContentAvatarResults>{result}</FeedbackContentAvatarResults>
                </FeedbackContentAvatarWrapper>
              </li>
            )
          })}
        </FeedBackContentWrapper>
      </Wrapper>
    </FeedBackWrapper>
  );
};

export default Feedback;