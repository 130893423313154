import styled from "styled-components";

// *,
// *:before,
// *:after {
//     box-sizing: border-box;
// }
// html,
//     body {
//     background: #ecf0f1;
//     color: #444;
//     font-family: 'Lato', Tahoma, Geneva, sans-serif;
//     font-size: 16px;
//     padding: 10px;
// }
// .set-size {
//     font-size: 10em;
// }
// .charts-container:after {
//     clear: both;
//     content: "";
//     display: table;
// }
// .pie-wrapper {
//     height: 1em;
//     width: 1em;
//     float: left;
//     margin: 15px;
//     position: relative;
// }
//
// .pie-wrapper .pie {
//     height: 100%;
//     width: 100%;
//     clip: rect(0, 1em, 1em, 0.5em);
//     left: 0;
//     position: absolute;
//     top: 0;
// }
// .pie-wrapper .pie .half-circle {
//     height: 100%;
//     width: 100%;
//     border: 0.1em solid #3498db;
//     border-radius: 50%;
//     clip: rect(0, 0.5em, 1em, 0);
//     left: 0;
//     position: absolute;
//     top: 0;
// }
// .pie-wrapper .label {
//     background: #34495e;
//     border-radius: 50%;
//     bottom: 0.4em;
//     color: #ecf0f1;
//     cursor: default;
//     display: block;
//     font-size: 0.25em;
//     left: 0.4em;
//     line-height: 2.6em;
//     position: absolute;
//     right: 0.4em;
//     text-align: center;
//     top: 0.4em;
// }
// .pie-wrapper .label .smaller {
//     color: #bdc3c7;
//     font-size: .45em;
//     padding-bottom: 20px;
//     vertical-align: super;
// }
// .pie-wrapper .shadow {
//     height: 100%;
//     width: 100%;
//     border: 0.1em solid #bdc3c7;
//     border-radius: 50%;
// }
// .pie-wrapper.style-2 .label {
//     background: none;
//     color: #7f8c8d;
// }
// .pie-wrapper.style-2 .label .smaller {
//     color: #bdc3c7;
// }
//
// .pie-wrapper.progress-45 .pie .right-side {
//     display: none;
// }
// .pie-wrapper.progress-45 .pie .half-circle {
//
//     border-color: #7078f4;
// }
// .pie-wrapper.progress-45 .pie .left-side {
//     transform: rotate(111deg);
// }

export const CircleProgressContainer = styled.div`
    font-size: 7em;
`

export const CircleProgressWrapper = styled.div`
    height: 1em;
    width: 1em;
    float: left;
    margin: 15px;
    position: relative;
`

export const CircleProgressLabel = styled.span`
border-radius: 50%;
    bottom: 0.4em;
    color: #191B1F;
    cursor: default;
    font-size: 0.25em;
    left: 0.4em;
    line-height: 1.2em;
    position: absolute;
    right: 0.4em;
    text-align: center;
    top: 0.4em;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const CircleProgressPercent = styled.span`
    color: #191B1F;
    font-size: 20px;
    font-weight: bold;
`

export const CircleProgressPie = styled.div`
    height: 100%;
    width: 100%;
    clip: rect(0, 1em, 1em, 0.5em);
    left: 0;
    position: absolute;
    top: 0;
`

export const CircleProgressLeftSide = styled.div`
    transform: rotate(${props => props.percent}deg);
    height: 100%;
    width: 100%;
    border: 0.1em solid #3498db;
    border-radius: 50%;
    clip: rect(0, 0.5em, 1em, 0);
    left: 0;
    position: absolute;
    top: 0;
    // border-color: #7078f4;
`

export const CircleProgressRightSide = styled.div`
    height: 100%;
    width: 100%;
    border: 0.1em solid #3498db;
    border-radius: 50%;
    clip: rect(0, 0.5em, 1em, 0);
    left: 0;
    position: absolute;
    top: 0;
    display: none;
`

export const CircleProgressShadow = styled.div`
    height: 100%;
    width: 100%;
    border: 0.1em solid #f6f6f6;
    border-radius: 50%;
`

export const CircleProgressValue = styled.span`
    font-size: 13px;
    color: #3F5366;
`
