import React from 'react';
import Collapsible from 'react-collapsible';

import {ArrowRounded} from 'components/Icons';
import { createMarkup } from "utils";
import {Dropdown, DropdownHeader, DropdownBody, DropdownHeaderText, DropdownHeaderArrow} from './styled';

const DropdownTop = ({ title }) =>
  <DropdownHeader>
    <DropdownHeaderText>
      {title}
    </DropdownHeaderText>
    <DropdownHeaderArrow>
      <ArrowRounded width="100%" height="100%"/>
    </DropdownHeaderArrow>
  </DropdownHeader>;

const DropdownComponent = ({ title, text }) => {
  return (
    <Dropdown>
      <Collapsible trigger={<DropdownTop title={title}/>}>
        <DropdownBody dangerouslySetInnerHTML={createMarkup(text)}/>
      </Collapsible>
    </Dropdown>
  )
}

export default DropdownComponent;
