import styled from "styled-components";

export const RecoveryPasswordWrapper = styled.div`

`

export const RecoveryPassword = styled.div`
  width: 100%;
  max-width: 602px;
  margin: 150px auto;
  padding: 30px 16px;
  @media (min-width: 960px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`

export const RecoveryPasswordContent = styled.div`
`

export const RecoveryPasswordTitle = styled.h2`
    font-size:24px;
    font-weight:bold;
    text-align: center;
`

export const RecoveryPasswordText = styled.p`
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin: 15px 0 0 0;
`

export const RecoveryPasswordForm = styled.form`
    margin: 50px 0 0 0;
`

export const RecoveryPasswordNotificationBlock = styled.div`
    height: 45px;
`

export const RecoveryPasswordError = styled.p`
    color: red;
`

export const RecoveryPasswordNotification = styled.p`
    color: green;
`