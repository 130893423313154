import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSlide from 'components/ButtonSlide';
import { ButtonComponent as Button } from 'components/Button';
import Input from 'components/Input';

import {
  Title,
  Content,
  WrapButton,
  WeightInput,
  ErrorText,
  Subtitle,
  Wrapper
} from 'components/Quiz/styled';

const WeightQuiz = ({ title, inputTitle, subtitle, error, placeholder, measure, moveReset }) => {
  const { t } = useTranslation();

  return (
      <Wrapper>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <ButtonSlide
          name='measure'
          valueFirst='imperial'
          valueSecond='metric'
          textFirst={t('messages.imperial')}
          textSecond={t('messages.metric')}
          move={'imperial' !== measure}
          moveReset={moveReset}
        />
        <Content>
          <WeightInput>
            <Input
              type='number'
              name='weight'
              placeholder={placeholder}
              title={inputTitle}
            />
            <ErrorText>
              {error}
            </ErrorText>
          </WeightInput>
          <WrapButton>
            <Button title={t('messages.continue')} type='submit' />
          </WrapButton>
        </Content>
      </Wrapper>
  );
}

export default WeightQuiz;
