import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import PeriodExpired from "pages/PeriodExpired";
import { getSubscribeInfo } from "api";
import { changeSubscriptionData } from "store/actions";

import {
    Layout,
    LayoutContent
} from './styled'

const LayoutComponent = ({children}) => {
    const { subscription } = useSelector((state) => state);
    const dispatch = useDispatch();

    const updateSubscription = () => {
        if (false === subscription.is_updated) {
            getSubscribeInfo().then(res => {
                if (200 === res.status) {
                    dispatch(changeSubscriptionData({
                        is_active: res.data.data.is_active,
                        type: res.data.data.type,
                        provider: res.data.data.method,
                        is_updated: true
                    }))
                }
            })
        }
    }

    useEffect(() => {
        updateSubscription()
    }, [])

    return(
         <Layout>
             {subscription.is_active ? children: <PeriodExpired />}
         </Layout>
    )
}

export default LayoutComponent