import styled from "styled-components";
import {NavLink} from "react-router-dom";

const activeClassName = 'active-item'

export const SidebarWrapper = styled.div`
    width: 64px;
    background: #fff;
    padding: 0 0 0 5px;
    border-top: 1px solid #F6F6FC;
`

export const SidebarNavigation = styled.div`
    margin: 140px 0 0 0;
`

export const SidebarNavigationLink = styled(NavLink).attrs({activeClassName})`
    padding: 20px 0 20px 15px;
    cursor: pointer;
    display: block;
    
    &.${activeClassName} {
        background: #F6F6FC;
        border-radius:10px 0 0 10px;
    }
`

export const SidebarNavigationActiveItem = styled.div`
    padding: 13px 10px 10px 10px;
    background: #e5e5e5;
    border-radius: 10px 0 0 10px;
`

export const SidebarNavigationIcon = styled.img`

`

