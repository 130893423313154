import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonDom,
  ButtonText,
  ButtonWrapperLoading
} from './styled';

import { ButtonLoader } from 'components/Icons';

export const ButtonComponent = ({ title, type, children, isLoading, ...props }) => {
  const [color, setColor] = useState('BLUE');

  useEffect(() => {
    if (props.color !== undefined) {
      setColor(props.color.toUpperCase());
    }
  }, []);

  return (
    <Button color={color} styleProps={isLoading && { opacity: 0.5 }}>
      <ButtonDom type={type} {...props}>
        {isLoading ? (
          <ButtonWrapperLoading>
            <ButtonLoader/>
          </ButtonWrapperLoading>
        ) : (
          <ButtonText>{title}</ButtonText>
        )}
        {children}
      </ButtonDom>
    </Button>
  );
};

export const PaymentButtonComponent = ({ title, type, ...props }) => {
  return (
    <Button>
      <ButtonDom type={type} {...props}>
        <ButtonText>{title}</ButtonText>
      </ButtonDom>
    </Button>
  );
};

export const ColorButton = ({ title, type, ...props }) => {
  const [color, setColor] = useState('PINK');

  useEffect(() => {
    if (props.color !== undefined) {
      setColor(props.color.toUpperCase());
    }
  }, []);

  return (
    <Button color={color}>
      <ButtonDom type={type} {...props}>
        <ButtonText>{title}</ButtonText>
      </ButtonDom>
    </Button>
  );
};
