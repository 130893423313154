import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep, setProfileData } from 'store/actions';
import { EventName } from './styled';
import EventNameQuizComponent from 'components/Quiz/EventNameQuiz';
import validate from './validate';
import { getNextProgressStep, resetField, trimSentence } from 'utils';

const EventNameComponent = ({ handleSubmit, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { eventName } = useSelector((state) => state.form);
  const [error, setError] = useState('');
  const items = t(`eventName.items`, { returnObjects: true });

  const submitForm = (values) => {
    const motivationEvent = values.motivationEvent ?? 'custom';
    const customEvent = values.customEvent ? trimSentence(values.customEvent) : '';
    dispatch(setProfileData({
      motivationEvent,
      motivationEventName: customEvent
    }));
    const isValid = ('custom' === motivationEvent && customEvent) || ('custom' !== motivationEvent);
    if (isValid && !error) {
      sendAmplitudeEvent('37_click_Event_name', {
        Event: motivationEvent
      });
      return history.push('/event-date');
    }
  };

  useEffect(() => {
    if (!eventName?.fields) {
      return;
    }

    if (!eventName?.fields?.customEvent) {
      return;
    }

    const errorResult = validate(eventName.values);

    if (errorResult.error) {
      setError(() => 'Choose at least one option');
      return;
    }

    if ('error' === errorResult.customEvent) {
      setError(() => 'Your event name should contain only latin characters, spaces and numbers');
      return;
    }

    if ('length' === errorResult.customEvent) {
      setError(() => 'Your event name should be 2 to 15 characters long');
      return;
    }

    return setError('');
  });

  const handleSelect = () => {
    resetField(dispatch,'eventName', {
      customEvent: ''
    });
  };

  const onChange = () => {
    resetField(dispatch,'eventName', {
      motivationEvent: ''
    })
    return setError('');
  };

  useEffect(() => {
    sendAmplitudeEvent('37_view_Event_name');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <EventName onSubmit={handleSubmit(submitForm)}>
      <EventNameQuizComponent
        title={t('eventName.title')}
        subtitle={t('eventName.subtitle')}
        items={items}
        name={'motivationEvent'}
        error={error}
        placeholder={t('eventName.inputPlaceholder')}
        onChange={onChange}
        handleSelect={handleSelect}
      />
    </EventName>
  );
};

export default reduxForm(
  {
    form: 'eventName',
    destroyOnUnmount: false
  }
)(EventNameComponent);
