import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkEmail } from 'api';
import { gaEmailInsert } from 'analytics/ga';
import { sendAmplitudeEvent, setUserProperties } from 'analytics/amplitude';
import { changeDataProgress, setProfileEmail } from 'store/actions';
import Email from 'components/Email';
import { EmailResults } from './styled';
import { Title, Subtitle } from 'pages/Payment/styled';
import validate from './validate';
import { useIsAppVersion } from 'customHooks';

const EmailResultsComponent = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isV4 = useIsAppVersion('v4');
  const { t } = useTranslation();
  const { emailResults } = useSelector((state) => state.form);
  const [email, setEmail] = useState(null)
  const [error, setError] = useState('');

  const updateValue = (value) => {
    setEmail(value);
  };

  const submitForm = () => {
    sendAmplitudeEvent('50_click_email',{ email_entered:email });
    checkEmail(email).then((res) => {
      if (200 === res.status) {
        dispatch(setProfileEmail({ email }));
        gaEmailInsert();
        setUserProperties({ 'email_address': email });
        const link = isV4 ? 'payment' : 'overall-results';
        history.push(link);
      }
      if (409 === res.status) {
        setError('This email has an existing account, please use another one');
      }
    });
  };

  useEffect(() => {
    if (!emailResults?.fields) {
      return;
    }
    if (!emailResults?.fields?.email) {
      return;
    }

    const errorResult = validate(emailResults.values);
    if ('required' === errorResult?.email) {
      setError(`Your email is required`);
      return;
    }
    if ('invalid' === errorResult?.email) {
      setError(`Your email doesn't look right, please check it again`);
      return;
    }

    setError('');
  }, [emailResults?.values?.email]);

  useEffect(() => {
    if (emailResults?.values?.email) {
      setEmail(() => emailResults.values.email);
    }
    sendAmplitudeEvent('50_view_email');
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);

  return (
    <EmailResults onSubmit={handleSubmit(submitForm)}>
      <Title>
        {isV4 ? t('resultEmail.titleV4') : t('resultEmail.title')}
      </Title>
      <Subtitle>
        {!isV4 && t('resultEmail.subtitle')}
      </Subtitle>
      <Email
        updateValue={updateValue}
        placeholder={t('resultEmail.placeholderInput')}
        titleInput={t('resultEmail.titleInput')}
        buttonText={t('messages.continue')}
        isPaymentPage={true}
        error={error}
      />
    </EmailResults>
  );
};

export default reduxForm({
  form: 'emailResults',
  destroyOnUnmount: false,
  validate,
})(EmailResultsComponent);

