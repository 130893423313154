import styled from "styled-components";
import { THEME } from 'THEME';

export const GetMyPlan = styled.div`
  background-color: ${THEME.COLOR_SAND};
  @media (min-width: 960px) {  
    position: relative;  
  }
`;

export const GetMyPlanContent = styled.div`
  position: relative;
`;

export const GetMyPlanButton = styled.div`
  max-width: 256px;
  margin: auto;
  @media (min-width: 960px) {
    margin: 0 auto 0 0;
  }
  button {
    background-color: ${THEME.COLOR_LIGHT_GREEN};
  }
`


export const GetMyPlanWrapper = styled.section`
  padding: 40px 16px 0;
  max-width: 330px;
  margin: auto;
  @media (min-width: 960px) {
    display: flex;
    max-width: 960px;
    align-items: center;
    justify-content: space-between;
    padding: 100px 16px 0;
  }
  @media (min-width: 1170px) {
    max-width: 1170px;
  }
`;

export const GetMyPlanLeftSection = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  @media (min-width: 960px) {
    max-width: 612px;
    margin-bottom: 80px;
  }
`

export const GetMyPlanTitle = styled.div`
  font-weight: 700;
  color: #090909;
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 29px;
  @media (min-width: 960px) {
    font-size: 56px;
    line-height: 67px;
    text-align: left;
    display: inline;
  }
`;

export const GetMyPlanSubtitle = styled.p`
  max-width: 400px;
  margin-bottom: 20px;
  @media (min-width: 960px) {
    margin: 20px 0 32px;
  }
`;


export const GetMyPlanRightSection = styled.div`
  max-width: 223px;
  margin: 30px auto -40px;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
  @media (min-width: 960px) {
    margin: 0;
    max-width: 400px;
    padding-bottom: 0;
  }
`

export const GetMyPlanImage = styled.img`
  display: block;
  width: 100%;
`;