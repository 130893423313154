import React from "react";
import {
    CircleProgressContainer,
    CircleProgressWrapper,
    CircleProgressLabel,
    CircleProgressPercent,
    CircleProgressPie,
    CircleProgressLeftSide,
    CircleProgressRightSide,
    CircleProgressValue,
    CircleProgressShadow

} from './styled';

const CircleProgressComponent = ({percent, text}) => {
    return (
        <CircleProgressContainer>
            <CircleProgressWrapper>
                <CircleProgressLabel>
                     <CircleProgressPercent> {percent} %</CircleProgressPercent>
                     <CircleProgressValue>{text}</CircleProgressValue>
                </CircleProgressLabel>
                <CircleProgressPie>
                    <CircleProgressLeftSide percent={percent * 3.6}>
                    </CircleProgressLeftSide>
                    <CircleProgressRightSide>
                    </CircleProgressRightSide>
                </CircleProgressPie>
                <CircleProgressShadow>

                </CircleProgressShadow>
            </CircleProgressWrapper>
        </CircleProgressContainer>
    )
}

export default CircleProgressComponent