import styled from "styled-components";
import { THEME } from "THEME";

export const NutritionWrapper = styled.div`
  max-width: 1002px;
  padding: 16px;
  margin: 50px auto;
  @media (min-width: 960px) {
    margin: 90px auto;
  }
`

export const NutritionTitle = styled.h2`
  color: ${THEME.COLOR_BLACK};
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  @media (min-width: 960px) {
    font-size: 42px;
    line-height: 48px;
    max-width: 630px;
    margin: auto;
  }
`

export const NutritionistsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1000px) {
    margin-top: 40px;
    flex-direction: row;
  }
`

export const NutritionistsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  &:first-child {
    margin-bottom: 30px;
  }
  @media (min-width: 1000px) {
    &:first-child {
      margin-right: 50px;
      margin-bottom: 0px;
    }
  }
`

export const NutritionistAvatar = styled.img`
  display: block;
  max-width: 100px;
  margin-right: 12px;
  @media (min-width: 960px) {
    max-width: 130px;
    margin-right: 30px;
  }
`

export const NutritionistBio = styled.div`
  
`

export const NutritionistBioName = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  color: #537EE7;
`

export const NutritionistBioDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  min-width: 175px;
  color: ${THEME.COLOR_BLACK};
  @media (min-width: 345px) {
    min-width: 216px;
  }
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
    min-width: 308px;
  }
`