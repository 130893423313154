import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import user from './user';
import progress from './progress';
import discount from './discount';
import profile from './profile';
import mealProgress from './mealProgress';
import subscription from './subscription';
import paymentPlan from './paymentPlan';
import onboardResult from './onboardResult';
import appVersion from './appVersion';
import paymentMethodState from './paymentMethod';
import remoteConfig from './remoteConfig';

const reducers = combineReducers({
  user,
  progress,
  mealProgress,
  discount,
  profile,
  subscription,
  paymentPlan,
  onboardResult,
  appVersion,
  remoteConfig,
  paymentMethodState,
  form: formReducer,
});

export default reducers;
