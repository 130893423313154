import {
  PaymentMethodsContentCard,
  PaymentMethodsContentCardText,
  PaymentMethodsContentWrapper
} from './styled';
import { PaymentCompanies } from 'components/PaymentCompanies';
import { useEffect, useState } from 'react';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { ApplePay } from 'components/ApplePay';
import { useSelector } from 'react-redux';

export const PaymentMethodsContent = () => {
  const { paymentMethodState }  = useSelector((state) => state.paymentMethodState);
  const [isToggled, setIsToggled] = useState({
    first : false,
    second : true
  });

  const handleClick = (id) => {
    if ('applePay' === id && !isToggled.first || 'creditCard' === id && !isToggled.second) {
      setIsToggled({first: !isToggled.first, second: !isToggled.second});
    }
  }

  useEffect(() => {
    if (isToggled.first) {
      setTimeout(() => {
        sendAmplitudeEvent(`y_pre-checkout_${paymentMethodState}_button_click`);
      }, 500);
    }
  }, [isToggled.first]);

  return (
    <PaymentMethodsContentWrapper>
      <ApplePay isToggled={isToggled.first} handleClick={() => handleClick('applePay')} />
      <PaymentMethodsContentCard isToggled={isToggled.second} onClick={() => handleClick('creditCard')}>
        <PaymentMethodsContentCardText>CreditCard</PaymentMethodsContentCardText>
        <PaymentCompanies/>
      </PaymentMethodsContentCard>
    </PaymentMethodsContentWrapper>
  );
};
