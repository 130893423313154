import styled from "styled-components";

export const RecipeWrapper = styled.div`
    background: #F6F6FC;
    display: flex;
    min-height: 85vh;
`

export const RecipeContent = styled.div`
    display: flex;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(3, 3, 35, 0.04);
    border-radius: 20px;
`;

export const RecipeContentWrapper = styled.div`
    width: 100%;
    padding: 30px;
`;

export const RecipeLeftBlock = styled.div`
  width: 50%;
  padding: 30px;
`;

export const RecipeRightBlock = styled.div`
    padding: 30px 30px 30px 0;
    width: 50%;
`;

export const RecipeText = styled.p`
    font-size: 13px;
    color: #3F5366
    margin: 5px 0;
`;

export const RecipeImage = styled.img`
    width: 100%;
    border-radius: 10px;

`;

export const RecipeImageBlock = styled.div`
    margin: 0 0 30px 0;
`;

export const RecipeTitle = styled.h2`
    margin: 10px 0;
    font-size: 30px;
    font-weight: bold;
    line-height: 33px;
`;

export const RecipeHeader = styled.h3`
    font-weight: bold;
    font-size: 24px;
    margin: 0 0 0 0;
`;

export const RecipeIngredientItem = styled.li`
    font-size: 18px;
    line-height: 28px;
    color: #3F5366;
`;

export const RecipeIngredientList = styled.ul`
    list-style: disc;
    margin: 15px 0 0 25px;
`

export const RecipeNutritionalBlock = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    margin: 14px 0;
`

export const RecipeNutritionalWrapper = styled.div`
    margin: 20px 0 0 0;
`

export const RecipeNutritionalTitle = styled.p`
`

export const RecipeNutritionalDetailedBlock = styled.div`
    display: flex;
    justify-content: space-between;
    background: #F6F6FC;
    border-radius: 70px;
    padding: 8px 16px;
    margin: 14px 0;
`

export const RecipeNutritionalDetailedTitle = styled.p`
    font-weight: bold;
`

export const RecipeNutritionalDetailedValue = styled.p`
    font-weight: bold;
`

export const RecipeNutritionalValue = styled.p`
`

export const RecipeIngredientBlock = styled.div`
    margin: 30px 0 0 0;
`

export const RecipeBackButton = styled.button`
    background: #fff;
    display: flex;
    align-items: center;
    width:96px;
    height: 40px;
    border-radius: 50px;
    justify-content: space-evenly;
    margin:0 0 30px 0;
`

export const RecipeBackButtonImage = styled.img`
`

export const RecipeBackButtonText = styled.p`
    color: #3F5366;
    font-size: 14px;
`

export const RecipeInstructionBlock = styled.div`
    margin: 20px 0 0 0;
`

export const RecipeInstructionButtonBlock = styled.div`
    margin: 20px 0 0 0;
`

export const RecipeProgressWrapper = styled.div`
    display: flex;
    justify-content: space-around;
`

export const RecipeProgressBlock = styled.div`

`

export const RecipeProgressNutritionalTitle = styled.div`
    font-size: 16px;
    color: #191B1F;
    text-align: center;
    font-weight: bold;
`