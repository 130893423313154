import { CHANGE_PROGRESS_STEP, CHANGE_DATA_PROGRES } from 'store/actions';

export const controlSteps = [
  {
    name: 'gender',
    route: '/gender',
  },
  {
    name: 'your-name',
    route: '/your-name',
  },
  {
    name: 'birthday',
    route: '/birthday',
  },
  {
    name: 'current-weight',
    route: '/current-weight',
  },
  {
    name: 'target-weight',
    route: '/target-weight',
  },
  {
    name: 'height',
    route: '/height',
  },
  {
    name: 'body-type',
    route: '/body-type',
  },
  {
    name: 'going-day',
    route: '/going-day',
  },
  {
    name: 'discomfort',
    route: '/discomfort',
  },
  {
    name: 'typical-lunch',
    route: '/typical-lunch',
  },
  {
    name: 'metabolism',
    route: '/metabolism',
  },
  {
    name: 'weight-gain',
    route: '/weight-gain',
  },
  {
    name: 'perfect-weight',
    route: '/perfect-weight',
  },
  {
    name: 'about-you',
    route: '/about-you',
  },
  {
    name: 'snack-trigger',
    route: '/snack-trigger',
  },
  {
    name: 'workout',
    route: '/workout',
  },
  {
    name: 'feel',
    route: '/feel',
  },
  {
    name: 'sleep',
    route: '/sleep',
  },
  {
    name: 'drink-water',
    route: '/drink-water',
  },
  {
    name: 'health-condition',
    route: '/health-condition',
  },
  {
    name: 'diabetes',
    route: '/diabetes',
  },
  {
    name: 'allergies',
    route: '/allergies',
  },
  {
    name: 'diets',
    route: '/diets',
  },
  {
    name: 'cooking-long',
    route: '/cooking-long',
  },
  {
    name: 'calories',
    route: '/calories',
  },
  {
    name: 'meals',
    route: '/meals',
  },
  {
    name: 'vegetables',
    route: '/vegetables',
  },
  {
    name: 'cereals',
    route: '/cereals',
  },
  {
    name: 'preferred-products',
    route: '/preferred-products',
  },
  {
    name: 'meat-fish',
    route: '/meat-fish',
  },
  {
    name: 'event-name',
    route: '/event-name',
  },
  {
    name: 'event-date',
    route: '/event-date',
  },
  {
    name: 'journey-feeling',
    route: '/journey-feeling',
  },
  {
    name: 'increase-in-me',
    route: '/increase-in-me',
  },
  {
    name: 'see-yourself',
    route: '/see-yourself',
  },
  {
    name: 'goal-addition',
    route: '/goal-addition',
  },
  {
    name: 'emotional-comfort',
    route: '/emotional-comfort',
  },
  {
    name: 'fail-back',
    route: '/fail-back',
  },
  {
    name: 'outside-motivation',
    route: '/outside-motivation',
  }
]

export const V3Steps = [
  {
    name: 'gender',
    route: '/v3/gender',
  },
  {
    name: 'your-name',
    route: '/v3/your-name',
  },
  {
    name: 'birthday',
    route: '/v3/birthday',
  },
  {
    name: 'current-weight',
    route: '/v3/current-weight',
  },
  {
    name: 'target-weight',
    route: '/v3/target-weight',
  },
  {
    name: 'height',
    route: '/v3/height',
  },
  {
    name: 'body-type',
    route: '/v3/body-type',
  },
  {
    name: 'going-day',
    route: '/v3/going-day',
  },
  {
    name: 'discomfort',
    route: '/v3/discomfort',
  },
  {
    name: 'typical-lunch',
    route: '/v3/typical-lunch',
  },
  {
    name: 'metabolism',
    route: '/v3/metabolism',
  },
  {
    name: 'weight-gain',
    route: '/v3/weight-gain',
  },
  {
    name: 'perfect-weight',
    route: '/v3/perfect-weight',
  },
  {
    name: 'about-you',
    route: '/v3/about-you',
  },
  {
    name: 'snack-trigger',
    route: '/v3/snack-trigger',
  },
  {
    name: 'workout',
    route: '/v3/workout',
  },
  {
    name: 'feel',
    route: '/v3/feel',
  },
  {
    name: 'sleep',
    route: '/v3/sleep',
  },
  {
    name: 'drink-water',
    route: '/v3/drink-water',
  },
  {
    name: 'health-condition',
    route: '/v3/health-condition',
  },
  {
    name: 'diabetes',
    route: '/v3/diabetes',
  },
  {
    name: 'allergies',
    route: '/v3/allergies',
  },
  {
    name: 'diets',
    route: '/v3/diets',
  },
  {
    name: 'cooking-long',
    route: '/v3/cooking-long',
  },
  {
    name: 'calories',
    route: '/v3/calories',
  },
  {
    name: 'meals',
    route: '/v3/meals',
  },
  {
    name: 'vegetables',
    route: '/v3/vegetables',
  },
  {
    name: 'cereals',
    route: '/v3/cereals',
  },
  {
    name: 'preferred-products',
    route: '/v3/preferred-products',
  },
  {
    name: 'meat-fish',
    route: '/v3/meat-fish',
  },
  {
    name: 'event-name',
    route: '/v3/event-name',
  },
  {
    name: 'event-date',
    route: '/v3/event-date',
  },
  {
    name: 'journey-feeling',
    route: '/v3/journey-feeling',
  },
  {
    name: 'increase-in-me',
    route: '/v3/increase-in-me',
  },
  {
    name: 'see-yourself',
    route: '/v3/see-yourself',
  },
  {
    name: 'goal-addition',
    route: '/v3/goal-addition',
  },
  {
    name: 'emotional-comfort',
    route: '/v3/emotional-comfort',
  },
  {
    name: 'fail-back',
    route: '/v3/fail-back',
  },
  {
    name: 'outside-motivation',
    route: '/v3/outside-motivation',
  }
];

export const V4Steps = [
  {
    name: 'gender',
    route: '/v4/gender'
  },
  {
    name: 'birthday',
    route: '/v4/birthday'
  },
  {
    name: 'body-type',
    route: '/v4/body-type'
  },
  {
    name: 'height',
    route: '/v4/height'
  },
  {
    name: 'current-weight',
    route: '/v4/current-weight'
  },
  {
    name: 'target-weight',
    route: '/v4/target-weight'
  },
  {
    name: 'event-date',
    route: '/v4/event-date'
  },
  {
    name: 'perfect-weight',
    route: '/v4/perfect-weight'
  },
  {
    name: 'weight-gain',
    route: '/v4/weight-gain'
  },
  {
    name: 'health-condition',
    route: '/v4/health-condition'
  },
  {
    name: 'going-day',
    route: '/v4/going-day'
  },
  {
    name: 'workout',
    route: '/v4/workout'
  },
  {
    name: 'sleep',
    route: '/v4/sleep'
  },
  {
    name: 'feel',
    route: '/v4/feel'
  },
  {
    name: 'diets',
    route: '/v4/diets'
  },
  {
    name: 'metabolism',
    route: '/v4/metabolism'
  },
  {
    name: 'typical-lunch',
    route: '/v4/typical-lunch'
  },
  {
    name: 'about-you',
    route: '/v4/about-you'
  },
  {
    name: 'snack-trigger',
    route: '/v4/snack-trigger'
  },
  {
    name: 'discomfort',
    route: '/v4/discomfort'
  },
  {
    name: 'drink-water',
    route: '/v4/drink-water'
  },
  {
    name: 'meals',
    route: '/v4/meals'
  },
  {
    name: 'cooking-long',
    route: '/v4/cooking-long'
  },
  {
    name: 'vegetables',
    route: '/v4/vegetables'
  },
  {
    name: 'cereals',
    route: '/v4/cereals'
  },
  {
    name: 'meat-fish',
    route: '/v4/meat-fish'
  },
  {
    name: 'preferred-products',
    route: '/v4/preferred-products'
  },
  {
    name: 'allergies',
    route: '/v4/allergies'
  },
  {
    name: 'outside-motivation',
    route: '/v4/outside-motivation'
  }
];

const contentInitialState = {
  activeStep: 0,
  visible: true,
  visibleHeader: true,
  lastStep: false,
  timer: null,
  allSteps: controlSteps
};

const contentReducer = (state = contentInitialState, action) => {
  switch (action.type) {
    case CHANGE_PROGRESS_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case CHANGE_DATA_PROGRES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default contentReducer;
