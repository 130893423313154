export const monthValidate = (value, cardYear) => {
  const date = new Date();
  const valueToNumber = value[0] === '0' ? Number(value.replace('0', '')) : Number(value);
  const valueLength = value.length;
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  return (valueLength < 2 || valueToNumber > 12 || (valueToNumber < currentMonth && cardYear <= currentYear));
}

export const yearValidate = (value) => {
  const date = new Date();
  const valueLength = value.length;
  const currentYear = date.getFullYear();
  return Number(value) < currentYear || valueLength < 4;
}