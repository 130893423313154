import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm, change, reset, clearFields } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { sendAmplitudeEvent } from 'analytics/amplitude';

import { changeProgressStep } from 'store/actions';

import {
  PreferredProducts
} from './styled';
import { getChoiceValues, getNextProgressStep, scrollPageToTop, scrollTo } from 'utils';
import ChoiceQuizComponent from 'components/Quiz/ChoiceQuiz';

const PreferredProductsComponent = ({ handleSubmit, dispatch, history }) => {
  const { t } = useTranslation();
  const [formRequired, changeFormRequired] = useState(false);
  const itemsPreferred = t(`preferredProducts.items`, { returnObjects: true });
  const { allergies } = useSelector((state) => state.form);
  const { lactose, egg } = allergies ? allergies.values : {};

  const submitForm = (values) => {
    const array = getChoiceValues(values);
    sendAmplitudeEvent('34_click_other_product', { Other_product: array });
    const arrValues = Object.values(values);
    for (let i = 0; i < arrValues.length; i++) {
      if (arrValues[i]) {
        changeFormRequired(false);
        return history.push('/meat-fish');
      }
    }
    changeFormRequired(true);
    scrollTo('error');
  };

  const handleSelect = () => {
    dispatch(clearFields('preferredProducts', false, false, 'reset'));
    changeFormRequired(false);
  };

  const resetSelected = () => {
    dispatch(reset('preferredProducts'));
    changeFormRequired(false);
  };

  const disabledChoice = (name) => {
    switch (name) {
      case 'milk':
        return lactose;
      case 'cottageCheese':
        return lactose;
      case 'tofu':
        return lactose;
      case 'eggs':
        return egg;
      default:
        return false;
    }
  };

  const cleanChoice = () => {
    if (lactose) {
      dispatch(change('preferredProducts', 'milk', false));
      dispatch(change('preferredProducts', 'cottageCheese', false));
      dispatch(change('preferredProducts', 'tofu', false));
    }
    if (egg) {
      dispatch(change('preferredProducts', 'eggs', false));
    }
  };

  
  useEffect(() => {
    sendAmplitudeEvent('34_view_other_product');
    scrollPageToTop();
    dispatch(changeProgressStep(getNextProgressStep()));
    cleanChoice();
  }, []);

  return (
    <PreferredProducts onSubmit={handleSubmit(submitForm)}>
      <ChoiceQuizComponent
        title={t('preferredProducts.title')}
        items={itemsPreferred}
        imagePath={'preferredProducts'}
        reset={t('preferredProducts.reset', {returnObjects: true})}
        formRequired={formRequired}
        resetSelected={resetSelected}
        handleSelect={handleSelect}
        disabled={disabledChoice}
      />
    </PreferredProducts>
  );
};

export default reduxForm(
  {
    form: 'preferredProducts',
    destroyOnUnmount: false
  }
)(PreferredProductsComponent);
