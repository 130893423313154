import styled from "styled-components";
import { THEME } from "THEME";

export const ErrorRequired = styled.div`
  width: 100%;
  color: ${THEME.COLOR_RED};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity ${THEME.TRANSITION};
`;

export const ErrorRequiredIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  align-content: center;
  margin-right: 16px;

`;

export const ErrorRequiredText = styled.p`
  @media (min-width: 960px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
