import { weekDiff } from 'utils';

const validate = values => {
  const errors = {};



  if (!values || !values.motivationEventDate) {
    errors.motivationEventDate = 'required';
  } else if (weekDiff(values.motivationEventDate) < 1) {
    errors.motivationEventDate = 'error';
  }

  return errors;
}

export default validate;
