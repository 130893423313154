import { THEME } from "THEME";

const defaultStyle = `
body {
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: ${THEME.COLOR_BLACK};
    background-color: #fff;
    overflow-x: hidden;
  }
  #root{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  } 
  #root > #expand {
    flex: 1;
  }
  .sideBenefits {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-decoration-line: underline;
    color: #3F5366;
  }
`;

export default defaultStyle;
