import styled from 'styled-components';
import { THEME } from 'THEME';

export const HelpFaqWrapper = styled.div`
  padding-top: 120px;
  background-size: cover;
  @media(max-width: 960px) {
    padding: 80px 0;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 16px;
  @media(max-width: 960px) {
    max-width: 468px;
  }
`

export const HelpFaqHeadline = styled.h2`
  font-weight: 500;
  font-size: 48px;
  line-height: 52px;
  color: ${THEME.COLOR_BLACK};
  margin-bottom: 60px;

  @media(max-width: 960px) {
    font-size: 36px;
    line-height: 39px;
  }
`