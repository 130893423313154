import React from 'react';
import { Field } from 'redux-form';
import { THEME } from 'THEME';
import { Check, Cancel } from 'components/Icons';
import {
  Input,
  InputTitle,
  InputIcon,
  InputValue,
  InputBody
} from './styled';
import { useIsAppVersion } from 'customHooks';

const renderField = ({ input, label, type, isIconShow, meta: { touched, error } }) => {
  return (
    <InputBody>
      <InputValue
        type={type}
        placeholder={label}
        step='any'
        autocomplete='off'
        {...input}
      />
      <InputIcon>
        {touched && !isIconShow ?
          (!error ? (
            <Check width='100%' height='100%' color={THEME.COLOR_GREEN}/>
            ) : (
              <Cancel width='100%' height='100%' color={THEME.COLOR_RED}/>
          )) : null}
      </InputIcon>
    </InputBody>
  );
};

const InputComponent = ({ type, name, placeholder, title, ...props }) => {
  const isV4 = useIsAppVersion('v4');
  return (
    <Input isV4={isV4}>
      <InputTitle>{title}</InputTitle>
      <Field
        name={name}
        type={type}
        label={placeholder}
        component={renderField}
        {...props}
      />
    </Input>
  );
};

export default InputComponent;
