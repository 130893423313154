import { SET_APP_VERSION } from "store/actions";

const appVersionInitialState = '';

const appVersionReducer = (state = appVersionInitialState, action) => {
  switch (action.type) {
    case SET_APP_VERSION:
      return state = action.payload;
    default:
      return state;
  }
};

export default appVersionReducer;
