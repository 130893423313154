import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Logo } from 'components/Icons';
import Menu from 'components/Menu';
import Progress from 'components/Progress';
import DiscountExpires from 'components/DiscountExpires';
import { useScrollBlock } from 'customHooks';
import {
  Header,
  HeaderWrapper,
  LogoWrapper,
  LogoSet,
  HeaderRight,
  MenuBtn
} from './styled';
import { useIsAppVersion } from 'customHooks';

const HeaderComponent = () => {
  const [visibleMenu, changeVisibleMenu] = useState(false);
  const { visible: visibleProgress } = useSelector((state) => state.progress);
  const { visibleDiscountHeader } = useSelector((state) => state.discount);
  const [blockScroll, allowScroll] = useScrollBlock();
  const openMenu = () => changeVisibleMenu(!visibleMenu);
  const { urlLink } = useSelector((state) => state.profile)
  const isV4 = useIsAppVersion('v4');

  useEffect(() => {
    if (visibleMenu) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [visibleMenu]);


  const { appVersion } = useSelector((state) => state);
  const getMainUrl = () => {
    switch (appVersion) {
      case '':
        return '/';
      case '/v3':
        return appVersion;
      case '/v4':
        return `/v4/home${urlLink}`;
    }
  };
  const mainUrl = getMainUrl();
  return (
    visibleDiscountHeader ? <DiscountExpires/> :
      <>
        <Header isV4={isV4}>
          <HeaderWrapper>
            <LogoSet>
              <Link style={{display: 'flex', alignItems: 'center'}} to={mainUrl}>
                <LogoWrapper>
                  <Logo />
                </LogoWrapper>
              </Link>
            </LogoSet>
            <HeaderRight>
              <Menu isV4={isV4} visibleMenu={visibleMenu}  changeVisibleMenu={changeVisibleMenu}/>
              <MenuBtn onClick={openMenu} open={visibleMenu}><i/></MenuBtn>
            </HeaderRight>
          </HeaderWrapper>
        </Header>
        {visibleProgress && <Progress/>}
      </>
  );
};

export default HeaderComponent;
