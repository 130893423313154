import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep, setProfileData } from 'store/actions';
import { Diets } from './styled';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils';

const DietsComponent = ({ dispatch }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isV4 = useIsAppVersion('v4');
  const items = t(`diets.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const diet = data.target.value;
    dispatch(setProfileData({ diet }));
    sendAmplitudeEvent('28_click_food_diets', {
      'Diets': diet
    });
    const link = isV4 ? 'metabolism' : 'cooking-long';
    history.push(link);
  };

  useEffect(() => {
    sendAmplitudeEvent('28_view_food_diets');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <Diets>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('diets.title')}
        subtitle={t('diets.subtitle')}
        name={'diet'}
      />
    </Diets>
  );
};

export default reduxForm(
  {
    form: 'diets',
    destroyOnUnmount: false
  }
)(DietsComponent);
