import Sidebar from 'components/Sidebar'
import Loader from 'components/Auth/Loader'
import MealProgress from 'components/Auth/MealProgress'
import React, { useEffect, useState } from "react";
import { setMealProgress } from "store/actions";
import { sendAmplitudeEvent } from "analytics/amplitude";
import Layout from "components/Auth/Layout";
import { successNotification } from "utils";

import {
    MealPlanWrapper,
    MealPlanHeader,
    MealPlanBlocksWrapper,
    DailyMealPlanBlockWrapper,
    DailyMealPlanHeader,
    DailyMealPlanBlock,
    DailyMealPlanItem,
    DailyMealPlan,
    DailyMealPlanSwap,
    DailyPlanImage,
    DailyPlanContent,
    DailyPlanPeriod,
    DailyPlanTitleLink,
    DailyPlanTitle,
    DailyPlanDescription,
    TodayLabelWrapper,
    DailyMealPlanHeaderWrapper,
    MealPlanContent,
    DailyMealImageLink,
    DailyMealPlanDisable,
    DailyMealPlanWrapperDisable,
    DailyMealPlanComplete,
    DailyMealPlanHeaderActive,
    MealPlanTitle
} from './styled';
import { getMealInfo, swapRecipe, getMealPlan } from "api"
import { useDispatch } from "react-redux";
import SuggesticLink from "components/SuggesticLink";

const MealPlanComponent = () => {
    const [mealPlan, setMealPlan] = useState([])
    const [loading, changeLoading] = useState(true)
    const [weekNumber, setWeekNumber] = useState(1);
    const dispatch = useDispatch()

    const updateWeekNumber = week => setWeekNumber(week);

    const compareDate = (mealDateString) => {
        const mealDate = new Date(mealDateString);
        const date = new Date();
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        const currentDate = new Date(date.getFullYear() + '-' + mm + '-' + dd)
        return currentDate.getTime() === mealDate.getTime()
    }

    useEffect(() => {
        getPlan()
    }, [])

    const swapMeal = (mealId, recipeId) => {
        sendAmplitudeEvent('pb_swap')
        swapRecipe(mealId, recipeId).then(result => {
            if (200 === result.status) {
                getMealPlan().then(result => {
                    if (200 === result.status) {
                        successNotification('Recipe changed successfully')
                        updateMealPlan(result.data.data.mealPlan);
                    }
                })
            }
        });
    }

    const updateMealPlan = (mealPlan) => {
        setMealPlan(mealPlan)
    }

    const getServingsWeight = (meal) => {
        const weight = meal.numOfServings * meal.recipe.servingWeight;

        return weight.toFixed() + ' g'
    }

    const getPlan = () => {
        getMealPlan().then(result => {
            if (200 === result.status) {
                updateMealPlan(result.data.data.mealPlan);
                getMealInfo().then(res => {
                    updateWeekNumber(res.data.data.weekNumber)
                    dispatch(setMealProgress(res.data.data))
                })

                changeLoading(false)
            }

        });
    }

    const getPlanDay = () => {
        var dateObj = new Date();
        var day = dateObj.getUTCDate();

        var values = {};
        const res = mealPlan.forEach(function(item, index){
            if (day === new Date(Date.parse(item.date)).getUTCDate()) {
                values.day = index + 1
            }
        });

        if (values.day === undefined) {
            return 1;
        }

        return values.day;
    }

    const isDisable = (mealDateString) => {
        const mealDate = new Date(mealDateString);
        const date = new Date();
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        const currentDate = new Date(date.getFullYear() + '-' + mm + '-' + dd)
        return currentDate.getTime() > mealDate.getTime()
    }

    const getTimeInMinutes = (timeInSeconds) => {
        if (timeInSeconds % 60 !== 0) {
            return +(timeInSeconds / 60).toFixed() + 1
        }

        return timeInSeconds / 60
    }

    const getDayNumber = (day) => {
        if (1 === weekNumber) {
            return day
        }

        return (weekNumber - 1) * 7 + day
    }

    return (
        <Layout>
        <Sidebar />
            <MealPlanContent>
                <MealPlanHeader>Meal Plan on Month</MealPlanHeader>
                <MealPlanTitle>Month</MealPlanTitle>
                <MealProgress day={getPlanDay()} />
                {loading ? <Loader /> : <MealPlanBlocksWrapper>
                    { mealPlan.map((item, itemCount) =>
                        <DailyMealPlanBlockWrapper key={itemCount}>
                            <DailyMealPlanHeaderWrapper>
                                <DailyMealPlanHeader>Day {getDayNumber(item.day)}</DailyMealPlanHeader>
                                {compareDate(item.date) ? <><TodayLabelWrapper>Today</TodayLabelWrapper></> : <></>}
                            </DailyMealPlanHeaderWrapper>
                            {isDisable(item.date)
                                ?
                                <DailyMealPlanWrapperDisable>
                                    <DailyMealPlanDisable>
                                        {item.meals.map((meal, mealCount) =>
                                            <DailyMealPlanItem key={mealCount}>
                                                <DailyMealPlan>
                                                    <DailyPlanImage src={meal.recipe.mainImage} />
                                                    <DailyPlanContent>
                                                        <DailyPlanPeriod>{meal.meal}</DailyPlanPeriod>
                                                        <DailyPlanTitle>{meal.recipe.name}</DailyPlanTitle>
                                                        <DailyPlanDescription>{meal.numOfServings} Servings ({getServingsWeight(meal)}) | {meal.recipe.totalTime}</DailyPlanDescription>
                                                    </DailyPlanContent>
                                                </DailyMealPlan>
                                                <DailyMealPlanSwap disable={true}>
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                                    </svg>
                                                </DailyMealPlanSwap>
                                            </DailyMealPlanItem>
                                        )}
                                    </DailyMealPlanDisable>
                                    <DailyMealPlanComplete src={`/images/mealPlan/complete-day.svg`} />
                                </DailyMealPlanWrapperDisable>
                                :
                                <DailyMealPlanBlock>
                                    {item.meals.map((meal, mealCount) =>
                                        <DailyMealPlanItem key={mealCount}>
                                            <DailyMealPlan>
                                                <DailyMealImageLink to={`/meal-plan/recipe/${meal.recipe.id}`}>
                                                    <DailyPlanImage src={meal.recipe.mainImage} />
                                                </DailyMealImageLink>
                                                <DailyPlanContent>
                                                    <DailyPlanPeriod>{meal.meal}</DailyPlanPeriod>
                                                    <DailyPlanTitleLink to={`/meal-plan/recipe/${meal.recipe.id}`}>{meal.recipe.name}</DailyPlanTitleLink>
                                                    <DailyPlanDescription>{meal.numOfServings} Servings ({getServingsWeight(meal)}) | {getTimeInMinutes(meal.recipe.totalTimeInSeconds)} min</DailyPlanDescription>
                                                </DailyPlanContent>
                                            </DailyMealPlan>
                                            <DailyMealPlanSwap onClick={() => swapMeal(meal.id, meal.recipe.id)}>
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                                    <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                                    <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                                    <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                                    <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                                </svg>
                                            </DailyMealPlanSwap>
                                        </DailyMealPlanItem>
                                    )}
                                </DailyMealPlanBlock>
                            }
                        </DailyMealPlanBlockWrapper>
                    )}
                </MealPlanBlocksWrapper>}
                <SuggesticLink style={{ justifyContent: 'center' }}/>
            </MealPlanContent>
        </Layout>
    )
}

export default MealPlanComponent