import {
  ChooseYourPlanBackground,
  ChooseYourPlanContent,
  ChooseYourPlanDefaultPrice,
  ChooseYourPlanDescription,
  ChooseYourPlanDescriptionSeparator,
  ChooseYourPlanDiscount,
  ChooseYourPlanDiscountPrice,
  ChooseYourPlanDiscountPricesBlock,
  ChooseYourPlanDiscountPricingBlock,
  ChooseYourPlanImportantText,
  ChooseYourPlanImportantTitle,
  ChooseYourPlanImportantWrapper,
  ChooseYourPlanItemTextWrapper,
  ChooseYourPlanItemTitle,
  ChooseYourPlanItemWrapper,
  ChooseYourPlanLabel,
  ChooseYourPlanPriceDescriptionBlock,
  ChooseYourPlanUpperName
} from '../../styled';
import { Field } from 'redux-form';
import React from 'react';

export const ChooseYourPlanItem =
  ({
     title,
     discountPrice,
     defaultPrice,
     discountPriceDuration,
     defaultPriceDuration,
     discountPercent,
     isDiscountTime,
     handleSelect,
     id,
     isShowPopup
  }) => {
  const isFirst = '0' === id;
  const priceDuration = isDiscountTime ? discountPriceDuration : defaultPriceDuration;
  const isEmpty = !discountPrice || !defaultPrice;

  return (
    <>
      <ChooseYourPlanItemWrapper isFirst={isFirst}>
        {isFirst && <ChooseYourPlanUpperName>Best Value</ChooseYourPlanUpperName>}
        <ChooseYourPlanLabel onClick={handleSelect}>
          <ChooseYourPlanContent>
            <ChooseYourPlanItemTextWrapper>
              {title && <ChooseYourPlanItemTitle>
                {title}
              </ChooseYourPlanItemTitle>}
              <ChooseYourPlanDiscountPricingBlock>
                <ChooseYourPlanDiscountPricesBlock>
                  <ChooseYourPlanDefaultPrice isDiscount={isDiscountTime} style={{textDecoration: isEmpty && 'none', color: !isDiscountTime && '#4c4c4c'}}>{defaultPrice && `$${defaultPrice ?? discountPrice}`}</ChooseYourPlanDefaultPrice>
                  {isDiscountTime && <ChooseYourPlanDiscountPrice>{discountPrice && `$${discountPrice}`}</ChooseYourPlanDiscountPrice>}
                </ChooseYourPlanDiscountPricesBlock>
                <ChooseYourPlanPriceDescriptionBlock>
                  {priceDuration && <ChooseYourPlanDescriptionSeparator>/</ChooseYourPlanDescriptionSeparator>}
                  <ChooseYourPlanDescription dangerouslySetInnerHTML={{__html: priceDuration}}/>
                </ChooseYourPlanPriceDescriptionBlock>
              </ChooseYourPlanDiscountPricingBlock>
            </ChooseYourPlanItemTextWrapper>
          </ChooseYourPlanContent>
          <Field name='chooseYourPlan' type='radio' value={id} component='input'/>
          <div>
            {discountPercent && <ChooseYourPlanDiscount>
              {discountPercent}
            </ChooseYourPlanDiscount>}
            <ChooseYourPlanBackground/>
          </div>
        </ChooseYourPlanLabel>
      </ChooseYourPlanItemWrapper>
      {isFirst && isShowPopup && <ChooseYourPlanImportantWrapper>
        <ChooseYourPlanImportantTitle>Important!</ChooseYourPlanImportantTitle>
        <ChooseYourPlanImportantText>You can save the most with the {title}</ChooseYourPlanImportantText>
      </ChooseYourPlanImportantWrapper>}
    </>
  );
};