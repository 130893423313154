import styled from 'styled-components';
import { THEME } from 'THEME';

export const AppStoreWrapper = styled.div`
  background-image: url('/images/landing/background-blue.svg');
  padding: 120px 0;
  background-size: cover;
  @media(max-width: 960px) {
    padding: 80px 0;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 16px;
  @media(max-width: 960px) {
    max-width: 468px;
  }
`

export const AppStoreContent = styled.div`
  display: grid;
  grid-template-columns: 6fr 5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 100px;
  grid-row-gap: 78px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;

  @media(max-width: 960px) {
    grid-template-columns: 1fr;
  }
  
  button {
    max-width: 256px;
  }
`

export const AppStoreContentInner = styled.div`
  max-width: 466px;
`

export const AppStoreContentHeadline = styled.h2`
  font-weight: 500;
  color: white;
  margin-bottom: 12px;
  font-size: 42px;
  line-height: 52px;

  @media(max-width: 960px) {
    font-size: 36px;
    line-height: 39px;
    margin-bottom: 32px;
  }
`

export const AppStoreContentText = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: white;
  margin-bottom: 120px;

  @media(max-width: 960px) {
    margin-bottom: 48px;
  }
`

export const AppStoreContentImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  max-width: 562px;
`