import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { Sleep } from './styled';
import StandardQuizComponent from "components/Quiz/StandardQuiz";
import { getNextProgressStep } from 'utils';

const SleepComponent = ({ dispatch }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const itemsSleep = t(`sleep.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const sleep = data.target.title;
    sendAmplitudeEvent('22_click_sleep', {
      'Sleep': sleep
    });
    history.push('/drink-water');
  };

  useEffect(() => {
    sendAmplitudeEvent('22_view_sleep');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <Sleep>
      <StandardQuizComponent
        items={itemsSleep}
        handleSelect={handleSelect}
        title={t('sleep.title')}
        name={'sleep'}
      />
    </Sleep>
  );
};

export default reduxForm(
  {
    form: 'sleep',
    destroyOnUnmount: false
  }
)(SleepComponent);
