import React from 'react';
import {
  DiscountGiftImg,
  DiscountIcon,
  DiscountIconDescription,
  DiscountIconWrapper,
  DefaultPrice,
  DiscountPricesBlock,
  DiscountPriceText,
  DiscountPricingBlock,
  DiscountTextWrapper,
  DiscountTrialHeader,
  DiscountTrialPricingWrapper,
  DiscountTrialWrapper,
  DiscountPrice,
  PriceDescriptionBlock,
  DescriptionSeparator,
  Description
} from './styled';
import { useSelector } from 'react-redux';
import { isNullish } from 'utils';
import { useIsAppVersion } from 'customHooks';

export const DiscountTrialBlock = () => {
  const isV4 = useIsAppVersion('v4');
  const { progress, remoteConfig, paymentPlan } = useSelector((state) => state);
  const { timer } = progress;
  const isDiscountTime = timer !== 'end';
  const { chooseYourPlan } = paymentPlan || {};
  const { checkoutPage } = remoteConfig || {};
  const { priceBlock } = checkoutPage || {};
  const {
    discountPriceCost,
    defaultPriceCost,
    upperName,
    priceText,
    discountPercent,
    defaultPriceDuration,
    discountPriceDuration
  } = priceBlock || {};
  const {
    title: chooseYourPlanPriceText,
    discountPrice: chooseYourPlanDiscountPrice,
    defaultPrice: chooseYourPlanDefaultPrice,
    discountPercent: chooseYourPlanDiscountPercent,
    defaultPriceDuration: chooseYourPlanDefaultDuration,
    discountPriceDuration: chooseYourPlanDiscountDuration
  } = chooseYourPlan || {};
  const isPriceBlock = !isNullish(priceBlock);
  const isChooseYourPlan = !isNullish(chooseYourPlan) && isV4;

  const getPriceDuration = () => {
    if (isChooseYourPlan) {
      return isDiscountTime ? chooseYourPlanDiscountDuration : chooseYourPlanDefaultDuration;
    }
    return isDiscountTime ? discountPriceDuration : defaultPriceDuration;
  };

  const getIsEmpty = () => {
    if (isChooseYourPlan) {
      return !chooseYourPlanDiscountPrice || !chooseYourPlanDefaultPrice;
    }
    return !discountPriceCost || !discountPriceCost
  };

  const priceDuration = getPriceDuration();
  const percent = isChooseYourPlan ? (chooseYourPlanDiscountPercent ? chooseYourPlanDiscountPercent.match(/\d{1,3}%/g) : '') : discountPercent;
  const subscriptionPriceText = isChooseYourPlan ? chooseYourPlanPriceText : priceText;
  const generalDefaultPriceCost = isChooseYourPlan ? chooseYourPlanDefaultPrice : defaultPriceCost;
  const generalDiscountPriceCost = isChooseYourPlan ? chooseYourPlanDiscountPrice : discountPriceCost;
  const isEmpty = getIsEmpty();

  return (
    <>
      {isPriceBlock && <DiscountTrialWrapper>
        <DiscountTrialHeader>{upperName}</DiscountTrialHeader>
        <DiscountTrialPricingWrapper>
          <DiscountTextWrapper>
            {isDiscountTime && (
              <DiscountGiftImg
                src='/images/payment/gift.png'
              />
            )}
            <DiscountPriceText>{subscriptionPriceText}</DiscountPriceText>
            <DiscountPricingBlock>
              <DiscountPricesBlock>
                <DefaultPrice isDiscount={isDiscountTime} style={{textDecoration: isEmpty && 'none'}}>{generalDefaultPriceCost && `$${generalDefaultPriceCost ?? generalDiscountPriceCost}`}</DefaultPrice>
                {isDiscountTime && <DiscountPrice>{generalDiscountPriceCost && `$${generalDiscountPriceCost}`}</DiscountPrice>}
              </DiscountPricesBlock>
              <PriceDescriptionBlock>
                {priceDuration && <DescriptionSeparator>/</DescriptionSeparator>}
                <Description dangerouslySetInnerHTML={{__html: priceDuration}}/>
              </PriceDescriptionBlock>
            </DiscountPricingBlock>
          </DiscountTextWrapper>
          {percent && <DiscountIconWrapper>
            <DiscountIconDescription>-{percent}</DiscountIconDescription>
            <DiscountIcon src='/images/payment/blueStar.svg'/>
          </DiscountIconWrapper>}
        </DiscountTrialPricingWrapper>
      </DiscountTrialWrapper>}
    </>
  );
};