import styled from "styled-components";
import { THEME } from "THEME";

export const OftenAsk = styled.div`
  width: 100%;
  color: ${THEME.COLOR_BLACK};
  @media (min-width: 960px) {

  }
`;

export const OftenAskWrapper = styled.div`
  width: 100%;
  max-width: 1002px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
`;

export const OftenAskTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: 960px) {
    font-size: 42px;
    line-height: 48px;
    margin-bottom: 40px;
  }
`;

export const OftenAskItems = styled.div`

  @media (min-width: 960px) {

  }
`;

export const OftenAskItem = styled.div`
  background: #F4F1E9;
  border-radius: 12px;
  padding: 20px 16px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 960px) {
    padding: 40px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }

  }
`;

export const OftenAskItemTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1px;

  @media (min-width: 960px) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 10px;
  }
`;

export const OftenAskItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3F5366;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
