import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';

import { changeProgressStep } from 'store/actions';
import { TypicalLunch } from './styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import {
  replaceString,
  getAge
} from 'utils';
import { useSelector } from 'react-redux';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';

const TypicalLunchComponent = ({ history, dispatch }) => {
  const { t } = useTranslation();
  const { age, gender } = useSelector((state) => state.profile);
  const sex = gender ? 'male' ===  gender ? 'male' : 'female' : null;
  const items = t(`typicalLunch.items`, {returnObjects: true});

  const handleSelect = (data) => {
    const typical = data.target.title;
    sendAmplitudeEvent('13_click_typical_lunch', {
      'typical_lunch': typical
    });
    history.push('/metabolism');
  };

  useEffect(() => {
    sendAmplitudeEvent('13_view_typical_lunch');
    dispatch(changeProgressStep(9));
  }, []);

  const getSubTitle = () => {
    return replaceString(t(`typicalLunch.subtitle.${sex}`), [{
      what: '{years}',
      forWhat: getAge(age)
    }]);
  };

  return (
    <TypicalLunch>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('typicalLunch.title')}
        subtitle={getSubTitle()}
        name={'typical_lunch'}
      />
    </TypicalLunch>

  );
};

export default reduxForm(
  {
    form: 'typicalLunch',
    destroyOnUnmount: false
  }
)(TypicalLunchComponent);
