import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setVisitCookie, checkVisitCookie } from 'helper/cookie';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeDataProgress } from 'store/actions';
import ExplanationWork from 'components/Landing/ExplanationWork';
import ImprovesHealth from 'components/Landing/ImprovesHealth';
import Reviews from 'components/Reviews';
import config from 'config';
import { gaFirstVisit, gaFirstClick } from 'analytics/ga';
import {
  StartingFooter,
  AllRightsReserved,
  SupportEmail,
  StartingFooterContainer,
  StartingWrapper
} from './styled';
import { sendFacebookEvent } from 'analytics/facebookPixel';

export const Starting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { urlLink } = useSelector((state) => state.profile);
  const goToQuiz = (event) => {
    sendAmplitudeEvent(event);
    gaFirstClick();
    history.push(`gender${urlLink}`);
  };

  const cookieName = 'mpofv';

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);
  
  useEffect(() => {
    sendFacebookEvent('Lead');
    sendAmplitudeEvent('view_landing');
    if (undefined === checkVisitCookie(cookieName)) {
      gaFirstVisit();
      setVisitCookie(cookieName);
    } else {
      sendAmplitudeEvent('site_open');
    }
  }, []);

  const handleClick = () => {
    sendAmplitudeEvent('lp_support_email_click');
  };

  const currentYear = new Date(Date.now()).getFullYear();
  return <StartingWrapper>
    <ImprovesHealth handleClick={goToQuiz}/>
    <ExplanationWork handleClick={goToQuiz} />
    <Reviews />
    <StartingFooter>
      <StartingFooterContainer>
        <AllRightsReserved>
          ©{currentYear} Your Meal Plan. All rights reserved.
        </AllRightsReserved>
        <SupportEmail onClick={handleClick} href={`mailto:${config.supportEmail}`}>{config.supportEmail}</SupportEmail>
      </StartingFooterContainer>
    </StartingFooter>
  </StartingWrapper>
};