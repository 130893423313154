import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import {
  BodyType,
  BodyTypeWrapper,
  BodyTypeTitle,
  BodyTypePreTitle,
  BodyTypeItems,
} from './styled';
import TileType from 'components/TileType';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils';

const BodyTypeComponent = ({ dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { gender } = useSelector((state) => state.form);
  const genderQuiz = gender.values.sex;
  const sex = genderQuiz ?  'male' === genderQuiz ? 'male' : 'female' : null;
  const isV4 = useIsAppVersion('v4');

  const itemsBodyType = t(`bodyType.${sex}`, {
    returnObjects: true,
  });

  const handleSelect = (data) => {
    const bodyType = data.target.value;
    sendAmplitudeEvent('7_click_body', {
      'Body.Type': bodyType
    });
    const link = isV4 ? 'height' : 'going-day';
    history.push(link);
  };

  useEffect(() => {
    sendAmplitudeEvent('7_view_body');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    sex && (
      <BodyType>
        <BodyTypeWrapper>
          <BodyTypeTitle>{t('bodyType.title')}</BodyTypeTitle>
          <BodyTypeItems>
            {itemsBodyType.map((item, count) => (
              <TileType
                title={item.title}
                name='bodyType'
                value={item.name}
                description={item.description}
                imageName={`${item.name}.svg`}
                handleSelect={handleSelect}
                key={count}
                isV4={isV4}
              />
            ))}
          </BodyTypeItems>
        </BodyTypeWrapper>
      </BodyType>
    )
  );
};

export default reduxForm({
  form: 'bodyType',
  destroyOnUnmount: false,
})(BodyTypeComponent);
