import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep, setProfileData } from 'store/actions';
import { DrinkWater } from './styled';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { getNextProgressStep } from 'utils';

const DrinkWaterComponent = ({ dispatch }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const itemsDrinkWater = t(`drinkWater.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const drinkWater = data.target.title;
    dispatch(setProfileData({ drinkWater }));
    sendAmplitudeEvent('23_click_water', {
      'Water': drinkWater
    });
    history.push('/forecast', { id: 1, flowId: 24 });
  };

  useEffect(() => {
    sendAmplitudeEvent('23_view_water');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <DrinkWater>
      <StandardQuizComponent
        items={itemsDrinkWater}
        handleSelect={handleSelect}
        title={t('drinkWater.title')}
        name={'drinkWaterType'}
      />
    </DrinkWater>
  );
};

export default reduxForm(
  {
    form: 'drinkWater',
    destroyOnUnmount: false
  }
)(DrinkWaterComponent);
