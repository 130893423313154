import styled from "styled-components";

export const PaidTrialWrapper = styled.div`
  width: 100%;
  max-width: 602px;
`;

export const PaidTrialTitle = styled.h2`
  font-weight: normal;
  font-size:  18px;
  line-height: 150%;
  color: #191B1F;

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;

export const PaidTrialSubTitle = styled.h2`
  margin: 14px 0;
  font-weight: normal;
  font-size:  18px;
  line-height: 150%;
  color: #191B1F;

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;

export const PaidTrialSubTitle2 = styled.h2`
  max-width: 628px;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #191B1F;
  margin-top: 48px;
  margin-bottom: 24px;

  @media (max-width: 960px) {
    font-size: 14px;
    line-height: 20px;
    max-width: 287px;
    margin-top: 36px;
    margin-bottom: 20px;
  }
`;

export const PaidTrialWarning = styled.h2`
  font-weight: normal;
  font-size:  18px;
  line-height: 150%;
  color: #191B1F;
    
  span {
    color: #537EE7;
  }

  @media (max-width: 960px) {
    font-size: 14px;
  }
`;

export const PaidTrialItems = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
  @media (min-width: 960px) {
    padding-top: 70px;
    padding-bottom: 40px;
  }
`;

export const PaidTrialItem = styled.div`
  & label input:checked + span {
    border: 2px solid transparent !important;
    outline: 1px solid #92C73C;
  }
  
  &:last-child span:before{
      border: 1px solid #92C73C !important;
      border-radius: 16px !important;
      padding: 8px 13px;
      position: absolute;
      content:"Popular";
      display: block !important;
      color: #92C73C !important;
      font-size: 15px!important;
      background-color: transparent !important;
      font-weight: 700;
      line-height: 100%;
      width: inherit !important;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      top: -70%;
      height: 31px !important;
      left: 0;
      margin: 0 !important;
      text-align: center;
      
      @media(max-width: 961px) {
        top: -90%;
      }

      @media (max-width: 460px) {
        top: -70%;
        height: 24px !important;
        font-size: 10px!important;
      }
      @media (max-width: 390px) {
        top: -70%;
        height: 24px !important;
        font-size: 8.5px!important;
      }
  }
  
  & label span {
    border-radius: 4px;
  }
  
  
  & label{
    padding: 0;
    justify-content: center;
  }
  & label, & label input, &{
    height:64px;
    width:100%;
    margin-right: 10px;
  }
  margin-bottom: 12px;
  @media (max-width: 960px) {
    & label, & label input, &{
      height: 48px;
      margin-right:2px
    }
    margin-bottom: 16px;
  }
`;

export const ErrorForm = styled.div`
  margin-top: 20px;
`;

export const PaidTrialWrapButton = styled.div`
  z-index: 2;
  bottom: 0;
  left: 0;
  background-color: #fff;
  @media (min-width: 960px) {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
  }
`;

export const PaidTrial = styled.form`
  width: 100%;
  
  ${PaidTrialWrapper} {
    padding: ${props => props?.isV4 ? '30px 0' : '30px 16px'};
    margin: ${props => props?.isV4 ? '0' : 'auto'};
    @media (min-width: 960px) {
      padding-top: ${props => props?.benefits ? '120px' : '40px'};
      padding-bottom: 40px;
    }
  }
  
  ${PaidTrialWrapButton} {
    position: ${props => props?.isV4 ? 'inherit': 'fixed'};
    box-shadow: ${props => props?.isV4 ? 'unset' : '0px -0.5px 0px rgba(0, 0, 0, 0.25)'};
    width: ${props => props?.isV4 ? '100%' : '100vw'};
    padding: ${props => props?.isV4 ? '12px 0' : '12px 16px'};
    max-width: ${props => props?.isV4 ? '602px' : '570px'};

    @media (min-width: 960px) {
      box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0);
      padding: 16px 0 0 0;
    }
  }
`;
