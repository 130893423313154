import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Wrapper, Image } from "./styled";
import { changeDataProgress } from "store/actions";

const ErrorComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, [])
  return (
    <Wrapper>
      <Image src={`/images/404-not-found.png`}/>
    </Wrapper>
  );
};

export default ErrorComponent;
