import styled from "styled-components";

export const PeriodExpired = styled.div`
    margin: 0 auto;
    text-align: center;
`

export const PeriodExpiredImage = styled.img`

`

export const PeriodExpiredTextBlock = styled.div`
    margin: 20px 0;
`

export const PeriodExpiredHeader = styled.h3`
    font-weight: 700;
    font-size: 30px;
    color: #191B1F;
`

export const PeriodExpiredText = styled.p`
    font-weight: 400;
    font-size: 20px;
    color: #3F5366;
    line-height: 28px;
    margin: 10px 0;
`

export const PeriodExpiredButtonBlock = styled.div`
    width: 70%;
    margin: 0 auto;
`
