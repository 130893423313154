import styled from "styled-components";
import { THEME } from "THEME";

export const GuaranteeBlock = styled.div`
  color: ${THEME.COLOR_BLACK};
  background-color: #EAE4D1;
  margin: 0 -16px;
`;

export const GuaranteeBlockWrapper = styled.div`
  width: 100%;
  max-width: 1002px;
`;

export const GuaranteeBlockContent = styled.div`
  position: relative;
  z-index: 3;
  padding: 70px 16px;
`;

export const GuaranteeBlockTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  text-align: left;
`;

export const GuaranteeBlockText = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3F5366;
  @media(min-width: 490px) {
    text-align: ${props => props.isPaymentPage ? 'left': 'center'};
  }

  p {
    margin-bottom: 20px;
    color: #7C7357;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: ${THEME.COLOR_BLUE};
  }
`;

export const GuaranteeImg = styled.img`
  max-width: 100px;
  display: block;
  margin: 20px auto auto;
`

export const GuaranteeImageBlock = styled.div``;