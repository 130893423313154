import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WelcomeWrapper, WelcomeText } from './styled';
import { useHistory } from 'react-router-dom';
import { ButtonComponent as Button } from 'components/Button';
import { Wrapper, Title, WrapButton } from 'components/Quiz/styled';
import { changeDataProgress } from 'store/actions';
import { useDispatch } from 'react-redux';
import { sendAmplitudeEvent, setUserProperties } from 'analytics/amplitude';
import { useGetGaExperimentId } from 'customHooks';

const Welcome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { title, text, buttonText } = t('welcomeV4', { returnObjects: true });

  const handleClick = () => {
    sendAmplitudeEvent('MealPlan_welcome_click');
    history.push('gender');
  };

  const experimentIdData = useGetGaExperimentId();
  useEffect(() => {
    setUserProperties({ ...experimentIdData });
    sendAmplitudeEvent('MealPlan_welcome_view');
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);

  return (
    <WelcomeWrapper>
      <Wrapper>
        <Title>{title}</Title>
        <WelcomeText dangerouslySetInnerHTML={{ __html: text }}/>
        <WrapButton>
          <Button title={buttonText} onClick={handleClick}/>
        </WrapButton>
      </Wrapper>
    </WelcomeWrapper>
  );
};

export default Welcome;
