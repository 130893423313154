import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { changeProgressStep, setProfileData } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import validate from './validate';
import { YourName } from './styled';
import SingleInputQuiz from 'components/Quiz/SingleInputQuiz';
import { getNextProgressStep } from 'utils/getNextProgressStep';

const YourNameComponent = ({ handleSubmit, dispatch }) => {
  const { yourName } = useSelector((state) => state.form);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const history = useHistory();

  const submitForm = (data) => {
    const name = data.name;
    dispatch(setProfileData({ name }));
    sendAmplitudeEvent('2_click_name', { name });
    history.push('birthday');
  };

  useEffect(() => {
    sendAmplitudeEvent('2_view_name');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  useEffect(() => {
    if (!yourName.fields) {
      return;
    }

    if (!yourName.fields.name) {
      return;
    }

    const errorResult = validate(yourName.values);

    if ('required' === errorResult.name) {
      setError(() => 'Your name is required');
      return;
    }

    if ('error' === errorResult.name) {
      setError(() => 'Your name should contain only latin characters and numbers');
      return;
    }

    if ('length' === errorResult.name) {
      setError(() => 'Your name should be 2 to 12 characters long');
      return;
    }

    return setError('')
  });

  return (
    <YourName onSubmit={handleSubmit(submitForm)}>
      <SingleInputQuiz
        title={t('yourName.title')}
        subtitle={t('yourName.subtitle')}
        error={error}
        type={'text'}
        name={'name'}
        onChange={() => setError('')}
        placeholder={'Your Name'}
      />
    </YourName>
  );
};

export default reduxForm({
  form: 'yourName',
  destroyOnUnmount: false,
  validate,
})(YourNameComponent);
