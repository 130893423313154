import styled from "styled-components";
import { THEME, BACKGROUND_GRADIENT } from "THEME";

export const Button = styled.div`
  width: 100%;
  position: relative;
  border-radius: 90px;
  overflow: hidden;
  opacity: 1;
  ${(props) => ({...props.styleProps})};
`;

export const ButtonDom = styled.button`
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
  border: none;
  background-color: #43BB5E;
  border-radius: 90px;
  transition: ${THEME.TRANSITION};

  &:active {
    background-color: #3ca854;
  }

  &:hover {
    background-color: #3ca854;
  }

  @media (min-width: 960px) {
    min-height: 64px;
  }
`;

export const ButtonText = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const ButtonWrapperLoading = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  
  -webkit-animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;



