import React, { useEffect } from "react";
import { logout } from "../../api";
import { useHistory } from "react-router-dom";
import Sidebar from 'components/Sidebar'
import { changeDataProgress } from "store/actions";

import {
    MealPlanWrapper,
    MealPlanHeader,
    MealPlanBlocksWrapper,
    DailyMealPlanBlockWrapper,
    DailyMealPlanHeader,
    DailyMealPlanBlock,
    DailyMealPlanItem,
    DailyMealPlan,
    DailyMealPlanSwap,
    DailyPlanImage,
    DailyPlanContent,
    DailyPlanPeriod,
    DailyPlanDescription,
    TodayLabelWrapper,
    DailyMealPlanHeaderWrapper,
    MealPlanContent,
} from './../MealPlan/styled';
import { useDispatch } from "react-redux";

const DebugComponent = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const logoutHandle = (e) => {
        e.preventDefault();
        logout().then((res) => {
            if (200 === res.status) {
                history.push('/login')
            }
        })
    }

    useEffect(() => {
        dispatch(changeDataProgress({ visible: false }));
        return () => {
            dispatch(changeDataProgress({ visible: true }));
        }
    }, []);

    return (
        <MealPlanWrapper>
            <Sidebar />
            <MealPlanContent>
                <MealPlanHeader>Meal plan</MealPlanHeader>
                <MealPlanBlocksWrapper>
                    <DailyMealPlanBlockWrapper>
                        <DailyMealPlanHeaderWrapper>
                            <DailyMealPlanHeader>Day 1</DailyMealPlanHeader>
                            <TodayLabelWrapper>Today</TodayLabelWrapper>
                        </DailyMealPlanHeaderWrapper>
                        <DailyMealPlanBlock>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://klike.net/uploads/posts/2019-06/1559545529_1.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Breakfast</DailyPlanPeriod>
                                        <DailyPlanDescription>4 Servings (418g) | 25 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://russiaedu.ru/media/cache/image_md_resize/uploads/upload-images/2019/12/05/b_img5de908746e39d3.43406923.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Lunch</DailyPlanPeriod>
                                        <DailyPlanDescription>1 Servings (357 g) | 35 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://ichef.bbci.co.uk/news/800/cpsprodpb/41F7/production/_105278861_gettyimages-855098134.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Dinner</DailyPlanPeriod>
                                        <DailyPlanDescription>2 Servings (392 g) | 40 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://kubnews.ru/upload/iblock/773/773e2ffb2e546e1d39689bc67dc7912a.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Snack</DailyPlanPeriod>
                                        <DailyPlanDescription>1 Servings (101 g) | 15 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                        </DailyMealPlanBlock>
                    </DailyMealPlanBlockWrapper>
                    <DailyMealPlanBlockWrapper>
                        <DailyMealPlanHeaderWrapper>
                            <DailyMealPlanHeader>Day 2</DailyMealPlanHeader>
                        </DailyMealPlanHeaderWrapper>
                        <DailyMealPlanBlock>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://klike.net/uploads/posts/2019-06/1559545529_1.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Breakfast</DailyPlanPeriod>
                                        <DailyPlanDescription>4 Servings (418g) | 25 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://russiaedu.ru/media/cache/image_md_resize/uploads/upload-images/2019/12/05/b_img5de908746e39d3.43406923.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Lunch</DailyPlanPeriod>
                                        <DailyPlanDescription>1 Servings (357 g) | 35 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://ichef.bbci.co.uk/news/800/cpsprodpb/41F7/production/_105278861_gettyimages-855098134.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Dinner</DailyPlanPeriod>
                                        <DailyPlanDescription>2 Servings (392 g) | 40 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://kubnews.ru/upload/iblock/773/773e2ffb2e546e1d39689bc67dc7912a.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Snack</DailyPlanPeriod>
                                        <DailyPlanDescription>1 Servings (101 g) | 15 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                        </DailyMealPlanBlock>
                    </DailyMealPlanBlockWrapper>
                    <DailyMealPlanBlockWrapper>
                        <DailyMealPlanHeaderWrapper>
                            <DailyMealPlanHeader>Day 3</DailyMealPlanHeader>
                        </DailyMealPlanHeaderWrapper>
                        <DailyMealPlanBlock>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://klike.net/uploads/posts/2019-06/1559545529_1.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Breakfast</DailyPlanPeriod>
                                        <DailyPlanDescription>4 Servings (418g) | 25 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://russiaedu.ru/media/cache/image_md_resize/uploads/upload-images/2019/12/05/b_img5de908746e39d3.43406923.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Lunch</DailyPlanPeriod>
                                        <DailyPlanDescription>1 Servings (357 g) | 35 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://ichef.bbci.co.uk/news/800/cpsprodpb/41F7/production/_105278861_gettyimages-855098134.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Dinner</DailyPlanPeriod>
                                        <DailyPlanDescription>2 Servings (392 g) | 40 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                            <DailyMealPlanItem>
                                <DailyMealPlan>
                                    <DailyPlanImage src={'https://kubnews.ru/upload/iblock/773/773e2ffb2e546e1d39689bc67dc7912a.jpg'} />
                                    <DailyPlanContent>
                                        <DailyPlanPeriod>Snack</DailyPlanPeriod>
                                        <DailyPlanDescription>1 Servings (101 g) | 15 min</DailyPlanDescription>
                                    </DailyPlanContent>
                                </DailyMealPlan>
                                <DailyMealPlanSwap>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#F6F6FC"/>
                                        <path d="M10 13.5H21" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M12.5 11L10 13.5L12.5 16" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M22 19.5H11" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                        <path d="M19.5 17L22 19.5L19.5 22" stroke="#ACB1DA" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </DailyMealPlanSwap>
                            </DailyMealPlanItem>
                        </DailyMealPlanBlock>
                    </DailyMealPlanBlockWrapper>
                </MealPlanBlocksWrapper>
            </MealPlanContent>
        </MealPlanWrapper>
    )
}

export default DebugComponent