import React from 'react';
import { ButtonComponent as Button } from "components/Button";
import {
  WrapButton,
  Title,
  Wrapper,
  Items,
  Item,
  Subtitle,
  ErrorForm
} from 'components/Quiz/styled';
import { useTranslation } from "react-i18next";
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import ErrorFormRequired from "components/ErrorFormRequired";

const EventNameQuizComponent = ({ title, subtitle, name, items, imagePath, handleSelect, error, onChange, placeholder }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Items>
        {items.map((item, count) => <Item key={count}>
          <Checkbox
            title={item.title}
            type="radio"
            name={name}
            value={item.value ?? item.name ?? item.title}
            imageName={imagePath ? `${imagePath}/${item.name}.svg` : null}
            handleSelect={handleSelect}/>
        </Item>)}
        <Input type={'text'} name={'customEvent'} error={error} placeholder={placeholder} onChange={onChange} isIconShow/>
        <ErrorForm>
          <ErrorFormRequired style={{opacity: error ? 1 : 0}}>{error}</ErrorFormRequired>
        </ErrorForm>
      </Items>
      <WrapButton>
        <Button title={t("messages.continue")} type="submit"/>
      </WrapButton>
    </Wrapper>
  )
}

export default EventNameQuizComponent