import styled from "styled-components";
import { THEME } from "THEME";

export const ProblemAreas = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const ProblemAreasContent = styled.div`
  margin-bottom: 30px;

  @media (min-width: 960px) {
    width: 100%;
    flex: 1 1 40%;
    max-width: 400px;
    margin-bottom: 0;
    margin-right: 50px;
  }

`;
export const ProblemAreasTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${THEME.COLOR_BLACK};
  margin-bottom: 30px;

  @media (min-width: 960px) {

  }
`;

export const ProblemAreasChecks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 500px;
  width: 100%;

  @media (min-width: 960px) {

  }
`;

export const ProblemAreasCheck = styled.div`
  display: flex;
  align-self: flex-start;
  flex: 1 1 30%;
  max-width: 140px;
  margin-bottom: 12px;

  @media (min-width: 960px) {

  }
`;

export const ProblemAreasCheckName = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const ProblemAreasCheckIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const ProblemAreasText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_DARK_GRAY};
  padding-top: 18px;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ProblemAreasImageWrap = styled.div`
  width: 100%;
  max-width: 500px;
  margin: auto;
  @media (min-width: 960px) {
    margin: 0;
  }
`;

export const ProblemAreasImage = styled.img`
  width: 100%;
  height: auto;
`;
export const ProblemAreasButton = styled.div`
  margin-top: 25px;
  @media (min-width: 960px) {
    margin: 38px auto 0;
    width: 100%;
    max-width: 370px;
  }
`;
