import React from 'react';
import { Field } from 'redux-form';
import {
  ButtonSlide,
  Label,
  ButtonSlideText,
  ButtonSlideBackground
} from './styled';
import { useIsAppVersion } from 'customHooks';

const defMoveChange = () => () => {};
const ButtonSlideComponent = ({ name, valueFirst, valueSecond, textFirst, textSecond, move, moveReset, moveChange = defMoveChange }) => {
  const isV4 = useIsAppVersion('v4');
  return (
    <ButtonSlide isV4={isV4}>
      <Label>
        <Field
          name={name}
          type='radio'
          value={valueFirst}
          component='input'
          onChange={() => {
            moveChange(valueFirst);
            moveReset();
          }}
        />
        <ButtonSlideText>{textFirst}</ButtonSlideText>
      </Label>
      <Label>
        <Field
          name={name}
          type='radio'
          value={valueSecond}
          component='input'
          onChange={() => {
            moveChange(valueSecond);
            moveReset();
          }}
        />
        <ButtonSlideText>{textSecond}</ButtonSlideText>
      </Label>
      <ButtonSlideBackground move={move}/>
    </ButtonSlide>
  );
};

export default ButtonSlideComponent;
