import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { changeProgressStep, setProfileData } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { EventDate } from './styled';
import DateQuiz from 'components/Quiz/DateQuiz';
import validate from './validate';
import { currentYear, formatDate, replaceString } from 'utils';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils/getNextProgressStep';

const EventDateComponent = ({ handleSubmit, dispatch }) => {
  const { eventDate } = useSelector((state) => state.form);
  const { eventName } = useSelector((state) => state.form);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const isV4 = useIsAppVersion('v4');
  const history = useHistory();

  const submitForm = (data) => {
    const motivationEventDate  = formatDate(data.motivationEventDate);
    dispatch(setProfileData({ motivationEventDate }));
    sendAmplitudeEvent('38_click_event_date', {
      Event_date: motivationEventDate
    });
    const link = isV4 ? '/v4/perfect-weight' : '/v3/journey-feeling';
    history.push(link);
  };

  useEffect(() => {
    sendAmplitudeEvent('38_view_event_date');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  useEffect(() => {
    if (!eventDate.fields) {
      return;
    }

    if (!eventDate.fields.motivationEventDate) {
      return;
    }

    const errorResult = validate(eventDate.values);

    if ('required' === errorResult.motivationEventDate) {
      setError(() => 'Your date is required');
      return;
    }

    if ('error' === errorResult.motivationEventDate) {
      setError(() => 'Your event date should be at least next week');
      return;
    }

    return setError('');
  });

  const getEvent = () => {
    const values = eventName.values;
    if (!values) {
      return 'event';
    }
    const motivationEvent = values.motivationEvent;
    const customEvent = values.customEvent;
    if (!motivationEvent) {
      return customEvent.toLowerCase();
    }
    if ('no_similar' === motivationEvent) {
      return 'event';
    }
    return  motivationEvent.replace('_', ' ');
  };

  const getTitle = () => {
    return replaceString(t(`eventDate.title`), [{
      what: '{event}',
      forWhat: getEvent()
    }]);
  };

  return (
    <EventDate onSubmit={handleSubmit(submitForm)}>
      <DateQuiz
        title={isV4 ? t('eventDate.titleV4') : getTitle()}
        subtitle={!isV4 && t('eventDate.subtitle')}
        type={'text'}
        name={'motivationEventDate'}
        placeholder={'Select a date'}
        inputTitle={'Select a date'}
        minYear={-5}
        maxYear={0}
        error={error}
        defaultYearValue={currentYear()}
        onChange={() => setError('')}
      />
    </EventDate>
  );
};

export default reduxForm({
  form: 'eventDate',
  destroyOnUnmount: false,
  validate
})(EventDateComponent);
