import React, { useEffect, useState } from 'react';
import { reduxForm, reset, clearFields } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { AboutYou } from './styled';
import ChoiceQuizComponent from 'components/Quiz/ChoiceQuiz';
import { getChoiceValues, getNextProgressStep } from 'utils';

const AboutYouComponent = ({ handleSubmit, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formRequired, changeFormRequired] = useState(false);
  const likes = t(`aboutYou.likes`, { returnObjects: true });

  const submitForm = (values) => {
    const array = getChoiceValues(values);
    sendAmplitudeEvent('18_click_bad_habbit', {
      'Bad_habit': array
    });
    const arrValues = Object.values(values);
    for (let i = 0; i < arrValues.length; i++) {
      if (arrValues[i]) {
        changeFormRequired(false);
        return history.push('/snack-trigger');
      }
    }
    changeFormRequired(true);
  };

  const resetSelected = () => {
    dispatch(reset('aboutYou'));
    changeFormRequired(false);
  }

  const handleSelect = () => {
    dispatch(clearFields('aboutYou', false, false, 'reset'));
    changeFormRequired(false);
  };

  useEffect(() => {
    sendAmplitudeEvent('18_view_bad_habbit');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <AboutYou onSubmit={handleSubmit(submitForm)}>
      <ChoiceQuizComponent
        title={t('aboutYou.title')}
        items={likes}
        imagePath={'aboutYou'}
        reset={t('aboutYou.reset', {returnObjects: true})}
        formRequired={formRequired}
        resetSelected={resetSelected}
        handleSelect={handleSelect}
      />
    </AboutYou>
  );
};

export default reduxForm(
  {
    form: 'aboutYou',
    destroyOnUnmount: false
  }
)(AboutYouComponent);
