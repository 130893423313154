import React from 'react';
import { useTranslation } from "react-i18next";
import {
  NutritionTitle,
  NutritionistsWrapper,
  NutritionistAvatar,
  NutritionistBio,
  NutritionistBioName,
  NutritionistBioDescription,
  NutritionistsContainer,
  NutritionWrapper
} from "./styled";

const Nutrition = () => {
  const { t } = useTranslation();
  const nutritionistItems = t(`nutrition.items`, { returnObjects: true });
  return (
      <NutritionWrapper>
        <NutritionTitle>{t("nutrition.title")}</NutritionTitle>

        <NutritionistsContainer>
          {nutritionistItems.map(({ name, description, image, image2x }, index) => {
            return (
              <NutritionistsWrapper key={index}>
                <NutritionistAvatar 
                  src={`/images/nutrition/${image}`}
                  srcSet={`/images/nutrition/${image} 320w, /images/nutrition/${image2x} 480w`}
                />
                <NutritionistBio>
                  <NutritionistBioName>{name}</NutritionistBioName>
                  <NutritionistBioDescription>{description}</NutritionistBioDescription>
                </NutritionistBio>
              </NutritionistsWrapper>
            )
          })}
        </NutritionistsContainer>
      </NutritionWrapper>
  )
}

export default Nutrition;