import styled from "styled-components";

export const EmailResults = styled.form`
  width: 100%;
  max-width: 570px;
`;

export const EmailError = styled.div`
    color: #FA5C78;
    text-align: left;
    margin: 10px 0;
    font-size: 14px;
    line-height: 20px;
`
