import React, { useEffect, useState } from 'react';
import { reduxForm, clearFields } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { JourneyFeeling } from './styled';
import { getChoiceValues, getNextProgressStep, scrollPageToTop } from 'utils';
import ChoiceQuizComponent from 'components/Quiz/ChoiceQuiz';

const JourneyFeelingComponent = ({ handleSubmit, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formRequired, changeFormRequired] = useState(false);
  const items = t(`journeyFeeling.items`, { returnObjects: true });

  const submitForm = (values) => {
    const array = getChoiceValues(values);
    sendAmplitudeEvent('39_click_journey_feeling', { 'journey_feeling': array });
    const arrValues = Object.values(values);
    for (let i = 0; i < arrValues.length; i++) {
      if (arrValues[i]) {
        changeFormRequired(false);
        return history.push('increase-in-me');
      }
    }
    changeFormRequired(true);
  };

  const handleSelect = () => {
    dispatch(clearFields('journeyFeeling', false, false, 'reset'));
    changeFormRequired(false);
  };

  useEffect(() => {
    dispatch(changeProgressStep(getNextProgressStep()));
    scrollPageToTop();
    sendAmplitudeEvent('39_view_journey_feeling');
  }, []);

  return (
    <JourneyFeeling onSubmit={handleSubmit(submitForm)}>
      <ChoiceQuizComponent
        title={t('journeyFeeling.title')}
        subtitle={t('journeyFeeling.subtitle')}
        items={items}
        formRequired={formRequired}
        handleSelect={handleSelect}
      />
    </JourneyFeeling>
  );
};

export default reduxForm(
  {
    form: 'journeyFeeling',
    destroyOnUnmount: false
  }
)(JourneyFeelingComponent);
