import styled from 'styled-components';
import { THEME } from 'THEME';

export const DiscountTrialWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  background-color: ${THEME.COLOR_BROWN};
  margin-top: 24px;
  
  @media(max-width: 960px) { 
    margin-top: 16px;
  }
`

export const DiscountTrialPricingWrapper = styled.div`
  position: relative;
  width: 100%;
  background: #FFFFFF;
  border: 2px solid #F4F1E9;
  border-radius: 9px 9px 12px 12px;
  display: flex;
`

export const DiscountTrialHeader = styled.h1`
  padding: 12px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: ${THEME.COLOR_BLACK_ALT};

  @media(max-width: 960px) {
    padding: 8px 0;
    font-size: 16px;
    line-height: 130%;
  }
`

export const DiscountIconWrapper = styled.div`
  position: absolute;
  right: 40px;
  bottom: 40px;
  width: 125px;
  height: 125px;

  @media(max-width: 960px) {
    right: 16px;
    bottom: 16px;
    width: 56px;
    height: 56px;
  }
`

export const DiscountIcon = styled.img`
  width: 100%;
  height: auto;
`

export const DiscountIconDescription = styled.div`
  position: absolute;
  left:50%; 
  top:50%; 
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #FFFFFF;

  @media(max-width: 960px) {
    font-size: 12px;
    line-height: 110%;
  }
`

export const DiscountTextWrapper = styled.div`
  padding: 24px 0 40px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: end;

  @media(max-width: 960px) {
    padding: 20px 0 20px 20px;
  }
`

export const DiscountGiftImg = styled.img`
  width: 36px;

  @media(max-width: 960px) {
    width: 24px;
  }
`

export const DiscountPriceText = styled.h1`
  max-width: 333px;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: ${THEME.COLOR_BLACK_ALT};
  margin: 16px 0 24px 0;

  @media(max-width: 960px) {
    font-size: 16px;
    line-height: 130%;
  }
`

export const DiscountPricingBlock = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  @media(max-width: 960px) {
    flex-direction: column-reverse;
    align-items: start;
  }
`

export const DiscountPricesBlock = styled.div`
  display: flex;
  align-items: start;
  margin-right: 16px;
`

export const DefaultPrice = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-decoration-line: ${props => props.isDiscount ? 'line-through' : 'unset'};
  color: ${THEME.COLOR_LIGHT_BLACK};
  margin-right: 8px;

  @media(max-width: 960px) {
    font-size: 20px;
    line-height: 120%;
  }
`

export const DiscountPrice = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: ${THEME.COLOR_BLUE};

  @media(max-width: 960px) {
    font-size: 24px;
    line-height: 120%;
  }
`

export const PriceDescriptionBlock = styled.div`
  display: flex;
  align-items: center;
`

export const DescriptionSeparator = styled.span`
  margin-right: 5px;
  @media (max-width: 960px) {
    display: none;
  }
`

export const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4C4C4C;

  @media (max-width: 960px) {
    font-size: 12px;
    line-height: 140%;
  }
`