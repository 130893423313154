import {
  PaymentCompaniesLogo,
  PaymentCompaniesWrapper
} from "./styled";

export const PaymentCompanies = (isActiveAdditionalStyles) => {
  return (
    <PaymentCompaniesWrapper isActiveAdditionalStyles={isActiveAdditionalStyles}>
      <PaymentCompaniesLogo src="/images/payment/visa.svg" alt="visa" isActiveAdditionalStyles={isActiveAdditionalStyles} />
      <PaymentCompaniesLogo src="/images/payment/american-express.svg" alt="american-express" isActiveAdditionalStyles={isActiveAdditionalStyles} />
      <PaymentCompaniesLogo src="/images/payment/mastercard.svg" alt="master-card" isActiveAdditionalStyles={isActiveAdditionalStyles} />
    </PaymentCompaniesWrapper>
  );
};