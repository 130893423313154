import {CHANGE_DATA_DISCOUNT} from "store/actions";

const discountInitialState = {
  visibleDiscountHeader: false,
};

const discountReducer = (state = discountInitialState, action) => {
  switch (action.type) {
    case CHANGE_DATA_DISCOUNT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default discountReducer;
