import styled from "styled-components";
import { THEME } from "THEME";

export const CurrentBMI = styled.div`
  background-color: #F8F6F0;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  max-width: 470px;
  height: 200px;
  margin-bottom: 20px;
  @media (min-width: 991px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;
export const CurrentBMITitle = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 4px;
  color: ${THEME.COLOR_DARK_GRAY};
  @media (min-width: 991px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
export const CurrentBMICount = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_BLACK};
  margin-bottom: 18px;
  @media (min-width: 991px) {
    font-size: 24px;
    line-height: 28px;
  }
`;
export const CurrentBMIValue = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.COLOR_BLACK};
  @media (min-width: 991px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CurrentBMIValueConclusion = styled.span`
  font-weight: 400;
`;

export const CurrentBMIProgress = styled.div`
  margin-top: 25px;
`;

export const CurrentBMIProgressLine = styled.div`
  background: linear-gradient(90deg, #FFE186 0%, #43BB5E 100%);
  border-radius: 90px;
  height: 8px;
  width: 100%;
  position: relative;
  margin-bottom: 18px;
`;

export const CurrentBMIProgressCircle = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  padding: 4px;
  border: 4px solid #ffffff;
  display: block;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
`;

export const CurrentBMIProgressItems = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CurrentBMIProgressItem = styled.p`
  color: ${THEME.COLOR_DARK_GRAY};
  opacity: 0.5;
  font-size: 13px;
  line-height: 18px;
  @media (min-width: 991px) {
    font-size: 14px;
    line-height: 20px;
  }
  width: 100px;
  text-align: center;
`;

