import styled from "styled-components";

export const PopupWrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;  
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 500;
    display: block
`

export const PopupBlock = styled.div`
    position: absolute;
    max-width: 490px;
    width: 100%;
    min-height: 170px;
    left: 50%;
    top: 50%;    
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 20px;
    padding:20px;
`

export const PopupTitle = styled.h3`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 30px 0;
`

export const PopupText = styled.p`
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 10px 0;
`

export const PopupButtonBlock = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 40px 0 0 0;
`

export const ButtonWrapper = styled.div`
    width: 40%;
`

export const ClosePopup = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,.4);
    z-index: 9999;
    display: block;
    width: 50px;
    height: 50px;
    font-size: 50px;
    padding: 10px;
    color: #666;
    
    &:hover {
        cursor: pointer;
        color: #908d8d;
    }
`
