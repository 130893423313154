import React, { useEffect, useState } from 'react';
import { reduxForm, clearFields } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { SeeYourself } from './styled';
import { getChoiceValues, getNextProgressStep, scrollPageToTop } from 'utils';
import ChoiceQuizComponent from 'components/Quiz/ChoiceQuiz';

const SeeYourselfComponent = ({ handleSubmit, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formRequired, changeFormRequired] = useState(false);
  const items = t(`seeYourself.items`, {returnObjects: true});

  const submitForm = (values) => {
    const array = getChoiceValues(values);
    sendAmplitudeEvent('41_click_see_yourself', {'see_yourself': array});
    const arrValues = Object.values(values);
    for (let i = 0; i < arrValues.length; i++) {
      if (arrValues[i]) {
        changeFormRequired(false);
        return history.push('goal-addition');
      }
    }
    changeFormRequired(true);
  };

  const handleSelect = () => {
    dispatch(clearFields('seeYourself', false, false, 'reset'));
    changeFormRequired(false);
  };

  useEffect(() => {
    dispatch(changeProgressStep(getNextProgressStep()));
    scrollPageToTop();
    sendAmplitudeEvent('41_view_see_yourself');
  }, []);

  return (
    <SeeYourself onSubmit={handleSubmit(submitForm)}>
      <ChoiceQuizComponent
        title={t('seeYourself.title')}
        subtitle={t('seeYourself.subtitle')}
        items={items}
        formRequired={formRequired}
        handleSelect={handleSelect}
      />
    </SeeYourself>
  );
};

export default reduxForm(
  {
    form: 'seeYourself',
    destroyOnUnmount: false
  }
)(SeeYourselfComponent);
