import styled from "styled-components";
import { THEME } from "THEME";

export const ResultsChart = styled.div`
  background-color: #F8F6F0;
  border-radius: 20px;
  padding: 16px;
  width: 100%;
  max-width: 470px;
  margin-bottom: 20px;
  max-height: 407px;
  height: 100%;
  @media (min-width: 991px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

export const ResultsChartPreTitle = styled.p`
  color: ${THEME.COLOR_DARK_GRAY};
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 4px;
  @media (min-width: 991px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

export const ResultsChartTitle = styled.p`
  font-family: 'DM Sans',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 18px;
  color: #191B1F;
  @media (min-width: 991px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const ResultsChartTitleWeight = styled.span`
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-right: 5px;
  margin-bottom: 18px;
  @media (min-width: 991px) {
    font-size: 30px;
    line-height: 28px;
  }
`;