import styled from "styled-components";

export const Faq = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  @media (min-width: 960px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  a {
    color: #537EE7;
  }
`;
export const FaqWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
`;

export const FaqItem = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 960px) {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
