import styled from "styled-components";
import { THEME } from "THEME";
import { NavLink } from "react-router-dom";

export const Form = styled.form`

`;

export const Input = styled.input`
    padding: 5px;
    display: flex;
    width: 100%;
    margin: 10px 0 !important;
`

export const LoginFormWrapper = styled.div`
    width: 400px;
    padding: 50px 20px;
    margin: 150px auto;
    /* border: 1px solid; */
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
`

export const LoginError = styled.div`
    color: red;
    height: 20px;
    text-align: center;
    margin:0 0 10px 0;
`

export const Login = styled.form`
    width: 100%;
`

export const LoginInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
  transition: opacity ${THEME.TRANSITION};
  opacity: ${props => props.error ? 1 : 0};
`;

export const LoginInfoText = styled.p`
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: ${THEME.COLOR_RED};
  text-align: center;
`;

export const InputValue = styled.input`
  background-color: ${THEME.COLOR_BROWN};
  border-radius: 12px;
  border: 2px solid rgba(234, 228, 209, 0);
  height: 48px;
  width: 100%;
  padding-left: 20px;
  padding-right: 50px;
  font-size: 16px;
  line-height: 24px;
  color: ${THEME.COLOR_BLACK};
  transition: border ${THEME.TRANSITION}, background-color ${THEME.TRANSITION};

  &:focus {
    border: 2px solid rgba(234, 228, 209, 0);
    background-color: ${THEME.COLOR_BROWN_FOCUSED};
  }

  @media (min-width: 960px) {
    height: 64px;
    &:hover {
      border: 2px solid rgba(234, 228, 209, 1);
    }

    &:focus {
      border: 2px solid rgba(234, 228, 209, 0);
      background-color: ${THEME.COLOR_BROWN_FOCUSED};
    }
  }

  ::-webkit-input-placeholder {
    color: #d8d8db;
  }

  ::-moz-placeholder {
    color: #d8d8db;
  }

  :-moz-placeholder {
    color: #d8d8db;
  }

  :-ms-input-placeholder {
    color: #d8d8db;
  }
`;

export const ForgotLink = styled(NavLink)`
    color: ${THEME.COLOR_BLUE};
    font-size: 14px;
    margin: 0 0 30px 0;
    display: flex;
    justify-content: flex-end;
`;

export const InputWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 37px;
    right: 20px;
    @media(min-width: 960px) {
      top: 49px;
    }
  }
`