import styled from "styled-components";

export const BenefitPlanWrapper = styled.div`
  @media(min-width: 960px) {
    display: flex;
    justify-content: space-between;
  }
`

export const PriceInfoBlock = styled.section`
  background-color: #F4F1E9;
  padding: 24px 12px;
  margin-top: 30px;
  position: relative;
  border-radius: 20px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    padding: 1px;
  }

  @media(min-width: 960px) {
    padding: 36px 30px;
    margin: 55px 0 0;
  }
`

export const PriceInfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  @media(min-width: 960px) {
    flex-direction: row;
  }
  &:nth-child(1) {
    @media(min-width: 960px) {
      margin-bottom: 0;
    }
  }
`

export const PriceInfoBlockDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #E3E3EC;
  margin: 20px 0;
`

export const PriceInfoBlockText = styled.p`
  font-size: 15px;
  line-height: 20px;
  min-width: 115px;
  @media(min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
    margin-right: 0;
    min-width: 285px;
    text-align: left;
  }
`

export const PriceInfoBlockGift = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  @media(min-width: 960px) {
    justify-content: unset;
  }
`

export const PriceInfoBlockGiftOldPrice = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #FF8298;
  text-decoration: line-through;
`

export const PriceInfoBlockGiftDiscount = styled.p`
  font-size: 24px;
  line-height: 31px;
  font-weight: 700;
  margin: 0 5px;
`

export const PriceInfoBlockGiftImg = styled.img`
  max-width: 15px;
`

export const PriceInfoBlockValue = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  @media(min-width: 960px) {
    width: 100%;
    text-align: left;
  }
`

export const PriceInfoBlockDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #3F5366;
  @media(min-width: 960px) {
    text-align: left;
  }
`

export const PriceInfoBlockSubDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
`

export const PriceInfoBlockContent = styled.section`
  &:first-child {
    display: flex;
    align-items: center;
  }
`

export const CheckBoxContentWrapper = styled.div`
  @media(min-width: 960px) {
    display: flex;
    flex-direction: column;
    
  }
`

export const WrapButton = styled.div`
  position: relative;
  bottom: auto;
  left: auto;
  width: 100%;
  margin-top: 30px;
`;