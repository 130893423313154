import React, {useState} from "react";

import {
  Login,
  LoginError,
  ForgotLink,
  InputWrapper
} from "./styled";
import { ButtonComponent as Button } from "components/Button";
import { login } from "api";
import Input from 'components/Auth/Input';
import { useDispatch } from "react-redux";
import { setProfileEmail } from "store/actions";
import { useHistory } from "react-router-dom";
import { sendAmplitudeEvent } from "analytics/amplitude";
import { setVisitCookie, checkVisitCookie } from "helper/cookie";
import { Eye, EyeOff } from "components/Icons";

const LoginFormComponent = () => {
    const [error, setError] = useState('');
    const [isShowPassword, setIsShowPassword] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const hideError = () => {
        setError('');
    }

    const cookieName = 'mpafv';

    const submitForm = (e) => {
        e.preventDefault();
        const email = e.target[0].value;
        const password = e.target[1].value.trim();

        login(email, password).then((res) => {
            if (200 === res.status) {
                if (undefined === checkVisitCookie(cookieName)) {
                    sendAmplitudeEvent('first_login')
                    setVisitCookie(cookieName)
                }
                dispatch(setProfileEmail({email: email}));
                history.push("/meal-plan")

                return;
            }
            setError('wrong login or password')
        })
    };
    return (
        <Login onSubmit={submitForm}>
            <Input type="text" name="email" placeholder="mail@website.com" title="Your email" onChange={hideError}/>
            <InputWrapper>
              <Input type={`${isShowPassword ? 'text' : 'password'}`} name="password" placeholder="Min. 6 character" title="Password" onChange={hideError}/>
              {isShowPassword ? <Eye onClick={() => setIsShowPassword(!isShowPassword)}/> : <EyeOff onClick={() => setIsShowPassword(!isShowPassword)}/>}
            </InputWrapper>
            <LoginError>{error}</LoginError>
            <ForgotLink to="/recovery-password">Forgot password?</ForgotLink>
            <Button title="Login" type="submit"/>
        </Login>
    )
}

export default LoginFormComponent