
export const SET_URL_LINK = 'SET_URL_LINK';
export const SET_PROFILE_EMAIL = "SET_PROFILE_EMAIL";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_PURCHASE_EVENT_SENT = 'SET_PURCHASE_EVENT_SENT';

export const setProfileEmail = (email) => {
    return {
        type: SET_PROFILE_EMAIL,
        payload: email,
    };
};

export const setProfileData = (data) => {
    return {
        type: SET_PROFILE_DATA,
        payload: data,
    };
};

export const setPurchaseEventSent = (data) => {
    return {
        type: SET_PURCHASE_EVENT_SENT,
        payload: data,
    };
};

export const setUrlLinkRemoteConfig = (data) => {
    return {
        type: SET_URL_LINK,
        payload: data,
    };
};