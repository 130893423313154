import styled from "styled-components";

export const TakeQuiz = styled.div`
    background: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    padding: 17px;
`;

export const TakeQuizWrapper = styled.div`
    background: #f1f1fc;
    border-radius: 10px;
    position: relative;
`;

export const MaleBlock = styled.div`
    min-height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    width: 50%;
    position: absolute;
    top: 5%;
    left: -10%;
`;

export const Text = styled.div`
`;

export const Image = styled.div`
`;

export const TakeQuizButton = styled.div`
    width: 40%;
    margin: 0 auto;
    padding: 100px 0;
`;

export const FemaleBlock = styled.div`
    min-height: 64px;
    background: #FFFFFF;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 90px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    width: 50%;
    position: absolute;
    bottom: 5%;
    right: -10%;
`;

export const EmptyBlock = styled.div`
    width: 50%;
`

export const GenderWrapper = styled.div`
    display: flex;
`

export const Mame = styled.div`
    padding: 5px 0 0 0;
    font-size: 20px;
    line-height: 28px;
    color: #191B1F;
`

export const Icon = styled.div`
    width: 36px;
    height: 36px;
`

export const GenderBlock = styled.div`
    width: 50%;
`

