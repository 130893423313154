import ReactGA from 'react-ga';
import config from "config";

ReactGA.initialize(config.gaid);

export const gaFirstVisit = () => {
  if ('production' === process.env.NODE_ENV) {
    ReactGA.event({
      category: 'First_visit',
      action: 'First_visit',
      label: 'First visit',
      nonInteraction: false
    });
  }
}

export const gaFirstClick = () => {
  if ('production' === process.env.NODE_ENV) {
    ReactGA.event({
      category: 'First_click',
      action: 'First_click',
      label: 'First click',
      nonInteraction: false
    });
  }
}

export const gaEmailInsert = () => {
  if ('production' === process.env.NODE_ENV) {
    ReactGA.event({
      category: 'Email_insert',
      action: 'Email_insert',
      label: 'Email insert',
      nonInteraction: false
    });
  }
}

export const gaGetMyPlanClickButton = () => {
  if ('production' === process.env.NODE_ENV) {
    ReactGA.event({
      category: 'Get_my_plan_button_click',
      action: 'Get_my_plan_button_click',
      label: 'Get my plan button click',
      nonInteraction: false
    });
  }
}

export const gaPaymentButtonClick = () => {
  if ('production' === process.env.NODE_ENV) {
    ReactGA.event({
      category: 'Payment_button_click',
      action: 'Payment_button_click',
      label: 'Payment button click',
      nonInteraction: false
    });
  }
}

export const gaPurchaseSuccess = () => {
  if ('production' === process.env.NODE_ENV) {
    ReactGA.event({
      category: 'Purchase_success',
      action: 'Purchase_success',
      label: 'Purchase success',
      nonInteraction: false
    });
  }
}

export const gaPurchDone = () => {
  if ('production' === process.env.NODE_ENV) {
    ReactGA.event({
      category: 'Purch_Done',
      action: 'Purch_Done',
      label: 'Purch Done',
      nonInteraction: false
    });
  }
}

export const gaGetMyPlan = () => {
  if ('production' === process.env.NODE_ENV) {
    ReactGA.event({
      category: "Get_my_plan_button_click",
      action: "payment_form_opened",
    });
  }
}