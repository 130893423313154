import styled from "styled-components";
import { THEME } from "THEME";

export const Form = styled.form`

`;

export const Input = styled.input`
    padding: 5px;
    display: flex;
    width: 100%;
    margin: 10px 0 !important;
`

export const LoginFormWrapper = styled.div`
    width: 400px;
    padding: 50px 20px;
    margin: 150px auto;
    /* border: 1px solid; */
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
`

export const LoginError = styled.div`
    color: red;
    height: 20px;
    text-align: center;
    margin:0 0 10px 0;
`

export const Login = styled.form`
    width: 100%;
`

export const LoginInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
  transition: opacity ${THEME.TRANSITION};
  opacity: ${props => props.error ? 1 : 0};
`;
export const LoginInfoText = styled.p`
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: ${THEME.COLOR_RED};
  text-align: center;
`;

export const LoginWrapper = styled.div`
    display: flex;
`

export const LoginLeftBlock = styled.div`
    width: 100%;
    @media (min-width: 960px) {
        width: 50%;
        font-size: 20px;
        line-height: 28px;
    }
`

export const LoginLeftContent = styled.div`
    margin:0 auto;
    padding: 80px 16px 0;
    max-width: 400px;
    width: 100%;

    @media (min-width: 800px) {
        padding: 80px 0 0;
        font-size: 20px;
        line-height: 28px;
    }
    
    @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 28px;
    }
    
    @media (min-width: 1400px) {
        width: 90%;
        font-size: 20px;
        line-height: 28px;
    }
`

export const LoginLogoBlock = styled.div`
    display: flex;
`

export const LoginLogoImage = styled.img`
`

export const LoginLogoText = styled.p`
    margin: 0 0 0 10px;
    font-weight: bold;
    font-size: 18px;
    line-height: 45px;
`

export const LoginTextBlock = styled.div`
    margin: 60px 0 0 0;
`

export const LoginLeftHeaderText = styled.h3`
    font-size: 30px;
    font-weight: bold;
    margin: 50px 0;
    text-align: center;
    
    @media (min-width: 800px) {
        text-align: left;
    }
`

export const LoginLeftText = styled.p`
    font-size: 18px;
    margin: 15px 0 40px 0;
`

export const LoginFooter = styled.div`
    width: 100%;
    margin-top: 250px;
    position: relative;
    padding: 0 25px;
    bottom: 10px;
    display: -ms-flexbox;
    justify-content: space-between;
    display: block;
    
    @media (min-width: 400px) {
        text-align: center;
        position: fixed;
        margin-top: 0;
    }
    
    @media (min-width: 600px) {
        display: flex;
    }
    
    @media (min-width: 960px) {
        width: 50%;
    }
`

export const LoginFooterCopyright = styled.p`
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   color: #191B1F;
`

export const LoginFooterSupport = styled.p`
    color: ${THEME.COLOR_BLUE};
    font-size: 14px;
`;

export const LoginRightBlock = styled.div`
    display: none;
    height: 100vh;
    background: radial-gradient(93.49% 59.83% at 69.58% 27.78%, #99D3F9 0%, #A394FD 42.19%, #616FF1 100%);
    @media (min-width: 960px) {
        display: block;
        width: 50%;
    }
`

export const LoginRightHeaderText = styled.h3`
    color: #fff;
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    margin: 100px 0 20px 0;
`

export const LoginRightText = styled.p`
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    padding: 0 70px 0 70px;
    line-height: 28px;
`

export const LoginTopCircle = styled.div`
position: absolute;
    width: 660px;
    height: 688px;
    right: -233px;
    top: -258px;
    border-radius: 351px/351px;
    border: 212px solid rgba(255,255,255,0.1);
`

export const LoginCalendar = styled.img`
    width: 60%;
    margin: 14% auto;
    display: block;
`