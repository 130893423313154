import styled from "styled-components";
import { QRCodeCanvas } from 'qrcode.react';

export const PaymentSuccessFulWrapper = styled.section`
  background-color: #F8F6F0;
`;
export const PaymentSuccessFulContainer = styled.div`
  max-width: 602px;
  padding: 30px 16px 60px;
  margin: 0 auto;
  height: 100vh;

  @media(min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
    color: #000000;
    height: 120vh;
  }
`;
export const PaymentSuccessFulTitle = styled.h2`
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
  color: #17181B;
  @media(min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
    color: #000000;
  }
  
  & p {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: -20px;
    @media(min-width: 960px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const PaymentSuccessFulSubTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  color: #444444;
  
  @media(min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
    color: #3D3D3D;
  }
`;

export const PaymentSuccessVideoWrapper = styled.div`
  position: relative;
`

export const PaymentSuccessFulVideo = styled.video`
  width: 100%;
  margin: 32px 0;
  @media(min-width: 960px) {
    margin: 48px 0;
  }
`;

export const PlayButton = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  display: ${props => props.isShow ? "block" : "none"};
`

export const DownloadAppFromAppStore = styled.div`
  background-color: #000;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  margin: 32px 0 20px;
  @media(min-width: 960px) {
    padding: 10px 0;
    margin: 24px 0 48px;
  }
`;
export const DownloadAppFromAppStoreIcon = styled.img`
  width: 34px;
  height: 40px;
`;
export const DownloadAppFromAppStoreContent = styled.div`
  color: #fff;
  margin-left: 10px;
`;
export const TopText = styled.p`
  font-size: 15px;
  line-height: 14px;
`;
export const BottomText = styled.p`
  font-size: 26px;
  line-height: 28px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  @media(min-width: 960px) {
    line-height: 31px;
  }
`;

export const PaymentSuccessfulDescription = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  max-width: 410px;
  margin: auto;
  color: #090909;
  @media(min-width: 960px) {
    font-size: 18px;
    line-height: 25px;
  }
  
  & span {
    color: #43BB5E;
  }
`;

export const PaymentSuccessfulInfoBlock = styled.div`
  background-color: #EEE9DA;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;
export const PaymentSuccessfulInfoBlockIcon = styled.p`
  font-size: 23px;
`;
export const PaymentSuccessfulInfoBlockText = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  margin-left: 12px;
  color: #17181B;
  font-size: 12px;
  line-height: 16px;
  max-width: 371px;
  @media(min-width: 960px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const SupportBlock = styled.p`
  font-size: 13px;
  line-height: 24px;
  color: #787878;
  text-align: center;
  margin-top: 24px;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  @media(min-width: 960px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const BlockOr = styled.p`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 32px 0;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #17181B;
  position: relative;

  @media(min-width: 960px) {
    font-size: 20px;
    line-height: 140%;
    color: #000000;
    margin: 48px 0;
  }
  &:before {
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    left: 35%;
    width: 40px;
    height: 1px;
    background-color: #787878;
  }
  &:after {
    display: block;
    position: absolute;
    content: '';
    top: 50%;
    right: 35%;
    width: 40px;
    height: 1px;
    background-color: #787878;
  }
`;

export const QrCodeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 48px 0;
  
  & canvas {
    border-radius: 12px;
  }
  
  @media(max-width: 960px) {
    margin: 32px 0;
  }
`