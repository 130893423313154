import moment from 'moment';

export const yearDiff = (date) => moment().diff(date, 'years');

export const weekDiff = (date) => Math.abs(Number(moment().diff(date, 'week')));

export const currentMonth = () => moment().format('MMMM');

export const currentYear = () => moment().year();

export const formatDate = (date) => moment(date).format('YYYY-MM-DD');

export const subtractYears = (date) => moment().subtract(date, 'years');