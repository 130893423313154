import styled from "styled-components";

export const PaymentFormWrapper = styled.section`
  position: relative;
`

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media(min-width: 960px) {
    margin-bottom: 40px;
  }
`
export const HeaderName = styled.p`
  font-size: 24px;
  line-height: 31px;
  color: #191B1F;
  font-weight: 700;
  @media(min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
  }
`

export const HeaderQuestion = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #7E80F7;
  min-width: 74px;
  &:hover {
    cursor: pointer;
  }
  @media(min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`

export const PaymentFormOverlay = styled.div`
  position: absolute;
  background-color: #fff;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 5;
  max-width: 630px;
  left: 50%;
  transform: translate(-50%, 0);
`