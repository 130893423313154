import { PAYMENT_METHOD } from "store/actions";

const discountInitialState = {
  paymentMethodState: 'applePay'
};

const paymentMethodState = (state = discountInitialState, action) => {
  switch (action.type) {
    case PAYMENT_METHOD:
      return {
        ...state,
        paymentMethodState: action.payload,
      };
    default:
      return state;
  }
};

export default paymentMethodState;
