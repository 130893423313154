import React, { useEffect, useState } from 'react';
import { clearFields, reduxForm, reset } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep } from 'store/actions';
import { Vegetables } from './styled';
import { getChoiceValues, getNextProgressStep, scrollPageToTop, scrollTo } from 'utils';
import ChoiceQuizComponent from 'components/Quiz/ChoiceQuiz';

const VegetablesComponent = ({ handleSubmit, dispatch }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formRequired, changeFormRequired] = useState(false);
  const itemsVegetables = t(`vegetables.items`, { returnObjects: true });

  const submitForm = (values) => {
    const array = getChoiceValues(values);
    sendAmplitudeEvent('32_click_veg', { Vegetables: array });
    const arrValues = Object.values(values);
    for (let i = 0; i < arrValues.length; i++) {
      if (arrValues[i]) {
        changeFormRequired(false);
        return history.push('/cereals');
      }
    }
    changeFormRequired(true);
    scrollTo('error');
  };

  const handleSelect = () => {
    dispatch(clearFields('vegetables', false, false, 'reset'));
    changeFormRequired(false);
  };

  const resetSelected = () => {
    dispatch(reset('vegetables'));
    changeFormRequired(false);
  };

  useEffect(() => {
    sendAmplitudeEvent('32_view_veg');
    scrollPageToTop();
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <Vegetables onSubmit={handleSubmit(submitForm)}>
      <ChoiceQuizComponent
        title={t('vegetables.title')}
        items={itemsVegetables}
        imagePath={'vegetables'}
        reset={t('vegetables.reset', { returnObjects: true })}
        formRequired={formRequired}
        resetSelected={resetSelected}
        handleSelect={handleSelect}
      />
    </Vegetables>
  );
};

export default reduxForm(
  {
    form: 'vegetables',
    destroyOnUnmount: false
  }
)(VegetablesComponent);
