import styled from 'styled-components';
import { THEME } from 'THEME';

export const ChooseYourPlan = styled.form`
  width: 100%;
  padding: 35px 0 40px 0;
`;

export const ChooseYourPlanWrapper = styled.div`
  width: 100%;
  max-width: 630px;
  margin: auto;

  @media (max-width: 960px) {
    padding: 0 16px;
  }
`;

export const ChooseYourPlanSubtitle = styled.h3`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.COLOR_DARK_GRAY};
  @media (min-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`

export const ChooseYourPlanTitle = styled.h2`
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  color: #191B1F;
  @media (max-width: 960px) {
    font-size: 15px;
  }
`;

export const ChooseYourPlanBackground = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: border ${THEME.TRANSITION}, background-color ${THEME.TRANSITION};
`;

export const ChooseYourPlanContent = styled.div`
  z-index: 2;
  position: relative;
`

export const ChooseYourPlanDiscount = styled.div`
  padding: 6px;
  border-radius: 6px;
  position: absolute;
  top: 15px;
  right: 36px;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  transition: color ${THEME.TRANSITION}, background-color ${THEME.TRANSITION};
  background: #EBEBEB;
  color: #4C4C4C;

  @media(max-width: 960px) {
    top: 12px;
    right: 16px;
    font-size: 13px;
    line-height: 110%;
  }
`

export const ChooseYourPlanUpperName = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #FFF;
  padding: 6px 0;
  text-align: center;
  
  @media(max-width: 960px) {
    font-size: 13px;
    line-height: 110%;
  }
`
export const ChooseYourPlanItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ChooseYourPlanItemTitle = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #090909;

  @media(max-width: 960px) {
    font-size: 18px;
    line-height: 130%;
  }
`

export const ChooseYourPlanLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 15px 24px;
  margin-bottom: 12px;
  cursor: pointer;
  width: 100%;
  background-color: white;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  input {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
    
    &:checked + div ${ChooseYourPlanDiscount} {
      background: #F9E5E4;
      color: #F34E5B;
    }
  }

  @media (min-width: 960px) {
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &:hover div ${ChooseYourPlanDiscount} {
    background: #F9E5E4;
    color: #F34E5B;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ChooseYourPlanImportantWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  position: relative;
  background: #E4ECFB;
  border-radius: 12px;
  padding: 12px 14px;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  transition: all 2s linear;
  
  &:after {
    content: '';
    position: absolute;
    left: 48%;
    top: -15px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #E4ECFB;
    clear: both;
    
    @media(max-width: 960px) {
      top: -10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #E4ECFB;
      left: 50%;
    }
  }
`

export const ChooseYourPlanImportantTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #090909;

  @media (max-width: 960px) {
    font-size: 13px;
    line-height: 110%;
  }
`

export const ChooseYourPlanImportantText = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #537EE7;

  @media (max-width: 960px) {
    font-size: 13px;
    line-height: 110%;
  }
`

export const ChooseYourPlanItems = styled.div`
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 16px;
`;

export const ChooseYourPlanItemWrapper = styled.div`
  width: 100%;
  border-radius: 12px 12px 0 0;
  background-color: #537EE7;

  ${ChooseYourPlanBackground} {
    border-radius: ${props => props?.isFirst ? '0 0 12px 12px' : '12px' };

    border: ${props => props?.isFirst ? '2px solid #537EE7' : '2px solid #E8E8E8'};
    border-top: ${props => props?.isFirst ? '0 !important' : '2px solid #E8E8E8'};
  }
  
  ${ChooseYourPlanLabel} {
    border-radius: ${props => props?.isFirst ? '20px 20px 0 0' : '0' };
    
    input {
      &:checked + div ${ChooseYourPlanBackground} {
        border: ${props => props?.isFirst ? '2px solid #537EE7' : '2px solid #43BB5E'};
      }
    }

    &:hover div ${ChooseYourPlanBackground} {
      border: ${props => props?.isFirst ? '2px solid #537EE7' : '2px solid #43BB5E'};
    }
  }
`

export const ChooseYourPlanDiscountPricingBlock = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

export const ChooseYourPlanDiscountPricesBlock = styled.div`
  display: flex;
  align-items: start;
  margin-right: 8px;

  @media(max-width: 960px) {
    margin-right: 0;
  }
`

export const ChooseYourPlanDefaultPrice = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-decoration-line: ${props => props.isDiscount ? 'line-through' : 'unset'};
  color: #F34E5B;
  margin-right: 8px;

  @media(max-width: 960px) {
    font-size: 13px;
    line-height: 140%;
  }
`

export const ChooseYourPlanDiscountPrice = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #4C4C4C;

  @media(max-width: 960px) {
    font-size: 14px;
    line-height: 21px;
  }
`

export const ChooseYourPlanPriceDescriptionBlock = styled.div`
  display: flex;
  align-items: center;
`

export const ChooseYourPlanDescriptionSeparator = styled.span`
  margin-right: 5px;
`

export const ChooseYourPlanDescription = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4C4C4C;

  @media (max-width: 960px) {
    font-size: 13px;
    line-height: 140%;
  }
`