import React, { useState, useEffect } from 'react';
import MaskInput from 'react-maskinput';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useHistory } from 'react-router-dom';
import { createSubscription, restoreSubscription, attachPaymentCard } from 'api';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { gaPurchaseSuccess } from 'analytics/ga';
import { errorNotification, successNotification } from 'utils'
import { ButtonComponent as Button } from 'components/Button';
import { changeSubscriptionData } from 'store/actions';
import Loader from 'components/Auth/Loader';

import {
  PayCard,
  PayCardWrapper,
  Card,
  CardWrapper,
  CardInputBlock,
  CardInputTitle,
  CardInputNumber,
  CardInputDate,
  WrapperMonth,
  WrapperYear,
  DateLine,
  CardInputBottom,
  WrapperSecure,
  ButtonCard,
  CardInfo,
  CardInfoText,
  LoadingWrapper,
  CardInput,
  CardInputIcon,
  CvvInfo,
  CardInputTitleWrapper,
  CardInputPowered,
  CardInputPoweredText,
  CardInputPoweredCompanyName,
  CardDescription,
  CardSecureContent,
  CardType,
  CardTypeWrapper
} from './styledV3';
import { Lock, Secure } from 'components/Icons';
import { getCardType, replaceString } from 'utils';
import { monthValidate, yearValidate } from './validateV3';

const PayCardComponent = ({ buttonText, handler, isPaymentPage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorPay, setErrorPay] = useState(null);
  const [cardNumber, setCardNumber] = useState('');
  const [cardMonth, setCardMonths] = useState('');
  const [cardMonthError, setCardMonthError] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [cardYearError, setCardYearError] = useState('');
  const [cardCvv, setCardCvv] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [cvvMask, setCvvMask] = useState('000');
  const [cardType, setCardType] = useState('');
  const { timer } = useSelector((state) => state.progress);
  const { prices } = useSelector((state) => state.paymentPlan);
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();

  const resetResetError = () => {
    setErrorPay(false);
  };

  const changeCardNumber = (e) => {
    setCardCvv('');
    let data = e.target.value;
    let newStr = data.replace(/[^0-9]/g, '').substr(0,16).replace(/\d{4}(?=.)/g, '$& ');
    setCardNumber(newStr);
    resetResetError();
  };

  const changeCardYear = (e) => {
    const { value } = e.target;
    const isInvalidYear = yearValidate(value);
    monthValidate(cardMonth, cardYear);
    setCardYearError('');
    if (isInvalidYear) {
      setCardYearError('Year');
      setErrorPay(true);
    }
    setCardYear(value);
    resetResetError();
  };

  const changeCardCvv = (e) => {
    setCardCvv(e.target.value);
    resetResetError();
  };


  const changeCardMonth = (e) => {
    const { value } = e.target;
    const isInvalidMonth = monthValidate(value, cardYear);
    setCardMonthError('');
    if (isInvalidMonth) {
      setErrorPay(true);
    }
    setCardMonths(value);
    resetResetError();
  };

  useEffect(() => {
    setCardType(getCardType(getValidCardNumber(cardNumber)));
    const cardLength = getValidCardNumber(cardNumber).length;
    if (cardLength === 16 || cardLength === 14) {
      setCvvMask('000');
      return;
    }
    setCvvMask('0000');
  }, [cardNumber]);

  useEffect(() => {
    if (monthValidate(cardMonth, cardYear)) {
      setCardMonthError('Month');
      return;
    }
    setCardMonthError('');
  }, [cardMonth, cardYear]);

  const getCompanyLogo = () => {
    let companyLogo = '';

    if ('visa' === cardType) {
      companyLogo = 'visa';
    } else if ('mastercard' === cardType) {
      companyLogo = 'mastercard';
    } else if ('amex' === cardType) {
      companyLogo = 'american-express';
    } else if ('discover' === cardType) {
      companyLogo = 'discover';
    }

    return <CardTypeWrapper><CardType src={`/images/payment/${companyLogo}.svg`}/></CardTypeWrapper>;
  };

  const submitForm = () => {
    const isValidCardMonth = !monthValidate(cardMonth, cardYear);
    const isValidCardYear = !yearValidate(cardYear);
    const isValidCardNumber = validationCardNumber(cardNumber);
    const isValidCardCvv = cardValidationData(cardCvv, cvvMask.length);
    if (
      isValidCardNumber
      && isValidCardMonth
      && isValidCardCvv
      && isValidCardYear
    ) {
      setLoading(true);
      setErrorPay(false);

      const planId = form?.selectedPlanId.values.planId;

      const email = form?.emailResults?.values.email;
      if (window.location.pathname === '/v3/payment') {
        sendAmplitudeEvent('click_payment')
        createSubscription(
          getValidCardNumber(cardNumber),
          cardYear, cardMonth,
          cardCvv,
          email,
          planId
        ).then((res) => {
          if (200 === res.status) {
            sendAmplitudeEvent('purchase');
            gaPurchaseSuccess();
            history.push('/payment-successful', {isPaid: true});
            setLoading(false);
          }

          if (400 === res.status) {
            errorNotification(res.data.message);
            setLoading(false);
          }
        });
      }
      if ('/meal-plan/settings' === window.location.pathname) {
        restoreSubscription(
          getValidCardNumber(cardNumber),
          cardYear, cardMonth,
          cardCvv,
          email,
          planId
        ).then((res) => {
          if (200 === res.status) {
            handler();
            setLoading(false);
          }
          if (400 === res.status) {
            errorNotification(res.data.message);
            setLoading(false);
          }
        });
      }

      if ('/meal-plan' === window.location.pathname) {
        attachPaymentCard(
          getValidCardNumber(cardNumber),
          cardYear, cardMonth,
          cardCvv,
          email,
          planId
        ).then((res) => {
          if (200 === res.status) {
            dispatch(changeSubscriptionData({
              is_active: true,
              type: 'premium'
            }));
            successNotification('Your payment method was successfully updated');
            handler();
            setLoading(false);
          }
          if (400 === res.status) {
            errorNotification('Oops, Something went wrong. Please try again or try using another card');
            handler();
            setLoading(false);
          }
        });
      }
    } else {
      setErrorPay(true);
    }
  }
  const getValidCardNumber = (cardNumber) => {
    return cardNumber.replace(/\s/g, '');
  };

  const getRegexCardNumber = (cardLength) => {
    if (cardLength === 16) {
      return new RegExp('^[0-9]{16}$');
    }

    if (cardLength === 14) {
      return new RegExp('^[0-9]{14}$');
    }

    return new RegExp('^[0-9]{15}$');
  };

  const validationCardNumber = (cardNumber) => {
    if (null === cardNumber) {
      return false;
    }

    const number = cardNumber.replace(/\s/g, '');
    const regex = getRegexCardNumber(number.length);

    return regex.test(number);
  };

  const cardValidationData = (value, length) => {
    if (null === value) {
      return false;
    }

    const pattern = `^[0-9]{${length}}$`;
    const regex = new RegExp(pattern);

    if (!regex.test(value)) {
      return false;
    }

    return true;
  };

  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.toLocaleString('en-US', { month: 'long' });
  const currentDayPlusThreeDays = date.getDate() + 3;


  const currentDate = `${currentMonth} ${currentDayPlusThreeDays}, ${currentYear}`;
  const title = t(`payment.subscriptionExplanation.title`);
  const subtitle = t(`payment.subscriptionExplanation.subtitle`);
  const textFromTranslate = t(`payment.subscriptionExplanation.text`);
  const description = t(`payment.subscriptionExplanation.description`);
  const subDescription = t(`payment.subscriptionExplanation.subDescription`);
  const cvvDescription = t(`payment.cvvDescription`);
  const poweredBy = t(`payment.poweredBy`);

  const getDescription = () => {
    const defaultPrice = prices?.default ? prices.default.price : '';
    const discountPrice = prices?.discount ? prices.discount.price : '';
    const price = (timer === 'end') || !discountPrice ? defaultPrice : discountPrice;
    return replaceString(description, [{
      what: '{price}',
      forWhat: price
    }]);
  };

  const subscriptionExplanationContent = `
     <strong>${title} ${currentDate}. </strong>${subtitle}
     <strong>${currentDate}</strong>, ${textFromTranslate}
     <strong>${getDescription()}</strong> ${subDescription}
  `

  return (
    <PayCard>
      <PayCardWrapper>
        <Card>
          <CardWrapper>
            <CardInputNumber>
              <CardInputBlock>
                <CardInput
                  value={cardNumber}
                  placeholder='1234 1234 1234 1234'
                  onChange={changeCardNumber}
                  id='cc-num'
                />
                <CardInputIcon>
                  {cardType ? (
                    getCompanyLogo()
                  ) : (
                    <Lock/>
                  )}
                </CardInputIcon>
              </CardInputBlock>
            </CardInputNumber>
            <CardInputBottom>
              <CardInputDate>
                <WrapperMonth>
                  <CardInputBlock>
                    <MaskInput
                      maskChar='M'
                      placeholder='MM' mask={'00'}
                      onChange={changeCardMonth}
                      value={cardMonth}
                      id='cc-exp-month'
                      name='cc-exp-month'
                      autoComplete='cc-exp-month'
                      x-autocompletetype='cc-exp-month'
                    />
                  </CardInputBlock>
                </WrapperMonth>
                <DateLine>/</DateLine>
                <WrapperYear>
                  <CardInputBlock>
                    <MaskInput
                      mask={'0000'}
                      placeholder='YEAR'
                      onChange={changeCardYear}
                      value={cardYear}
                      id='cc-exp-year'
                      name='cc-exp-year'
                      autoComplete='cc-exp-year'
                      x-autocompletetype='cc-exp-year'
                    />
                  </CardInputBlock>
                </WrapperYear>
              </CardInputDate>
              <WrapperSecure>
                <CardInputBlock>
                  <MaskInput
                    mask={cvvMask}
                    placeholder='CVV2'
                    type='password'
                    onChange={changeCardCvv}
                    value={cardCvv}
                    id='cvv2'
                    name='cvv2'
                    autoComplete='cc-csc'
                  />
                </CardInputBlock>
              </WrapperSecure>
            </CardInputBottom>
          </CardWrapper>
        </Card>
        {errorPay && (
          <CardInfo error={errorPay}>
            <CardInfoText>
              {t(`payment.error`)}
              {' '}{cardMonthError || cardYearError ? t('payment.errorMonthOrYear') : ''}
              {' '}{cardMonthError} {cardMonthError && cardYearError ? 'and' : ''} {cardYearError}
            </CardInfoText>
          </CardInfo>
        )}
        {isPaymentPage && (
          <>
            <CardDescription dangerouslySetInnerHTML={{ __html: subscriptionExplanationContent }}/>
            <CardSecureContent>
              <Secure/>
            </CardSecureContent>
          </>
        )}
        <ButtonCard style={{ margin: `${isPaymentPage ? 0 : '5px 0'}` }}>
          <Button  type='submit' title={buttonText} onClick={() => submitForm()}/>
        </ButtonCard>
      </PayCardWrapper>
      {isLoading
        ? <LoadingWrapper>
          <Loader/>
        </LoadingWrapper>
        : <></>
      }
    </PayCard>
  )
};


export default reduxForm(
  {
    form: 'pay',
    destroyOnUnmount: false
  }
)(PayCardComponent);
