import {
    SidebarNavigation,
    SidebarNavigationLink,
    SidebarNavigationIcon,
    SidebarWrapper,
} from "./styled";
import React from "react";
import {useLocation} from "react-router-dom";

const SidebarComponent = () => {
    const location = useLocation();

    const currentPage = (path) => {
        if (path === location.pathname) {
            return true;
        }

        return false;
    }

    return (
        <SidebarWrapper>
            <SidebarNavigation>
                    <SidebarNavigationLink to='/meal-plan'>
                        {currentPage('/meal-plan')
                            ? <SidebarNavigationIcon src={`/images/sidebar/calendar-active.svg`} />
                            : <SidebarNavigationIcon src={`/images/sidebar/calendar.svg`} />
                        }
                    </SidebarNavigationLink>
                    {/*<SidebarNavigationLink to="/debug">*/}
                    {/*    {currentPage('/debug')*/}
                    {/*        ? <SidebarNavigationIcon src={`/images/sidebar/recipe-active.svg`} />*/}
                    {/*        : <SidebarNavigationIcon src={`/images/sidebar/recipe.svg`} />*/}
                    {/*    }*/}
                    {/*</SidebarNavigationLink>*/}
            </SidebarNavigation>
        </SidebarWrapper>
    )
}

export default SidebarComponent;
