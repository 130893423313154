import styled from "styled-components";
import { THEME } from "THEME";

export const OverallReview = styled.div`
  width: 100%;
  color: ${THEME.COLOR_BLACK};
  margin-top: 100px;
  @media screen and (min-width: 375px) and (max-width: 960px){
    margin-top: 76px;
  }
`;

export const OverallReviewLine = styled.div`
  margin-bottom: 50px;
  padding: 0 10px;

  &:first-child {
    margin-bottom: 0;
  }

  @media (min-width: 960px) {
    margin-bottom: 90px;
  }
`;
