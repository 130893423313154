import styled from "styled-components";
import { THEME } from "THEME";

export const Reviews = styled.div`
  width: 100%;
  color: ${THEME.COLOR_BLACK};
  margin-top: 64px;
  @media (min-width: 960px) {
    margin-top: 150px;
  }
`;

export const ReviewsWrapper = styled.div`
  width: 100%;
  max-width: 288px;
  margin: auto;

  @media (min-width: 960px) {
    max-width: 960px;
    padding: 0 16px;
  }

  @media (min-width: 1170px) {
    max-width: 1170px;
  }
`;

export const ReviewsTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin: 20px 0;
  text-align: ${props => props?.center ? 'center' : 'start'};
  @media (min-width: 960px) {
    font-size: 42px;
    line-height: 48px;
    margin: 60px 0 60px;
  }
  
  span {
    display: block;
    @media (min-width: 960px) {
      display: inline;
    }
  }
`;

export const ReviewsItems = styled.div`
  @media (min-width: 960px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const ReviewsItem = styled.div`
  padding: 16px;
  background-color: #F4F1E9;
  border-radius: 20px;
  margin-bottom: 16px;
  max-width: 288px;
  @media (min-width: 960px) {
    padding: 24px 30px 30px;
  }
  @media (min-width: 1170px) {
    max-width: 370px;
  }
`;
export const ReviewsItemTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  @media (min-width: 960px) {
    margin-bottom: 20px;
  }
`;
export const ReviewsItemImageWrap = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 12px;
  @media (min-width: 960px) {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
`;
export const ReviewsItemImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ReviewsItemName = styled.p`
  color: ${THEME.COLOR_PURPLE};
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const ReviewsItemText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${THEME.COLOR_DARK_GRAY};
  @media (min-width: 960px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ReviewsButtonWrapper = styled.div`
  max-width: 288px;
  max-height: 48px;
  margin: 25px auto auto;
  @media (min-width: 960px) {
    max-width: 240px;
    max-height: 64px;
    margin: 64px auto auto;
  }
  
  button {
    background: linear-gradient(90deg, #99D3F9 0%, #A394FD 47.36%, #616FF1 92.79%);
  }
`;

export const ReviewsImageWrapper = styled.div`
  margin: 50px auto;
  background: linear-gradient(90deg, rgba(101, 204, 213, .2) 0%, rgba(179, 147, 247, .2) 55.73%, rgba(255, 114, 122, .3) 100%);
  min-height: 510px;
  border-radius: 30px;
  position: relative;
  @media (min-width: 960px) {
    margin: 100px auto;
    min-height: 390px;
    max-height: 390px;
    display: flex;
    align-items: center;
  }
`;

export const ReviewsImage = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  top: -11px;
  left: 10px;
  max-width: 270px;
  @media (min-width: 960px) {
    max-width: 470px;
    top: 16px;
  }
  @media (min-width: 1170px) {
    max-width: 530px;
    min-height: 390px;
    top: 16px;
  }
`

export const ReviewImageTitle = styled.h2`
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #191B1F;
  text-align: center;
  @media (min-width: 1170px) {
    font-size: 42px;
    line-height: 48px;
  }
`

export const ReviewImageDescription = styled.p`
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #191B1F;
  text-align: center;
  padding: 0 11px;
  @media (min-width: 1170px) {
    font-size: 20px;
    line-height: 28px;
  }
`

export const AppStoreLogo = styled.img`
  width: 100%;
  height: auto;
  max-width: 144px;
  cursor: pointer;
  @media(min-width: 1070px) {
    max-width: 192px;
  }
`

export const DisclaimerText = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 48px;
  margin-top: 160px;
  color: #A7A7A7;

  @media(max-width: 768px) {
    margin-bottom: 48px;
    margin-top: 24px;
  }
`

