import React from 'react'
import { useTranslation } from 'react-i18next';

import { ButtonComponent as Button } from 'components/Button';

import {
  ImprovesHealth,
  ImprovesHealthImage,
  ImprovesHealthContent,
  ImprovesHealthTitle,
  ImprovesHealthPreTitle,
  ImprovesHealthButton,
  ImprovesHealthWrapper,
  ImprovesHealthLeftSection,
  ImprovesHealthRightSection,
  ImprovesHealthTitleFirstWorld,
  ImprovesHealthContentBackground
} from './styled'

const ImprovesHealthComponent = ({ handleClick }) => {
  const { t } = useTranslation();


  return <ImprovesHealth>
    <ImprovesHealthContent>
      <ImprovesHealthWrapper>
        <ImprovesHealthLeftSection>
          <ImprovesHealthTitleFirstWorld>
            {t(`landing.improvesHealth.titleFirstWord`)}
          </ImprovesHealthTitleFirstWorld>
          <ImprovesHealthTitle>
            {t(`landing.improvesHealth.title`)}
          </ImprovesHealthTitle>

          <ImprovesHealthPreTitle>
            {t(`landing.improvesHealth.preTitle`)}
          </ImprovesHealthPreTitle>
          <ImprovesHealthButton>
            <Button title={t(`landing.improvesHealth.buttonTitle`)} type='button' onClick={handleClick}/>
          </ImprovesHealthButton>
        </ImprovesHealthLeftSection>

        <ImprovesHealthRightSection>
          <ImprovesHealthImage src={'/images/landing/devices.png'} />
        </ImprovesHealthRightSection>
      </ImprovesHealthWrapper>
      <ImprovesHealthContentBackground />
    </ImprovesHealthContent>
  </ImprovesHealth>
}

export default ImprovesHealthComponent;