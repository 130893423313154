import React from 'react';
import {
  AppStoreContent,
  AppStoreContentHeadline,
  AppStoreContentImage,
  AppStoreContentInner,
  AppStoreContentText,
  AppStoreWrapper,
  Wrapper
} from './styled';
import { Title } from 'components/Landing/styled';
import { useTranslation } from 'react-i18next';
import { ButtonComponent as Button } from 'components/Button';

const AppStore = ({ buttonText, handleClick }) => {
  const { t } = useTranslation();
  const appStore = t('landingV4.appStore', { returnObjects: true });

  return(
    <AppStoreWrapper>
      <Wrapper>
        <Title titleColor={'#3B5EB2'}>{appStore.title}</Title>
        <AppStoreContent>
          <AppStoreContentInner>
            <AppStoreContentHeadline>{appStore.headline}</AppStoreContentHeadline>
            <AppStoreContentText>{appStore.text}</AppStoreContentText>
            <Button title={buttonText} type='button' onClick={handleClick}/>
          </AppStoreContentInner>
          <AppStoreContentImage
            src={`${appStore.image}`}
            srcSet={`${appStore.image}.png 320w, ${appStore.image}@2x.png 480w`}
            loading='lazy'
          />
        </AppStoreContent>
      </Wrapper>
    </AppStoreWrapper>
  );
};

export default AppStore;