import { CHANGE_LANGUAGE, CHANGE_USER_DATA } from 'store/actions';
import config from 'config';

const contentInitialState = {
  language: localStorage.getItem('language') || `${config.defaultLanguage}`,
  isMobile: false,
  hasBeenToPaymentBefore: false
};

const contentReducer = (state = contentInitialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      localStorage.setItem('language', action.payload);
      return {
        ...state,
        language: action.payload,
      };
    case CHANGE_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default contentReducer;
