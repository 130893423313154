import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { changeProgressStep } from 'store/actions';
import { GoalAddition } from './styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { getNextProgressStep } from 'utils';

const GoalAdditionComponent = ({ history, dispatch }) => {
  const { t } = useTranslation();
  const items = t(`goalAddition.items`, {returnObjects: true});

  const handleSelect = (data) => {
    const goalAddition = data.target.title;
    sendAmplitudeEvent('42_click_goal_addition', {
      'goal_addition': goalAddition
    });
    history.push('achievement', { id: 3, flowId: 43 });
  }

  useEffect(() => {
    sendAmplitudeEvent('42_view_goal_addition')
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <GoalAddition>
      <StandardQuizComponent
        items={items}
        handleSelect={handleSelect}
        title={t('goalAddition.title')}
        name={'goal_addition'}
      />
    </GoalAddition>

  )
}

export default reduxForm(
  {
    form: 'goalAddition',
    destroyOnUnmount: false
  }
)(GoalAdditionComponent)
