import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CurrentBMI,
  CurrentBMICount,
  CurrentBMIProgress,
  CurrentBMIProgressCircle,
  CurrentBMIProgressItem,
  CurrentBMIProgressItems,
  CurrentBMIProgressLine,
  CurrentBMITitle,
  CurrentBMIValue,
  CurrentBMIValueConclusion
} from "./styled";

const CurrentBMIComponent = () => {
  const { t } = useTranslation();

  const currentItems = t(`overallResults.profileSummary.items`, { returnObjects: true });
  const { bmi } = useSelector((state) => state.onboardResult);

  return (
    <CurrentBMI>
      <CurrentBMITitle> {t(`overallResults.profileSummary.bmi`)}</CurrentBMITitle>
      <CurrentBMICount>{bmi.current_bmi}</CurrentBMICount>
      <CurrentBMIValue>
        {t(`overallResults.profileSummary.weightIs`)}
        <CurrentBMIValueConclusion>{bmi.weight_state}</CurrentBMIValueConclusion>
      </CurrentBMIValue>
      <CurrentBMIProgress>
        <CurrentBMIProgressLine>
          <CurrentBMIProgressCircle style={{left: `${bmi.percent}%`}}/>
        </CurrentBMIProgressLine>
        <CurrentBMIProgressItems>
          {currentItems.map((elem, index) => <CurrentBMIProgressItem key={index}>{elem.title}</CurrentBMIProgressItem>)}
        </CurrentBMIProgressItems>
      </CurrentBMIProgress>
    </CurrentBMI>
  )
}

export default CurrentBMIComponent;
