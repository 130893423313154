import axios from 'axios'
import Cookies from 'js-cookie';
const rootUrl = process.env.REACT_APP_ROOT_HOST

axios.defaults.withCredentials = true

export const createSubscription = (cardNumber, expYear, expMonth, cvv, email, planId, paidTrialPlanId) => {
  return axios.post(`${rootUrl}/api/v3/payment/subscription/layout/create`, {
    cardNumber: cardNumber,
    expYear: expYear,
    expMonth: expMonth,
    cvv: cvv,
    email: email,
    planId: planId,
    paidTrialPlanId: paidTrialPlanId
  }).then((response) => {
    return response
  }).catch(error => {
    return error.response;
  });
};

export const restoreSubscription = (cardNumber, expYear, expMonth, cvv, email, product) => {
  return axios.post(`${rootUrl}/api/payment/subscription/restore`, {
    cardNumber: cardNumber,
    expYear: expYear,
    expMonth: expMonth,
    cvv: cvv,
    email: email,
    product: product
  }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
};

export const attachPaymentCard = (cardNumber, expYear, expMonth, cvv) => {
  return axios.post(`${rootUrl}/api/payment/card/attach`, {
    cardNumber: cardNumber,
    expYear: expYear,
    expMonth: expMonth,
    cvv: cvv,
  }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
};

export const checkEmail = (email) => {
  return axios.post(`${rootUrl}/api/email/check`, {
    email: email,
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const login = (email, password) => {
  return axios.post(`${rootUrl}/api/auth/login`, {
    _username: email,
    _password: password
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const logout = () => {
  return axios.post(`${rootUrl}/api/auth/logout`)
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const saveSurveyResult = (email, data) => {
  return axios.post(`${rootUrl}/api/survey-result`, {
    email: email,
    data: data
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const createUser = (email) => {
  return axios.post(`${rootUrl}/api/user/create`, {
    email: email
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const changePassword = (oldPassword, newPassword) => {
  return axios.put(`${rootUrl}/api/password/change`, {
    old_password: oldPassword,
    new_password: newPassword,
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const recoveryPassword = (email) => {
  return axios.post(`${rootUrl}/api/password/recovery`, {
    email: email
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const getMealInfo = () => {
  return axios.get(`${rootUrl}/api/meal-plan/info`)
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const paymentUnsubscribe = () => {
  return axios.post(`${rootUrl}/api/payment/unsubscribe`)
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const getSubscribeInfo = () => {
  return axios.get(`${rootUrl}/api/payment/subscription/info`)
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const getPaymentPlan = () => {
  return axios.get(`${rootUrl}/api/payment/plan`)
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const calculateOnboardResult = (
    gender,
    year,
    desiredWeight,
    desiredWeightMeasure,
    currentWeight,
    currentWeightMeasure,
    height,
    heightInch,
    workout
) => {
  return axios.post(`${rootUrl}/api/onboard/result`, {
    gender: gender,
    desired_weight: desiredWeight,
    desired_weight_measure: desiredWeightMeasure,
    year: year,
    current_weight: currentWeight,
    current_weight_measure: currentWeightMeasure,
    height: height,
    height_inch: heightInch,
    workout: workout
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const getMealPlan = () => {
  return axios.get(`${rootUrl}/api/meal-plan`, {
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const getMealPlanRecipe = (recipeId) => {
  return axios.get(`${rootUrl}/api/meal-plan/recipe`, {
    params: {
      recipeId: recipeId
    }
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const swapRecipe = (mealId, recipeId) => {
  return axios.put(`${rootUrl}/api/meal-plan/recipe`, {
    mealId: mealId,
    recipeId: recipeId
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const saveOnboardData = (
  name,
  birthDate,
  height,
  heightInches,
  currentWeight,
  desiredWeight,
  gender,
  motivationEvent,
  motivationEventName,
  motivationEventDate,
  mealsPerDay,
  drinkWater,
  timeForCooking,
  dietaryRestrictions,
  caloriesPerDay,
  diet,
  workout,
  measure,
  authToken,
) => {
  const url = window.location.pathname;
  const YourName = 'YourName';
  const caloriesPerDayDefault = '2000';
  const motivationEventNameDefault = '';
  const motivationEventDefault = 'no_similar';

  return axios.post(`${rootUrl}/api/v3/onboard`, {
    name: '/v4/payment-successful' === url ? YourName : name,
    birthDate: birthDate,
    height: height,
    heightInches: heightInches,
    currentWeight: currentWeight,
    desiredWeight: desiredWeight,
    gender: gender,
    motivationEvent: '/v4/payment-successful' === url ? motivationEventDefault : motivationEvent,
    motivationEventName: '/v4/payment-successful' === url ? motivationEventNameDefault : motivationEventName,
    motivationEventDate: motivationEventDate,
    mealsPerDay: mealsPerDay,
    drinkWater: drinkWater,
    timeForCooking: timeForCooking,
    dietaryRestrictions: dietaryRestrictions,
    caloriesPerDay: '/v4/payment-successful' === url ? caloriesPerDayDefault : caloriesPerDay,
    diet: diet,
    workout: workout,
    measure: measure
  }, {
    headers: {
      'Platform': 'ios',
      'Authorization': authToken
    }
  })
    .then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

export const getPaymentPlansId = (payload) => {
  const PlansId = Object.values(payload).map(item => "&prices[]=" + item).join("").substring(1);
  return axios.get(`${rootUrl}/api/payment/plan/details?${PlansId}`)
    .then((response) => {
      return response.data.data;
    }).catch(error => {
      return error.response;
    });
};

export const getPaymentPlanId = (payload) => {
  return axios.get(`${rootUrl}/api/payment/plan/details?prices[]=${payload}`)
    .then((response) => {
      return response.data.data;
    }).catch(error => {
      return error.response;
    });
};

export const subscribePay = ({ paymentMethodId, planId, email, paidTrialPlanId }) => {
    return axios.post(`${rootUrl}/api/v3/payment/subscription/layout/create-via-wallet`, {
      paymentMethodId,
      planId,
      email,
      paidTrialPlanId
    }).then((response) => {
        return response;
    }).catch(error => {
        return error.response;
    });
};

export const postBizzOffersCookie = (host, transaction_id, price, goal_id) => {
    return axios.get(`${host}/GPFwB?transaction_id=${transaction_id}&amount=${price}&adv_sub=${transaction_id}_initial&goal_id=${goal_id}`
    ).then((response) => {
        return response;
    }).catch(error => {
        return error.response;
    });
};

export const googleAnalyticsStoreApi = (email, ga, source, campaign, medium) => {
  return axios.post(`${rootUrl}/api/v3/google-analytics-storage`, {
    email: email,
    clientId: ga,
    funnel: 'ymp',
    source,
    campaign,
    medium
  }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
}

export const facebookConversionApi = (
    eventName,
    sourceUrl,
    timestamp,
    price,
    fbpId,
    email,
    firstName,
    birthDate,
    gender,
) => {
    return axios.post(`${rootUrl}/api/v3/conversion/layout/event-push`, {
        service: '1',
        data: {
          eventName: eventName,
          sourceUrl: sourceUrl,
          funnelType: 'meal-plan',
          timestamp: timestamp,
          currency: 'USD',
          price: price,
          fbp: fbpId,
          email: email,
          firstName: firstName,
          birthDate: birthDate,
          gender: gender
        }
    }).then((response) => {
        return response
    }).catch(error => {
        return error.response;
    })
}


export const upSellPayment = (planId) => {
  const token = Cookies.get('authToken');
  return axios.post(`${rootUrl}/api/v3/payment/create-upsell`, {
    planId,
  }, {
    headers: {
      'Platform': 'ios',
      'Authorization': token
    },
  }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
};

export const getDeepLink = (authToken) => {
  return axios.post(`${rootUrl}/api/v3/deep-link/layout/token`, {},
    {
      headers: {
        'Platform': 'ios',
        'Authorization': authToken
      }
    }).then((response) => {
    return response;
  }).catch(error => {
    return error.response;
  });
};

export const getWebRemoteConfig = (branchId, config, utmParams) => {
  return axios.get(`${rootUrl}/api/v3/remote-config${config}?branch=${branchId}&funnel=ymp${utmParams}`).then((response) => {
      return response;
    }).catch(error => {
      return error.response;
    });
};

