import React from "react";
import { useSelector } from "react-redux";

import {
    PopupWrapper,
    PopupBlock,
    PopupTitle,
    ClosePopup
} from './styled'
import { PayCard } from "../../PayCard";

const PaymentPopupComponent = ({...props}) => {
    const { email } = useSelector((state) => state.profile);

    return (
        <PopupWrapper>
            <PopupBlock>
                <PopupTitle>Add a new payment card</PopupTitle>
                <PayCard
                    email={email}
                    buttonText={'Pay'}
                    handler={props.handle()}
                />
            </PopupBlock>
            <ClosePopup onClick={props.handle()}>&times;</ClosePopup>
        </PopupWrapper>
    )
}

export default PaymentPopupComponent;