import { transformWeight } from 'utils';

const isValidTargetWeight = (current, target, minDiff, maxDiff) => {
  const difference = Math.abs(target - current);

  if (difference < minDiff) {
    return false;
  }
  if (difference > maxDiff) {
    return false;
  }

  return true
}

const validate = (values) => {
  const errors = {};

  const minWeight = 'imperial' === values.measure ? 90 : 40;
  const maxWeight = 'imperial' === values.measure ? 550 : 250;

  const minDifference = 'imperial' === values.measure ? 6 : 3;
  const maxDifference = 'imperial' === values.measure ? 88 : 40;

  const current = transformWeight(values.measure, values.currentMeasure, values.currentWeight);

  if (!values || !values.weight) {
    errors.weight = 'required'
  } else if (isNaN(Number(values.weight))) {
    errors.weight = 'number'
  } else if (Number(values.weight) < minWeight) {
    errors.weight = 'count_min'
  } else if (Number(values.weight) > maxWeight) {
    errors.weight = 'count_max'
  } else if (!isValidTargetWeight(current, values.weight, minDifference, maxDifference)) {
    errors.weight = 'difference'
  } else if (Number(values.weight) >= current) {
    errors.weight = 'error'
  }

  return errors;
}

export default validate;
