import React from 'react';
import {useTimer} from 'react-timer-hook';
import { useDispatch } from "react-redux";

import {addZero} from 'utils';
import { changeDataProgress } from "store/actions";

const TimerComponent = ({expiryTimestamp}) => {
  const dispatch = useDispatch();
  const {
    seconds,
    minutes,
  } = useTimer({
    expiryTimestamp, onExpire: () => {
      dispatch(changeDataProgress({ timer: 'end' }));
    }
  });

  return (
    <span>{addZero(minutes)}:{addZero(seconds)}</span>

  );
}

export default TimerComponent;
