import React from 'react';
import Choice from 'components/Choice';
import { ButtonComponent as Button } from 'components/Button';
import ErrorFormRequired from 'components/ErrorFormRequired';
import {
  WrapButton,
  Title,
  Wrapper,
  Items,
  Item,
  ErrorForm,
  Subtitle
} from 'components/Quiz/styled';
import { useTranslation } from 'react-i18next';

const ChoiceQuizComponent = ({ title, subtitle, items, imagePath, reset, handleSelect, resetSelected, formRequired, disabled }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Items>
        {items.map((item, count) => <Item key={count}>
          <Choice
            title={item.title}
            type='checkbox'
            value={item.name}
            name={item.name}
            imageName={imagePath ? `${imagePath}/${item.name}.svg` : null}
            disabled={disabled ? disabled(item.name) : null}
            handleSelect={handleSelect}/>
        </Item>)}
        {reset && <Item>
          <Choice
            title={reset.title}
            handleSelect={resetSelected}
            type='checkbox'
            value={reset.name}
            name={reset.name}/>
        </Item>}
        <ErrorForm>
          <ErrorFormRequired id={'error'} style={{opacity: formRequired ? 1 : 0}}>{t('errors.requiredSelect')}</ErrorFormRequired>
        </ErrorForm>
      </Items>
      <WrapButton>
        <Button title={t('messages.continue')} type='submit'/>
      </WrapButton>
    </Wrapper>
  )
}

export default ChoiceQuizComponent