import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Timer from 'components/Timer';
import {
  ReachGoalAndLoseTitle,
  ReachGoalAndLoseSubTitle,
  Discount,
  DiscountTitle,
  DiscountValue,
  DiscountText,
  DiscountGiftIcon,
  DiscountPrice,
  DiscountPriceValue,
  DiscountPriceText
 } from './styled';
import { PaymentContainer } from 'pages/Payment/styled';
import { useDispatch, useSelector } from 'react-redux';
import { createTime } from 'utils';
import { replaceString } from 'utils';
import { useIsAppVersion } from 'customHooks';
import { changeDataProgress } from 'store/actions';
import { useLocation } from 'react-router-dom';

const ReachGoalAndLose = ({ getDiffWeight }) => {
  const { t } = useTranslation();
  const { progress, remoteConfig, paymentPlan } = useSelector((state) => state);
  const { timer } = progress || {};
  const { chooseYourPlan } = paymentPlan || {};
  const { discountPrice, defaultPrice } = chooseYourPlan || {};
  const isV4 = useIsAppVersion('v4');
  const { checkoutPage } = remoteConfig || {};
  const {
    mainH1,
    mainH2,
  } = checkoutPage?.header || {};
  const {
    defaultPriceCost,
    discountPriceCost
  } = checkoutPage?.priceBlock || {};
  const generalDiscountPrice = (isV4 && chooseYourPlan) ? discountPrice : discountPriceCost;
  const generalDefaultPrice = (isV4 && chooseYourPlan) ? defaultPrice : defaultPriceCost;
  const isEmpty = !generalDiscountPrice || !generalDefaultPrice;
  const isDiscountTime = 'end' !== timer;
  const location = useLocation();
  const dispatch = useDispatch();
  let url = location.search;
  const urlEmail = url && url.includes('utm_medium=email');

  useEffect(() => {
    urlEmail && dispatch(changeDataProgress({ timer: null }));
  }, [urlEmail]);

  useEffect(() => {
    (timer === null) && ('end' !== timer) && dispatch(changeDataProgress({ timer: +createTime() }));
  }, [timer]);

  const getTimerBlock = () => {
    if (null !== timer && 'end' !== timer) {
      return (
        <>
          <Timer expiryTimestamp={timer}/>
        </>
      );
    }
    return (
      t('discountExpires.text')
    );
  };

  const getTitle = () => {
    return replaceString(mainH1 ?? '', [{
      what: '{diffWeight}',
      forWhat: `${getDiffWeight()}`
    }]);
  };

  const getSubtitle = () => {
    return replaceString(mainH2 ?? '', [{
      what: '{diffWeight}',
      forWhat: `${getDiffWeight()}`
    }]);
  };

  const getDiscountBlock = () => {
    const title = t('payment.reachGoalAndLose.discountBlock.title');
    const value = t('payment.reachGoalAndLose.discountBlock.value');
    const text = t('payment.reachGoalAndLose.discountBlock.text');
    const priceText = t('payment.reachGoalAndLose.discountBlock.priceText');
    const timerTitle = t('payment.reachGoalAndLose.discountBlock.timerTitle');

    return (
      <Discount>
        <DiscountGiftIcon
          src='/images/payment/gift.png'
        />
        <DiscountTitle>
          {isDiscountTime ? `${title} ${timerTitle}` : title}
        </DiscountTitle>
        <DiscountPrice>
          {(timer === 'end' || isEmpty)  ? (
            <DiscountPriceValue style={{ textDecoration: 'none' }}>{(generalDefaultPrice || generalDiscountPrice) && `$${generalDefaultPrice ? generalDefaultPrice : generalDiscountPrice}`} </DiscountPriceValue>
          ) : (
            <>
              <DiscountPriceValue>{generalDefaultPrice && `$${generalDefaultPrice}`} </DiscountPriceValue>
              <DiscountPriceText style={{ margin: '0 5px' }}>{priceText} </DiscountPriceText>
              <DiscountPriceValue>{generalDiscountPrice && `$${generalDiscountPrice}`}</DiscountPriceValue>
            </>
          )}
        </DiscountPrice>
        {isDiscountTime && (
          <DiscountValue>
            {value} {getTimerBlock()}
          </DiscountValue>
        )}
        <DiscountText>
          {text}
        </DiscountText>
      </Discount>
    );
  };
  return (
    <PaymentContainer>
      <ReachGoalAndLoseTitle>
        {getTitle()}
      </ReachGoalAndLoseTitle>
      <ReachGoalAndLoseSubTitle>
        {getSubtitle()}
      </ReachGoalAndLoseSubTitle>
      {isDiscountTime ? getDiscountBlock() : ''}
    </PaymentContainer>
  );
};

export default ReachGoalAndLose;