import React, { useState, useEffect } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { subscribePay } from 'api';
import { Logo, PaymentMethodsContentCard } from '../PaymentMethodsContent/styled';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { errorNotification, isNullish } from 'utils';
import { sendGAStore } from 'analytics/backend';
import { setProfileEmail, setPaymentMethodState } from 'store/actions';
import Cookies from 'js-cookie';
import { saveOnboard } from 'utils/saveOnbord';
import { useIsAppVersion } from 'customHooks';

export const ApplePay = ({ isToggled, handleClick }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const isV3 = useIsAppVersion('v3');
  const isV4 = useIsAppVersion('v4');
  const { paymentPlan, progress, remoteConfig } = useSelector((state) => state);
  const { timer } = progress || {};
  const { paidTrial, chooseYourPlan } = paymentPlan || {};
  const { checkoutPage } = remoteConfig || {};
  const {
    defaultSubscriptionSlug,
    discountSubscriptionSlug,
    defaultSubscription,
    discountSubscription,
  } = checkoutPage || {};
  const {
    discountFullPrice,
    defaultFullPrice,
  } = checkoutPage?.priceBlock || {};
  const {
    discountFullPrice: chooseYourPlanDiscountPrice,
    defaultFullPrice: chooseYourPlanDefaultPrice,
    defaultPlanId,
    discountPlanId
  } = chooseYourPlan || {};
  const isChooseYourPlan = !isNullish(chooseYourPlan) && isV4;
  const generalDiscountPlanId = isChooseYourPlan ? discountPlanId : discountSubscription;
  const generalDefaultPlanId = isChooseYourPlan ? defaultPlanId : defaultSubscription;
  const generalDefaultPriceCost = isChooseYourPlan ? chooseYourPlanDefaultPrice : defaultFullPrice;
  const generalDiscountPriceCost = isChooseYourPlan ? chooseYourPlanDiscountPrice : discountFullPrice;
  const isDiscountTime = 'end' !== timer;
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('applePay');
  const url = window.location.pathname;

  const { plan_id: paidTrialPlanId, price: paidTrialPrice } = paidTrial ?? {};

  const getPrice = () => {
    if (!paidTrialPrice) {
      const isEmpty = !generalDiscountPriceCost || !generalDefaultPriceCost;
      return isDiscountTime && !isEmpty
        ? generalDiscountPriceCost
        : (generalDiscountPriceCost ? generalDiscountPriceCost : (generalDefaultPriceCost ? generalDefaultPriceCost : 1));
    }
    return paidTrialPrice;
  };

  const getSlug = () => {
    const isEmpty = !discountSubscriptionSlug || !defaultSubscriptionSlug;
    return isDiscountTime && !isEmpty
      ? discountSubscriptionSlug
      : (discountSubscriptionSlug ? discountSubscriptionSlug : defaultSubscriptionSlug);
  };

  const getPlanId = () => {
    const isEmpty = !generalDiscountPlanId || !generalDefaultPlanId;
    return isDiscountTime && !isEmpty
      ? generalDiscountPlanId
      : (generalDiscountPlanId ? generalDiscountPlanId : generalDefaultPlanId);
  };

  const price = getPrice();
  const slug = getSlug();
  const planId = getPlanId();
  const handlePaymentMethodReceived = async (event) => {
    const paymentDetails = {
      paymentMethodId: event.paymentMethod.id,
      planId,
      email: event.payerEmail,
      paidTrialPlanId: paidTrialPlanId ?? null
    };
    await sendGAStore(event.payerEmail);
    dispatch(setProfileEmail({ email: event.payerEmail }));
    const response = await subscribePay(paymentDetails);

    if(200 !== response.status) {
      event.complete('fail');
      errorNotification(response.data.message);
      return;
    }

    const { authToken } = response.data.data;
    Cookies.set('authToken', authToken);
    Cookies.set('price', price);
    saveOnboard(authToken);
    event.complete('success');
    sendAmplitudeEvent(`y_${event.walletName}_sub_started`, { YMP_Price: price });
    '/v4/payment' === url ? history.push('payment-successful', { authToken, price }) : history.push('upsell/1');
  };

  useEffect(() => {
    if (!stripe || !elements || ((isV3 || isV4) && !paidTrial)) {
      return;
    }
    if (stripe) {
      const amount = Math.round((price*100).toFixed(0));
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: slug,
          amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pr.canMakePayment().then((result) => {
        if (result.applePay || result.googlePay) {
          setPaymentMethod(result.applePay ? 'applePay' : 'googlePay');
          dispatch(setPaymentMethodState(result.applePay ? 'applePay' : 'googlePay'));
          pr.on('paymentmethod', handlePaymentMethodReceived);
          setPaymentRequest(pr);
        }
      }).catch(error => console.log(error));
    }
  }, [stripe, elements, paidTrial?.price, chooseYourPlan?.discountPlanId, timer]);

  const appleButtonClick = () => {
    handleClick();
    if (paymentRequest) {
      paymentRequest.show();
    }
  };

  const icon = `/images/payment/feedbacks/${'applePay' === paymentMethod ? 'apple-pay' : 'google-pay'}.svg`;
  return (
    <>
      {paymentRequest ? (
        <PaymentMethodsContentCard isToggled={isToggled} onClick={appleButtonClick}>
          <Logo src={icon} method={paymentMethod}/>
        </PaymentMethodsContentCard>
      ) : null}
    </>
    );
};
