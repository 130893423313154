import styled from "styled-components";

export const ComponentWrapper = styled.div`
  width: 100%;
  max-width: 602px;
  margin: auto;
  padding: 30px 16px;
  @media (min-width: 960px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const ComponentTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  @media (min-width: 960px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const ComponentItems = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  @media (min-width: 960px) {
    padding-top: 30px;
    padding-bottom: 4px;
  }
`;
