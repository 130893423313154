import styled from 'styled-components';
import Slider from 'react-slick';
import { THEME } from 'THEME';

export const Dot = styled.div`
  bottom: -60px;
  & li {
    list-style: none;
    background-color: ${THEME.COLOR_BROWN};
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 6px;
    padding: 0;
  }
  & li button {
    border: none;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }
  & li.slick-active button {
    background-color: ${THEME.COLOR_LIGHT_GREEN};
  }
  & li button:before, & li.slick-active button:before{
    color: transparent;
    height: 8px;
    width: 8px;
  }
  & li button:hover,
  & li button:focus {
    background: ${THEME.COLOR_LIGHT_GREEN};
    outline: 0;
  }
`

export const SliderItemWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  @media(max-width: 450px) {
    height: 740px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`

export const SliderItemImage = styled.img`
  max-width: 200px;
  max-height: 390px;
  object-fit: cover;
`

export const SliderItemTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
  max-width: 300px;
  margin-bottom: 20px;
  @media(max-width: 960px) {
    margin-left: 10px;
  }
`

export const SliderItemTitle = styled.h2`
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: ${THEME.COLOR_BLACK_ALT};
`

export const SliderFirstText = styled.p`
  margin-bottom: 24px;
`

export const SliderSecondText = styled.p`
  margin-bottom: 24px;
`

export const CustomDot = styled.span`
  font-weight: 500;
  font-size: 36px;
  line-height: 39px;
`

export const CustomDotIndex = styled.span`
  position: absolute;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  top: -16px;
  left: 2px;
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 100px 0;
  @media(max-width: 960px) {
    padding: 80px 0;
  }  
  .slick-dots {
    top: 0;
    left: -500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media(max-width: 1200px) {
      left: -400px;
    }
    @media(max-width: 1100px) {
      left: -300px;
    }
  }
  .slick-dots li {
    width: 100%;
    height: 100%;
    max-height: 80px;
    display: flex;
    justify-content: start;
  }
  .slick-slider .slick-arrow {
    display: none !important;
  }
  ${CustomDot}, ${CustomDotIndex} {
    color: ${props => props?.dotColor ?? '#4C4C4C'};
  }
  ${SliderItemTitle}, ${SliderFirstText}, ${SliderSecondText} {
    color: ${props => props?.textColor ?? 'inherit'};
  }
  background-color: ${props => props?.backgroundColor ?? 'unset'};
  .slick-dots li ${CustomDot} {
    font-weight: 500;
    font-size: 36px;
    line-height: 39px;
    @media(max-width: 1100px) {
      font-size: 30px;
    }
  }
  .slick-dots li:before {
    position: absolute;
    left: -40px;
    top: 15px;
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
  }
  li.slick-active:before {
    background-color: ${THEME.COLOR_LIGHT_GREEN};
  }
  li.slick-active ${CustomDot}, li.slick-active ${CustomDotIndex} {
    color: ${THEME.COLOR_LIGHT_GREEN}
  }

  @media(max-width: 450px) {
    .slick-track {
      height: 750px;
    }
    ${SliderItemTitle} {
      margin-top: 24px;
      align-self: center;
    }
    ${SliderItemTextWrapper} {
      height: 352px;
      flex: 1;
    }
  }
`

export const LastaSlickSlider = styled(Slider)`
  max-height: 450px;
  max-width: 600px;
  margin: 20px 20px 0 auto;
  z-index: 2;
  @media(max-width: 1200px) {
    margin: 20px 60px 0 auto;
  }
  @media(max-width: 960px) {
    margin: 20px auto 0 auto;
    padding: 16px;
  }
  @media(max-width: 450px) {
    max-height: 1000px;
    margin: 20px auto 0 auto;
  }
`

export const Title = styled.div`
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props?.titleColor ?? THEME.COLOR_BLUE};
  padding: 0 16px;
  margin: 0 auto 0 0;
  @media(max-width: 1170px) {
    margin: 0 auto;
  }
  @media(min-width: 960px) {
    max-width: 960px;
  }
  @media(max-width: 960px) {
    max-width: 530px;
    margin: 0 auto 30px auto;
  }
`

export const SliderWrapper = styled.div`
  width: 100%;
  background-color: ${props => props?.backgroundColor ?? 'unset'};
`

