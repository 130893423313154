import React from 'react';
import { useTranslation } from 'react-i18next';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { createMarkup } from 'utils';
import {
  Reviews,
  ReviewsWrapper,
  ReviewsTitle,
  ReviewsItems,
  ReviewsItem,
  ReviewsItemTop,
  ReviewsItemImageWrap,
  ReviewsItemImage,
  ReviewsItemName,
  ReviewsItemText,
  ReviewsImageWrapper,
  ReviewsImage,
  ReviewImageTitle,
  ReviewImageDescription,
  AppStoreLogo,
  DisclaimerText
} from './styled';
import { gaFirstClick } from 'analytics/ga';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ReviewsComponent = ({ isOverallReviewPage }) => {
  const history = useHistory();
  const location = history.location.pathname;

  const { t } = useTranslation();
  const result = isOverallReviewPage ? t(`reviews.items2`, { returnObjects: true }) : t(`reviews.items`, { returnObjects: true });
  const reviewTitle = isOverallReviewPage ? t('reviews.title') : t('reviews.title2');
  const appVersion = useSelector((state) => state.appVersion);

  const goToQuiz = (eventName) => {
    gaFirstClick();
    sendAmplitudeEvent(eventName);
    const URL = `${appVersion}/gender`;
    history.push(URL);
  };

  const sendAnalytics = () => {
    sendAmplitudeEvent('lp_appstore');
  };
  
  const getSuitableBlock = () => {
    if (location !== '/overall-review') {
      return (
        <>
          <ReviewsImageWrapper>
            <ReviewsImage
              src='/images/reviews/vegetables.png'
              srcSet='/images/reviews/vegetables.png 320w, /images/reviews/vegetables@2x.png 480w'
              loading='lazy'
            />
            <div>
            <ReviewImageTitle>
              {t('reviews.image.title')}
            </ReviewImageTitle>
            <ReviewImageDescription dangerouslySetInnerHTML={{ __html: t('reviews.image.description') }}/>
            <a href='https://yourmeal.onelink.me/aMaD/90f1ae70'>
              <AppStoreLogo
                style={{ margin: '24px auto', display: 'flex' }}
                src='/images/reviews/appStore.png'
                srcSet='/images/reviews/appStore.png 320w, /images/reviews/appStore@2x.png 480w'
                onClick={sendAnalytics}
                loading='lazy'
              />
            </a>
            </div>
          </ReviewsImageWrapper>
        </>
      )
    }
  };

  return (
    <Reviews>
      <ReviewsWrapper>
        <ReviewsTitle center={!isOverallReviewPage} dangerouslySetInnerHTML={{ __html: reviewTitle }}/>
        <ReviewsItems>
          {result.map((elem, count) =>
            <ReviewsItem key={count}>
              <ReviewsItemTop>
                <ReviewsItemImageWrap>
                  <ReviewsItemImage
                    src={`/images/reviews/${elem.image}`}
                    loading='lazy'
                  />
                </ReviewsItemImageWrap>
                <ReviewsItemName>{elem.name}</ReviewsItemName>
              </ReviewsItemTop>
              <ReviewsItemText dangerouslySetInnerHTML={createMarkup(t(elem.text))}/>
            </ReviewsItem>)}
        </ReviewsItems>
        {!isOverallReviewPage && <DisclaimerText>{t('disclaimer')}</DisclaimerText>}
      </ReviewsWrapper>
    </Reviews>
  )
}

export default ReviewsComponent;
