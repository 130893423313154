import Cookies from 'js-cookie';
import { postBizzOffersCookie } from 'api';

const cookieName = '_aff_tds';
export const setInitialBizzOffersCookie = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (!Cookies.get(cookieName) && urlParams.get('aff_id')) {
    const transactionId = urlParams.get('transaction_id');
    Cookies.set(cookieName, transactionId);
  }
};

export const sendBizzOffersCookie = (price) => {
  const transactionId = Cookies.get(cookieName);
  const goalId = 1551;
  const host = new URL(window.location.href).origin;
  if (transactionId) {
    postBizzOffersCookie(host, transactionId, price, goalId).then(res => {
      if (200 === res.status) {
        Cookies.remove(cookieName);
      }
    });
  }
};