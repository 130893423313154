import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { ButtonComponent as Button} from 'components/Button';
import { useHistory } from 'react-router-dom';
import {
  AchievementParent,
  AchievementWrapper,
  AchievementWrapButton,
  AchievementTextWrapper,
  AchievementImage
} from './styled';
import { useTranslation } from 'react-i18next';
import { changeDataProgress } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';

export const Achievement = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const content = t(`achievement.items`, {returnObjects:true});
  const id = history.location?.state?.id ?? 0;
  const flowId = history.location?.state?.flowId ?? 10;
  const { appVersion } = useSelector((state) => state);

  useEffect(() => {
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    }
  }, []);

  useEffect(() => {
    const event = `${flowId}_Achievement_${id+1}_view`;
    sendAmplitudeEvent(event);
  }, []);

  const goNextPage = () => {
    const event = `${flowId}_Achievement_${id+1}_click`;
    sendAmplitudeEvent(event);

    switch (id){
      case 0: {
        history.push(`${appVersion}/forecast`, { id: 0, flowId: 11 });
        break;
      }
      case 1: {
        history.push(`${appVersion}/weight-gain`, { id: 0 });
        break;
      }
      case 2: {
        history.push(`${appVersion}/event-name`);
        break;
      }
      case 3: {
        history.push(`${appVersion}/emotional-comfort`);
        break;
      }
      case 4: {
        history.push(`${appVersion}/forecast`, { id: 2, flowId: 48 });
        break;
      }
    }
  };

  const {title, image, image2x} = content[id];
  const width = id <= 2 ? 122 : 36;
  const height = id <= 2 ? 188 : 36;

  return (
    <AchievementParent id={'expand'}>
      <AchievementWrapper>
        <AchievementImage
          src={`/images/achievement/${image}`}
          srcSet={`/images/achievement/${image} 320w, /images/achievement/${image2x} 480w`}
          width={width}
          height={height}
          loading='lazy'
          alt='achievement'
        />
        <AchievementTextWrapper dangerouslySetInnerHTML={{__html: title}}/>
        <AchievementWrapButton>
          <Button title={t('messages.continue')} type='submit' onClick={goNextPage}/>
        </AchievementWrapButton>
      </AchievementWrapper>
    </AchievementParent>
    );
};
