import styled from "styled-components";

export const SettingsWrapper = styled.div`
    background: #F6F6FC;
    display: flex;
    min-height: 85vh;
`;

export const SubscriptionLayout = styled.div`
    margin: 0 auto;
    position: relative;
    height: 100%;
`;

export const SubscriptionHeader = styled.h2`
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #191B1F;
    margin: 0 0 20px 0;
`;

export const SubscriptionText = styled.p`
    color: #3F5366;
    font-size: 20px;
    text-align: center;
    margin: 5px 0;
`;

export const SubscriptionLogoImage = styled.img`

`;

export const SubscriptionPremiumText = styled.p`
    font-size: 13px;
    text-align: center;
    color: #3F5366;
    margin: 10px 0;
    text-transform: uppercase;
`;

export const SubscriptionTotalText = styled.p`
    font-size: 16px;
    color: #191B1F;
    font-weight: 700;
    text-align: center;
    margin: 5px 0;
`;

export const SubscriptionPriceText = styled.p`
    font-size: 30px;
    color: #191B1F;
    font-weight: 700;
    text-align: center;
`;

export const SubscriptionSmallText = styled.p`
    font-size: 16px;
    color: #3F5366;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
`;

export const SubscriptionContent = styled.div`
    height: 100%;
    margin: 50px auto;
    width: 70%;
`

export const SubscriptionLogoImageBlock = styled.div`
    text-align: center;
    margin: 30px 0 0 0;
`

export const ButtonWrapper = styled.div`
    margin: 35px auto;
    width: 40%;
`

export const SubscriptionPremiumBlock = styled.div`
    text-align: center;
    margin: 10px;
`

export const SubscriptionTrialLabel = styled.p`
    background: #0ABFB6;
    color: #fff;
    font-size:13px;
    padding:7px 15px;
    display: inline;
    border-radius: 27.2px;
`

export const SubscriptionSuccessImage = styled.img`
`

export const SubscriptionNextPaymentBlock = styled.div`
`

export const SubscriptionBoldText = styled.p`
    color: #191B1F;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0 0 0;
`

export const SubscriptionError = styled.div`
    color: red;
    height: 20px;
    text-align: center;
    margin: 20px 0;
`
