import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getRandomInt, isNullish } from 'utils';
import { calculateOnboardResult, getPaymentPlansId } from 'api';

import {
  DataProcessing,
  DataProcessingWrapper,
  DataProcessingTitle,
  DataProcessingPreTitle,
  Linear,
  LinearPercent,
  LinearLine,
  LinearFilling,
  LinearText
} from './styled';
import { setOnboardResult } from 'store/actions/onboardResult';
import { useIsAppVersion } from 'customHooks';
import { changePaymentPlan } from 'store/actions';
import config from 'config';

const DataProcessingComponent = ({afterLoading}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  const { remoteConfig, profile, paymentPlan } = useSelector((state) => state);
  const isV4 = useIsAppVersion('v4');
  const isV3 = useIsAppVersion('v3');
  const { subscriptionCardsBlock, paidTrialBlock } = remoteConfig?.checkoutPage || {};
  const { prices } = paidTrialBlock || [];
  const {
    age: birthday,
    gender,
    desiredWeight: desired,
    currentWeight: current,
    measure,
    height: peak,
    heightInches: inches,
    workoutType
  } = profile || {};

  const sex = ('male' ===  gender ? 'male' : 'female') ?? {};
  const desiredWeight = desired ? Math.round(desired) : '';
  const currentWeight = current ? Math.round(current) : '';
  const height = peak ? Math.round(peak) : '';
  const heightInches = inches ? Math.round(inches) : '';
  const age = birthday ? (Number(birthday) > 80 ? '80' : birthday) : '';

  const itemsProgress = t(`dataProcessing.items`, { returnObjects: true });
  const getChooseYourPlan = () => {
    if (isV4 && !isNullish(subscriptionCardsBlock?.cards) && !paymentPlan?.chooseYourPlan) {
      dispatch(changePaymentPlan({
        chooseYourPlan: subscriptionCardsBlock?.cards[0],
      }));
    }
  };

  const pricesID = () => {
    return prices?.map(item => item.planId);
  };

  const getPaidTrial = () => {
    if ((isV4 && !isNullish(paidTrialBlock?.prices) && !paymentPlan?.paidTrial) || (isV3 && !paymentPlan?.paidTrial)) {
      const priceID = isV4 && pricesID();
      getPaymentPlansId(isV4 ? priceID : config.prices.paidTrial).then(res => {
        const result = res.prices.find(({ price: planPrice }) => planPrice === Number('16.41'));
        dispatch(changePaymentPlan({
          paidTrial: result,
        }));
      });
    }
  };

  useEffect(async () => {
    await getChooseYourPlan();
    await getPaidTrial();
    await calculateOnboardResult(
        sex,
        age,
        desiredWeight,
        measure,
        currentWeight,
        measure,
        height,
        heightInches,
        workoutType
    ).then(res => {
      if (200 === res?.status) {
        dispatch(setOnboardResult({
          after_first_week: res.data.data.after_first_week,
          bmi: res.data.data.bmi,
          chart: res.data.data.chart,
          daily_calorie_norm: res.data.data.daily_calorie_norm,
          water_minimum: res.data.data.water_minimum,
        }));
      }
    }).catch( err => console.log('err', err));
  }, []);

  useEffect(() => {
    let timer = null;

    (function loop(timer) {
      timer = setTimeout(() => {
        let yak = true;
        setProgress((prevProgress) => {

          if (prevProgress === 98) {
            clearTimeout(timer);
            yak = false;
            timer = setTimeout(() => {
              setProgress(100);
              afterLoading();
            }, getRandomInt(350, 700));
          }

          return prevProgress >= 100 ? 100 : prevProgress + 1;
        });

        if (yak) loop();
      }, 50);
    }());

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const progressSetText = () => {
    const result = itemsProgress.find((item) => item.percent >= progress);
    return result.title;
  }

  return (
    <DataProcessing>
      <DataProcessingWrapper>
        <DataProcessingTitle>{t('dataProcessing.title')}</DataProcessingTitle>
        <Linear>
          <LinearText>{progressSetText()}</LinearText>
          <LinearPercent>{progress}%</LinearPercent>
          <LinearLine>
            <LinearFilling style={{width: `${progress}%`}}/>
          </LinearLine>
        </Linear>
        <DataProcessingPreTitle>{t('dataProcessing.preTitle')}</DataProcessingPreTitle>
      </DataProcessingWrapper>
    </DataProcessing>
  )
}

export default DataProcessingComponent;
