import styled from "styled-components";
import { THEME } from "THEME";

export const OverallResults = styled.div`
  width: 100%;
  color: ${THEME.COLOR_BLACK};
  padding-top: 130px;
  @media (min-width: 991px) {
    padding-top: 160px;
  }
`;

export const OverallResultsWrapper = styled.div`
  width: 100%;
  max-width: 1002px;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
`;

export const OverallResultsTitle = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px;
  color: ${THEME.COLOR_BLACK};
  @media (min-width: 991px) {
    margin-bottom: 40px;
    font-size: 30px;
    line-height: 36px;
  }
`;

export const OverallResultsContent = styled.div`
  @media (min-width: 991px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const OverallResultsLine = styled.div`
  margin-bottom: 50px;
  @media (min-width: 991px) {
    margin-bottom: 90px;
  }
`;

export const OverallResultsChart = styled.div`
  background-color: #F8F6F0;
  border-radius: 20px;
  padding: 16px;
  width: 100%;
  max-width: 470px;
  margin-bottom: 20px;
  @media (min-width: 991px) {
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

export const OverallResultsChartPreTitle = styled.p`
  color: ${THEME.COLOR_DARK_GRAY};
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 4px;
  @media (min-width: 991px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const OverallResultsChartTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
  @media (min-width: 991px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const OverallResultsTiles = styled.div`
  width: 100%;
  max-width: 470px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 440px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const OverallResultsTile = styled.div`
  margin-bottom: 12px;
  width: 100%;
  max-width: 227px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 991px) {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 499px) {
    max-width: 200px;
  }
`;

