import React from 'react';
import {
  StronglyItem,
  StronglyItems,
  Subtitle,
  UpTitle,
  TextWrapper,
  Title,
  Text
} from 'components/Quiz/styled';
import { ComponentWrapper } from 'components/UI';
import Checkbox from 'components/Checkbox';

const StronglyQuizComponent = ({ handleSelect, uptitle, subtitle, title, name }) => {

  return (
    <ComponentWrapper>
      { uptitle && <UpTitle>{uptitle}</UpTitle>}
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <StronglyItems>
        {['1','2','3','4','5'].map((item) => <StronglyItem key={item}>
          <Checkbox title={item} type="radio" name={name} value={item} handleSelect={handleSelect}/>
        </StronglyItem>)}
      </StronglyItems>
      <TextWrapper>
        <Text>Strongly disagree</Text>
        <Text>Strongly agree</Text>
      </TextWrapper>
    </ComponentWrapper>
  )
}

export default StronglyQuizComponent;