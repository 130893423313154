import styled from "styled-components";

export const PaymentCompaniesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isActiveAdditionalStyles ? 'space-between' : 'center')};
  margin: ${(props) => (props.isActiveAdditionalStyles ? '5px 0 7px' : 0)};
`;
export const PaymentCompaniesLogo = styled.img`
  display: block;
  width: ${(props) => (props.isActiveAdditionalStyles ? '38px' : '24px')};
`;