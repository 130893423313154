import styled from "styled-components";

export const Video = styled.div`
    position: relative;
    z-index: 2;
    height: 100%;
    width: 100%;
`;

export const VideoWrapper = styled.div`
    z-index: 3;
    position: relative;
`;

export const Player = styled.div`
    display: flex;
    justify-content: center;
    max-width: 468px;
    min-width: 468px;
    max-height: 263px;
    min-height: 263px;
    position: relative;
    @media (min-width: 960px) {
      max-width: 700px;
      min-width: 700px;
      max-height: 400px;
      min-height: 400px;
    }
    @media (min-width: 1100px) {
      min-width: 700px;
      min-height: 400px;
    }
    @media (min-width: 1300px) {
      min-width: 700px;
      min-height: 400px;
    }
    @media (min-width: 1600px) {
      min-width: 800px;
      min-height: 450px;
    }
    @media(max-width: 468px) {
      min-height: 225px;
      min-width: 100%;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
    }
    @media(max-width: 400px) {
      min-height: 200px;
    }
   
  .youtube {
    position: relative;
    width: 100%;
    min-height: 100%;
    z-index: 0;
    
    &.is-show-youtube {
      z-index: 5;
    }
    
    iframe {
      border-radius: 30px;
    }
  }
`;

export const PlayerPreviewImage = styled.img`
  position: absolute;
  opacity: ${props => props.isPlayVideo ? "0" : "1"};
  width: 100%;
  height: 100%;

`

export const VideoTitle = styled.h2`
    font-weight: 800;
    font-size: 24px;
    line-height: 31px;
    color: #fff;
    text-align: center;
    max-width: 289px;
    margin: auto;
    @media (min-width: 960px) {
        max-width: 780px;
        font-size: 42px;
        line-height: 48px;
    }
`

export const VideoContent = styled.div`
  
`

export const PlayerPreviewImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

export const PlayerPreviewImageVegetables = styled.img`
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  z-index: -1;

  @media (max-width: 959px) {
    display: none;
  }
  
  @media (min-width: 960px) {
    right:353px;
    top: -138px;
  }

  @media (min-width: 1100px) {
    right: 410px;
    top: -157px;
  }
  @media (min-width: 1300px) {
    right: 505px;
    top: -180px;
  } 
  @media (min-width: 1600px) {
    right: 536px;
    display: block;
    top: -254px;
    max-width: 980px;
    max-height: 550px;
    width: auto;
    height: auto;
  }
`

export const PlayerPreviewImageBroccoli = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 220px;
  max-height: 220px;
  right: -147px;
  bottom: -14px;
  z-index: -1;
  @media (max-width: 959px) {
    display: none;
  }

`
export const PlayerPreviewImageBroccoliMobile = styled.img`
  position: absolute;
  max-width: 420px;
  height: 235px;
  left: -13px;
  top: 73px;
  z-index: -1;
  @media (min-width: 969px) {
    display: none;
  }
`


export const VideoTitleSecondText = styled.p`
  font-size: 24px;
  line-height: 31px;
  color: #7E80F7;
  @media (min-width: 960px) {
    font-weight: 800;
    font-size: 42px;
    line-height: 48px;
  }
`

export const VideoTitleThirdText = styled.p`
  font-size: 16px;
  line-height: 31px;
  color: #191B1F;
  @media (min-width: 960px) {
    font-weight: 800;
    font-size: 24px;
    line-height: 48px;
  }
`
