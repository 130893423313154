const validate = values => {
  const errors = {};

  if (!values || !values.calories) {
    errors.calories = 'required';
  } else if (values.calories > 5000 || values.calories < 1200) {
    errors.calories = 'error';
  }

  return errors;
}

export default validate;
