import React from 'react';

import {Loading, LoadingImage} from './styled';

const LoadingComponent = () => {
  return (
    <Loading>
      <LoadingImage src={`/images/loading.gif`}/>
    </Loading>
  )
}

export default LoadingComponent
