import React from 'react';
import {
  BenefitsWrapper,
  StatisticsCaption,
  StatisticsContent,
  StatisticsNumber,
  StatisticsWrapper,
  Wrapper,
  VideoContent, VideoContentCaption
} from './styled';
import { Title } from 'components/Landing/styled';
import { useTranslation } from 'react-i18next';
import { Video } from 'components/Landing';

const Benefits = () => {
  const { t } = useTranslation();
  const statistics = t('landingV4.benefits.items', { returnObjects: true });

  return(
    <BenefitsWrapper>
      <Wrapper>
        <Title>{t('landingV4.benefits.title')}</Title>
        <VideoContent>
          <Video/>
          <VideoContentCaption>{t('landingV4.benefits.videoCaption')}</VideoContentCaption>
        </VideoContent>
        <StatisticsWrapper>
          <StatisticsContent>
            {statistics.map(({number, text}, index) => {
              return(
                <li key={index}>
                  <StatisticsNumber>{number}</StatisticsNumber>
                  <StatisticsCaption>{text}</StatisticsCaption>
                </li>
              )
            })}
          </StatisticsContent>
        </StatisticsWrapper>
      </Wrapper>
    </BenefitsWrapper>
  );
};

export default Benefits;