import { saveOnboardData } from 'api';
import store from 'store';

export const saveOnboard = (authToken) => {
  const { profile } = store.getState();

  const {
    gender,
    name,
    birthDate,
    currentWeight,
    desiredWeight,
    measure,
    height,
    heightInches,
    workoutType,
    drinkWater,
    diet,
    dietaryRestrictions,
    timeForCooking,
    calories,
    mealsPerDay,
    motivationEvent,
    motivationEventName,
    motivationEventDate,
  } = profile;

  saveOnboardData(
    name,
    birthDate,
    height,
    heightInches,
    currentWeight,
    desiredWeight,
    gender,
    motivationEvent,
    motivationEventName,
    motivationEventDate,
    mealsPerDay,
    drinkWater,
    timeForCooking,
    dietaryRestrictions,
    calories,
    diet,
    workoutType,
    measure,
    authToken
  ).catch(err => console.log(err.message));
};