import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { ButtonComponent as Button } from 'components/Button';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { getMonthForProgram } from 'utils';
import { AnaliticsGoalGraph } from 'components/AnaliticsGoalGraph';
import { useHistory } from 'react-router-dom';
import {
  ForecastWrapper,
  SubTitle,
  StyledTitle,
  ResultTitle,
  ReviewTitle,
  ForecastWrapButton
} from './styled';
import { scrollPageToTop } from 'utils';
import { GRAPH_IMAGE_DATA } from 'components/AnaliticsGoalGraph/graphImageData';
import { ReviewContent } from 'components/ReviewContent/reviewContent';
import { useTranslation } from 'react-i18next';
import { changeDataProgress } from 'store/actions';

export const Forecast = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { desiredWeight, currentWeight  } = useSelector((store) => store.profile);
  const { appVersion } = useSelector((state) => state);
  const subtractWeight = currentWeight && desiredWeight ? currentWeight - Math.round(desiredWeight) : 'some';
  const images = GRAPH_IMAGE_DATA.forecast;
  const history = useHistory();
  const id = history.location?.state?.id ?? '0';
  const flowId = history.location?.state?.flowId ?? 10;

  const { month, year, day } = getMonthForProgram(currentWeight, desiredWeight, id);

  const getForecastContent = (id) => {
    const forecastContent = {};
    switch (id){
      case 0:{
        forecastContent.link = `${appVersion}/discomfort`;
        forecastContent.subtitleDate = `${month} ${year}`;
        forecastContent.title = 'The only weight loss program you’ll ever need';
        break;
      }
      case 1:{
        forecastContent.link = `${appVersion}/health-condition`;
        forecastContent.subtitleDate = `${month} ${day}`;
        forecastContent.title = 'Great! We\'re sure you can do even better than you think!';
        break;
      }
      case 2:{
        forecastContent.link = `${appVersion}/loading-results`;
        forecastContent.subtitleDate = `${month} ${day}`;
        forecastContent.title = 'Your Meal Plan will help you reach your goals faster!';
        break;
      }
    }
    return forecastContent;
  };

  const { link, subtitleDate, title } = getForecastContent(id);

  const date = {
    month,
    year,
  };

  const measure =  useSelector((store) => store.form?.currentWeight?.values.measure);
  const measureValue = 'imperial' === measure ? 'lbs' : 'kg';
  const subtitleText = `${subtractWeight} ${measureValue} by ${subtitleDate}`;

  useEffect(() => {
    const eventView = `${flowId}_Forecast_screen_${id+1}_view`;
    sendAmplitudeEvent(eventView);
  }, []);

  useEffect(() => {
    scrollPageToTop();
    dispatch(changeDataProgress({ visible: false }));
    return () => {
      dispatch(changeDataProgress({ visible: true }));
    };
  }, []);

  const goNextPage = () => {
    const eventClick = `${flowId}_Forecast_screen_${id+1}_click`;
    sendAmplitudeEvent(eventClick);
    history.push(link);
  };

  return (
    <ForecastWrapper>
      <StyledTitle title={title} />
      <SubTitle title='Based on your answers and personal goals, you can reach your goal weight of' />
      <ResultTitle title={subtitleText} />
      <AnaliticsGoalGraph
        date={date}
        isShowBottomValue={false}
        images={images}
        valueTop={'10px'}
        valueLeft={'0px'}
        color={'#D75B4C'}
        currentWeight={currentWeight}
      />
      <ReviewTitle title='Customer reviews' />
      <ReviewContent />
      <ForecastWrapButton>
        <Button title={t('messages.continue')} type='submit' onClick={goNextPage}/>
      </ForecastWrapButton>
    </ForecastWrapper>
  );
};
