import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import { changeProgressStep, setProfileData } from 'store/actions';
import { CookingLong } from './styled';
import StandardQuizComponent from 'components/Quiz/StandardQuiz';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils';

const CookingLongComponent = ({ dispatch }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isV4 = useIsAppVersion('v4');
  const itemsCookingLong = t(`cookingLong.items`, { returnObjects: true });

  const handleSelect = (data) => {
    const cooking = data.target.value;
    dispatch(setProfileData({ 'timeForCooking': cooking }));
    sendAmplitudeEvent('29_click_cooking', {
      'Cooking_time': cooking
    });
    const link = isV4 ? 'eating-passed' : 'calories';
    history.push(link);
  };

  useEffect(() => {
    sendAmplitudeEvent('29_view_cooking');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, []);

  return (
    <CookingLong>
      <StandardQuizComponent
        items={itemsCookingLong}
        handleSelect={handleSelect}
        title={t('cookingLong.title')}
        subtitle={!isV4 && t('cookingLong.subtitle')}
        name={'cookingLongType'}
      />
    </CookingLong>
  );
};

export default reduxForm(
  {
    form: 'cookingLong',
    destroyOnUnmount: false
  }
)(CookingLongComponent);
