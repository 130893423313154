import React, { useState, useEffect } from "react";
import { ButtonComponent, ColorButton } from "components/Button";
import TwoButtonPopup from "components/Popup/TwoButtonPopup";
import { paymentUnsubscribe, getSubscribeInfo } from "api"
import config from "config";
import Loader from 'components/Auth/Loader'
import { sendAmplitudeEvent } from "analytics/amplitude";

import {
    SubscriptionLayout,
    SubscriptionContent,
    SubscriptionHeader,
    SubscriptionText,
    SubscriptionLogoImage,
    SubscriptionPremiumText,
    SubscriptionTotalText,
    SubscriptionPriceText,
    SubscriptionSmallText,
    SubscriptionLogoImageBlock,
    ButtonWrapper,
    SubscriptionPremiumBlock,
    SubscriptionTrialLabel,
    SubscriptionSuccessImage,
    SubscriptionNextPaymentBlock,
    SubscriptionBoldText,
    SubscriptionError
} from './styled'
import { useSelector } from "react-redux";
import { PayCard } from "../../../../PayCard";

const SubscriptionLayoutComponent = () => {
    const { email } = useSelector((state) => state.profile);
    const [loading, changeLoading] = useState(true)

    const [showPopup, updateStatePopup] = useState(false)
    const [subscriptionInfo, setSubscriptionInfo] = useState({})
    const [error, setError] = useState("");

    useEffect(() => {
        getSubscribeInfo().then(res => {
            InitialSubscriptionInfo(res.data.data)
            changeLoading(false)
        })

    }, [])

    const unsubscribe = () => () => {
        paymentUnsubscribe().then(res => {
            if (200 === res.status) {
                sendAmplitudeEvent('sub_canceled')
                updateSubscriptionInfoStatus();
            } else{
                setError("Oops…something went wrong. Please contact our Billing Team via email.");
            }
            updateStatePopup(false)
        })
    }

    const InitialSubscriptionInfo = (data) => {
        setSubscriptionInfo(data)
    }

    const getNextDate = (dateString) => {
        let currentDate = new Date(Date.parse(dateString))
        const date = new Date(currentDate.setMonth(currentDate.getMonth()+1));
        let dd = date.getDate();
        if (dd < 10) dd = '0' + dd;

        let mm = date.getMonth() + 1;
        if (mm < 10) mm = '0' + mm;

        return date.getFullYear() + '-' + mm + '-' + dd;
    }

    const updateSubscriptionInfoStatus = () => {
        setSubscriptionInfo(prev => {
            return {
                ...prev,
                is_active: !subscriptionInfo.is_active,
            }
        })
    }

    const updateSubscriptionInfo = () => {
        setSubscriptionInfo(prev => {
            return {
                ...prev,
                is_active: !subscriptionInfo.is_active,
                charged_date: getNextDate(subscriptionInfo.charged_date)
            }
        })
    }

    const hidePopup = () => () => {
        updateStatePopup(false)
    }

    const subscribe = () => {
        // if ('production' !== process.env.NODE_ENV) {
        //     Paddle.Environment.set('sandbox');
        // }
        //
        // Paddle.Setup({vendor: config.paddleVendorId});
        // Paddle.Checkout.open({
        //     product: config.paddleProductId,
        //     email: email,
        //     successCallback: (data, err) => {
        //         updateSubscriptionInfo()
        //     }
        // })
    }

    const callbackUpdateStatus = () => {
        getSubscribeInfo().then(res => {
            InitialSubscriptionInfo(res.data.data)
        })
    }

    return (
        <SubscriptionLayout>
            {loading ? <Loader /> :
            <SubscriptionContent>
                <SubscriptionHeader>Subscription</SubscriptionHeader>
                {(false === subscriptionInfo.is_active) &&
                    <SubscriptionText>You have just unsubscribed from YourMealPlan!</SubscriptionText>
                }
                { (subscriptionInfo.type === 'trial' &&  true === subscriptionInfo.is_active) &&
                    <SubscriptionText>You are currently subscribed for a trial period of YourMealPlan!</SubscriptionText>
                }
                { (subscriptionInfo.type === 'premium' &&  true === subscriptionInfo.is_active) &&
                    <SubscriptionText>You are currently subscribed for a Premium Subscription<br /> of YourMealPlan!</SubscriptionText>
                }
                <SubscriptionLogoImageBlock>
                    <SubscriptionLogoImage src={'/images/settings/logo.svg'}></SubscriptionLogoImage>
                </SubscriptionLogoImageBlock>
                { (subscriptionInfo.type === 'trial' &&  true === subscriptionInfo.is_active) &&
                    <SubscriptionPremiumBlock>
                        <SubscriptionPremiumText>Premium program</SubscriptionPremiumText>
                        <SubscriptionTrialLabel>Trial</SubscriptionTrialLabel>
                    </SubscriptionPremiumBlock>
                }
                { (subscriptionInfo.type === 'premium' &&  true === subscriptionInfo.is_active) &&
                    <SubscriptionPremiumBlock>
                        <SubscriptionPremiumText>Premium program</SubscriptionPremiumText>
                        <SubscriptionSuccessImage src={'/images/settings/success.svg'}/>
                    </SubscriptionPremiumBlock>
                }
                { (false === subscriptionInfo.is_active) &&
                    <SubscriptionPremiumBlock>
                        <SubscriptionPremiumText>Premium program</SubscriptionPremiumText>
                    </SubscriptionPremiumBlock>
                }
                <SubscriptionTotalText>Total of</SubscriptionTotalText>
                <SubscriptionPriceText>{subscriptionInfo.price}$</SubscriptionPriceText>
                {(false === subscriptionInfo.is_active) &&
                    <SubscriptionSmallText>Don’t lose time to improve your health!</SubscriptionSmallText>
                }
                { (subscriptionInfo.type === 'trial' &&  true === subscriptionInfo.is_active) &&
                    <SubscriptionNextPaymentBlock>
                        <SubscriptionSmallText>Next payment will be charged on {subscriptionInfo.charged_date}</SubscriptionSmallText>
                        <SubscriptionBoldText>Paleo diet of Your Meal Plan</SubscriptionBoldText>
                    </SubscriptionNextPaymentBlock>
                }
                { (subscriptionInfo.type === 'premium' &&  true === subscriptionInfo.is_active) &&
                    <SubscriptionNextPaymentBlock>
                        <SubscriptionSmallText>Next payment will be charged on {subscriptionInfo.charged_date}</SubscriptionSmallText>
                        <SubscriptionBoldText>Paleo diet of Your Meal Plan</SubscriptionBoldText>
                    </SubscriptionNextPaymentBlock>
                }
                {
                    showPopup
                        ? <TwoButtonPopup
                                execute={unsubscribe}
                                disable={hidePopup}
                                title='Are you sure you want to quit?'
                                text='Don’t lose a chance to become a better you with YourMealPlan!'
                            />
                        : <div></div>
                }

                {subscriptionInfo.is_active === true && (
                    <ButtonWrapper>
                        <ColorButton
                            title="Unsubscribe"
                            type="submit"
                            onClick={() => updateStatePopup(true)}
                        />
                    </ButtonWrapper>
                )}
                {error && <SubscriptionError>{error}</SubscriptionError>}

                { 'paddle' === subscriptionInfo.method && false === subscriptionInfo.is_active && (
                    <ButtonComponent
                        title="Subscribe"
                        type="submit"
                        onClick={() => subscribe()}
                    />
                )}
                { 'stripe' === subscriptionInfo.method && false === subscriptionInfo.is_active && (
                    <PayCard
                        email={email}
                        price={subscriptionInfo.price}
                        handler={callbackUpdateStatus}
                        buttonText={'Pay'}
                    />
                )}
                    <SubscriptionSmallText>If you have any billing related questions, contact billing@yourmealplan.co</SubscriptionSmallText>
            </SubscriptionContent>}
        </SubscriptionLayout>
    )
}

export default SubscriptionLayoutComponent