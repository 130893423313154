import React, {useState} from "react";
import { ButtonComponent as Button } from "components/Button";
import { changePassword } from "api"
import Input from 'components/Auth/Input';
import {successNotification, errorNotification} from "utils";

import {
    SettingsChangePassword,
    SettingsChangePasswordContent,
    SettingsChangePasswordInputWrapper,
    SettingsChangePasswordTitle,
    SettingsError,
    SettingsNotification,
    SettingsNotificationBlock
} from "./styled";

const ChangePasswordLayoutComponent = () => {
    const regexValidate = password => {
        return (/^[\w+!@#$%^&*]{8,30}$/i.test(password))
    }

    const submitForm = (e) => {
        e.preventDefault();

        const oldPassword = e.target[0].value;
        const newPassword = e.target[1].value;
        const confirmPassword = e.target[2].value;

        if (false === regexValidate(oldPassword)) {
            errorNotification('Old password not valid');
            return;
        }

        if (false === regexValidate(newPassword)) {
            errorNotification('New password not valid');
            return;
        }

        if (newPassword !== confirmPassword) {
            errorNotification('New password and confirmation password do not match');
            return;
        }

        if (oldPassword === newPassword) {
            errorNotification("Old and new password must be different");
            return;
        }

        changePassword(oldPassword, newPassword).then(res => {
            if (400 === res.status) {
                errorNotification('Password has not been changed');
                return
            }
            if (200 === res.status) {
                successNotification('Password changed successfully');
                return
            }
        })
    }

    return (
        <SettingsChangePassword onSubmit={submitForm}>
            <SettingsChangePasswordContent>
                <SettingsChangePasswordTitle>Change Password</SettingsChangePasswordTitle>
                <SettingsChangePasswordInputWrapper>
                    <Input type="password" name="old_password" placeholder="Min. 8 character" title="Old Password"/>
                    <Input type="password" name="new_password" placeholder="Min. 8 character" title="New Password"/>
                    <Input type="password" name="confirm_password" placeholder="Min. 8 character" title="Confirm New Password"/>
                </SettingsChangePasswordInputWrapper>
                <Button title="Change Password" type="submit" />
            </SettingsChangePasswordContent>
        </SettingsChangePassword>
    )
}

export default ChangePasswordLayoutComponent;

