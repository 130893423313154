import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { changeProgressStep, setProfileData } from 'store/actions';
import { sendAmplitudeEvent } from 'analytics/amplitude';
import validate from './validate';
import { CaloriesPerDay } from './styled';
import SingleInputQuiz from 'components/Quiz/SingleInputQuiz';
import { useIsAppVersion } from 'customHooks';
import { getNextProgressStep } from 'utils';

const CaloriesPerDayComponent = ({ handleSubmit, dispatch }) => {
  const { caloriesPerDay } = useSelector((state) => state.form);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const history = useHistory();
  const isV4 = useIsAppVersion('v4');

  const submitForm = (data) => {
    const calories = data.calories;
    dispatch(setProfileData({ calories }));
    sendAmplitudeEvent('30_click_calories_per_day', {
      'Calories': calories
    });
    const link = isV4 ? 'food-passed' : 'meals';
    history.push(link);
  };

  useEffect(() => {
    sendAmplitudeEvent('30_view_calories_per_day');
    dispatch(changeProgressStep(getNextProgressStep()));
  }, [])

  useEffect(() => {
    if (!caloriesPerDay.fields) {
      return;
    }

    if (!caloriesPerDay.fields.calories) {
      return;
    }

    const errorResult = validate(caloriesPerDay.values);

    if ('required' === errorResult.calories) {
      setError(() => 'Your daily calorie intake is required');
      return;
    }

    if ('error' === errorResult.calories) {
      setError(() => 'Your daily calorie intake should be between 1200 and 5000 kcal');
      return;
    }

    return setError('');
  });

  return (
    <CaloriesPerDay onSubmit={handleSubmit(submitForm)}>
      <SingleInputQuiz
        title={t('caloriesPerDay.title')}
        error={error}
        type={'number'}
        name={'calories'}
        onChange={() => setError('')}
        placeholder={'1200'}
        inputTitle={'Amount of calories'}
      />
    </CaloriesPerDay>
  );
};

export default reduxForm({
  form: 'caloriesPerDay',
  destroyOnUnmount: false,
  validate,
})(CaloriesPerDayComponent);
